import * as React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

export interface SettingItem {
  label: string;
  active: boolean;
}

export interface FiltersProps {
  items: SettingItem[];
  changeFilter: (index: number, filter: SettingItem) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column wrap',
    [theme.breakpoints.up('lg')]: {
      marginRight: theme.spacing(2),
      '& > * + *': {
        marginTop: theme.spacing(3)
      }
    },
    [theme.breakpoints.down('md')]: {
      flexFlow: 'row wrap',
      justifyContent: 'center',
      margin: `-${theme.spacing(1)}px -${theme.spacing(1)}px ${theme.spacing(8)}px`,
      '& > * ': {
        margin: theme.spacing(1),
        flex: '1 1 auto'
      }
    }
  },
  inactiveButton: {
    backgroundColor: theme.palette.common.white
  }
}));

const Filters: React.FunctionComponent<FiltersProps> = (props) => {
  const classes = useStyles(props);
  const { items, changeFilter } = props;
  return (
    <div className={classes.root}>
      {items.map((item, index) => {
        return (
          <Button
            variant='contained'
            color={item.active ? 'primary' : undefined}
            className={classNames({ [classes.inactiveButton]: !item.active })}
            key={`settings_${item.label}`}
            onClick={() => changeFilter(index, item)}
          >
            {item.label}
          </Button>
        );
      })}
    </div>
  );
};

export default Filters;
