import { MobileStepper, IconButton, createStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { CSSTransitionGroup } from 'react-transition-group';
import { connect } from 'react-redux';
import { RegistrationActions, updatePagination } from 'redux/ducks/registration';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';

type StateProps = ReturnType<typeof mapState>;
type DispatchProps = ReturnType<typeof mapDispatch>;

type PaginatedSwipeableViewsProps = StateProps & DispatchProps & WithStyles;

const PaginatedSwipeableViews: React.FunctionComponent<PaginatedSwipeableViewsProps> = ({
  classes,
  children: pages,
  pageNumber,
  updatePagination
}) => {
  const numOfPages = React.Children.count(pages);
  // In the event that the page gets resized, and the tiles get moved to the previous page leaving the current page blank,
  // update the pagination to the previous page
  if (pageNumber !== 0 && pageNumber >= numOfPages) {
    updatePagination(pageNumber - 1);
  }

  return (
    <div>
      {pageNumber > 0 && (
        <IconButton
          className={`${classes.button} ${classes.leftButton}`}
          onClick={() => updatePagination(pageNumber - 1)}
        >
          <ChevronLeftIcon />
        </IconButton>
      )}
      {pageNumber < numOfPages - 1 && (
        <IconButton
          className={`${classes.button} ${classes.rightButton}`}
          onClick={() => updatePagination(pageNumber + 1)}
        >
          <ChevronRightIcon />
        </IconButton>
      )}
      <CSSTransitionGroup
        transitionName='fade'
        transitionLeaveTimeout={250}
        transitionEnterTimeout={500}
      >
        {pages[pageNumber]}
      </CSSTransitionGroup>
      {numOfPages > 1 && (
        <MobileStepper
          classes={{
            root: classes.stepper,
            dotActive: classes.dotActive
          }}
          variant='dots'
          steps={numOfPages}
          position='static'
          activeStep={pageNumber}
          backButton={<div />}
          nextButton={<div />}
        />
      )}
    </div>
  );
};

const styles = createStyles({
  button: {
    position: 'absolute !important' as 'absolute',
    top: 'calc(50% - 12px)',
    cursor: 'pointer',
    background: 'white !important',
    borderRadius: 40,
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    transform: 'translateY(-50%)',
    zIndex: 2
  },
  leftButton: {
    left: 4
  },
  rightButton: {
    right: 4
  },
  stepper: {
    background: 'transparent',
    justifyContent: 'center'
  },
  dotActive: {
    backgroundColor: '#009688' //FIXME
  }
});

const mapState = (state: RootState) => ({
  pageNumber: state.registration.pageNumber
});

const mapDispatch = (dispatch: ThunkDispatch<RootState, void, RegistrationActions>) => ({
  updatePagination: (pageNumber: number) => dispatch(updatePagination(pageNumber))
});

export default connect(mapState, mapDispatch)(withStyles(styles)(PaginatedSwipeableViews));
