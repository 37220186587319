import * as React from 'react';
import moment from 'moment-timezone';
import { MenuItem, Select } from '@material-ui/core';
import './index.scss';
interface TimezonePickerProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => void;
}

interface TimeZone {
  name: string;
  value: string;
}

interface TimezonePickerState {
  timezones: TimeZone[];
}

const styles = () => ({
  select: {
    marginTop: '10px',
    width: '100%',
    border: '1px solid #57cbbf',
    paddingLeft: '15px',
    paddingRight: '25px',
    height: '45px',
    borderRadius: '3px',
    '& svg': {
      color: '#4cab9f',
      marginRight: '10px'
    }
  }
});

class TimezonePicker extends React.Component<TimezonePickerProps, TimezonePickerState> {
  constructor(props: TimezonePickerProps) {
    super(props);

    this.state = {
      timezones: this.getTimezones()
    };
  }

  getTimezones = (): TimeZone[] => {
    return moment.tz
      .names()
      .filter((zone) => zone.includes('Europe'))
      .map((zone: string) => {
        return {
          name: `${zone} (${moment.tz(zone).format('Z')})`,
          value: zone
        };
      });
  };

  componentDidMount() {
    this.setState({
      timezones: this.getTimezones()
    });
  }
  render() {
    const { onChange, value } = this.props;
    const classes = styles();
    return (
      <Select
        className={'input__fix'}
        fullWidth={true}
        value={value}
        style={{ ...classes.select }}
        onChange={onChange}
      >
        {this.state.timezones.map((option, index: number) => {
          return (
            <MenuItem key={index} value={option.value}>
              {option.name}
            </MenuItem>
          );
        })}
      </Select>
    );
  }
}

export default TimezonePicker;
