import { createSelectorCreator, defaultMemoize } from 'reselect';
import { getRegistrations } from 'redux/ducks/data/registrations/selectors';
import { Registration } from 'redux/ducks/data/registrations';
import isEqual from 'lodash/isEqual';
import { RootState } from 'redux/rootReducer';
import { CurrentRegistration } from 'redux/ducks/registration/types';
import { RegistrationPoint } from 'redux/ducks/data/registrationPoints';
import moment from 'moment';
import { API_DATE_FORMAT } from 'utils/datetime';

export interface IRecentRegistration {
  id: string;
  date: string;
  amount: number;
  cost: number;
  createdAt: string;
  areaName: string;
  areaStatus: string;
  productName: string;
  productStatus: string;
  categoryName: string;
  categoryStatus: string;
}

// create a custom "selector creator" that uses lodash.isEqual instead of the default equality check "==="
const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getRecentRegistrations = createDeepEqualSelector(getRegistrations, (registrations) => {
  return registrations.map((registration: Registration) => {
    const { registrationPoint } = registration;
    let productStatus = 'active';

    if (!registrationPoint || registrationPoint.deletedAt) {
      productStatus = 'deleted';
    } else if (!registrationPoint.active) {
      productStatus = 'inactive';
    }

    return {
      id: registration.id,
      amount: registration.amount,
      date: registration.date,
      cost: registration.cost,
      createdAt: Date.parse(registration.createdAt),
      productName: registrationPoint ? registrationPoint.name : null,
      productStatus,
      productId: registrationPoint.id
    };
  });
});

export const getMultipleRegistration = createDeepEqualSelector(
  (state: RootState) => state.registration.currentNodes,
  (state: RootState) => state.registration.currentRegistrations,
  (state: RootState) => state.registration.nodesHistory,
  (currentNodes, currentRegistrations, nodesHistory) => {
    const finalArray = currentNodes.map((node) => {
      const formatedRoot = currentRegistrationDataFormat(node);

      const findEditedCurrentRegistration = currentRegistrations.find(
        (registration) => formatedRoot.id === registration.id
      );

      return {
        ...formatedRoot,
        ...findEditedCurrentRegistration,
        ...{ pointPath: nodesHistory.flatMap((pointsByName) => Object.keys(pointsByName)) }
      };
    });

    return selectFirstAvailableRow(finalArray);
  }
);

export const currentRegistrationDataFormat = (root: RegistrationPoint): CurrentRegistration => {
  const { id, name, image } = root;
  return {
    status: undefined,
    pointPath: undefined,
    unit: 'g',
    date: undefined,
    id,
    name,
    image,
    amount: undefined
  };
};

const selectFirstAvailableRow = (registrations: CurrentRegistration[]): CurrentRegistration[] => {
  let selected = false; // flag for knowing if one registration was selected already
  return registrations.map((regPoint) => {
    const editable = regPoint.status !== undefined; // change to "true" if you want pencil icon displayed
    if (regPoint.status === undefined && selected === false) {
      selected = true;
      return {
        ...regPoint,
        selected: true,
        editable: editable
      };
    } else {
      return {
        ...regPoint,
        selected: false,
        editable: editable
      };
    }
  });
};

export const selectLastRegistration = createDeepEqualSelector(
  (state: RootState) => state.registration.currentRegistrations,
  (state: RootState) => state.registration.displayMultipleRegistration,
  (currentRegistrations, displayMultipleRegistration) => {
    if (currentRegistrations) {
      const firstRegistration = currentRegistrations[0];
      const areRegistrationDatesEqual = currentRegistrations.every(
        (registration) => registration.date === firstRegistration.date
      );

      if (firstRegistration) {
        const totalWeightCalc = currentRegistrations.reduce((total = 0, node) => {
          return node.status === 'registered' ? total + node.amount : total;
        }, 0);

        return {
          ...firstRegistration,
          amount: totalWeightCalc,
          name: displayMultipleRegistration
            ? firstRegistration.pointPath.slice(-1)[0]
            : firstRegistration.name,
          date: areRegistrationDatesEqual
            ? moment(firstRegistration.date, API_DATE_FORMAT).format('L')
            : 'Multiple dates'
        };
      } else return undefined;
    } else return undefined;
  }
);

export const getFreePlanRegistrationHistory = createDeepEqualSelector(
  (state: RootState) => getRegistrations(state),
  (registrations) => {
    const byDate = registrations.reduce((date, curr) => {
      const formattedDate = moment(curr.date).format(API_DATE_FORMAT);
      const currWithName = {
        ...curr,
        name: curr.registrationPoint ? curr.registrationPoint.name : null
      };

      return {
        ...date,
        [formattedDate]: date[formattedDate]
          ? [...date[formattedDate], currWithName]
          : [currWithName]
      };
    }, {} as { [key: string]: Registration[] });

    const now = moment.utc();
    const pastWeek = moment.utc().subtract(7, 'day');

    for (const day = pastWeek; day.isSameOrBefore(now, 'day'); day.add(1, 'day')) {
      const formattedDate = day.format(API_DATE_FORMAT);
      if (!byDate[formattedDate]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        byDate[formattedDate] = [{ id: formattedDate, date: formattedDate }];
      }
    }

    return Object.values(byDate).flatMap((identity) => identity);
  }
);
