import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';
import { RootState } from 'redux/rootReducer';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getPopupTypes = createDeepEqualSelector(
  (state: RootState) => state.popups.popups,
  (popups) => popups.filter((popup) => popup.type === 'popup')
);
