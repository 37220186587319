import * as React from 'react';

export type Modal = {
  visible?: boolean;
  title?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fullWidth?: boolean;
  content: React.ReactNode | React.ReactNode[];
  className?: string;
  fullBleed?: boolean;
  disablePadding?: boolean;
};

export type Module = {
  id: number;
  icon: string;
  name: string;
  url: string;
  app?: string;
};

export type ModuleLink = {
  id: number;
  label: string;
  link: string;
  icon: string;
  app?: string;
};

export interface UiState {
  isMenuOpen: boolean;
  modal: Modal;
  modules: Module[];
  pages: {
    report: {
      currentTab: number;
    };
  };
  isMenuPinned: boolean;
  credentials: {
    dealNumber: string;
    username: string;
    password: string;
  };
  introWizardDisplayed: { [accountId: string]: boolean };
  isScreenLocked: boolean;
  returnTo: string;
}

export enum UiActionTypes {
  SHOW_MENU = 'esmiley/ui/SHOW_MENU',
  HIDE_MENU = 'esmiley/ui/HIDE_MENU',
  TOGGLE_MENU = 'esmiley/ui/TOGGLE_MENU',
  TOGGLE_MENU_PINNING = 'esmiley/ui/TOGGLE_MENU_PINNING',
  GET_SELECTOR_ITEMS = 'esmiley/ui/GET_SELECTOR_ITEMS',
  CLEAR_SELECTOR_ITEMS = 'esmiley/ui/CLEAR_SELECTOR_ITEMS',
  SHOW_MODAL = 'esmiley/ui/SHOW_MODAL',
  HIDE_MODAL = 'esmiley/ui/HIDE_MODAL',
  CHANGE_LOCALE = 'esmiley/ui/CHANGE_LOCALE',
  TOGGLE_SCREEN_LOCK = 'esmiley/ui/TOGGLE_LOCK_SCREEN',
  SET_BACK_TO_HACCP = 'esmiley/ui/SET_BACK_TO_HACCP'
}

type ShowMenuAction = {
  type: typeof UiActionTypes.SHOW_MENU;
};

type HideMenuAction = {
  type: typeof UiActionTypes.HIDE_MENU;
};

type ToggleMenuAction = {
  type: typeof UiActionTypes.TOGGLE_MENU;
};

type ShowModalAction = {
  type: typeof UiActionTypes.SHOW_MODAL;
  payload: Modal;
};

type HideModalAction = {
  type: typeof UiActionTypes.HIDE_MODAL;
};

type ToggleMenuPinAction = {
  type: typeof UiActionTypes.TOGGLE_MENU_PINNING;
  payload: boolean;
};

type GetSelectorItemsAction = {
  type: typeof UiActionTypes.GET_SELECTOR_ITEMS;
  payload: Module[];
};

type ClearSelectorItemsAction = {
  type: typeof UiActionTypes.CLEAR_SELECTOR_ITEMS;
};

type ToggleScreenLockAction = {
  type: typeof UiActionTypes.TOGGLE_SCREEN_LOCK;
  payload: boolean;
};

type SetBackToHaccpAction = {
  type: typeof UiActionTypes.SET_BACK_TO_HACCP;
  payload: string;
};

export type UiActions =
  | ShowMenuAction
  | HideMenuAction
  | ToggleMenuAction
  | ShowModalAction
  | HideModalAction
  | ToggleMenuPinAction
  | GetSelectorItemsAction
  | ClearSelectorItemsAction
  | ToggleScreenLockAction
  | SetBackToHaccpAction;
