import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const SaladIcon: React.FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d='M 4.5 12 L 7.9375 12 L 3.859375 7.921875 C 3.714844 7.777344 3.714844 7.539062 3.859375 7.390625 L 4.390625 6.859375 C 4.539062 6.714844 4.777344 6.714844 4.921875 6.859375 L 9.75 11.6875 L 9.75 4.875 C 9.75 4.667969 9.917969 4.5 10.125 4.5 L 10.875 4.5 C 11.082031 4.5 11.25 4.667969 11.25 4.875 L 11.25 12 L 13.710938 12 C 13.578125 11.511719 13.507812 11.007812 13.5 10.5 C 13.503906 7.1875 16.1875 4.503906 19.5 4.5 C 19.625 4.5 19.738281 4.527344 19.863281 4.535156 C 19.039062 3.601562 17.84375 3 16.5 3 C 16.101562 3.003906 15.703125 3.066406 15.320312 3.175781 C 14.492188 1.25 12.597656 0 10.5 0 C 8.402344 0 6.507812 1.25 5.679688 3.175781 C 5.296875 3.066406 4.898438 3.003906 4.5 3 C 2.015625 3 0 5.015625 0 7.5 C 0 9.984375 2.015625 12 4.5 12 Z M 15 10.5 C 15.003906 11.011719 15.097656 11.519531 15.277344 12 L 23.210938 12 C 23.378906 12 23.542969 12.019531 23.707031 12.058594 C 24.332031 10.371094 23.894531 8.476562 22.589844 7.238281 C 21.289062 5.996094 19.371094 5.65625 17.71875 6.363281 C 16.066406 7.074219 14.996094 8.703125 15 10.5 Z M 23.210938 13.5 L 0.757812 13.5 C 0.328125 13.5 -0.0351562 13.863281 0.00390625 14.289062 C 0.3125 17.871094 2.730469 20.824219 6 21.96875 L 6 22.5 C 6 23.328125 6.671875 24 7.5 24 L 16.5 24 C 17.328125 24 18 23.328125 18 22.5 L 18 21.960938 C 21.253906 20.804688 23.65625 17.859375 23.964844 14.289062 C 24.003906 13.863281 23.640625 13.5 23.210938 13.5 Z M 23.210938 13.5 ' />
  </SvgIcon>
);

export default SaladIcon;
