import * as React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField
} from '@material-ui/core';
import { useIntl } from 'react-intl';

interface SaveSelectionDialogProps {
  onSave: (name: string) => void;
  disabled: boolean;
}

const SaveSelectionDialog: React.FunctionComponent<SaveSelectionDialogProps> = (props) => {
  const { onSave, disabled } = props;
  const intl = useIntl();
  const [open, setOpen] = React.useState<boolean>(false);
  const [name, setName] = React.useState<string>('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    handleClose();
    onSave(name);
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value }
    } = event;
    setName(value);
  };

  return (
    <>
      <Button
        fullWidth
        variant='outlined'
        color='primary'
        onClick={handleClickOpen}
        disabled={disabled}
      >
        {intl.formatMessage({ id: 'report.filter.saveSelectionAs' })}
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>
          {' '}
          {intl.formatMessage({ id: 'report.filter.saveSelectionAs' })}
        </DialogTitle>
        <DialogContent>
          <TextField
            color='primary'
            InputLabelProps={{ shrink: true }}
            autoFocus
            margin='dense'
            label={intl.formatMessage({ id: 'name' })}
            onChange={handleNameChange}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button variant='text' onClick={handleClose} color='primary'>
            {intl.formatMessage({ id: 'base.cancel' })}
          </Button>
          <Button
            disabled={name.length === 0}
            variant='contained'
            onClick={handleSave}
            color='primary'
          >
            {intl.formatMessage({ id: 'base.save' })}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SaveSelectionDialog;
