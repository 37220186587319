import { Point } from 'highcharts';
import { SeriesData } from 'redux/ducks/reportData';
import { Formatter } from 'pages/Report/utils/createValueFormatter';

const getCustomDataLabel = (
  point: Point,
  exactValues: SeriesData,
  formatter?: Formatter,
  unit?: string
): string => {
  const { name, x, y } = point;
  const formattedPercentage = unit ? `${y} ${unit}` : `${y} %`;

  // Currently the exact values are shown on the Accounts page only
  if (formatter) {
    const matchedPoint = exactValues && exactValues.points && exactValues.points[x];
    const value = (matchedPoint && matchedPoint.value) || 0;
    const formattedValue: string = formatter.format(value, exactValues.unit);

    return (
      '<div style="display: flex; padding-bottom: 10px;">' +
      ' <span style="font-weight: 900">' +
      name +
      '</span>' +
      ' <div style="display: flex; flex-direction: column; align-items: flex-end">' +
      '   <span>' +
      formattedPercentage +
      '</span>' +
      '   <span>' +
      formattedValue +
      '</span>' +
      ' </div>' +
      '</div>'
    );
  } else {
    return unit
      ? '<span>' + name + '</br>' + formattedPercentage + '</span>'
      : name + ' ' + formattedPercentage;
  }
};

export default getCustomDataLabel;
