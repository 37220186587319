import CheckIcon from '@material-ui/icons/Check';
import { GridListTile as MuiGridListTile, GridListTileBar, Tooltip, Fab } from '@material-ui/core';
import * as React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import ImagePlaceholder from 'static/img/placeholder.png';
import { makeStyles } from '@material-ui/styles';

interface IComponentProps {
  classes?: { [name: string]: string };
  value: React.ReactNode;
  onClick: (event: React.MouseEvent, value: React.ReactNode) => void;
  name: string;
  image?: string;
  isSelected: boolean;
  disabled?: boolean;
  allowRegistrationsOnAnyPoint?: boolean;
  className?: string;
}

const useStyles = makeStyles({
  tile: {
    cursor: 'pointer'
  },
  selectedTile: {
    transition: 'all 200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
    // elevation: 8
    boxShadow: `0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);`
  },
  disabled: {
    opacity: 0.5,
    cursor: 'not-allowed'
  },
  fab: {
    marginRight: 16,
    height: 40,
    width: 40
  }
});

const GridListTile: React.FC<IComponentProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles(props);

  const {
    disabled,
    name,
    image,
    isSelected,
    value,
    onClick,
    allowRegistrationsOnAnyPoint,
    ...rest
  } = props;

  return (
    <MuiGridListTile
      classes={{
        tile: classNames(classes.tile, {
          [classes.selectedTile]: isSelected,
          [classes.disabled]: disabled
        })
      }}
      onClick={(event) => !disabled && onClick(event, value)}
      key={name}
      {...rest}
    >
      <img src={image || ImagePlaceholder} alt={name} />
      <GridListTileBar
        title={name}
        titlePosition='bottom'
        actionIcon={
          allowRegistrationsOnAnyPoint && (
            <Tooltip
              placement='top-end'
              title={intl.formatMessage({ id: 'registration.registerHere' })}
            >
              <Fab color={'primary'} className={classes.fab}>
                <CheckIcon />
              </Fab>
            </Tooltip>
          )
        }
      />
    </MuiGridListTile>
  );
};

export default GridListTile;
