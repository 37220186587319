export type Growth = 'positive' | 'negative' | 'equal' | 'noTarget' | 'noData' | 'noTrend';

interface GetGrowthOptions {
  inverted?: boolean;
  threshold?: number;
}

function getTrendChange(trend: number, options: GetGrowthOptions = {}): Growth {
  const { inverted = false, threshold = 0 } = options;

  if (trend === threshold) {
    return 'equal';
  }

  if (inverted) {
    return trend > threshold ? 'negative' : 'positive';
  }

  return trend >= threshold ? 'positive' : 'negative';
}

export function getGrowth(value: number, trend?: number, options: GetGrowthOptions = {}): Growth {
  if (trend === undefined) {
    return 'noTrend';
  }

  if (value === 0) {
    return 'noData';
  }

  return getTrendChange(trend, options);
}
