import * as React from 'react';
import RegistrationsPerAccount from './RegistrationsPerAccount';
import getChartData from 'pages/Report/components/Chart/utils/getChartData';
import { seriesMappers, themeMapper } from './utils/chartMappers';
import { connect } from 'react-redux';
import * as reportDispatch from 'redux/ducks/reportData';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { getAvailableAccounts } from 'redux/ducks/reportFilter/selectors';
import mapAccountNames from 'pages/Report/RegistrationFrequency/components/RegistrationsPerAccount/utils/mapAccountNames';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { ReportActions } from 'redux/ducks/reportFilter';
import { VACATION_COLOR } from 'pages/Report';
import { SeriesData } from 'redux/ducks/reportData';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type RegistrationsPerAccountContainerProps = StateProps & DispatchProps;

const identifier = 'registrationsPerAccount';
export const filteredSeriesKeys = ['frequencyOnTargetDays', 'frequencyVacationDays'];
export const FREQUENCY_PER_ACCOUNT_CHART_COLORS = ['#90cbfc', VACATION_COLOR];

export const filteredSeries = (data: SeriesData) => {
  return {
    ...data,
    series: data?.series ? data.series.filter((s) => filteredSeriesKeys.includes(s.id)) : []
  };
};

const RegistrationsPerAccountContainer: React.FunctionComponent<
  RegistrationsPerAccountContainerProps
> = (props) => {
  const intl = useIntl();
  const { accounts, registrationsPerAccount, fetchData, filter } = props;
  const { data: chartsData, error, isLoading } = registrationsPerAccount;
  const { series = [], extra = { target: 0 } } = chartsData;
  const perAccountSeries = mapAccountNames(accounts, filteredSeries(series[0]));

  useEffect(() => {
    void fetchData(identifier);
  }, [filter.basis, filter.dimension, filter.timeRange, filter.period, filter.filter]);

  const frequencyPerAccount = React.useMemo(() => {
    return getChartData(
      {
        series: perAccountSeries.series,
        unit: perAccountSeries.unit,
        plotLines: {
          best: perAccountSeries.aggregates?.max,
          worst: perAccountSeries.aggregates?.min,
          average: perAccountSeries.aggregates?.avg,
          target: extra.target
        },
        intl
      },
      seriesMappers,
      themeMapper
    );
  }, [chartsData]);

  return (
    <RegistrationsPerAccount
      isLoading={isLoading}
      error={error}
      chartOptions={frequencyPerAccount}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  registrationsPerAccount: state.reportData[identifier],
  filter: state.reportFilter,
  accounts: getAvailableAccounts(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, ReportActions>) => ({
  fetchData: (identifier: reportDispatch.ReportDataIdentifier) =>
    dispatch(reportDispatch.fetchData(identifier))
});

export default connect<StateProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationsPerAccountContainer);
