import { AxiosError } from 'axios';
import { ApiError } from 'redux/ducks/error';
import { Labels } from 'utils/labels';
import { InitFilterOptions } from 'redux/ducks/reportFilter/index';

export type CachedFilter = {
  state: Partial<InitFilterOptions>;
};

export enum ReportActionTypes {
  FILTER_INIT_REQUEST = 'esmiley/reports/FILTER_INIT_REQUEST',
  FILTER_INIT_REG_POINTS_REQUEST = 'esmiley/reports/FILTER_INIT_REG_POINTS_REQUEST',
  FILTER_INIT_REG_POINTS_SUCCESS = 'esmiley/reports/FILTER_INIT_REG_POINTS_SUCCESS',
  FILTER_INIT_QUERY_SUCCESS = 'esmiley/reports/FILTER_INIT_QUERY_SUCCESS',
  FILTER_INIT_SUCCESS = 'esmiley/reports/FILTER_INIT_SUCCESS',

  FILTER_CHANGE_REQUEST = 'esmiley/reports/FILTER_CHANGE_REQUEST',
  FILTER_CHANGE_SUCCESS = 'esmiley/reports/FILTER_CHANGE_SUCCESS',

  FILTER_ADD_COMPARE_REQUEST = 'esmiley/reports/FILTER_ADD_COMPARE_REQUEST',
  FILTER_ADD_COMPARE_SUCCESS = 'esmiley/reports/FILTER_ADD_COMPARE_SUCCESS',

  FILTER_REMOVE_COMPARE_SUCCESS = 'esmiley/reports/FILTER_REMOVE_COMPARE_SUCCESS',
  FILTER_CHANGE_COMPARE_REQUEST = 'esmiley/reports/FILTER_CHANGE_COMPARE_REQUEST',
  FILTER_CHANGE_COMPARE_SUCCESS = 'esmiley/reports/FILTER_CHANGE_COMPARE_SUCCESS',

  FILTER_ERROR = 'esmiley/reports/FILTER_RELOAD_ALL_ERROR',

  FILTER_INIT_GUEST_TYPE_REQUEST = 'esmiley/reports/FILTER_INIT_GUEST_TYPE_REQUEST',
  FILTER_INIT_GUEST_TYPE_SUCCESS = 'esmiley/reports/FILTER_INIT_GUEST_TYPE_SUCCESS'
}

export interface ReportQueryAccountGroup {
  accounts: string[] | string; // list of ids or top/bottom/* query
  areas?: string[];
  categories?: string[];
  products?: string[];
  order?: string;
}

export interface ReportQuery {
  id: string;
  query: {
    guestTypes?: string[];
    accountGroups?: ReportQueryAccountGroup[];
  };
}

export type Order = 'asc' | 'desc';
export type Period = 'day' | 'week' | 'month' | 'quarter' | 'year' | 'custom';
export type Basis = 'per-guest' | 'total';
export type Dimension = 'weight' | 'cost' | 'co2';
export type TimeRange = { from: string; to: string };
export type TimeRangeOptions = {
  skipFilterReload?: boolean;
  updateCache?: boolean;
};

export interface ReportTree {
  name: string;
  label: Labels;
  registrations: number;
  children: ReportTree[];
}

export interface AccountGroupFilter {
  accounts: string[];
  selectedRegistrationPoints: RegistrationPointNamesByLabel;
  // availability trees, points in the trees maintain number of registrations for the chosen accounts/time range.
  // this for visual purposes only;
  // the points that dont have registration need to be
  // highlighted in the filters.
  // this needs to be update everytime accounts/time range/dimension changes
  trees: ReportTree[];
  order: Order;
}

export type RegistrationPointNamesByLabel = {
  [key in Labels]: string[];
};

export interface ReportFilterState {
  queryId?: string;
  timeRange: TimeRange;
  period: Period;
  dimension: Dimension;
  basis: Basis;
  includeSoftDeleted: boolean;
  guestTypes: string[];
  selectedGuestTypeNames: string[];
  // all available trees points from account / sub-accounts,
  // this is used to build registration point filters
  registrationPoints: ReportTree[];
  // deprecated
  // todo remove filter, it's redundant (filter = accountGroups[0])
  // this is only for backwards compat
  filter: AccountGroupFilter;
  accountGroups: AccountGroupFilter[];
  isInitialized: boolean;
  isInitializing: boolean;
  loading: boolean;
  error: string;
}

export type AccountGroupFilterUpdate = NestedPartial<
  Omit<AccountGroupFilter, 'availableRegistrationPoints'>
>;

type InitFilterRequestAction = {
  type: typeof ReportActionTypes.FILTER_INIT_REQUEST;
};

type ChangeFilterRequestAction = {
  type: typeof ReportActionTypes.FILTER_CHANGE_REQUEST;
  payload?: Partial<ReportFilterState>;
};
type ChangeFilterSuccessAction = {
  type: typeof ReportActionTypes.FILTER_CHANGE_SUCCESS;
  payload: Partial<ReportFilterState>;
};

type InitFilterSuccessAction = {
  type: typeof ReportActionTypes.FILTER_INIT_SUCCESS;
};

type InitRegistrationPointsRequestAction = {
  type: typeof ReportActionTypes.FILTER_INIT_REG_POINTS_REQUEST;
};

type InitRegistrationPointsSuccessAction = {
  type: typeof ReportActionTypes.FILTER_INIT_REG_POINTS_SUCCESS;
  payload: ReportTree[];
};

type InitGuestTypeRequestAction = {
  type: typeof ReportActionTypes.FILTER_INIT_GUEST_TYPE_REQUEST;
};

type InitGuestTypeSuccessAction = {
  type: typeof ReportActionTypes.FILTER_INIT_GUEST_TYPE_SUCCESS;
  payload: string[];
};

type RemoveComparisonFilterSuccessAction = {
  type: typeof ReportActionTypes.FILTER_REMOVE_COMPARE_SUCCESS;
  payload: number;
};

type ChangeCompareFilterRequestAction = {
  type: typeof ReportActionTypes.FILTER_CHANGE_COMPARE_REQUEST;
};

type ChangeCompareFilterSuccessAction = {
  type: typeof ReportActionTypes.FILTER_CHANGE_COMPARE_SUCCESS;
  payload: { key: number; accountGroup: AccountGroupFilter };
};

type AddCompareFilterSuccessAction = {
  type: typeof ReportActionTypes.FILTER_ADD_COMPARE_SUCCESS;
  payload: AccountGroupFilter;
};

type ReportErrorAction = {
  type: typeof ReportActionTypes.FILTER_ERROR;
  payload: AxiosError<ApiError>;
};

type InitQuerySuccessAction = {
  type: typeof ReportActionTypes.FILTER_INIT_QUERY_SUCCESS;
  payload: {
    id: string;
    accountGroups?: AccountGroupFilter[];
    guestTypes?: string[];
  };
};

export type ReportActions =
  | InitFilterRequestAction
  | ChangeFilterRequestAction
  | ChangeFilterSuccessAction
  | InitFilterSuccessAction
  | InitRegistrationPointsRequestAction
  | InitRegistrationPointsSuccessAction
  | InitQuerySuccessAction
  | ChangeCompareFilterRequestAction
  | RemoveComparisonFilterSuccessAction
  | ChangeCompareFilterSuccessAction
  | AddCompareFilterSuccessAction
  | InitGuestTypeRequestAction
  | InitGuestTypeSuccessAction
  | ReportErrorAction;
