import * as React from 'react';
import { useSnackbar } from 'notistack';
import { Box, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { TopbarMenu } from 'styles/themes/global';

const SnackbarStyles = { top: `calc(${TopbarMenu.height} + 10px)` };

interface UseNotificationOptions {
  persist?: boolean;
  preventDuplicate?: boolean;
  id?: string;
  action?: React.ReactNode;
}

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const showNotification = React.useCallback(
    (message: string, options?: UseNotificationOptions) => {
      const { persist, preventDuplicate, id, action } = options || {};
      enqueueSnackbar(message, {
        persist,
        preventDuplicate,
        key: id,
        style: SnackbarStyles,
        action: (id: string) => (
          <>
            {action}
            <Box ml={1}>
              <IconButton size='small' onClick={() => closeSnackbar(id)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </>
        )
      });
    },
    [enqueueSnackbar, closeSnackbar]
  );

  return { showNotification, closeNotification: closeSnackbar };
};
