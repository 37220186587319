import * as React from 'react';
import { Dialog, DialogContent, Grid, IconButton } from '@material-ui/core';
import { Bar } from 'pages/Report/components/Chart';
import ChartHeader from '../ChartHeader';
import concatenateArrayValues from 'pages/Report/components/Chart/utils/concatenateArrayValues';
import getChartData from 'pages/Report/components/Chart/utils/getChartData';
import { themeMapper } from './utils/chartMappers';
import seriesMappers from 'pages/Report/components/Chart/utils/seriesMappers';
import { SeriesData } from 'redux/ducks/reportData';
import { formatWeight, formatMoney } from 'utils/number-format';
import { sum } from 'utils/array';
import createValueFormatter from 'pages/Report/utils/createValueFormatter';
import { useIntl } from 'react-intl';
import { Basis, Dimension } from 'redux/ducks/reportFilter';
import { ZoomIn } from '@material-ui/icons';

interface BarChartGroupProps {
  chartsData: (SeriesData | SeriesData[])[];
  colors: Array<string>;
  isLoading?: boolean;
  basis: Basis;
  dimension: Dimension;
}

const BarChartGroup: React.FunctionComponent<BarChartGroupProps> = ({
  isLoading,
  chartsData,
  colors,
  dimension,
  basis
}) => {
  // note: if chartsData not array, a new array instance on every render,
  // see comment below - data should be mapped in parent
  const maxValue = Math.max(...concatenateArrayValues(chartsData));

  return (
    <>
      {
        // todo this needs to go to caller's chartMappers, which knows what kind of data it has
        chartsData.map(
          (data: Array<SeriesData> | SeriesData, index: number): React.ReactElement => (
            <BarChartItem
              key={index}
              data={data}
              maxValue={maxValue}
              index={index}
              colors={colors}
              basis={basis}
              dimension={dimension}
              isLoading={isLoading}
            />
          )
        )
      }
    </>
  );
};

interface BarChartItemProps extends Omit<BarChartGroupProps, 'chartsData'> {
  data: SeriesData[] | SeriesData;
  maxValue: number;
  index: number;
}

const BarChartItem: React.FC<BarChartItemProps> = ({
  data,
  index,
  isLoading,
  colors,
  dimension,
  basis,
  maxValue
}) => {
  const intl = useIntl();
  const [open, setOpen] = React.useState(false);
  const seriesData = Array.isArray(data) ? data : [data];

  // filter out empty series, only an issue with per-guest data
  // should probably filter out empty data points at service?
  if (seriesData.every((data) => data.aggregates.total === 0)) {
    return null;
  }
  // filter empty series (name undefined), which are needed currently for keeping colors consistent (based on index),
  // which is only occurring on reports/account page after transposing series
  const nonEmptySeries: SeriesData = seriesData.find((data) => !!data.name);
  const formattedName = nonEmptySeries
    ? nonEmptySeries.name.toLowerCase() === 'other'
      ? intl.formatMessage({ id: 'report.terms.other' })
      : nonEmptySeries.name
    : '';
  const chartColors: Array<string> = Array.isArray(data) ? colors : [colors[index]];
  const valueFormatter = createValueFormatter(dimension, basis);
  const chartOptions = getChartData(
    {
      series: seriesData,
      chartColors,
      maxValue,
      intl
    },
    seriesMappers(),
    themeMapper(valueFormatter)
  );

  const nonEmptyData = seriesData.filter((series) => series.points.length > 0);
  const formatWeightFn = (dataArr: number) =>
    basis === 'per-guest' ? formatWeight(dataArr, true, 'g') : formatWeight(dataArr);

  const value =
    dimension === 'cost'
      ? formatMoney(sum(nonEmptyData.map((data) => data.aggregates.total))).toString()
      : formatWeightFn(sum(nonEmptyData.map((data) => data.aggregates.total)));

  return (
    <Grid item xs={12} sm={6} md={6} lg={4} key={`${formattedName}-${index}`}>
      <Dialog fullWidth maxWidth='md' open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <ChartHeader
            name={formattedName}
            value={value}
            color={chartColors[0]}
            dimension={dimension}
          />
          <div style={{ maxHeight: '80vh', overflow: 'auto' }}>
            <Bar chartOptions={chartOptions} group isLoading={isLoading} asModal enableDrillDown />
          </div>
        </DialogContent>
      </Dialog>
      <ChartHeader
        name={formattedName}
        value={value}
        color={chartColors[0]}
        dimension={dimension}
        action={
          <IconButton size='small' onClick={() => setOpen(true)}>
            <ZoomIn />
          </IconButton>
        }
      />
      <Bar chartOptions={chartOptions} group isLoading={isLoading} />
    </Grid>
  );
};

export default BarChartGroup;
