import { useIntl } from 'react-intl';
import * as React from 'react';
import {
  Box,
  TextField,
  InputLabel,
  Typography,
  Divider,
  Button,
  Link,
  InputAdornment,
  IconButton,
  LinearProgress,
  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { eSmileyBlue } from 'styles/palette';
import { SignUpData } from 'redux/ducks/signup/types';
import { onSubmitForm } from 'utils/helpers';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import classNames from 'classnames';

interface SignupPageProps {
  onStepChange: React.Dispatch<React.SetStateAction<number>>;
  data: SignUpData;
  onChange: (value: string, target: string) => void;
}

export type OwnProps = SignupPageProps;

const useStyles = makeStyles((theme) => ({
  input: {
    border: '1px solid ' + theme.palette.grey.A100,
    borderRadius: '6px',
    padding: '6px',
    '& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
      {
        display: 'none'
      }
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  inputField: {
    margin: '8px 0'
  },
  label: {
    ...theme.typography.h3,
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  arrow: {
    ...theme.typography.h1,
    fontWeight: theme.typography.fontWeightBold,
    cursor: 'pointer',
    color: theme.palette.text.primary,
    margin: '0 0 8px 0'
  },
  signInLabel: {
    ...theme.typography.subtitle2,
    fontWeight: theme.typography.fontWeightLight,
    display: 'flex',
    alignItems: 'center',
    margin: '12px 0 0 0',
    flexWrap: 'wrap'
  },
  formContainer: {
    width: '90%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '6px',
    padding: '12px 10px',
    height: '550px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    maxWidth: '400px',
    marginBottom: '20px'
  },
  container: {
    background: 'linear-gradient(180deg, rgba(2,93,136,1) 0%, rgba(0,130,197,1) 100%)',
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  divider: {
    margin: '18px 0 12px 0',
    color: theme.palette.grey.A100
  },
  textLink: {
    ...theme.typography.subtitle2,
    margin: '0 0 0 3px',
    fontWeight: theme.typography.fontWeightBold,
    color: eSmileyBlue
  },
  button: {
    backgroundColor: eSmileyBlue,
    color: theme.palette.background.paper,
    height: '60px',
    textTransform: 'none',
    fontSize: '16px'
  },
  footerText: {
    fontSize: '12px'
  },
  select: {
    color: theme.palette.grey.A400,
    border: 0,
    width: '45px'
  },
  flagIcon: {
    margin: '4px 0 0 0'
  },
  strengthContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  strengthLine: {
    width: '150px',
    margin: '1px 0 0 6px',
    borderRadius: '6px',
    height: '6px'
  },
  headerLabel: {
    color: theme.palette.background.default,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '30px'
  },
  red: {
    color: theme.palette.error.dark
  },
  orange: {
    color: theme.palette.secondary.dark
  },
  blue: {
    color: theme.palette.background.default
  },
  green: {
    color: theme.palette.success.main
  },
  redLine: {
    background: theme.palette.grey.A100,
    '& .MuiLinearProgress-bar': {
      backgroundColor: theme.palette.error.dark
    }
  },
  orangeLine: {
    background: theme.palette.grey.A100,
    '& .MuiLinearProgress-bar': {
      backgroundColor: theme.palette.secondary.dark
    }
  },
  greenLine: {
    background: theme.palette.grey.A100,
    '& .MuiLinearProgress-bar': {
      backgroundColor: theme.palette.success.dark
    }
  },
  blueLine: {
    background: theme.palette.grey.A100,
    '& .MuiLinearProgress-bar': {
      backgroundColor: theme.palette.background.default
    }
  }
}));

const SignUpStepTwo: React.FunctionComponent<OwnProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles(props);
  const { onStepChange, data, onChange } = props;
  const { email, password, firstName, lastName } = data;
  const [emailError, setEmailError] = React.useState<string>('');
  const [passwordError, setPasswordError] = React.useState<string>('');

  const [showPassword, setShowPassword] = React.useState<boolean>(false);

  const checkPasswordStrength = (
    password: string
  ): {
    color: 'red' | 'blue' | 'green' | 'orange';
    status: string;
    strength: number;
  } => {
    const blacklist = new RegExp(
      `^(?!.*(password|abcd|123|${firstName}|${lastName}|${email}))`,
      'i'
    );
    const strongPassword = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{12,}`);
    const goodPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{10,})');
    const fairPassword = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.{8,})');

    if (blacklist.test(password) && strongPassword.test(password)) {
      return {
        color: 'green',
        status: intl.formatMessage({ id: 'base.strong' }),
        strength: 100
      };
    }
    if (goodPassword.test(password)) {
      return {
        color: 'blue',
        status: intl.formatMessage({ id: 'base.good' }),
        strength: 75
      };
    }
    if (fairPassword.test(password)) {
      return {
        color: 'orange',
        status: intl.formatMessage({ id: 'base.fair' }),
        strength: 50
      };
    } else {
      return {
        color: 'red',
        status: intl.formatMessage({ id: 'base.weak' }),
        strength: 25
      };
    }
  };

  const { color, status, strength } = checkPasswordStrength(password);

  const handleSubmit = () => {
    const reg = new RegExp(/^\S+@\S+\.\S+$/);
    const isValidEmail = reg.test(email);
    const isValidPassword = strength >= 50;

    if (isValidEmail && isValidPassword) {
      onStepChange(3);
    } else {
      if (!isValidEmail) {
        setEmailError(intl.formatMessage({ id: 'signup.validation.email' }));
      }
      if (!isValidPassword) {
        setPasswordError(intl.formatMessage({ id: 'signup.validation.password' }));
      }
    }
  };

  return (
    <Box className={classes.formContainer} component='form' onSubmit={onSubmitForm(handleSubmit)}>
      <div>
        <Typography className={classes.headerLabel} variant='body1' component='h1'>
          {intl.formatMessage({ id: 'signup.createPassword' })}
        </Typography>
        <div className={classes.form}>
          <div className={classes.inputField}>
            <InputLabel className={classes.label} shrink>
              {intl.formatMessage({ id: 'base.email' })}
            </InputLabel>
            <TextField
              fullWidth
              required
              className={classes.input}
              placeholder={intl.formatMessage({ id: 'base.email' })}
              value={email}
              onChange={(e) => onChange(e.target.value, 'email')}
              focused
              InputProps={{ disableUnderline: true }}
              error={emailError !== ''}
              helperText={emailError}
            />
          </div>
          <div className={classes.inputField}>
            <div className={classes.strengthContainer}>
              <InputLabel className={classes.label} shrink>
                {intl.formatMessage({ id: 'auth.login_password' })}
              </InputLabel>
              <div className={classes.strengthContainer}>
                <Typography variant='subtitle2' className={classes[color]}>
                  {status}
                </Typography>
                <LinearProgress
                  className={classNames(classes.strengthLine, classes[`${color}Line`])}
                  variant='determinate'
                  value={strength}
                />
              </div>
            </div>
            <Tooltip title={intl.formatMessage({ id: 'signup.validation.password' })}>
              <TextField
                fullWidth
                required
                className={classes.input}
                placeholder={intl.formatMessage({ id: 'auth.login_password' })}
                value={password}
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => onChange(e.target.value, 'password')}
                focused
                error={passwordError !== ''}
                helperText={passwordError}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={() => setShowPassword(!showPassword)}
                        edge='end'
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Tooltip>
          </div>
        </div>
      </div>

      <div>
        <Divider className={classes.divider} />

        <Button type='submit' fullWidth variant='contained' className={classes.button}>
          {intl.formatMessage({ id: 'base.continue' })}
        </Button>

        <div className={classes.signInLabel}>
          <Typography variant='body1' component='p'>
            {intl.formatMessage({ id: 'base.tos.message' })}
          </Typography>
          <Link
            target='_blank'
            href={intl.formatMessage({ id: 'url.termsAndConditions' })}
            className={classes.textLink}
            underline='none'
          >
            {intl.formatMessage({ id: 'base.tos' })}
          </Link>
        </div>
      </div>
    </Box>
  );
};

export default SignUpStepTwo;
