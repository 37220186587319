import { DataValidation } from 'frontend-core';
import { getSchemas } from 'utils/validator/schemaLoader';
export interface ValidationResult {
  isValid: boolean;
  erroneousKeys: Set<string>;
}

/**
 * Validate function return ths validation of either specific key or a whole data value set
 * @param schema Schema name
 * @param data Data to pass to AJV
 * @param requiredOnly Boolean to check all keys or just required (optional)
 * @returns {ValidationResult}
 */
export function validate(schema: string, data: any, requiredOnly = false): ValidationResult {
  const validation = new DataValidation({ allErrors: true });
  getSchemas().forEach((schema, name) => validation.addSchema(schema, name));
  const isValid = validation.validate(schema, data);
  const validationErrors = validation.library.errors;
  const validationResult = { isValid, erroneousKeys: new Set() } as ValidationResult;

  if (!validationErrors) {
    return validationResult;
  }

  validationErrors.forEach((error) => {
    const dataPathKey = error.dataPath.split('.');
    const dataKey = dataPathKey[dataPathKey.length ? dataPathKey.length - 1 : dataPathKey.length];
    if (requiredOnly && error.keyword === 'required') {
      validationResult.erroneousKeys.add(dataKey);
    } else if (!requiredOnly) {
      validationResult.erroneousKeys.add(dataKey);
    }
  });

  return validationResult;
}
