import * as React from 'react';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, IconButton, Link, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useEffect } from 'react';
import classNames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { ErrorActions } from 'redux/ducks/error';
import * as popupDispatch from 'redux/ducks/popups';
import { AdPopup, PopupActions } from 'redux/ducks/popups';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { getPopupTypes } from 'redux/ducks/popups/selectors';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type PopupProps = StateProps & DispatchProps & WithRouterProps;

const useStyles = makeStyles((theme) => {
  return {
    container: {
      backgroundColor: '#eaf1f5',
      width: '435px',
      position: 'fixed',
      bottom: '20px',
      right: '5px',
      borderRadius: '4px',
      boxShadow: '1px 3px 6px 0 rgba(0, 0, 0, 0.5)',
      border: 'solid 1px #fcfcfc',
      zIndex: 100,
      animation: `$popupSlide 1s cubic-bezier(0.25,0.1,0.25,1)`
    },
    mobile: {
      width: '100%',
      bottom: '70px',
      right: '0',
      borderRadius: '0',
      animation: `$popupSlideMobile 0.5s cubic-bezier(0.25,0.1,0.25,1)`
    },
    hidden: {
      display: 'none'
    },
    adContent: {
      minHeight: '255px',
      padding: '20px',
      height: '100%',
      position: 'relative'
    },
    closeButton: {
      position: 'absolute',
      top: '4px',
      right: '8px'
    },
    textContainer: {
      width: '55%',
      color: '#595959',
      paddingRight: '12px'
    },
    header: {
      fontSize: '35px',
      lineHeight: '35px',
      '&:first-line': {
        color: theme.palette.background.default
      }
    },
    subHeader: {
      marginTop: '10px',
      fontSize: '14px'
    },
    link: {
      fontSize: '10px'
    },
    img: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '5px 0',
      overflow: 'hidden',
      maxHeight: '156px',
      objectFit: 'cover'
    },
    button: {
      backgroundColor: '#419641',
      color: theme.palette.background.paper,
      height: '49px',
      fontSize: '16px',
      fontWeight: 'bold',
      textTransform: 'initial',
      marginTop: '10px',
      '&:hover': {
        backgroundColor: '#327232'
      }
    },
    '@keyframes popupSlide': {
      '0%': {
        right: '-450px'
      },
      '50%': {
        right: '15px'
      },
      '100%': {
        right: '5px'
      }
    },
    '@keyframes popupSlideMobile': {
      '0%': {
        bottom: '-250px'
      },
      '100%': {
        bottom: '70px'
      }
    }
  };
});

const AdPopUp: React.FunctionComponent<PopupProps> = ({
  popups,
  activePopup,
  getPopups,
  closePopup,
  showPopup
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const isMobile = useMediaQuery('(max-width: 480px)', { noSsr: true });

  useEffect(() => {
    void getPopups();
  }, []);

  useEffect(() => {
    if (popups.length > 0) {
      setTimeout(() => {
        showPopup(popups[0]);
      }, 100);
    }
  }, [activePopup]);

  const handleCTAClick = () => {
    if (activePopup.open_module_selector) {
      const moduleButton = document.getElementById('moduleButton');
      if (moduleButton) {
        moduleButton.click();
      }
    } else {
      window.location.href = activePopup.action;
    }
    handleClose();
  };

  const handleClose = () => {
    void closePopup(activePopup.id);
  };

  return activePopup ? (
    <div
      className={classNames(classes.container, {
        [classes.hidden]: !activePopup,
        [classes.mobile]: isMobile
      })}
    >
      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        className={classes.adContent}
      >
        <IconButton size='small' className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <Box display='flex' flexDirection='row' alignItems='center'>
          <div className={classes.textContainer}>
            <Typography className={classes.header} variant='h1'>
              {activePopup.title}
            </Typography>
            <div
              className={classes.subHeader}
              dangerouslySetInnerHTML={{ __html: activePopup.content }}
            ></div>
            {activePopup['read_more_link'] && (
              <Link
                href={activePopup['read_more_link']}
                color={'inherit'}
                target={'_blank'}
                className={classes.link}
              >
                {intl.formatMessage({ id: 'base.readMore' })}
              </Link>
            )}
          </div>
          <div className={classes.img}>
            <img src={activePopup.image} width={'100%'} />
          </div>
        </Box>
        <Button onClick={handleCTAClick} className={classes.button}>
          {activePopup['action_title']}
        </Button>
      </Box>
    </div>
  ) : null;
};

const mapStateToProps = (state: RootState) => ({
  popups: getPopupTypes(state),
  activePopup: state.popups.activePopup
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<unknown, unknown, PopupActions | ErrorActions>
) => ({
  getPopups: () => dispatch(popupDispatch.getPopups()),
  showPopup: (popup: AdPopup) => dispatch(popupDispatch.showPopup(popup)),
  closePopup: (id: number) => dispatch(popupDispatch.closePopup(id))
});

export default connect<StateProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AdPopUp));
