import * as React from 'react';
import { useIntl } from 'react-intl';
import { AvailabilityStatus } from 'redux/ducks/reportFilter/selectors';
import { Button, Divider, Grid, ListItemText, makeStyles } from '@material-ui/core';
import SortIcon from 'components/icons/SortIcon';
import Select from 'pages/Report/components/Select';
import SelectItem from 'pages/Report/components/Select/components/SelectItem';
import ApplyButton from 'pages/Report/components/ApplyButton/ApplyButton';

interface RegistrationPointSelectProps {
  label: string;
  onChange: (label: string, names: string[]) => void;
  available: AvailabilityStatus[];
  selected: string[];
}

const SelectAll = 'all';

interface SelectionDraft {
  selection: string[];
  changed?: boolean;
}

const RegistrationPointSelect: React.FunctionComponent<RegistrationPointSelectProps> = (props) => {
  const { available, selected, onChange, label } = props;
  const intl = useIntl();
  const classes = useStyles(props);
  const sortIconClass = classes[`${label}Icon` as keyof typeof classes];

  const [draft, setDraft] = React.useState<SelectionDraft>({ selection: selected, changed: false });
  const [pending, setPending] = React.useState(false);

  React.useEffect(() => {
    setDraft({ selection: selected, changed: false });
    setPending(false);
  }, [selected, available]);

  const renderValue = React.useCallback(() => {
    if (selected.length === 0) {
      return intl.formatMessage({ id: 'report.filter.no_selection' });
    }

    return selected.join(',');
  }, [selected, available]);

  const handleDraftChange = (selection: string[]) => {
    const changed =
      selection.some((id) => !selected.includes(id)) ||
      selected.some((id) => !selection.includes(id));
    setDraft({ selection, changed });
  };

  const handleChange = (e: React.ChangeEvent<any>, value: string, isChecked?: boolean) => {
    if (value === SelectAll) {
      handleDraftChange([]);
    } else {
      const selectedValues = isChecked
        ? draft.selection.filter((selectedName) => selectedName !== value)
        : [...draft.selection, value];
      handleDraftChange(selectedValues);
    }
  };

  const handleResetChanges = () => {
    setDraft({ selection: selected, changed: false });
  };

  const handleApplyChanges = () => {
    if (pending || !draft.changed) {
      return;
    }
    setPending(true);
    onChange(label, draft.selection);
  };

  return (
    <Select
      renderValue={renderValue}
      buttonProps={{
        fullWidth: true,
        disabled: available.length === 0,
        startIcon: <SortIcon className={sortIconClass} />
      }}
      menuProps={{
        MenuListProps: {
          style: { minWidth: '220px' },
          disablePadding: true
        }
      }}
    >
      <SelectItem
        disabled={draft.selection.length === 0}
        selected={draft.selection.length === 0}
        onClick={(e: React.ChangeEvent) => handleChange(e, SelectAll)}
        checkbox
      >
        <ListItemText>{intl.formatMessage({ id: 'report.filter.no_selection' })}</ListItemText>
      </SelectItem>
      <Divider />
      {available.map((point) => {
        const isSelected = draft.selection.some((name) => name === point.name);
        return (
          <SelectItem
            onClick={(e: React.ChangeEvent) => handleChange(e, point.name, isSelected)}
            key={point.name}
            selected={isSelected}
            disabled={!isSelected && !point.enabled}
            checkbox
          >
            <ListItemText style={{ textDecoration: !point.available ? 'line-through' : undefined }}>
              {point.name}
            </ListItemText>
          </SelectItem>
        );
      })}
      {draft.changed && (
        <SelectItem
          disableLookup
          disableRipple
          disableHover
          style={{
            position: 'sticky',
            bottom: 0,
            backgroundColor: '#fff',
            borderTop: '1px solid rgba(0, 0, 0, 0.12)'
          }}
        >
          <Grid container item justify='space-between'>
            <Button variant='text' color='primary' onClick={handleResetChanges}>
              {intl.formatMessage({ id: 'base.undo' })}
            </Button>
            <ApplyButton variant='outlined' color='primary' onClick={handleApplyChanges}>
              {intl.formatMessage({ id: 'base.apply' })}
            </ApplyButton>
          </Grid>
        </SelectItem>
      )}
    </Select>
  );
};

const useStyles = makeStyles((theme) => ({
  areaIcon: {
    '& > path:nth-child(1)': {
      color: theme.palette.primary.main
    }
  },
  categoryIcon: {
    '& > path:nth-child(2)': {
      color: theme.palette.primary.main
    }
  },
  productIcon: {
    '& > path:nth-child(3)': {
      color: theme.palette.primary.main
    }
  }
}));

export default RegistrationPointSelect;
