import * as React from 'react';
import RegistrationsPerDay from './RegistrationsPerDay';
import getChartData from 'pages/Report/components/Chart/utils/getChartData';
import { seriesMappers, themeMapper } from './utils/chartMappers';
import { connect } from 'react-redux';
import * as reportDispatch from 'redux/ducks/reportData';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ReportActions } from 'redux/ducks/reportFilter';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { getAvailableAccounts } from 'redux/ducks/reportFilter/selectors';
import { PointData, SeriesData } from 'redux/ducks/reportData';
import { groupBy } from 'utils/array';
import { Grid } from '@material-ui/core';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type RegistrationsPerDayContainerProps = StateProps & DispatchProps;

const identifier = 'registrationsPerDay';

const RegistrationsPerDayContainer: React.FunctionComponent<RegistrationsPerDayContainerProps> = (
  props
) => {
  const intl = useIntl();
  const { fetchData, filter, registrationsPerDay, accounts } = props;
  const { data: chartsData, isLoading, error } = registrationsPerDay;

  useEffect(() => {
    void fetchData(identifier);
  }, [filter.basis, filter.dimension, filter.timeRange, filter.period, filter.filter]);

  const getData = (data: SeriesData) => {
    const byAccountId = groupBy(accounts, 'id');
    const series = [data].map(({ points, ...rest }) => {
      const withAccountName = points.map((point: PointData<{ onVacation?: string[] }>) => {
        const { extra, ...restPoint } = point;
        if (extra?.onVacation) {
          return {
            ...restPoint,
            extra: {
              onVacation: extra.onVacation.map((accountId) => byAccountId[accountId][0]?.name)
            }
          };
        }
        return point;
      });
      return { ...rest, points: withAccountName };
    });
    return getChartData(
      {
        series,
        intl
      },
      seriesMappers,
      themeMapper
    );
  };

  const { avgPerDay, totalGuests } = React.useMemo(() => {
    if (chartsData && chartsData.series) {
      return {
        avgPerDay: chartsData.series[0] ? getData(chartsData.series[0]) : {},
        totalGuests: chartsData.series[1] ? getData(chartsData.series[1]) : {}
      };
    }
  }, [chartsData, accounts]);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <RegistrationsPerDay chartOptions={avgPerDay} isLoading={isLoading} error={error} />
      </Grid>
      <Grid item xs={12}>
        <RegistrationsPerDay
          chartOptions={totalGuests}
          isLoading={isLoading}
          error={error}
          isGuestType
        />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  registrationsPerDay: state.reportData[identifier],
  filter: state.reportFilter,
  accounts: getAvailableAccounts(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, ReportActions>) => ({
  fetchData: (identifier: reportDispatch.ReportDataIdentifier) =>
    dispatch(reportDispatch.fetchData(identifier))
});

export default connect<StateProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationsPerDayContainer);
