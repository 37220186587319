import { combineReducers } from 'redux';
import registrations from './registrations';
import registrationPoints from './registrationPoints';

const reducer = combineReducers({
  registrations,
  registrationPoints
});

export default reducer;
