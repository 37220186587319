export interface OfflineState {
  lastSyncTimestamp?: number;
}

export enum OfflineActionTypes {
  SYNC_OFFLINE_DATA = 'esmiley/offline/SYNC_OFFLINE_DATA',
  DELETE_OFFLINE_DATA = 'esmiley/offline/DELETE_OFFLINE_DATA'
}

type SyncAction = {
  type: typeof OfflineActionTypes.SYNC_OFFLINE_DATA;
  payload: {
    lastSyncTimestamp?: number;
  };
};

export type OfflineActions = SyncAction;
