import * as fwConfig from './foodwasteConfig';
import * as salesConfig from './salesConfig';
import * as benchmarkConfig from './benchmarkConfig';
import { AdvancedReportType } from 'pages/Report/Advanced';

export function createConfig(type: AdvancedReportType) {
  if (type === AdvancedReportType.benchGuests || type === AdvancedReportType.benchWaste) {
    return benchmarkConfig;
  }
  if (type === AdvancedReportType.foodwaste) {
    return fwConfig;
  }
  return salesConfig;
}
