import * as React from 'react';
import { Column } from 'material-table';
import { Checkbox, colors, SvgIconProps, Typography } from '@material-ui/core';
import AlteredMaterialTable from 'components/MaterialTable';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { isIE11 } from 'utils/browsers';
import { Dimension } from 'redux/ducks/reportFilter';
import DollarIcon from '@material-ui/icons/AttachMoney';
import EmissionIcon from 'components/icons/EmissionIcon';
import BalanceScaleIcon from 'components/icons/BalanceScaleIcon';
import { ReportMetric } from 'pages/Report/Mail/api';
import { SelectedMetric } from 'pages/Report/Mail/components/MailReportEditor';
import CustomTableHead from 'components/MaterialTable/components/customTableHead';
import { CSSProperties } from 'react';

const dimensionIcons: { [key in Dimension]: React.ElementType<SvgIconProps> } = {
  cost: DollarIcon,
  weight: BalanceScaleIcon,
  co2: EmissionIcon
};

const canBeSelected = (metric: ReportMetric, selected: SelectedMetric): boolean => {
  const {
    id,
    setting: { enabledDimensions }
  } = metric;
  const { dimensions: selectedDimensions } = selected[id];
  return enabledDimensions.length === 0 || selectedDimensions.length > 0;
};

const isSelected = (metric: ReportMetric, selected: SelectedMetric): boolean => {
  const { id } = metric;
  const { selected: isSelected } = selected[id];
  return isSelected && canBeSelected(metric, selected);
};

export interface CategoryMetricSelectorProps extends WrappedComponentProps {
  style?: CSSProperties;
  metrics: ReportMetric[];
  selected: SelectedMetric;
  onChange: (selected: SelectedMetric) => void;
}

// MT options (https://material-table.com/#/docs/all-props)
const materialTableOptions = (selected: SelectedMetric) => ({
  selection: true,
  selectionProps: (rowData: ReportMetric) => ({
    checked: isSelected(rowData, selected),
    disabled:
      rowData.setting.enabledDimensions.length > 0 && selected[rowData.id].dimensions.length === 0,
    color: 'primary'
  }),
  toolbar: false,
  paging: false,
  disableToolbar: true,
  actionsColumnIndex: -1,
  showTitle: false,
  search: false,
  searchFieldAlignment: 'left',
  draggable: false,
  tableLayout: 'fixed',
  headerStyle: {
    height: '42px',
    width: '0%',
    backgroundColor: colors.grey['50'],
    padding: 0,
    selectionProps: {
      color: 'primary'
    }
  },
  // need to override mui cell padding
  rowStyle: {
    //CRAS: IE11 has an issue with transitioning opacity on table rows
    transition: (isIE11 ? '' : 'opacity 300ms ease-out, ') + 'background-color 500ms ease-out',
    padding: 0,
    height: 80
  },
  actionsCellStyle: {
    width: '0%',
    whiteSpace: 'pre',
    padding: '5px 10px'
  }
});

const intlKeys: { [key: string]: string } = {
  all: 'report.mail.metrics.allCategories',
  total_waste: 'report.totalFoodwaste.title',
  departments: 'report.accounts.title',
  frequency: 'report.registrationFrequency.title',
  overview: 'report.quickOverview.title',
  per_guest: 'report.foodwaste.perGuest.title'
};

class CategoryMetricSelector extends React.Component<CategoryMetricSelectorProps> {
  tableRef = React.createRef<any>();
  whitelistedKeys: string[];

  constructor(props: CategoryMetricSelectorProps) {
    super(props);
    this.whitelistedKeys = ['name', 'cost', 'weight', 'co2'];
  }

  shouldComponentUpdate(nextProps: Readonly<CategoryMetricSelectorProps>): boolean {
    const { metrics, selected, style } = this.props;
    return (
      style !== nextProps.style || metrics !== nextProps.metrics || selected !== nextProps.selected
    );
  }

  extractColumnsFromData = (): Column<ReportMetric>[] => {
    return this.whitelistedKeys.map((key) => {
      switch (key) {
        case 'name':
          return this.setupNameColumn(key);
        default:
          return this.setupDimensionColumn(key);
      }
    });
  };

  setupNameColumn = (key: string): Column<ReportMetric> => {
    const { intl } = this.props;

    return {
      field: key as keyof ReportMetric,
      title: intl.formatMessage({ id: 'report.mail.metrics.availableOptions' }),
      cellStyle: {
        width: '100%',
        padding: 0,
        position: 'relative',
        height: '80px'
      },
      customSort: (a, b) => a.name.localeCompare(b.name, intl.locale, { sensitivity: 'base' }),
      render: this.renderNameColumn
    };
  };

  renderNameColumn = (rowData: ReportMetric): string | JSX.Element => {
    const { onChange, selected, intl } = this.props;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (
      <div
        style={{ position: 'relative' }}
        onClick={() =>
          onChange({
            [rowData.id]: {
              ...selected[rowData.id],
              id: rowData.id,
              selected: !!selected[rowData.id]
            }
          })
        }
      >
        <span>{intl.formatMessage({ id: rowData.nameKey })}</span>
        <Typography variant='caption' style={{ position: 'absolute', bottom: '-20px', left: 0 }}>
          {intl.formatMessage({ id: intlKeys[rowData.categoryKey.split('.').slice(-1)[0]] })}
        </Typography>
      </div>
    );
  };

  setupDimensionColumn = (key: string): Column<ReportMetric> => {
    const Icon = dimensionIcons[key as Dimension];
    return {
      sorting: false,
      title: <Icon color='primary' fontSize='small' />,
      headerStyle: {
        textAlign: 'center',
        minWidth: '42px'
      },
      cellStyle: {
        padding: 0,
        position: 'relative',
        height: '80px'
      },
      render: this.renderDimensionColumn(key as Dimension)
    };
  };

  renderDimensionColumn =
    (dimension: Dimension) =>
    (rowData: ReportMetric): string | JSX.Element => {
      const { onChange, selected } = this.props;
      const dimensions = selected[rowData.id].dimensions;

      const { setting } = rowData;
      const { enabledDimensions = ['co2', 'weight', 'cost'] } = setting || {};

      if (!enabledDimensions.includes(dimension)) {
        return null;
      }

      const checked = dimensions.includes(dimension);
      const toggledDimension = checked
        ? dimensions.filter((d) => d != dimension)
        : [...dimensions, dimension];
      // Ensure the metrics selected property gets updated in case all dimensions were deselected
      const metricSelected = toggledDimension.length > 0;

      return (
        <Checkbox
          color='primary'
          onChange={() => {
            onChange({
              ...selected,
              [rowData.id]: {
                ...selected[rowData.id],
                id: rowData.id,
                selected: metricSelected,
                dimensions: toggledDimension
              }
            });
          }}
          checked={checked}
          value={dimension}
        />
      );
    };

  overrideTableHead = (props): JSX.Element => {
    return <CustomTableHead {...props} draggable={false} />;
  };

  render() {
    const { intl, metrics, selected, onChange, style } = this.props;

    return (
      <AlteredMaterialTable
        noContentMessage={intl.formatMessage({ id: 'report.mail.noReports' })}
        tableRef={this.tableRef}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        columns={this.extractColumnsFromData()}
        data={metrics}
        components={{
          Header: this.overrideTableHead
        }}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        options={materialTableOptions(selected)}
        style={{
          border: `1px solid ${colors.grey['200']}`,
          overflow: 'hidden',
          borderBottomLeftRadius: 0,
          borderTopLeftRadius: 0,
          ...style
        }}
        onSelectionChange={(
          data: ReportMetric[],
          rowData: ReportMetric & Record<string, unknown>
        ) => {
          if (data.length === metrics.length) {
            onChange(
              metrics.reduce(
                (all, curr) => ({
                  ...all,
                  [curr.id]: {
                    ...all[curr.id],
                    selected: canBeSelected(curr, all)
                  }
                }),
                selected
              )
            );
            return;
          }

          // if rowData = undefined,
          // select all button was clicked (data either empty or has all metrics)
          if (!rowData) {
            onChange(
              metrics.reduce(
                (all, curr) => ({
                  ...all,
                  [curr.id]: {
                    ...all[curr.id],
                    selected: data.length !== 0 && canBeSelected(curr, selected)
                  }
                }),
                selected
              )
            );
            return;
          }

          if (rowData) {
            onChange({
              [rowData.id]: {
                ...selected[rowData.id],
                selected:
                  (rowData.tableData as { checked: boolean }).checked &&
                  canBeSelected(rowData, selected)
              }
            });
            return;
          }
        }}
      />
    );
  }
}

export default injectIntl(CategoryMetricSelector);
