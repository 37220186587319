import * as React from 'react';
import { NetworkStatus, useAuth } from 'frontend-core';
import { ServiceWorkerActionTypes, ServiceWorkerMessage } from 'components/ServiceWorker/types';

import { syncOfflineData } from 'redux/ducks/offline';

import { connect } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { useIntl } from 'react-intl';
import { useNotification } from 'hooks/useNotification';
import { AutoActivateButton } from 'components/ServiceWorker/AutoActivateButton';
import { useIdleTimeout } from 'hooks/useIdleTimeout';

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;
type ServiceWorkerProps = DispatchProps & StateProps;

interface ActiveWorkerOptions {
  freshInstall?: boolean;
  silentUpdate?: boolean;
}

const SW_UPDATE_CHECK_INTERVAL = 30 * 60 * 1000; // 30 mins
const IDLE_TIME_OUT = 60 * 1000; // 1 min
const OFFLINE_TOAST_SESSION_KEY = 'offline-toast';

const isChromeOrSafari =
  navigator.userAgent.includes('Chrome') || navigator.userAgent.includes('Safari');

// skipping firefox from initial version, it will prompt the user
// where as chrome/safari automatically approve/deny the request based on some heuristics
const persistStorage = 'storage' in navigator && isChromeOrSafari;

const ServiceWorker: React.FunctionComponent<ServiceWorkerProps> = ({
  enableOffline,
  client,
  syncOfflineData
}) => {
  const intl = useIntl();
  const { showNotification, closeNotification } = useNotification();
  const { getAccessToken, addEventListener, removeEventListener } = useAuth();
  const { onTimeout } = useIdleTimeout();
  const serviceWorkerRegistration = React.useRef<ServiceWorkerRegistration>(null);
  const serviceWorkerIsEnabled =
    enableOffline && ENABLE_SERVICE_WORKER && 'serviceWorker' in navigator;

  React.useEffect(() => {
    // only a bigger issue with safari, which removes data
    // if user hasnt used the app for a week
    // see https://webkit.org/tracking-prevention/#intelligent-tracking-prevention-itp
    // this option should be put to settings behind a flag?
    if (persistStorage) {
      void navigator.storage.persisted().then((isPersisted) => {
        if (!isPersisted) {
          void navigator.storage.persist();
        }
      });
    }
  }, []);

  React.useEffect(() => {
    const handleLogout = () => {
      navigator.serviceWorker.controller.postMessage({
        type: ServiceWorkerActionTypes.RESET_OFFLINE_STORE
      });
    };

    if (serviceWorkerIsEnabled) {
      addEventListener('onLogout', handleLogout);
    }

    return () => {
      removeEventListener('onLogout', handleLogout);
    };
  }, [serviceWorkerIsEnabled]);

  React.useEffect(() => {
    // run manual sw update checks, in case client remains idle for longer periods of time
    const id = setInterval(() => {
      void serviceWorkerRegistration.current?.update();
    }, SW_UPDATE_CHECK_INTERVAL);

    return () => {
      clearInterval(id);
    };
  }, []);

  React.useEffect(() => {
    const initActiveWorker = async () => {
      const worker = await navigator.serviceWorker.ready;
      if (!navigator.serviceWorker.controller) {
        // sw active, but the app was not loaded with it, thus its not controlling the app yet,
        // eg when user hard-reloads the page
        activateWorker(worker.active, { freshInstall: true });
        return;
      }

      const token = await getAccessToken();
      worker.active.postMessage({
        type: ServiceWorkerActionTypes.REQUEST_INIT,
        payload: { token }
      });
    };

    void initActiveWorker();
  }, []);

  React.useEffect(() => {
    const handleControllerChange = () => {
      window.location.reload();
    };

    if (serviceWorkerIsEnabled) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        // new active worker has become controller
        handleControllerChange();
      });
      navigator.serviceWorker.addEventListener('message', handleMessage);
      void registerWorker();
    } else if ('serviceWorker' in navigator) {
      void navigator.serviceWorker.getRegistrations().then((workers) => {
        for (const worker of workers) {
          void worker.unregister();
        }
      });
    }

    return () => {
      navigator.serviceWorker.removeEventListener('message', handleMessage);
      navigator.serviceWorker.removeEventListener('controllerchange', handleControllerChange);
    };
  }, [serviceWorkerIsEnabled]);

  React.useEffect(() => {
    const handleOnlineChange = (online: boolean) => {
      if (enableOffline && online && navigator.serviceWorker.controller) {
        void getAccessToken().then((token) => {
          navigator.serviceWorker.controller.postMessage({
            type: ServiceWorkerActionTypes.REQUEST_SYNC,
            payload: { token }
          });
        });
      }
    };

    NetworkStatus.subscribe(handleOnlineChange);

    return () => NetworkStatus.unsubscribe(handleOnlineChange);
  }, [enableOffline]);

  const handleMessage = (e: MessageEvent<ServiceWorkerMessage>) => {
    const { data: message } = e;
    const worker = navigator.serviceWorker.controller;

    switch (message.type) {
      case ServiceWorkerActionTypes.SUCCESS_INIT: {
        if (!sessionStorage.getItem(OFFLINE_TOAST_SESSION_KEY)) {
          showNotification(intl.formatMessage({ id: 'notification.readyToWorkOffline' }));
          sessionStorage.setItem(OFFLINE_TOAST_SESSION_KEY, 'true');
        }
        break;
      }
      case ServiceWorkerActionTypes.REQUEST_INIT: {
        void getAccessToken().then((token) => {
          worker?.postMessage({
            type: ServiceWorkerActionTypes.REQUEST_SYNC,
            payload: { token }
          });
        });
        break;
      }
      case ServiceWorkerActionTypes.SUCCESS_SYNC: {
        if (message.payload === 0) {
          // no offline data was synced
          return;
        }

        showNotification(
          intl.formatMessage(
            { id: 'notification.syncedPendingRegistrations' },
            { count: message.payload }
          )
        );

        syncOfflineData({ lastSyncTimestamp: Date.now() });
        break;
      }
      case ServiceWorkerActionTypes.REQUEST_SYNC: {
        void getAccessToken().then((token) => {
          worker?.postMessage({
            type: ServiceWorkerActionTypes.REQUEST_SYNC,
            payload: { token }
          });
        });
        break;
      }
      case ServiceWorkerActionTypes.NETWORK_STATUS: {
        NetworkStatus.changeOnlineStatus(message.payload);
        break;
      }
      default: {
        console.log('[ServiceWorker]: received unsupported message from sw ', message);
      }
    }
  };

  const activateWorker = (worker: ServiceWorker, options: ActiveWorkerOptions = {}) => {
    const { freshInstall = false, silentUpdate = false } = options;

    // app was loaded without sw, reload page right away to activate new sw
    if (freshInstall) {
      window.location.reload();
      return;
    }

    // app was loaded with sw, but there's a new version awaiting
    if (silentUpdate) {
      worker.postMessage({ type: ServiceWorkerActionTypes.SKIP_WAITING });
      return;
    }

    // app was loaded without a new sw update, but an update was discovered while user has been using the app

    // when there's an update, it wont become active, until all clients (tabs) are closed and user then navigates back to our app.
    // here we make service worker to invoke self.skipWaiting, so it's enough to just reload the page
    showNotification(intl.formatMessage({ id: 'notification.updateAvailable' }), {
      id: 'update-available',
      persist: true,
      preventDuplicate: true,
      action: (
        <AutoActivateButton
          onTimeout={() => {
            worker.postMessage({ type: ServiceWorkerActionTypes.SKIP_WAITING });
            closeNotification('update-available');
          }}
        />
      )
    });
  };

  const registerWorker = async () => {
    const workerUrl = `${ASSET_PATH}service-worker.js?q=${btoa(
      JSON.stringify({
        ...window.sysvars,
        AUTH_CLIENT:
          client === 'scale' ? window.sysvars.AUTH_SCALE_CLIENT : window.sysvars.AUTH_CLIENT
      })
    )}`;
    const registration = await navigator.serviceWorker.register(workerUrl);

    serviceWorkerRegistration.current = registration;

    // old sw exists
    if (registration.waiting) {
      activateWorker(registration.waiting, { silentUpdate: true });
    }

    // update checks are triggered by:
    // 1. navigation to an in-scope page
    // 2. functional events, eg push / sync
    // 3. calling serviceWorker.register(url), when the sw bytecode / url has changed
    // 4. manual invocation of registration.update()
    registration.addEventListener('updatefound', () => {
      if (registration.installing) {
        registration.installing.addEventListener('statechange', () => {
          if (registration.waiting) {
            if (navigator.serviceWorker.controller) {
              // there is old sw controlling the page
              onTimeout(() => activateWorker(registration.waiting), { timeout: IDLE_TIME_OUT });
              return;
            }
          }
        });
      }
    });
  };

  return null;
};

const mapStateToProps = (state: RootState) => ({
  enableOffline: state.settings.enableOffline,
  client: state.user.client
});

const mapDispatchToProps = {
  syncOfflineData
};

export default connect<StateProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(ServiceWorker);
