import * as React from 'react';
import { connect } from 'react-redux';
import Sustainability from 'pages/Report/Sustainability/Sustainability';
import { CHART_COLORS } from 'pages/Report';
import {
  getReportDashboardMetrics,
  getSustainabilityDashboardMetrics
} from 'redux/ducks/reportFilter/selectors';
import { useDownloadReportPDF } from 'pages/Report/utils/useDownloadPDF';
import DownloadBtn from 'pages/Report/components/DownloadBtn';
import { getChartPNGs } from 'pages/Report/Sustainability/utils/generateChartImages';
import { RootState } from 'redux/rootReducer';
import {
  getHighestLowestEmissionByCategory,
  getTotalGuests
} from 'redux/ducks/reportData/selector';
import { Dimension, ReportActions } from 'redux/ducks/reportFilter';
import { ThunkDispatch } from 'redux-thunk';
import * as reportsDispatch from 'redux/ducks/reportFilter';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type SustainabilityContainerProps = StateProps & DispatchProps;

const SustainabilityContainer: React.FunctionComponent<SustainabilityContainerProps> = (props) => {
  const intl = useIntl();
  const {
    chartRefs,
    foodWasteOverview,
    trendFoodWaste,
    foodWasteMetrics,
    emissionMetrics,
    highestLowestEmission,
    filter: {
      basis,
      dimension,
      timeRange: { from: startDate, to: endDate }
    },
    filter,
    dashboardMetrics,
    totalGuestRegistrations,
    setDimension
  } = props;

  useEffect(() => {
    void setDimension('co2');
  }, []);

  const [isDownloading, downloadPDF] = useDownloadReportPDF({
    generateChartPNG: () => getChartPNGs({ chartRefs }),
    reportData: {
      AsyncDocument: async () => (await import('./PDF')).default,
      data: {
        chartColors: CHART_COLORS,
        foodWasteOverview,
        trendFoodWaste,
        foodWasteMetrics,
        emissionMetrics,
        highestLowestEmission,
        filter,
        dashboardMetrics,
        totalGuestRegistrations,
        intl
      },
      basis,
      dimension,
      name: `sustainability-${startDate}-${endDate}`
    }
  });

  return (
    <Sustainability
      downloadButton={<DownloadBtn onClick={downloadPDF} isDownloading={isDownloading} />}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    chartRefs: state.charts.chartRefs,
    foodWasteOverview: state.reportData.foodWasteOverview.data,
    trendFoodWaste: state.reportData.trendFoodWaste.data,
    foodWasteMetrics: state.reportData.foodWasteMetricsOverview.data,
    emissionMetrics: getSustainabilityDashboardMetrics(state),
    highestLowestEmission: getHighestLowestEmissionByCategory(state),
    filter: state.reportFilter,
    dashboardMetrics: getReportDashboardMetrics(state),
    totalGuestRegistrations: getTotalGuests(state)
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, ReportActions>) => ({
  setDimension: (dimension: Dimension) => dispatch(reportsDispatch.changeDimension(dimension))
});

export default connect<StateProps, unknown, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(SustainabilityContainer);
