import * as React from 'react';
import { Link, IndexLink, LinkProps } from 'react-router';
import { ListItemIcon, MenuItem, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SidebarMenu } from 'styles/themes/global';
import classNames from 'classnames';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// duplicated code, need to refactor this
export interface MobileLinkProps {
  path?: string;
  href?: string;
  icon?: JSX.Element;
  text?: string;
  isActive?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  target?: string;
  isMobile?: boolean;
  isInline?: boolean;
  disabled?: boolean;
}

const DisabledLink: React.FunctionComponent<LinkProps> = (props) => {
  const { children } = props;
  return (
    <a role='link' aria-disabled='true'>
      {children}
    </a>
  );
};

const ExtLink: React.FunctionComponent<LinkProps> = (props) => {
  const { to, children, ...rest } = props;
  return (
    <a {...rest} href={to as string}>
      {children}
    </a>
  );
};

export const MobileLink = React.forwardRef<HTMLElement, MobileLinkProps>((props, ref) => {
  const classes = useStyles(props);
  const { path, href, icon, text, isActive, onClick, isInline, disabled, ...rest } = props;
  const hideText = useMediaQuery('(max-width:350px)') && !isInline;

  if (!path && !href) {
    return null;
  }

  const LinkComponent = disabled ? DisabledLink : href ? ExtLink : path === '/' ? IndexLink : Link;
  const to = href || path;

  // due to outdated react-router, we cant use refs properly on Link
  return (
    <LinkComponent className={classNames(classes.link)} to={to} title={text} {...rest}>
      <MenuItem
        disabled={disabled}
        ref={ref as React.RefObject<HTMLLIElement>}
        className={classNames(
          { [classes.menuItem]: !isInline },
          { [classes.menuItemInline]: isInline },
          { [classes.isActive]: isActive }
        )}
        onClick={onClick}
      >
        {icon && (
          <ListItemIcon
            className={classNames(classes.menuItemIcon, { [classes.isActive]: isActive })}
          >
            {icon}
          </ListItemIcon>
        )}
        <Typography
          className={classNames(
            { [classes.text]: !isInline },
            { [classes.textInline]: isInline },
            { [classes.textHidden]: hideText },
            { [classes.isActive]: isActive }
          )}
        >
          {text}
        </Typography>
      </MenuItem>
    </LinkComponent>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
    position: 'relative',
    display: 'flex',
    marginLeft: '-5px',
    marginRight: '-5px'
  },
  iconOnlyMenuItem: {},
  menuItemInline: {
    display: 'flex',
    flexDirection: 'row',
    padding: `${theme.spacing(2)}px 0`,
    width: '100%',
    alignItems: 'center'
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.spacing(1.55)}px 0`,
    width: '100%'
  },
  textInline: {
    fontSize: theme.typography.pxToRem(14)
  },
  text: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold'
  },
  isActive: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main
  },
  iconOnlyIcon: {
    minWidth: 'auto'
  },
  menuItemIcon: {
    width: SidebarMenu.collapsedWith,
    justifyContent: 'center'
  },
  textHidden: {
    display: 'none'
  }
}));
