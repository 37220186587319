import * as React from 'react';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button/Button';
import { connect } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { selectReportDataIsLoading } from 'redux/ducks/reportData/selector';
import { Spinner } from 'components/LoadingPlaceholder';

type StateProps = ReturnType<typeof mapStateToProps>;
export type ApplyButtonProps = ButtonProps & StateProps;

const ApplyButton = React.forwardRef<HTMLButtonElement, ApplyButtonProps>((props, ref) => {
  const { reportsAreLoading, children, disabled, ...rest } = props;

  return (
    <Button {...rest} ref={ref} disabled={reportsAreLoading || disabled}>
      {reportsAreLoading ? <Spinner size='xs' /> : children}
    </Button>
  );
});

const mapStateToProps = (state: RootState) => ({
  reportsAreLoading: selectReportDataIsLoading(state)
});

export default connect<StateProps, unknown, unknown>(mapStateToProps)(
  ApplyButton
) as unknown as typeof Button;
