export interface Feature {
  id: string;
  name: string;
}

type FeaturesStatus = 'loading' | 'fetched' | 'init';

export interface FeaturesState {
  features: Feature[];
  status: FeaturesStatus;
}

export enum FeaturesActionTypes {
  GET_FEATURES = 'esmiley/features/GET_FEATURES',
  SET_FEATURES_STATUS = 'esmiley/features/SET_FEATURES_STATUS'
}

type GetFeaturesAction = {
  type: typeof FeaturesActionTypes.GET_FEATURES;
  payload: Feature[];
};

type SetFeaturesStatusAction = {
  type: typeof FeaturesActionTypes.SET_FEATURES_STATUS;
  payload: FeaturesStatus;
};

export type FeaturesActions = GetFeaturesAction | SetFeaturesStatusAction;
