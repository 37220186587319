import { useIntl } from 'react-intl';
import SubmitButton from './SubmitButton';
import { Button, Grid, InputAdornment, Theme, Typography } from '@material-ui/core';
import WeightHandler from './WeightHandler';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import * as registrationDispatch from 'redux/ducks/registration';
import { RegistrationActions, ScaleStatus } from 'redux/ducks/registration';
import { makeStyles } from '@material-ui/core/styles';
import ScanButton from './ScanButton';
import { BluetoothSearching } from '@material-ui/icons';
import * as notificationDispatch from 'redux/ducks/notification';
import moment from 'moment';
import { Moment } from 'moment';
import { DatePicker } from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { NotificationActions } from 'redux/ducks/notification';
import ScaleConfirmationModal from 'pages/Registration/ScaleConfirmationModal';
import isRegistrationLockedForDate from 'pages/Registration/utils/isRegistrationLocked';
import WeightPerItemHandler from 'pages/Registration/Register/AmountInsertion/WeightPerItemHandler';

type StoreProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface ComponentProps extends StoreProps, DispatchProps {}

let hasPrevBleConnection = false;

const AmountInsertion: React.FunctionComponent<ComponentProps> = (props) => {
  const {
    date,
    scaleStatus: { isConnected: isScaleConnected, type: connectionType },
    setDate,
    canUseBluetooth,
    isScaleClient,
    hasEsmileyScale,
    hasEsmileyScaleConfirmed,
    skipRegistration,
    multipleRegistrationsDone,
    allowMultipleRegistrations,
    isLoading,
    enableLockedRegistrations,
    currentNodes,
    currentRegistrations
  } = props;
  const showScaleConnection = isScaleClient && hasEsmileyScale !== false;
  const [isConnecting, setConnecting] = useState<boolean>(false);
  const classes = useStyles(props);
  const intl = useIntl();

  const onDateChange = (newDate: Moment) => {
    const date = new Date(newDate.toDate());
    date.setHours(0, 0, 0, 0);
    setDate(date);
  };

  // hasPrevBleConnection is set to "true" only once, but only if we can use Bluetooth, and if a BLE connection has been made
  useEffect(() => {
    if (canUseBluetooth && !hasPrevBleConnection && isScaleConnected && connectionType === 'BLE') {
      hasPrevBleConnection = true;
    }
  }, [isScaleConnected, connectionType]);

  useEffect(() => {
    if (canUseBluetooth) {
      window.addEventListener('message', onReceivePostMessage);

      // Trigger a scan when this component has been mounted
      // The reasons to do this is both to have an extra scan in the event of the first one failing,
      // but also to "teach" the user on the connecting / connected state
      // This scan is triggered only if a previous connection has been made and the scale is not currently connected
      if (
        hasPrevBleConnection &&
        !isScaleConnected &&
        window.ReactNativeWebView &&
        window.ReactNativeWebView.postMessage
      ) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ startScan: true }));
      }

      return () => {
        window.removeEventListener('message', onReceivePostMessage);
      };
    }
  }, []);

  const currentRegistrationPoint = useMemo(() => {
    const completedNodes = currentRegistrations.map((r) => r.id);
    const nodes = currentNodes.filter((node) => !completedNodes.includes(node.id));
    return nodes[0];
  }, [currentNodes, currentRegistrations]);

  // These messages are sent from app-scale. That's how we communicate with the React Native app.
  const onReceivePostMessage = (event: MessageEvent<ScaleStatus>) => {
    if (!event || !event.data) return;

    if (event.data.hasOwnProperty('isConnecting')) {
      setConnecting((prevState) => {
        triggerNoConnectionNotification(prevState, event.data);

        return event.data.isConnecting;
      });
    }
  };

  /**
   *  Communicate to the user when a connection attempt was not successful,
   *  so that they can take some measures like enabling Bluetooth, if disabled.
   *  This "Bluetooth" part of the message is especially needed for iOS,
   *  because on Android we automatically trigger the "Enable Bluetooth" popup, but we are not allowed to do that on iOS.
   *
   * @param {boolean} prevIsConnecting - previous state of isConnecting upon state change
   * @param {ScaleStatus} scaleStatus - the message received from the app, containing scale status details
   */
  const triggerNoConnectionNotification = (prevIsConnecting: boolean, scaleStatus: ScaleStatus) => {
    const isNoLongerTryingToConnect =
      prevIsConnecting === true && scaleStatus.isConnecting === false;
    const hasNoConnectedScale =
      !scaleStatus.hasOwnProperty('isConnected') || !scaleStatus.isConnected;

    if (isNoLongerTryingToConnect && hasNoConnectedScale) {
      const { showNotification } = props;
      void showNotification(
        intl.formatMessage({ id: 'registration.scale.notification.couldNotConnect' }),
        true
      );
    }
  };

  return (
    <Grid className='amount-insertion' container spacing={4}>
      <DatePicker
        className={classes.mobilePicker}
        format='L'
        value={moment(date)}
        onChange={onDateChange}
        inputProps={{ style: { textAlign: 'right', fontSize: '1.2rem', width: '100%' } }}
        InputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position='end' classes={{ root: classes.pickerInputAdornment }}>
              <EventIcon style={{ width: '1.5rem', height: '1.5rem', marginRight: '-100px' }} />
            </InputAdornment>
          )
        }}
        shouldDisableDate={(date) =>
          isRegistrationLockedForDate(new Date(date.toDate()), enableLockedRegistrations)
        }
      />
      {currentRegistrationPoint?.registerPerItem ? (
        <WeightPerItemHandler currentRegistrationPoint={currentRegistrationPoint} date={date} />
      ) : (
        <WeightHandler />
      )}
      {!showScaleConnection && (
        <Typography variant='caption' className={classes.statusText}>
          {intl.formatMessage({
            id: `registration.scale.error.body${
              currentRegistrationPoint?.registerPerItem ? '.perItem' : ''
            }`
          })}
        </Typography>
      )}
      <Grid item xs={12}>
        {showScaleConnection && (
          <div className={classes.scaleHelp}>
            <div className={classes.scaleHelpSubContainer}>
              <div
                className={isConnecting ? classes.scaleHelpInnerConnecting : classes.scaleHelpInner}
              >
                <div className={classes.scaleHelpTitleContainer}>
                  <Typography variant='body1' component='span' className={classes.scaleHelpTitle}>
                    {isConnecting && canUseBluetooth
                      ? intl.formatMessage({ id: 'registration.scale.connectingToScale' })
                      : isScaleConnected
                      ? connectionType
                        ? intl.formatMessage({
                            id: `registration.scale.connected.via${connectionType}`
                          })
                        : intl.formatMessage({ id: 'registration.scale.connected.viaUSB' })
                      : intl.formatMessage({ id: 'registration.scale.error.title' })}
                  </Typography>
                  {!isConnecting &&
                    (isScaleConnected ? (
                      <CheckCircleIcon className='teal-fill status-icon' />
                    ) : (
                      <ErrorIcon className='red-fill status-icon' />
                    ))}
                </div>
                {!isConnecting && (
                  <Typography variant='caption' className={classes.statusText}>
                    {isScaleConnected
                      ? intl.formatMessage({ id: 'registration.scale.ok.body' })
                      : intl.formatMessage({ id: 'registration.scale.error.body' })}
                  </Typography>
                )}
              </div>
              {!isScaleConnected &&
                canUseBluetooth &&
                (isConnecting ? (
                  <div className={classes.connectingIconContainer}>
                    <BluetoothSearching className={classes.connectingIcon} />
                  </div>
                ) : (
                  <div className={classes.scanButton}>
                    <ScanButton />
                  </div>
                ))}
            </div>
          </div>
        )}
        <SubmitButton disabled={isLoading} fullWidth>
          {multipleRegistrationsDone
            ? intl.formatMessage({ id: 'registration.btnFinished' })
            : intl.formatMessage({ id: 'registration.btn' })}
        </SubmitButton>
        {allowMultipleRegistrations && (
          <Button
            color='primary'
            className={classes.skipButtonSpacing}
            variant='outlined'
            type={'button'}
            onClick={skipRegistration}
            fullWidth
            disabled={multipleRegistrationsDone}
          >
            {intl.formatMessage({ id: 'skip' })}
          </Button>
        )}
      </Grid>
      {showScaleConnection && !hasEsmileyScaleConfirmed && <ScaleConfirmationModal />}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  scanButton: {
    '& .MuiIconButton-root': {
      width: '56px',
      height: '56px',
      marginLeft: '56px',
      display: 'flex',
      marginTop: '-10px'
    }
  },
  connectingIcon: {
    fill: 'rgb(0, 150, 136)',
    color: 'rgb(0, 150, 136)',
    width: 30,
    height: 30,
    marginLeft: '-30px'
  },
  connectingIconContainer: {
    display: 'flex',
    top: '50%',

    '&:before': {
      content: '""',
      width: 30,
      height: 30,
      backgroundColor: 'rgb(0, 150, 136)',
      borderRadius: '50%',
      transition: 'opacity .3s, transform .3s',
      animation: `$pulse 2.5s cubic-bezier(0.25, 1, 0.5, 1) infinite`,
      zIndex: -1
    }
  },
  '@keyframes pulse': {
    '0%': {
      opacity: 0.6,
      transform: 'scale(1) translate3d(0,0,0)'
    },
    '50%': {
      opacity: 0,
      transform: 'scale(2.5) translate3d(0,0,0)'
    },
    '100%': {
      opacity: 0,
      transform: 'scale(2.5) translate3d(0,0,0)'
    }
  },
  statusText: {
    textAlign: 'center',
    display: 'inline-block',
    width: '100%',
    ['@media screen and (max-width: 599px)']: {
      textAlign: 'left'
    }
  },
  mobilePicker: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'end',
    width: '100%',
    marginLeft: '30px',
    marginTop: '7px'
  },
  pickerInputAdornment: {
    marginRight: '-40px'
  },
  scaleHelp: {
    minHeight: theme.spacing(2),
    margin: `${theme.spacing(2)}px 0`,
    ['@media screen and (max-width: 599px)']: {
      display: 'flex',
      flexFlow: 'row nowrap'
    }
  },
  scaleHelpInner: {
    width: '100%',
    marginRight: '-100px',
    ['@media screen and (max-width: 750px)']: {
      marginRight: '-40px'
    },
    ['@media screen and (max-width: 599px)']: {
      marginRight: '0px'
    }
  },
  scaleHelpInnerConnecting: {
    width: '100%',
    marginRight: '-50px',
    ['@media screen and (max-width: 750px)']: {
      marginRight: '-40px'
    },
    ['@media screen and (max-width: 599px)']: {
      marginRight: '0px'
    }
  },
  scaleHelpSubContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center'
  },
  scaleHelpTitleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ['@media screen and (max-width: 599px)']: {
      justifyContent: 'initial'
    }
  },
  scaleHelpTitle: {
    marginRight: theme.spacing(1),
    fontSize: '0.9rem',
    fontWeight: 500,
    color: theme.palette.text.primary
  },
  skipButtonSpacing: {
    margin: '18px 0 0 0',
    height: '56px',
    fontSize: '22px',
    lineHeight: '56px',
    [theme.breakpoints.up('lg')]: {
      height: '95px',
      lineHeight: '95px'
    },
    [theme.breakpoints.down('sm')]: {
      height: '75px',
      lineHeight: '75px'
    },
    ['@media (max-height: 631px)']: {
      height: '56px',
      fontSize: '22px',
      lineHeight: '56px'
    },
    ['@media (max-width: 480px)']: {
      height: '56px',
      lineHeight: '56px'
    }
  }
}));

const mapStateToProps = (state: RootState) => ({
  isLoading: state.registration.loading,
  isScaleClient: state.user.client === 'scale',
  scaleStatus: state.registration.scaleStatus,
  date: state.registration.date,
  canUseBluetooth: state.settings.canUseBluetooth,
  hasEsmileyScale: state.settings.hasEsmileyScale,
  hasEsmileyScaleConfirmed: state.settings.hasEsmileyScaleConfirmed,
  multipleRegistrationsDone:
    state.registration.areRegistrationsValid && state.registration.currentRegistrations.length > 0,
  allowMultipleRegistrations:
    state.settings.allowMultipleRegistrations && state.registration.displayMultipleRegistration,
  enableLockedRegistrations: state.settings.enableLockedRegistrations,
  currentNodes: state.registration.currentNodes,
  currentRegistrations: state.registration.currentRegistrations
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, void, RegistrationActions | NotificationActions>
) => ({
  setDate: (date: Date) => {
    dispatch(registrationDispatch.setDate(date));
  },
  showNotification: (message: string, isError: boolean, icon?: JSX.Element) => {
    dispatch(notificationDispatch.showNotification(message, isError || false, icon ? icon : null));
  },
  skipRegistration: () => {
    dispatch(registrationDispatch.updateMultipleRegistrations('skipped'));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AmountInsertion);
