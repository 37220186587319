import * as React from 'react';
import { useIntl } from 'react-intl';
import { Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { Reason } from 'redux/ducks/reasons';
import { SelectReasonOption } from 'pages/Registration/Register/Overview/SelectReason/SelectReasonOption';
import { makeStyles } from '@material-ui/core/styles';

interface OwnProps {
  isOpen: boolean;
  onClose: () => void;
  reasons: Reason[];
  selected?: Reason;
  onSelect: (reason: Reason) => void;
}

type SelectReasonDialogProps = OwnProps;

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold'
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '23px'
  },
  button: {
    background: 'rgb(0, 150, 136)',
    color: '#ffffff',
    width: '145px',
    height: '40px',
    '&:hover': {
      background: 'rgba(0, 150, 136, 0.4)'
    },
    '&:disabled': {
      background: '#ffffff'
    }
  },
  contentContainer: {
    padding: 0,
    paddingLeft: '38px',
    paddingRight: '15px',
    width: '100%',
    overflowX: 'hidden'
  }
}));

const SelectReasonDialog: React.FunctionComponent<SelectReasonDialogProps> = (props) => {
  const intl = useIntl();
  const { isOpen, onClose, reasons, selected = {} as Reason, onSelect } = props;
  const classes = useStyles();
  const [draft, setDraft] = React.useState(selected);

  const handleTempSelect = (reason: Reason) => {
    setDraft(reason);
  };

  const handleApply = () => {
    onSelect(draft);
    onClose();
  };

  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle>
        <span className={classes.title}>
          {intl.formatMessage({ id: 'registration.selectReason' })}
        </span>
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <div>
          {reasons.map((reason, index) => (
            <SelectReasonOption
              onClick={handleTempSelect}
              selected={reason.id === draft.id}
              reason={reason}
              key={index}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions className={classes.buttonWrapper}>
        <Button onClick={onClose}>{intl.formatMessage({ id: 'base.cancel' })}</Button>
        <Button className={classes.button} onClick={handleApply}>
          {intl.formatMessage({ id: 'base.apply' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default SelectReasonDialog;
