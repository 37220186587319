import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { AccountPointFilterWithNames } from 'redux/ducks/reportFilter/selectors';
import AccountSelect from 'pages/Report/components/AccountSelect';
import RegistrationPointSelect from 'pages/Report/components/RegistrationPointSelect';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import { LABELS } from 'utils/labels';

const InputLabels = {
  area: 'report.filter.filterIn',
  category: 'report.filter.for',
  product: 'report.filter.andSpecifically'
};

export interface AccountGroupSelectProps {
  classes?: {
    root?: string;
    accountSelect?: string;
    registrationPointSelect?: string;
  };
  inline?: boolean;
  group: AccountPointFilterWithNames;
  onAccountChange: (accounts: string[]) => void;
  onRegistrationPointChange: (points: { [label: string]: string[] }) => void;
}

const AccountGroupSelect: React.FunctionComponent<AccountGroupSelectProps> = (props) => {
  const intl = useIntl();
  const {
    classes: classesProp = {},
    group,
    onAccountChange,
    onRegistrationPointChange,
    inline
  } = props;

  const classes = useStyles();

  return (
    <div className={classNames(classes.AccountGroupSelect, classesProp.root)}>
      <div className={classNames(classes.accountFilter, classesProp.accountSelect)}>
        <Typography
          component='div'
          className={classNames({
            [classes.label]: !inline,
            [classes.labelInline]: inline
          })}
        >
          {intl.formatMessage({ id: 'department.other' })}
        </Typography>
        <AccountSelect
          disableRankFilters
          avatarProps={{ className: classes.avatarTransparent }}
          availableAccounts={group.availableAccounts}
          selectedAccounts={group.accounts}
          accountQuery={group.accountQuery}
          onChange={(accounts) => onAccountChange(accounts)}
        />
      </div>
      <div
        className={classNames(
          classes.registrationPointFilterList,
          classesProp.registrationPointSelect
        )}
      >
        {LABELS.map((label, index) => (
          <div
            className={classNames(classes.registrationPointFilterListItem, {
              [classes.registrationPointFilterListItemNotFirst]: index > 0
            })}
            key={`registration_point_${label}`}
          >
            <Typography
              component='div'
              className={classNames({
                [classes.label]: !inline,
                [classes.labelInline]: inline
              })}
            >
              {intl.formatMessage({ id: InputLabels[label] })}
            </Typography>
            <RegistrationPointSelect
              label={label}
              available={group.availableRegistrationPoints[label]}
              selected={group.selectedRegistrationPoints[label]}
              onChange={(label, names) => onRegistrationPointChange({ [label]: names })}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const CustomFilterRowBreakpointInPx = 1500;

const useStyles = makeStyles((theme) => ({
  avatarTransparent: {
    color: 'inherit',
    backgroundColor: 'transparent'
  },
  AccountGroupSelect: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  labelInline: {
    fontSize: theme.typography.pxToRem(12),
    width: '100%',
    marginRight: 'initial',
    marginBottom: theme.typography.pxToRem(5)
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12),
      width: '100%',
      marginRight: 'initial',
      marginBottom: theme.typography.pxToRem(5)
    }
  },
  accountFilter: {
    flexGrow: 2,
    marginRight: theme.spacing(2),
    maxWidth: '40%'
  },
  registrationPointFilterList: {
    display: 'flex',
    flex: '1 1 auto'
  },
  registrationPointFilterListItem: {
    flex: '1 1 auto',
    [theme.breakpoints.down(CustomFilterRowBreakpointInPx)]: {
      flexFlow: 'row wrap'
    }
  },
  registrationPointFilterListItemNotFirst: {
    marginLeft: theme.spacing(1)
  }
}));
export default AccountGroupSelect;
