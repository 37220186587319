import * as React from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';
import { Reason } from 'redux/ducks/reasons';
import { makeStyles } from '@material-ui/core/styles';

interface SelectReasonOptionProps {
  reason: Reason;
  selected?: boolean;
  onClick: (reason: Reason) => void;
}

const useStyles = makeStyles((theme) => ({
  radio: {
    '&$checked': {
      color: '#018b87'
    }
  },
  checked: {},
  formControlLabelRoot: {
    width: '100%',
    border: '2px solid #c4e4e2',
    borderRadius: '1px',
    padding: '10px 10px 10px 10px',
    marginBottom: '15px'
  },
  formControlLabelRootChecked: {
    width: '100%',
    padding: '10px 10px 10px 10px',
    backgroundColor: '#e0f2f1',
    borderRadius: '1px',
    border: '2px solid #e0f2f1',
    marginBottom: '15px'
  },
  formControlLabel: {
    color: theme.palette.text.primary,
    fontSize: '16px'
  },
  formControlLabelBold: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 'bold'
  }
}));

export const SelectReasonOption: React.FunctionComponent<SelectReasonOptionProps> = (props) => {
  const { onClick, selected, reason } = props;
  const { name: ReasonName, id: ReasonId } = reason;
  const classes = useStyles();

  const isActiveRoot = selected
    ? classes.formControlLabelRootChecked
    : classes.formControlLabelRoot;
  const isActiveLabel = selected ? classes.formControlLabelBold : classes.formControlLabel;

  const handleChange = () => {
    onClick(reason);
  };

  return (
    <FormControlLabel
      classes={{
        root: isActiveRoot,
        label: isActiveLabel
      }}
      value={ReasonId}
      name={ReasonName}
      checked={selected}
      control={
        <Radio
          disableRipple
          onChange={handleChange}
          classes={{ root: classes.radio, checked: classes.checked }}
        />
      }
      label={ReasonName}
    />
  );
};
