import * as React from 'react';
import { groupBy } from 'utils/array';
import { Button, Grid, IconButton } from '@material-ui/core';
import CategorySelector from './CategorySelector';
import { SelectedMetric } from 'pages/Report/Mail/components/MailReportEditor';
import CategoryMetricSelector from './CategoryMetricSelector';
import { ReportMailCategory, ReportMetric } from 'pages/Report/Mail/api';
import AccountGroupSelector, {
  AccountGroup
} from 'pages/Report/Mail/components/AccountGroupSelector';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { RootState } from 'redux/rootReducer';
import { connect } from 'react-redux';

type StateProps = ReturnType<typeof mapStateToProps>;

export interface MetricSelectorProps {
  category: ReportMailCategory;
  onCategoryChange: (category: ReportMailCategory) => void;
  accountGroups: AccountGroup[];
  onAccountChange: (accountGroups: AccountGroup[]) => void;
  metrics: ReportMetric[];
  selected: SelectedMetric;
  onMetricChange: (metrics: SelectedMetric) => void;
}

type ComponentProps = StateProps & MetricSelectorProps;

type CategoryMetrics = {
  [key in ReportMailCategory]: ReportMetric[];
};

const groupMetrics = (metrics: ReportMetric[]): CategoryMetrics => {
  const all = { all: metrics };
  const grouped = groupBy(metrics, 'category') as CategoryMetrics;
  return { ...all, ...grouped };
};

const noRightBorderStyle = { borderBottomRightRadius: 0 };

const MetricSelector: React.FunctionComponent<ComponentProps> = (props) => {
  const intl = useIntl();
  const {
    metrics,
    selected,
    onMetricChange,
    accountGroups,
    onAccountChange,
    category,
    onCategoryChange,
    accountId
  } = props;
  const classes = useStyles();
  const metricsByCategory = React.useMemo(() => groupMetrics(metrics), [metrics]);
  const categories = React.useMemo(
    () => Object.keys(metricsByCategory),
    [metricsByCategory]
  ) as ReportMailCategory[];
  const isAccountMetric = category === 'departments';

  const handleGroupChange = (index: number, group: AccountGroup) => {
    onAccountChange(accountGroups.map((g, i) => (i === index ? group : g)));
  };

  const handleAddGroup = () => {
    onAccountChange([
      ...accountGroups,
      { departments: [accountId], area: [], category: [], product: [] }
    ]);
  };

  const handleDeleteGroup = (index: number) => {
    if (index === 0) {
      return;
    }
    onAccountChange(accountGroups.filter((_, i) => i !== index));
  };

  return (
    <Grid container item>
      <Grid item xs={12} sm={3}>
        <CategorySelector selected={category} onChange={onCategoryChange} categories={categories} />
      </Grid>
      <Grid item xs={12} sm={9}>
        <CategoryMetricSelector
          style={isAccountMetric && accountGroups.length > 0 ? noRightBorderStyle : undefined}
          metrics={metricsByCategory[category]}
          selected={selected}
          onChange={onMetricChange}
        />
        {isAccountMetric && (
          <div>
            {accountGroups.map((group, index) => (
              <div key={`account_group_${index}`} className={classes.accountGroupRow}>
                {index > 0 && (
                  <IconButton
                    className={classes.accountRemoveButton}
                    onClick={() => handleDeleteGroup(index)}
                  >
                    <CloseIcon />
                  </IconButton>
                )}
                <AccountGroupSelector
                  inline
                  classes={{ root: classes.accountGroup }}
                  group={group}
                  onGroupChange={(group) => handleGroupChange(index, group)}
                />
              </div>
            ))}
            <Button
              variant='contained'
              color='primary'
              onClick={handleAddGroup}
              className={classes.compareButton}
            >
              {intl.formatMessage({ id: 'base.compare' })}
            </Button>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  accountGroupRow: {
    position: 'relative',
    border: `1px solid ${colors.grey[200]}`,
    padding: '30px 20px 20px',
    '&:first-child': {
      marginTop: '-1px'
    },
    '&:nth-child(odd)': {
      backgroundColor: '#f9f9f9'
    }
  },
  accountRemoveButton: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  accountGroup: {
    overflowX: 'auto'
  },
  compareButton: {
    marginTop: theme.spacing(1)
  }
}));

const mapStateToProps = (state: RootState) => ({
  accountId: state.user.accountId
});

export default connect<StateProps, unknown, MetricSelectorProps>(mapStateToProps)(MetricSelector);
