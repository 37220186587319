import {
  SubAccount,
  SubAccountActions,
  SubAccountActionTypes,
  SubAccounts,
  SubAccountState
} from 'redux/ducks/subAccounts/types';
import { ThunkResult } from 'redux/types';
import * as errorDispatch from 'redux/ducks/error';
import { ErrorActions } from 'redux/ducks/error';
import { AxiosResponse } from 'axios';
import { DataTransfer } from 'frontend-core';
import { compare } from 'fast-json-patch';
import { UserActions, UserActionTypes } from 'redux/ducks/user';

const transfer = new DataTransfer();

export const initialState: SubAccountState = {
  subscribed: [],
  unsubscribed: [],
  // all: []
  state: 'init'
};

export default function reducer(
  state: SubAccountState = initialState,
  action: SubAccountActions | UserActions
): SubAccountState {
  switch (action.type) {
    case UserActionTypes.USER_LOADED: {
      const { subAccounts } = action.payload;
      return { ...state, ...subAccounts, state: 'loaded' };
    }
    case SubAccountActionTypes.GET_SUB_ACCOUNTS:
      return { ...state, ...action.payload, state: 'loaded' };
    case SubAccountActionTypes.GET_SUB_ACCOUNTS_REQUEST:
      return { ...state, state: 'loading' };
    case SubAccountActionTypes.UPDATE_SUB_ACCOUNTS: {
      return { ...state, ...action.payload, state: 'loaded' };
    }
    case SubAccountActionTypes.UPDATE_SUB_ACCOUNTS_REQUEST: {
      return { ...state, state: 'pending' };
    }
    default:
      return state;
  }
}

export function fetchSubAccounts(): ThunkResult<
  Promise<SubAccountActions | ErrorActions>,
  SubAccountActions | ErrorActions
> {
  return async (dispatch) => {
    try {
      dispatch({ type: SubAccountActionTypes.GET_SUB_ACCOUNTS_REQUEST });
      const subAccountsResponse = (await transfer.get(
        `/foodwaste/sub-accounts`
      )) as AxiosResponse<SubAccounts>;
      return dispatch({
        type: SubAccountActionTypes.GET_SUB_ACCOUNTS,
        payload: subAccountsResponse.data
      });
    } catch (error: unknown) {
      return dispatch(errorDispatch.displayError(error as Error));
    }
  };
}

export function updateSubAccounts(
  subscribedIds: SubAccount['id'][]
): ThunkResult<Promise<SubAccountActions | ErrorActions>, SubAccountActions | ErrorActions> {
  return async (dispatch, getState) => {
    try {
      const { subscribed: oldSubscribed } = getState().subAccounts;
      const oldSubIds = oldSubscribed.map((sub) => sub.id);
      const sortedNextIds = subscribedIds.sort((a, b) => parseInt(a) - parseInt(b));
      const patchOps = compare({ subscribed: oldSubIds }, { subscribed: sortedNextIds });

      dispatch({ type: SubAccountActionTypes.UPDATE_SUB_ACCOUNTS_REQUEST });
      const subAccountsResponse = (await transfer.patch(
        `/foodwaste/sub-accounts`,
        patchOps
      )) as AxiosResponse<SubAccounts>;
      return dispatch({
        type: SubAccountActionTypes.UPDATE_SUB_ACCOUNTS,
        payload: subAccountsResponse.data
      });
    } catch (error: unknown) {
      return dispatch(errorDispatch.displayError(error as Error));
    }
  };
}
