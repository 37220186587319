import * as React from 'react';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button/Button';

const Component = (props: ButtonProps) => {
  const { children } = props;
  return (
    <Button
      className={'registrationButton'}
      type={'submit'}
      color='primary'
      variant='contained'
      {...props}
    >
      {children}
    </Button>
  );
};

export default Component;
