import { IntlShape } from 'react-intl';
import { Localization, Options } from 'material-table';

export const getLocalization = (intl: IntlShape): Localization => ({
  header: {
    actions: ''
  },
  body: {
    editTooltip: intl.formatMessage({ id: 'edit' }),
    deleteTooltip: intl.formatMessage({ id: 'data_table.delete' }),
    emptyDataSourceMessage: intl.formatMessage({ id: 'settings.content.noRegistrationPoints' }),
    editRow: {
      cancelTooltip: intl.formatMessage({ id: 'base.cancel' }),
      saveTooltip: intl.formatMessage({ id: 'base.ok' })
    }
  },
  pagination: {
    labelDisplayedRows: '{from}-{to}/{count}',
    previousTooltip: intl.formatMessage({ id: 'settings.content.table.prevPage' }),
    nextTooltip: intl.formatMessage({ id: 'settings.content.table.nextPage' }),
    labelRowsSelect: intl.formatMessage({ id: 'settings.content.table.rows' }),
    lastTooltip: intl.formatMessage({ id: 'table.lastPage' }),
    firstTooltip: intl.formatMessage({ id: 'table.firstPage' })
  },
  toolbar: {
    searchTooltip: intl.formatMessage({ id: 'search' }),
    searchPlaceholder: intl.formatMessage({ id: 'search' })
  }
});

export const options: Options = {
  padding: 'dense' as const,
  sorting: true,
  pageSize: 25,
  pageSizeOptions: [25, 50, 100],
  exportAllData: true,
  showTitle: false
};
