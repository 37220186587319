import * as React from 'react';
import { Box } from '@material-ui/core';
import Header from 'pages/Report/components/ReportPageLayout/components/Header';
import FilterHeader from 'pages/Report/components/FilterHeader';
import { RootState } from 'redux/rootReducer';
import { connect } from 'react-redux';
import LoadingPlaceHolder from 'components/LoadingPlaceholder';
import { makeStyles } from '@material-ui/styles';
import { ErrorBoundary } from 'react-error-boundary';
import ReportError from 'pages/Report/ReportError';

export { default as PageTitle } from './components/PageTitle';

interface PageProps {
  children: React.ReactElement | React.ReactElement[];
  disableMenu?: boolean;
  disableFilter?: boolean;
  disableHeader?: boolean;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type OwnProps = StateProps & PageProps;

const useStyles = makeStyles({
  spinner: {
    margin: '0 -32px'
  }
});

const ReportPageLayout: React.FunctionComponent<OwnProps> = (props) => {
  const classes = useStyles(props);
  const {
    children,
    disableMenu,
    isFilterInitialized,
    disableFilter = false,
    disableHeader
  } = props;
  return (
    <>
      <FilterHeader hidden={disableFilter} />
      <ErrorBoundary fallback={<ReportError />}>
        <Box px={2} mb={3}>
          {!disableHeader && <Header disableMenu={disableMenu} />}
          {disableMenu || isFilterInitialized || disableFilter ? (
            children
          ) : (
            <LoadingPlaceHolder className={classes.spinner} />
          )}
        </Box>
      </ErrorBoundary>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  isFilterInitialized: state.reportFilter.isInitialized
});

export default connect<StateProps, unknown, PageProps>(mapStateToProps)(ReportPageLayout);
