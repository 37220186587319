import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from 'redux/rootReducer';
import {
  WasteAmount,
  getSettings,
  WasteTargetKey,
  WasteTargetKeys,
  SavedSettings,
  OverrideSettings
} from 'redux/ducks/settings';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { update as updateSettings } from 'redux/ducks/settings';
import { Button, Card, CardActions, CardContent, Chip } from '@material-ui/core';

import moment from 'moment';
import CardHeader from 'pages/Settings/components/settings/goals/CardHeader';
import FoodwasteField from 'pages/Settings/components/settings/goals/FoodwasteField';
import SelectPeriodModal from 'pages/Settings/components/settings/goals/SelectPeriodModal';
import { isNumeric } from 'utils/math';
import { API_DATE_FORMAT } from 'utils/datetime';
import HelpText from 'components/HelpText';
import classNames from 'classnames';
import LockIcon from '@material-ui/icons/Lock';

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column wrap'
  },
  content: {
    flexGrow: 1
  },
  cardActions: {
    '& > :last-child': { marginLeft: 'auto' }
  },
  formLabel: {
    fontWeight: 600
  },
  switch: {
    display: 'flex',
    alignItems: 'center'
  }
});

const getDefaultTarget = (key: WasteTargetKey): WasteAmount => ({
  amount: null,
  unit: 'g',
  period: key === 'expectedFoodwaste' ? 'week' : 'fixed'
});

type SubState = {
  [K in WasteTargetKey]: WasteAmount;
};

// I couldnt figure out how to add showModal prop to SubState,
// threw type errors
type State = SubState & { showModal: boolean };

type Action =
  | { type: 'reset'; payload: State }
  | {
      type: 'change';
      payload:
        | { key: WasteTargetKey; value: WasteAmount }
        | { key: 'overrideChildSettings'; value: boolean };
    }
  | { type: 'toggleModal'; payload: boolean };

const initialState = WasteTargetKeys.reduce<State>(
  (state, key) => ({
    ...state,
    [key]: getDefaultTarget(key)
  }),
  { showModal: false } as State
);

const wasteTargetReducer = (state: State = initialState, action: Action): State => {
  switch (action.type) {
    case 'reset': {
      return action.payload;
    }
    case 'change': {
      const { key, value } = action.payload;
      return {
        ...state,
        [key]: value
      };
    }
    case 'toggleModal': {
      return {
        ...state,
        showModal: action.payload
      };
    }
  }
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type FoodwasteTargetCardProps = StateProps & DispatchProps;

const FoodwasteTargetCard: React.FunctionComponent<FoodwasteTargetCardProps> = (props) => {
  const classes = useStyles(props);
  const intl = useIntl();
  const { updateSettings, overrideChildSettings, hasSubscribedAccounts, lockedByAdmin } = props;
  const [draftState, dispatch] = React.useReducer(wasteTargetReducer, {
    ...WasteTargetKeys.reduce<State>(
      (state, key) => ({
        ...state,
        [key]: props[key] || getDefaultTarget(key)
      }),
      { showModal: false } as State
    )
  });

  const [overrideState, setOverrideState] = React.useState<undefined | 'show' | 'loading'>();

  const allLockedByAdmin = WasteTargetKeys.every((key) => lockedByAdmin[key]);
  const isActionEnabled =
    WasteTargetKeys.every(
      (key) => isNumeric(draftState[key].amount) && draftState[key].amount > 0
    ) &&
    WasteTargetKeys.some(
      (key) =>
        !props[key] ||
        props[key].amount !== draftState[key].amount ||
        props[key].period !== draftState[key].period
    );

  React.useEffect(() => {
    if (overrideState) {
      setOverrideState(undefined);
    }
  }, [overrideChildSettings]);

  const handleSave = (overridePrevious: boolean) => {
    if (overridePrevious) {
      const from = moment(new Date(0)).format(API_DATE_FORMAT);
      const nextGoalSettings = WasteTargetKeys.reduce<Partial<SavedSettings>>(
        (state, key) => ({
          ...state,
          [key]: [{ ...draftState[key], from }]
        }),
        {}
      );
      updateSettings(nextGoalSettings);
    } else {
      const from = moment().format(API_DATE_FORMAT);
      const nextGoalSettings = WasteTargetKeys.reduce<Partial<SavedSettings>>(
        (state, key) => ({
          ...state,
          [key]: [
            ...props[`${key}History`].filter((slot) => slot.from !== from),
            { ...draftState[key], from }
          ]
        }),
        {}
      );
      updateSettings(nextGoalSettings);
    }
    dispatch({ type: 'toggleModal', payload: false });
  };

  const handleOverride = (overridePrevious: boolean) => {
    setOverrideState('loading');
    const nextOverrideChildSettings = WasteTargetKeys.reduce(
      (all, curr) => ({
        ...all,
        [curr]: {
          overridePrevious,
          enabled: true
        }
      }),
      { ...overrideChildSettings } as OverrideSettings
    );

    updateSettings({ overrideChildSettings: nextOverrideChildSettings });
  };

  const handleUndo = () => {
    const stateFromProps = WasteTargetKeys.reduce<State>(
      (state, key) => ({
        ...state,
        [key]: props[key] || getDefaultTarget(key)
      }),
      { showModal: false } as State
    );

    dispatch({ type: 'reset', payload: stateFromProps });
  };

  const handleShowModal = () => {
    dispatch({ type: 'toggleModal', payload: true });
  };

  return (
    <Card className={classes.root}>
      <CardContent className={classes.content}>
        <CardHeader
          title={intl.formatMessage({ id: 'settings.personalFoodwasteTargets' })}
          titleHelpIcon={
            <HelpText
              helpText={intl.formatMessage({ id: 'settings.personalFoodwasteTargets.description' })}
            />
          }
        />
        <FoodwasteField
          name='total'
          label={intl.formatMessage({ id: 'base.total' })}
          value={draftState.expectedFoodwaste}
          disabled={lockedByAdmin.expectedFoodwaste}
          as={'kg'}
          onChange={(value) =>
            dispatch({ type: 'change', payload: { key: 'expectedFoodwaste', value } })
          }
        />
        <FoodwasteField
          name='perGuest'
          label={intl.formatMessage({ id: 'base.perGuest' })}
          value={draftState.expectedFoodwastePerGuest}
          disabled={lockedByAdmin.expectedFoodwastePerGuest}
          onChange={(value) =>
            dispatch({ type: 'change', payload: { key: 'expectedFoodwastePerGuest', value } })
          }
        />
        <FoodwasteField
          name='perGuestBaseline'
          label={intl.formatMessage({ id: 'base.perGuestBaseline' })}
          value={draftState.perGuestBaseline}
          disabled={lockedByAdmin.perGuestBaseline}
          onChange={(value) =>
            dispatch({ type: 'change', payload: { key: 'perGuestBaseline', value } })
          }
        />
        <FoodwasteField
          name='perGuestStandard'
          label={intl.formatMessage({ id: 'base.perGuestStandard' })}
          value={draftState.perGuestStandard}
          disabled={lockedByAdmin.perGuestStandard}
          onChange={(value) =>
            dispatch({ type: 'change', payload: { key: 'perGuestStandard', value } })
          }
        />
      </CardContent>
      <CardActions
        className={classNames({ [classes.cardActions]: allLockedByAdmin || hasSubscribedAccounts })}
      >
        <Button variant='text' onClick={handleUndo} disabled={allLockedByAdmin || !isActionEnabled}>
          {intl.formatMessage({ id: 'base.undo' })}
        </Button>
        <Button
          color='primary'
          variant='contained'
          onClick={handleShowModal}
          disabled={allLockedByAdmin || !isActionEnabled}
        >
          {intl.formatMessage({ id: 'base.save' })}
        </Button>
        {allLockedByAdmin && (
          <Chip
            variant='outlined'
            label={intl.formatMessage({ id: 'settings.targets.locked' })}
            icon={<LockIcon fontSize='small' />}
          />
        )}
        {hasSubscribedAccounts && (
          <Button
            disabled={isActionEnabled}
            color='primary'
            variant='contained'
            onClick={() => setOverrideState('show')}
          >
            {intl.formatMessage({ id: 'settings.targets.applyToSubaccounts' })}
          </Button>
        )}
      </CardActions>
      <SelectPeriodModal
        title={intl.formatMessage({ id: 'settings.personalFoodwasteTargets' })}
        content={intl.formatMessage({
          id: 'dashboard.widget.settings.applySettingsForPreviousPeriods.msg'
        })}
        open={draftState.showModal}
        onCancel={() => dispatch({ type: 'toggleModal', payload: false })}
        onAccept={() => {
          handleSave(true);
        }}
        onDecline={() => {
          handleSave(false);
        }}
      />
      <SelectPeriodModal
        title={intl.formatMessage({ id: 'settings.targets.modal.overrideTargets.title' })}
        content={intl.formatMessage({ id: 'settings.targets.modal.overrideContent' })}
        open={!!overrideState}
        isLoading={overrideState === 'loading'}
        onCancel={() => setOverrideState(undefined)}
        onAccept={() => {
          handleOverride(true);
        }}
        onDecline={() => {
          handleOverride(false);
        }}
      />
    </Card>
  );
};

const mapStateToProps = (state: RootState) => ({
  expectedFoodwaste: getSettings(state).currentExpectedFoodWaste,
  expectedFoodwasteHistory: getSettings(state).expectedFoodwaste || [],
  expectedFoodwastePerGuest: getSettings(state).currentExpectedFoodWastePerGuest,
  expectedFoodwastePerGuestHistory: getSettings(state).expectedFoodwastePerGuest || [],
  perGuestBaseline: getSettings(state).currentPerGuestBaseline,
  perGuestBaselineHistory: getSettings(state).perGuestBaseline || [],
  perGuestStandard: getSettings(state).currentPerGuestStandard,
  perGuestStandardHistory: getSettings(state).perGuestStandard || [],
  hasSubscribedAccounts: state.subAccounts.subscribed.length !== 0,
  overrideChildSettings: state.settings.overrideChildSettings || {},
  lockedByAdmin: getSettings(state).lockedByAdmin
});

const mapDispatchToProps = {
  updateSettings
};
export default connect<StateProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(FoodwasteTargetCard);
