import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';
import { RootState } from 'redux/rootReducer';
import { Feature } from 'redux/ducks/features/types';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

function setNested<T>(obj: Record<string, unknown>, path: string, value: T) {
  const keys = path.split('.');
  const lastKey = keys.pop();
  const lastObj = keys.reduce((obj, key) => (obj[key] = obj[key] || {}), obj);
  lastObj[lastKey] = value;
  return obj;
}

// Remove after PROD-8856 is released,
// which after name is only used
type BackwardCompatFeature = Feature & { variant?: string };

export const getFeaturesFlags = createDeepEqualSelector(
  (state: RootState) => state.features.features,
  (features) =>
    (features as BackwardCompatFeature[]).reduce((obj, { name, variant }) => {
      const featureName = variant ? `${name}.${variant}` : name;
      obj = setNested(obj, featureName, true);
      return obj;
    }, {})
);
