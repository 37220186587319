import { AdPopup, PopupActions, PopupActionTypes, PopupState } from './types';
import { ThunkResult } from 'redux/types';
import { ErrorActions } from 'redux/ducks/error';
import { AxiosResponse } from 'axios';
import * as errorDispatch from 'redux/ducks/error';
import { DataTransfer } from 'frontend-core';

export * from './types';

const transfer = new DataTransfer();

export const initialState: PopupState = {
  popups: [],
  activePopup: undefined
};

export default function reducer(
  state: PopupState = initialState,
  action: PopupActions
): PopupState {
  switch (action.type) {
    case PopupActionTypes.GET_POPUPS: {
      return {
        ...state,
        popups: action.payload,
        activePopup: action.payload[0]
      };
    }
    case PopupActionTypes.SHOW_POPUP: {
      return {
        ...state,
        activePopup: action.payload
      };
    }
    case PopupActionTypes.CLOSE_POPUP: {
      const remainingPopups = state.popups.filter((popup) => popup.id !== action.payload.id);
      return {
        popups: remainingPopups,
        activePopup: undefined
      };
    }

    default:
      return state;
  }
}

export function getPopups(): ThunkResult<
  Promise<PopupActions | ErrorActions>,
  PopupActions | ErrorActions
> {
  return async (dispatch) => {
    try {
      const response = (await transfer.get('/foodwaste/popups')) as AxiosResponse<AdPopup[]>;

      return dispatch({
        type: PopupActionTypes.GET_POPUPS,
        payload: response.data
      });
    } catch (error: unknown) {
      return dispatch(errorDispatch.displayError(error as Error));
    }
  };
}

export function closePopup(
  id: number
): ThunkResult<Promise<PopupActions | ErrorActions>, PopupActions | ErrorActions> {
  return async (dispatch) => {
    dispatch({
      type: PopupActionTypes.CLOSE_POPUP,
      payload: {
        id
      }
    });

    try {
      await transfer.get(`/foodwaste/popups/${id}`);
    } catch (error: unknown) {
      return dispatch(errorDispatch.displayError(error as Error));
    }
  };
}

export function showPopup(popup: AdPopup): PopupActions {
  return {
    type: PopupActionTypes.SHOW_POPUP,
    payload: popup
  };
}
