import * as React from 'react';
import { useAuth, useNetworkStatus } from 'frontend-core';
import { IconButton, Tooltip } from '@material-ui/core';
import { ServiceWorkerActionTypes, ServiceWorkerMessage } from 'components/ServiceWorker/types';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SyncIcon from '@material-ui/icons/Sync';

const useStyles = makeStyles({
  icon: {
    transform: 'scaleX(-1)'
  },
  rotate: {
    animation: '$spin 2s linear infinite'
  },
  '@keyframes spin': {
    '0%': {
      transform: 'scaleX(-1) rotate(360deg)'
    },
    '100%': {
      transform: 'scaleX(-1) rotate(0deg)'
    }
  }
});

export const SyncButton: React.FC = () => {
  const classes = useStyles();
  const { getAccessToken } = useAuth();
  const { online } = useNetworkStatus();
  const [pending, setPending] = React.useState(false);

  const serviceWorker = React.useRef<ServiceWorkerContainer>(navigator.serviceWorker);

  React.useEffect(() => {
    const handleMessageEvent = (event: unknown) => {
      handleMessage((event as { data: ServiceWorkerMessage }).data);
    };

    serviceWorker?.current.addEventListener('message', handleMessageEvent);

    return () => {
      serviceWorker?.current.removeEventListener('message', handleMessageEvent);
    };
  }, []);

  const handleMessage = (message: ServiceWorkerMessage): void => {
    switch (message.type) {
      case ServiceWorkerActionTypes.PENDING_SYNC: {
        setPending(true);
        break;
      }
      case ServiceWorkerActionTypes.SUCCESS_SYNC: {
        setPending(false);
        break;
      }
    }
  };

  const handleSync = () => {
    void getAccessToken().then((token) => {
      serviceWorker.current?.controller?.postMessage({
        type: ServiceWorkerActionTypes.REQUEST_SYNC,
        payload: { token }
      });
    });
  };

  if (!serviceWorker.current) {
    return null;
  }

  return (
    <Tooltip title='Sync'>
      <IconButton onClick={handleSync} disabled={!online || pending}>
        {pending ? (
          <SyncIcon className={classNames(classes.icon, classes.rotate)} />
        ) : (
          <CloudUploadIcon className={classes.icon} />
        )}
      </IconButton>
    </Tooltip>
  );
};
