import * as React from 'react';
import * as settingsDispatch from '../../../../../redux/ducks/settings';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import RecipientsList from './components/recipientsList';
import { onSubmitForm } from 'utils/helpers';
import { Select, MenuItem, Switch } from '@material-ui/core';
import { RootState } from 'redux/rootReducer';
import { Alarm, getSettings, SettingsActions } from 'redux/ducks/settings';

import './index.scss';
import { ThunkDispatch } from 'redux-thunk';
import TextAreaInput from 'components/TextAreaInput';
import { getAlarms } from '../../../../../redux/ducks/settings';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface OwnProps {
  maxMessageLength?: number;
}

type AlarmsPanelProps = StateProps & DispatchProps & OwnProps;

const DEFAULT_MAX_LENGTH = 140;

const AlarmsPanel: React.FC<AlarmsPanelProps> = (props) => {
  const intl = useIntl();
  const { alarm, save, maxMessageLength = DEFAULT_MAX_LENGTH } = props;

  const updateAlarm = (key: keyof Alarm, value: unknown): void => {
    void save({ ...alarm, [key]: value });
  };

  const getTimepickerHoursOptions = (): React.ReactElement[] => {
    const elements: React.ReactElement[] = [];
    for (let i = 0; i <= 23; i++) {
      elements.push(
        <MenuItem key={i} value={i.toString()}>
          {(i < 10 ? `0${i}` : i.toString()) + ':00'}
        </MenuItem>
      );
    }

    return elements;
  };

  const saveHandler = () => {
    void save(alarm);
  };

  const { enabled, executionTime, message, recipients } = alarm;

  return (
    <div className='alarmsPanel'>
      <form onSubmit={onSubmitForm(saveHandler)}>
        <div className='field'>
          <label htmlFor='toggler'>
            <span>{intl.formatMessage({ id: 'enabled' })}</span>
          </label>
          <div id='toggler' className='control'>
            <Switch
              color='primary'
              onChange={() => updateAlarm('enabled', !enabled)}
              checked={enabled}
            />
          </div>
          <div className='helpWrapper' />
        </div>
        <div className='field'>
          <label htmlFor='timePicker'>
            <span>{intl.formatMessage({ id: 'hourOfTheDay' })}</span>
          </label>
          <div id='timePicker' className='control'>
            <Select
              value={executionTime}
              fullWidth
              onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                updateAlarm('executionTime', e.target.value);
              }}
            >
              {getTimepickerHoursOptions()}
            </Select>
          </div>
          <div className='helpWrapper' />
        </div>
        <div className='field' style={{ alignItems: 'flex-start', marginTop: '12px' }}>
          <label htmlFor='message'>
            <span>{intl.formatMessage({ id: 'message' })}</span>
          </label>
          <div id='message' className='control'>
            <TextAreaInput
              name='message'
              maxLength={maxMessageLength}
              fullWidth
              rows={5}
              rowsMax={50}
              type='text'
              placeholder={intl.formatMessage({ id: 'settings.alarms.messagePlaceholder' })}
              multiline
              value={message}
              onChange={(value) => updateAlarm('message', value)}
            />
          </div>
          <div className='helpWrapper' />
        </div>

        <div className='field' style={{ alignItems: 'flex-start', marginTop: '12px' }}>
          <label htmlFor='recipient'>
            <span>{intl.formatMessage({ id: 'recipientsMultiple' })}</span>
          </label>
          <div id='recipient' className='control'>
            <RecipientsList
              value={recipients}
              onChange={(value) => {
                updateAlarm('recipients', value);
              }}
            />
          </div>
          <div className='helpWrapper' />
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  alarm: getAlarms(state),
  isInitial: getSettings(state).isInitial
});

const mapDispatchToProps = (dispatch?: ThunkDispatch<RootState, void, SettingsActions>) => ({
  save: (data: Alarm) => dispatch(settingsDispatch.update({ alarms: data }))
});

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(AlarmsPanel);
