import { useIntl } from 'react-intl';
import { Typography, CircularProgress } from '@material-ui/core';
import * as React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Spinner from 'components/LoadingPlaceholder/Spinner';
import palette from 'styles/palette';

interface OwnProps {
  title?: string;
  classes?: { [className: string]: string };
  className?: string;
  simple?: boolean;
}

type LoadingPlaceHolderProps = OwnProps;

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#eeeeee',
    display: 'flex',
    padding: 24,
    flexDirection: 'column' as const,
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontWeight: 600,
    textShadow: '0px 0px 2px rgba(34, 34, 34, 0.3)',
    marginTop: '2em !important'
  },
  spinnerColor: {
    color: palette.primary.main
  }
}));

function LoadingPlaceHolder({ title, className, simple }: LoadingPlaceHolderProps) {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div
      className={classNames('placeholder', classes.container, {
        [className]: !!className
      })}
    >
      {simple ? <CircularProgress className={classes.spinnerColor} thickness={10} /> : <Spinner />}
      <Typography variant='h6' className={classes.title}>
        {title || intl.formatMessage({ id: 'base.loading' })}
      </Typography>
    </div>
  );
}

export default LoadingPlaceHolder;
export { Spinner };
