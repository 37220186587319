import * as React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { RootState } from 'redux/rootReducer';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import MenuIcon from '@material-ui/icons/Menu';
import { Button, CircularProgress, Theme } from '@material-ui/core';
import { useAuth } from 'frontend-core';
import { useEffect } from 'react';

const useStyles = makeStyles(
  (theme: Theme) => ({
    menuButton: {
      position: 'relative',
      minHeight: '32px',
      minWidth: '180px',
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
      '& .MuiButton-startIcon': {
        [theme.breakpoints.down('sm')]: {
          margin: 0
        }
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        minWidth: '48px'
      },
      '&.blink': {
        animation: '$buttonBlink 3s infinite'
      }
    },
    menuButtonIcon: {
      fill: '#ffffff',
      '&.load': {
        fill: 'rgba(255, 255, 255, 0.2)'
      }
    },
    menuButtonText: {
      color: '#ffffff',
      '&.load': {
        color: 'rgba(255, 255, 255, 0.2)'
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    menuButtonSpinner: {
      color: '#ffffff',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: '-8px',
      marginLeft: '-8px'
    },
    '@keyframes buttonBlink': {
      '0%': {
        backgroundColor: theme.palette.primary
      },
      '50%': {
        backgroundColor: 'rgba(255, 255, 255, 0.4)'
      },
      '100%': {
        backgroundColor: theme.palette.primary
      }
    },
    '@keyframes menuBlink': {
      '0%': {
        backgroundColor: 'transparent'
      },
      '50%': {
        backgroundColor: 'rgba(33, 150, 243, 0.25)'
      },
      '100%': {
        backgroundColor: 'transparent'
      }
    }
  }),
  { name: 'NewModuleSelector' }
);

export interface OwnProps {
  isEnabled: boolean;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type NewModuleSelectorProps = OwnProps & StateProps;

const NewModuleSelector: React.FunctionComponent<NewModuleSelectorProps> = (props) => {
  const classes = useStyles(props);
  const intl = useIntl();
  const { locale, backToHaccp, isEnabled } = props;
  const { getAccessToken } = useAuth();
  const isGoNative = navigator.userAgent.includes('esmnative');
  const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
  const [modulesLoaded, setModulesLoaded] = React.useState<boolean>(false);

  useEffect(() => {
    if (isEnabled && anchor) {
      let styleTag: HTMLLinkElement = document.querySelector('#module-selector-styles');
      if (!styleTag) {
        styleTag = document.createElement('link');
        styleTag.id = 'module-selector-styles';
        styleTag.rel = 'stylesheet';
        styleTag.type = 'text/css';
        styleTag.href = `${window.sysvars.MODULE_SELECTOR_URL}/index.prefixed.css`;
        document.head.appendChild(styleTag);
      }

      let scriptTag: HTMLScriptElement = document.querySelector('#module-selector-script');
      if (!scriptTag) {
        scriptTag = document.createElement('script');
        scriptTag.id = 'module-selector-script';
        scriptTag.type = 'text/javascript';
        scriptTag.src = `${window.sysvars.MODULE_SELECTOR_URL}/index.umd.js`;
        document.head.appendChild(scriptTag);

        scriptTag.addEventListener('load', () => {
          window.ModuleSelector.render({
            locale,
            anchorElement: anchor,
            getAuthorizationHeader: getAccessToken,
            positionOptions: { placement: 'bottom-end', offset: 5 },
            onReady: handleIsReady
          });
        });
      }

      return () => {
        const moduleSelectorRoot = document.querySelector('#module-selector-root');
        document.body.removeChild(moduleSelectorRoot);
        document.head.removeChild(styleTag);
        document.head.removeChild(scriptTag);
      };
    }
  }, [locale, isEnabled, anchor]);

  const handleIsReady = () => {
    if (!modulesLoaded) {
      setModulesLoaded(true);
    }
  };

  return isEnabled ? (
    <Button
      id='moduleButton'
      ref={setAnchor}
      className={classNames(classes.menuButton, {
        blink: backToHaccp && isGoNative
      })}
      startIcon={
        <MenuIcon
          className={classNames(classes.menuButtonIcon, {
            load: !modulesLoaded
          })}
        />
      }
      disabled={!modulesLoaded}
    >
      <span
        className={classNames(classes.menuButtonText, {
          load: !modulesLoaded
        })}
      >
        {intl.formatMessage({ id: 'module.name' })}
      </span>
      {!modulesLoaded && <CircularProgress size={16} className={classes.menuButtonSpinner} />}
    </Button>
  ) : null;
};

const mapStateToProps = (state: RootState) => ({
  locale: state.settings.locale,
  backToHaccp: state.ui.returnTo === 'haccp'
});

export default connect<StateProps, unknown, OwnProps>(mapStateToProps, null)(NewModuleSelector);
