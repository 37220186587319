import * as React from 'react';
import MediaCard from 'pages/Dashboard/MediaCard';
import { makeStyles } from '@material-ui/core/styles';
import clubContent from './clubContent.json';
import { RootState } from 'redux/rootReducer';
import { connect } from 'react-redux';
import moment from 'moment';

type WeekContent = {
  weeks: string; // format 34-35
  headline: string;
  content: string;
  link?: { text: string; url: string };
  imageUrl: string;
};

type StoreProps = ReturnType<typeof mapStateToProps>;
type EsmileyClubProps = StoreProps;

const useStyles = makeStyles((theme) => ({
  linkMargin: {
    marginTop: theme.spacing(2)
  },
  link: {
    display: 'inline-block',
    fontWeight: 'bold'
  }
}));

function getNewsOfTheWeek(language: string, week: number) {
  const contentWeeks = (clubContent[language] ?? clubContent['en']) as WeekContent[];
  const news = contentWeeks.find((content) => {
    const { weeks } = content;
    const [from, to] = weeks.split('-').map((weekString) => parseInt(weekString));
    return week >= from && week <= to;
  });

  if (!news) {
    return contentWeeks[contentWeeks.length - 1];
  }

  return news;
}

const EsmileyClubCard: React.FunctionComponent<EsmileyClubProps> = (props) => {
  const classes = useStyles(props);
  const { locale } = props;
  const currentWeek = moment().isoWeek();
  const clubNews = React.useMemo(
    () => getNewsOfTheWeek(locale, currentWeek),
    [locale, currentWeek]
  );

  return (
    <MediaCard title={clubNews.headline} imageSrc={clubNews.imageUrl} imageAlign='right'>
      <span dangerouslySetInnerHTML={{ __html: clubNews.content }} />
      {clubNews.link && (
        <div className={classes.linkMargin}>
          <a className={classes.link} href={clubNews.link.url} target='_BLANK' rel='noreferrer'>
            {clubNews.link.text}
          </a>
        </div>
      )}
    </MediaCard>
  );
};

const mapStateToProps = (state: RootState) => ({
  locale: state.settings.locale
});

export default connect<StoreProps, unknown, unknown>(mapStateToProps)(EsmileyClubCard);
