import * as React from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
  TextField,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AutoComplete from '@material-ui/lab/Autocomplete';
import phoneCountryCodes, { CountryCode } from 'utils/phoneCountryCodes';
import { AlarmRecipient } from 'redux/ducks/settings';
import { IRecipient } from './types';
import { Recipient } from 'pages/Settings/components/settings/alarms/components/recipientsList/Recipient';

export interface OwnProps {
  value: AlarmRecipient[];
  onChange: (recipients: AlarmRecipient[]) => void;
}

export interface IComponentState {
  recipientsSMS: IRecipient[];
  recipientsEmail: IRecipient[];
  phoneCountryCode?: CountryCode;
  originalRecipientValue?: string;
  originalRecipientName?: string;
  recipient?: Partial<IRecipient>;
  dialogTitle?: string;
  isAddingNewRecipient?: boolean;
  errors: {
    phoneNum: boolean;
    name: boolean;
    email: boolean;
  };
}

type RecipientsListProps = OwnProps;

const emptyErrors = {
  phoneNum: false,
  name: false,
  email: false
};

const RecipientsList: React.FC<RecipientsListProps> = (props) => {
  const intl = useIntl();
  const phoneNumInputRef = React.useRef<HTMLInputElement>();
  const [state, setState] = React.useState<IComponentState>({
    recipientsSMS: [],
    recipientsEmail: [],
    phoneCountryCode: null,
    errors: emptyErrors
  });

  const { onChange, value } = props;

  const update = (value: IRecipient[]) => {
    setState((prev) => ({
      ...prev,
      recipientsSMS: value.filter((recipient: IRecipient) => {
        return recipient.type == 'sms';
      }),
      recipientsEmail: value.filter((recipient: IRecipient) => {
        return recipient.type == 'email';
      })
    }));
  };

  React.useEffect(() => {
    update(value);
  }, [value]);

  const editRecipient = (recipient: IRecipient, isNew?: boolean) => {
    const { type, value, name } = recipient;

    const originalRecipientValue = recipient.value;
    let phoneCountryCode: CountryCode = null;

    recipient.newValue = value;
    recipient.newName = name;

    if (type === 'sms') {
      // todo: store country code separately
      phoneCountryCode = phoneCountryCodes.find((code) => value.startsWith(code.code));
      recipient.newValue = phoneCountryCode ? value.slice(phoneCountryCode.code.length) : value;
    }

    setState((prev) => ({
      ...prev,
      recipient,
      dialogTitle: isNew
        ? recipient.type == 'email'
          ? intl.formatMessage({ id: 'addMailRecipient' })
          : intl.formatMessage({ id: 'addSMSRecipient' })
        : intl.formatMessage({ id: 'recipient' }) + ': ' + recipient.name,
      originalRecipientValue: originalRecipientValue,
      originalRecipientName: recipient.name,
      isAddingNewRecipient: isNew,
      phoneCountryCode: phoneCountryCode,
      errors: emptyErrors
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLElement> | React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const {
      errors,
      recipient: { type, newName: name, newValue: value }
    } = state;

    const nextErrors = {
      name: !name || name.length === 0 || errors.name,
      email: (type === 'email' && (!value || value.length === 0)) || errors.email,
      phoneNum: (type === 'sms' && (!value || value.length === 0)) || errors.phoneNum
    };

    if (Object.keys(nextErrors).some((key) => nextErrors[key])) {
      setState((prev) => ({ ...prev, errors: nextErrors }));
      return;
    }

    saveRecipient();
  };

  const saveRecipient = () => {
    const {
      recipient: draft,
      originalRecipientValue: rValue,
      originalRecipientName: rName,
      isAddingNewRecipient,
      phoneCountryCode
    } = state;
    const recipient = {
      type: draft.type,
      name: draft.newName.trim(),
      value:
        (draft.type === 'sms' ? phoneCountryCode.value.replace('+', '') : '') +
        draft.newValue.trim()
    };

    const nextValue = isAddingNewRecipient
      ? [...value, recipient]
      : value.map((r) => (r.name === rName && r.value === rValue ? recipient : r));

    setState((prev) => ({
      ...prev,
      recipient: null,
      isAddingNewRecipient: false,
      originalRecipientValue: recipient.value,
      originalRecipientName: recipient.name
    }));

    onChange(nextValue);
  };

  const deleteRecipient = (recipient: IRecipient) => {
    onChange(value.filter((r) => r !== recipient));
  };

  const cancelEditing = () => {
    setState((prev) => ({
      ...prev,
      recipient: null,
      isAddingNewRecipient: false,
      phoneCountryCode: null
    }));
  };

  const renderRecipientValueInput = (recipient: IRecipient) => {
    const { phoneCountryCode, errors } = state;

    switch (recipient.type) {
      case 'email':
        return (
          <TextField
            label={intl.formatMessage({ id: 'emailAddress' })}
            value={recipient.newValue}
            required={true}
            autoFocus={recipient.newName != ''}
            type='email'
            name='value'
            error={errors.email}
            fullWidth={true}
            onChange={(e: { target: { value: string } }) => {
              const {
                target: { value }
              } = e;
              const hasError = !value || e.target.value.length === 0;
              recipient.newValue = value;
              setState((prev) => ({
                ...prev,
                recipient,
                errors: { ...prev.errors, email: hasError }
              }));
            }}
          />
        );
      case 'sms':
        return (
          <div className='phoneInput'>
            <div className='phoneCountryCodeInput'>
              <AutoComplete
                fullWidth
                autoSelect
                autoComplete
                disableClearable
                style={{ minWidth: '128px' }}
                value={phoneCountryCode}
                openOnFocus
                options={phoneCountryCodes}
                getOptionLabel={(option) => option.text}
                onChange={(e, value: CountryCode) =>
                  setState((prev) => ({ ...prev, phoneCountryCode: value }))
                }
                renderOption={(option) => <span>{option.text}</span>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      placeholder: '+00*'
                    }}
                    error={!phoneCountryCode && errors.phoneNum}
                    required
                  />
                )}
              />
            </div>
            <TextField
              ref={phoneNumInputRef}
              label={intl.formatMessage({ id: 'phoneNumber' })}
              value={recipient.newValue}
              required
              autoFocus={recipient.newName != ''}
              type='tel'
              name='value'
              error={errors.phoneNum}
              fullWidth
              onChange={(e: { target: { value: string } }) => {
                const hasError = !/^[0-9()#.\s/ext-]+$/.test(e.target.value);
                recipient.newValue = e.target.value;
                setState((prev) => ({
                  ...prev,
                  recipient,
                  errors: { ...prev.errors, phoneNum: hasError }
                }));
              }}
            />
          </div>
        );
    }
  };

  const { recipientsSMS, recipientsEmail, recipient, dialogTitle, errors } = state;

  return (
    <div className='recipientsList'>
      <Dialog
        maxWidth='sm'
        fullWidth
        className='recipientDialog'
        open={recipient != null}
        onClose={cancelEditing}
      >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          {recipient && (
            <form className='recipientForm' onSubmit={handleSubmit}>
              <TextField
                className='nameInput'
                label={intl.formatMessage({ id: 'nameOfRecipient' })}
                value={recipient.newName}
                required
                fullWidth
                name='name'
                error={errors.name}
                autoFocus={recipient.newName == ''}
                onChange={(e: { target: { value: string } }) => {
                  const {
                    target: { value }
                  } = e;
                  const hasError = !value || value.length === 0;
                  recipient.newName = value;
                  setState((prev) => ({
                    ...prev,
                    recipient,
                    errors: { ...prev.errors, name: hasError }
                  }));
                }}
              />
              {renderRecipientValueInput(recipient as IRecipient)}
            </form>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelEditing}>{intl.formatMessage({ id: 'base.cancel' })}</Button>
          <Button
            disabled={Object.keys(errors).some((key) => errors[key])}
            variant='contained'
            type='submit'
            color='primary'
            onClick={handleSubmit}
          >
            {intl.formatMessage({ id: 'base.save' })}
          </Button>
        </DialogActions>
      </Dialog>
      <div className='list'>
        <label>
          <span>{intl.formatMessage({ id: 'email' })}</span>
        </label>
        {recipientsEmail.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{intl.formatMessage({ id: 'nameOfRecipient' })}</TableCell>
                <TableCell>{intl.formatMessage({ id: 'emailAddress' })}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {recipientsEmail.map((recipient: IRecipient, index: number) => (
                <Recipient
                  key={`email_${index}`}
                  recipient={recipient}
                  type='email'
                  onEdit={editRecipient}
                  onDelete={deleteRecipient}
                />
              ))}
            </TableBody>
            <TableFooter />
          </Table>
        )}

        <div className='recipientBtns'>
          <Button
            variant={'contained'}
            className='btn addRecipientBtn'
            onClick={() => {
              editRecipient({ name: '', type: 'email', value: '' }, true);
            }}
            startIcon={<AddIcon />}
          >
            {intl.formatMessage({ id: 'addMailRecipient' })}
          </Button>
        </div>
      </div>
      <div className='list'>
        <label>
          <span>{intl.formatMessage({ id: 'SMS' })}</span>
        </label>
        {recipientsSMS.length > 0 && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{intl.formatMessage({ id: 'nameOfRecipient' })}</TableCell>
                <TableCell>{intl.formatMessage({ id: 'phoneNumber' })}</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {recipientsSMS.map((recipient: IRecipient, index: number) => (
                <Recipient
                  key={`sms_${index}`}
                  recipient={recipient}
                  type='sms'
                  onEdit={editRecipient}
                  onDelete={deleteRecipient}
                />
              ))}
            </TableBody>
          </Table>
        )}
        <div className='recipientBtns'>
          <Button
            variant='contained'
            className='btn addRecipientBtn'
            onClick={() => {
              editRecipient({ name: '', type: 'sms', value: '' }, true);
            }}
            startIcon={<AddIcon />}
          >
            {intl.formatMessage({ id: 'addSMSRecipient' })}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default RecipientsList;
