import * as React from 'react';
import { connect } from 'react-redux';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import {
  getAvailableAccounts,
  getAvailableByName,
  getRegistrationPoints
} from 'redux/ducks/reportFilter/selectors';
import { RootState } from 'redux/rootReducer';
import { makeStyles } from '@material-ui/styles';
import { Labels } from 'utils/labels';
import AccountGroupSelect, { AccountGroupSelectProps } from './AccountGroupSelect';

type StateProps = ReturnType<typeof mapStateToProps>;

export interface AccountGroup {
  departments?: string[];
  area?: string[];
  category?: string[];
  product?: string[];
}

interface OwnProps extends Pick<AccountGroupSelectProps, 'classes'> {
  inline?: boolean;
  group: AccountGroup;
  onGroupChange: (group: AccountGroup) => void;
}

type ComponentProps = StateProps & OwnProps;

const useStyles = makeStyles({
  spinner: { margin: '0 -50px', width: '110%' }
});

export const AccountGroupSelector: React.FunctionComponent<ComponentProps> = (props) => {
  const classes = useStyles();
  const {
    inline,
    isInitialized,
    group,
    registrationPoints,
    onGroupChange,
    accounts,
    classes: classesProp
  } = props;

  const accountGroup = React.useMemo(() => {
    const { departments, area, category, product } = group;
    const selectedAccounts = departments;

    const selectedRegistrationPoints = {
      area,
      category,
      product
    };

    const availableRegistrationPoints = getAvailableByName(
      registrationPoints,
      selectedRegistrationPoints
    );

    return {
      selectedRegistrationPoints,
      availableRegistrationPoints,
      accounts: selectedAccounts,
      availableAccounts: accounts
    };
  }, [accounts, registrationPoints, group.departments, group.area, group.category, group.product]);
  const handleAccountChange = (ids: string[]) => {
    onGroupChange({
      ...group,
      departments: ids
    });
  };

  const handleRegistrationPointChange = (points: { [label in Labels]: string[] }) => {
    const { area, category, product } = group;
    const selected = Object.keys(points).reduce(
      (all, label: Labels) => ({
        ...all,
        [label]: points[label]
      }),
      {
        area,
        category,
        product
      }
    );

    onGroupChange({
      ...group,
      area: selected.area,
      category: selected.category,
      product: selected.product
    });
  };

  return isInitialized ? (
    <AccountGroupSelect
      classes={classesProp}
      inline={inline}
      group={accountGroup}
      onAccountChange={handleAccountChange}
      onRegistrationPointChange={handleRegistrationPointChange}
    />
  ) : (
    <LoadingPlaceholder className={classes.spinner} />
  );
};

const mapStateToProps = (state: RootState) => ({
  isInitialized: state.reportFilter.isInitialized,
  accounts: getAvailableAccounts(state),
  registrationPoints: getRegistrationPoints(state)
});

export default connect<StateProps, unknown, OwnProps>(mapStateToProps)(AccountGroupSelector);
