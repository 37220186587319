import * as React from 'react';
import { useIntl } from 'react-intl';
import { Button } from '@material-ui/core';

export const AutoActivateButton: React.FC<{ onTimeout: () => void }> = ({ onTimeout }) => {
  const intl = useIntl();
  const [counter, setCounter] = React.useState(15);

  React.useEffect(() => {
    const timerId = setInterval(() => {
      setCounter((prev) => {
        if (prev === 0) {
          clearInterval(timerId);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  React.useEffect(() => {
    if (counter === 0) {
      onTimeout();
    }
  }, [counter]);

  return (
    <Button variant='outlined' onClick={onTimeout}>
      {intl.formatMessage({ id: 'label.toAutoUpdate' }, { counter })}
    </Button>
  );
};
