import * as React from 'react';

interface ServiceWorkerContext {
  active: boolean;
}

const ServiceWorkerContext = React.createContext<ServiceWorkerContext>(null);
export const ServiceWorkerProvider: React.FC = ({ children }) => {
  // logic here: if app doesnt have an active controller (service worker) instance,
  // we are not offline ready; activating installed service worker requires page reload,
  // which sets the controller
  const active = 'serviceWorker' in navigator && !!navigator.serviceWorker.controller;
  const value = React.useMemo(() => ({ active }), []);

  return <ServiceWorkerContext.Provider value={value}>{children}</ServiceWorkerContext.Provider>;
};

export const useServiceWorker = () => {
  const context = React.useContext(ServiceWorkerContext);

  if (!context) {
    throw new Error('useServiceWorker must be called within a ServiceWorkerProvider');
  }

  return context;
};
