import * as React from 'react';
import Accounts from './Accounts';
import * as reportDataDispatch from 'redux/ducks/reportData';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { Basis, ReportActions } from 'redux/ducks/reportFilter';
import { useIntl } from 'react-intl';
import * as reportFilterDispatch from 'redux/ducks/reportFilter';
import {
  AccountPointFilterWithNames,
  getCompareToFilters
} from 'redux/ducks/reportFilter/selectors';
import { ReportDataActions } from 'redux/ducks/reportData';
import DownloadBtn from 'pages/Report/components/DownloadBtn';
import { useDownloadReportPDF } from 'pages/Report/utils/useDownloadPDF';
import { getChartPNGs } from 'pages/Report/Accounts/utils/generateChartImages';
import { chartColors } from 'pages/Report/Accounts/utils/constants';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';

type StoreProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface ComponentProps extends StoreProps, DispatchProps {}

const identifier = 'foodWastePerAccount';

const AccountsContainer: React.FunctionComponent<ComponentProps> = (props) => {
  const intl = useIntl();
  const { chartRefs, fetchData, accountSeries, filter, setBasis, accountPointFilters } = props;

  const { data: chartsData, basis, timeRange } = accountSeries;
  const [isDownloading, downloadPDF] = useDownloadReportPDF({
    generateChartPNG: () => getChartPNGs({ chartRefs, chartsData, filter }),
    reportData: {
      // react-renderer bundles with huge yoga-template (0.5mb),
      AsyncDocument: async () => (await import('./PDF')).default,
      data: {
        chartsData,
        chartColors,
        reportFilter: filter,
        accountPointFilters,
        intl
      },
      basis,
      name: `accounts-${timeRange.from}-${timeRange.to}`
    }
  });

  const onTabChange = (basis: Basis) => {
    setBasis(basis);
  };

  useEffect(() => {
    void fetchData(identifier);
  }, [
    filter.basis,
    filter.dimension,
    filter.timeRange,
    filter.period,
    filter.filter,
    filter.accountGroups,
    filter.selectedGuestTypeNames
  ]);

  return (
    <Accounts
      onTabChange={onTabChange}
      accountSeries={accountSeries}
      downloadButton={<DownloadBtn isDownloading={isDownloading} onClick={downloadPDF} />}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const compareToFilters: AccountPointFilterWithNames[] = getCompareToFilters(state);
  return {
    chartRefs: state.charts.chartRefs,
    accountSeries: state.reportData[identifier],
    filter: state.reportFilter,
    accountPointFilters: compareToFilters
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, void, ReportDataActions | ReportActions>
) => ({
  fetchData: (
    identifier: reportDataDispatch.ReportDataIdentifier,
    options?: reportDataDispatch.FetchDataOptions
  ) => dispatch(reportDataDispatch.fetchData(identifier, options)),
  setBasis: (basis: Basis) => dispatch(reportFilterDispatch.changeBasis(basis))
});

export { chartColors };
export default connect<StoreProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(AccountsContainer);
