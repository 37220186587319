export interface FunFact {
  id: string;
  nameKey: string;
  weightCoefficient: number;
  image: string;
  amount: number;
  co2Coefficient: number;
}

type FunFactsStatus = 'loading' | 'ready';

export interface FunFactsState {
  funFacts: FunFact[];
  status: FunFactsStatus;
}

export enum FunFactsActionTypes {
  GET_FUN_FACTS = 'esmiley/funfacts/GET_FUN_FACTS',
  SET_FUN_FACTS_STATUS = 'esmiley/funfacts/SET_FUN_FACTS_STATUS'
}

type GetFunFactsAction = {
  type: typeof FunFactsActionTypes.GET_FUN_FACTS;
  payload: FunFact[];
};

type SetFunFactsStatusAction = {
  type: typeof FunFactsActionTypes.SET_FUN_FACTS_STATUS;
  payload: FunFactsStatus;
};

export type FunFactsActions = GetFunFactsAction | SetFunFactsStatusAction;
