import * as React from 'react';
import { Card, CardContent, CardHeader, Grid, makeStyles } from '@material-ui/core';
import { Bar } from 'pages/Report/components/Chart';
import { Options } from 'highcharts';
import { Icon } from 'components/Icon';
import Progress from 'pages/Report/components/Progress';
import { formatWeight, formatMoney } from 'utils/number-format';
import { useIntl } from 'react-intl';
import getFormattedTimeRange from 'pages/Report/utils/getFormattedDateRange';
import ProgressMessage from 'pages/Report/components/ProgressMessage';
import ErrorMessage from 'pages/Report/components/ErrorMessage';
import { ApiError } from 'redux/ducks/error';
import { ReportChart, SeriesResponse } from 'redux/ducks/reportData';
import scaleImage from 'static/icons/balance-scale.svg';

interface ComponentProps {
  chartOptions: Options;
  chartsData: ReportChart<SeriesResponse>;
  error: ApiError;
}

const FoodWasteStatus: React.FunctionComponent<ComponentProps> = (props) => {
  const classes = useStyles(props);
  const intl = useIntl();
  const { chartOptions, chartsData, error } = props;

  const {
    isLoading,
    basis,
    dimension,
    period,
    timeRange: { from, to },
    data: { metrics = [], extra: { target } = { target: null } }
  } = chartsData;

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({ id: 'report.foodwaste.recentHistory.title' })}
        subheader={intl.formatMessage(
          {
            id:
              basis === 'per-guest'
                ? 'report.foodwaste.recentHistory.perGuest.subtitle'
                : 'report.foodwaste.recentHistory.total.subtitle'
          },
          {
            range: getFormattedTimeRange(from, to),
            period
          }
        )}
        avatar={<Icon icon={scaleImage} />}
      />
      <CardContent>
        {error ? (
          <ErrorMessage error={error} />
        ) : (
          <Grid container spacing={4}>
            <Grid container item xs={12} md={5} alignItems={'center'} justify={'center'}>
              <Grid item xs={12} md={12}>
                {metrics.length && (
                  <Progress
                    className={classes.progress}
                    text={
                      metrics[0].point !== 0 && (
                        <ProgressMessage
                          id={
                            metrics[0].trend === 0
                              ? 'report.quickOverview.trend.equalToTarget'
                              : `report.foodwaste.status.trend.${
                                  metrics[0].trend > 0 ? 'more' : 'less'
                                }FoodWaste`
                          }
                          target={`${
                            dimension === 'cost'
                              ? formatMoney(target).toString()
                              : basis === 'per-guest'
                              ? formatWeight(target, true, 'g', 0)
                              : formatWeight(target, undefined, undefined, 0)
                          }${dimension === 'co2' ? ' CO2' : ''}`}
                        />
                      )
                    }
                    value={metrics[0].trend}
                    invertedProgress={true}
                    isLoading={isLoading}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container item xs={12} md={7}>
              <Grid item xs={12} md={11}>
                <Bar chartOptions={chartOptions} isLoading={isLoading} />
              </Grid>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  progress: {
    [theme.breakpoints.up('md')]: {
      marginTop: -60
    }
  }
}));

export default FoodWasteStatus;
