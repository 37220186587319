import * as React from 'react';
import { connect } from 'react-redux';
import ReportMailFilter from './ReportMailFilter';

import { Dimension } from 'redux/ducks/reportFilter';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { getGuestTypeNames } from 'redux/ducks/reportFilter/selectors';
import { RootState } from 'redux/rootReducer';
import { makeStyles } from '@material-ui/styles';
import { ReportMailCategory, ReportMailPeriod } from 'pages/Report/Mail/api';
import { DraftReportFilter } from 'pages/Report/Mail/components/MailReportEditor';
import { AccountGroup } from 'pages/Report/Mail/components/AccountGroupSelector';

type StateProps = ReturnType<typeof mapStateToProps>;

interface OwnProps {
  hideComparisonGroups?: boolean;
  accountGroups: AccountGroup[];
  onAccountGroupChange: (group: AccountGroup[]) => void;
  filter: DraftReportFilter;
  category: ReportMailCategory;
  dimensions: Dimension[]; // should be  merged with filter
  onDimensionChange: (dimensions: Dimension[]) => void;
  onFilterChange: (filter: DraftReportFilter) => void;
}

type ComponentProps = StateProps & OwnProps;

const useStyles = makeStyles({
  spinner: { margin: '0 -50px', width: '110%' }
});

export const Filter: React.FunctionComponent<ComponentProps> = (props) => {
  const classes = useStyles(props);
  const {
    isInitialized,
    filter,
    guestTypes,
    onFilterChange,
    dimensions,
    onDimensionChange,
    category,
    accountGroups,
    onAccountGroupChange,
    hideComparisonGroups
  } = props;

  const selectedGuestTypes = filter.guestTypes;

  const handleDimensions = (dimensions: Dimension[]) => {
    onDimensionChange(dimensions);
  };

  const handleGuestTypeChange = (names: string[]) => {
    onFilterChange({ ...filter, guestTypes: names });
  };

  const handleAccountChange = (index: number, group: AccountGroup) => {
    onAccountGroupChange(accountGroups.map((g, i) => (index === i ? group : g)));
  };

  const handlePeriodChange = (period: ReportMailPeriod) => {
    onFilterChange({ ...filter, period });
  };

  return isInitialized ? (
    <ReportMailFilter
      category={category}
      dimensions={dimensions}
      onDimensionChange={handleDimensions}
      accountGroupFilters={hideComparisonGroups ? accountGroups.slice(0, 1) : accountGroups}
      onAccountGroupChange={handleAccountChange}
      guestTypeNames={guestTypes}
      selectedGuestTypes={selectedGuestTypes}
      onGuestTypeChange={handleGuestTypeChange}
      onPeriodChange={handlePeriodChange}
      selectedPeriod={filter.period}
    />
  ) : (
    <LoadingPlaceholder className={classes.spinner} />
  );
};

const mapStateToProps = (state: RootState) => ({
  isInitialized: state.reportFilter.isInitialized,
  guestTypes: getGuestTypeNames(state)
});

export default connect<StateProps, unknown, OwnProps>(mapStateToProps)(Filter);
