import * as React from 'react';
import { RootState } from 'redux/rootReducer';
import { resetStep } from 'redux/ducks/tutorials';
import { connect } from 'react-redux';
import { Popper } from 'components/Popper';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type OwnProps = StateProps & DispatchProps;

const TutorialPopper: React.FunctionComponent<OwnProps> = (props) => {
  return <Popper backDrop open={!!props.anchorEl} {...props} />;
};

const mapStateToProps = (state: RootState) => ({
  anchorEl: state.tutorials.stepAnchorEl,
  title: state.tutorials.title,
  content: state.tutorials.content
});

const mapDispatchToProps = { onClose: resetStep };
export default connect(mapStateToProps, mapDispatchToProps)(TutorialPopper);
