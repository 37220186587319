import * as React from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { LinearProgress } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Subscription } from 'redux/ducks/user';

interface PlanStatus {
  planLength: number;
  daysToExpiration: number;
}

interface SubscriptionExpirationCounterProps {
  subscription: Subscription;
}

type CounterProps = SubscriptionExpirationCounterProps;

const useStyles = makeStyles(() => {
  return {
    planLength: {
      fontSize: '0.9rem',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: '12px'
    },
    planLengthTotal: {
      paddingTop: '12px',
      color: '#626262'
    },
    LinearProgress: {
      width: '100%',
      height: '10px',
      borderRadius: '10px',
      backgroundColor: '#c2c2c2'
    }
  };
});

const getTimeDiff = (subscription: Subscription): PlanStatus => {
  const { startDate, endDate } = subscription;
  const planLength = moment(endDate).diff(startDate, 'days');
  const expiration = moment(endDate).diff(Date.now(), 'days');
  const daysToExpiration = expiration <= 0 ? 0 : expiration;
  return { planLength, daysToExpiration };
};

const SubscriptionExpirationCounter: React.FC<CounterProps> = (props) => {
  const intl = useIntl();
  const { subscription } = props;
  const { planLength, daysToExpiration } = getTimeDiff(subscription);
  const normalise = (value) => Math.round(100 - ((value - 0) * 100) / planLength);
  const classes = useStyles();

  return (
    <div className={classes.planLength}>
      <LinearProgress
        className={classes.LinearProgress}
        variant='determinate'
        value={normalise(daysToExpiration)}
      />
      <span className={classes.planLengthTotal}>
        {intl.formatMessage(
          { id: 'base.subscriptionDaysLeft' },
          { value: `${daysToExpiration}/${planLength}` }
        )}
      </span>
    </div>
  );
};

export default SubscriptionExpirationCounter;
