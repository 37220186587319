import App from 'components/App';
import Dashboard from 'pages/Dashboard';
import Registration from 'pages/Registration';
import Settings from 'pages/Settings';
import Project from 'pages/Project';
import QuickOverview from 'pages/Report/QuickOverview';
import Accounts from 'pages/Report/Accounts';
import ReportsOverview from 'pages/Report/StartPage';
import Mail from 'pages/Report/Mail';
import Sustainability from 'pages/Report/Sustainability';
import RegistrationFrequency from 'pages/Report/RegistrationFrequency';
import FoodWaste from 'pages/Report/FoodWaste';
import Advanced from 'pages/Report/Advanced';
import { RouteConfig, RouterState, RedirectFunction } from 'react-router';
import ReportPage from 'pages/Report';
import Error from 'pages/Error';
import GuestRegistrationPage from 'pages/Registration/GuestRegistrationPage';
import Signup from 'pages/Signup';
import Success from 'pages/Signup/Success';
import StarterRegister from 'pages/Registration/StarterRegister';
import Account from 'pages/Account';
import { isScaleApp } from 'utils/scale';

export type QueryWithToken = {
  token?: string;
  [other: string]: unknown;
};

export function redirectScaleApp(
  nextState: RouterState<QueryWithToken>,
  replace: RedirectFunction
): void {
  const { location } = nextState;
  // can be moved outside as a module variable, once
  // play store apps use version >2.2
  const isScale = isScaleApp();

  if (isScale) {
    replace({ ...location, pathname: '/registration' });
  }
}

export default [
  {
    path: '/signup',
    component: Signup
  },
  {
    path: '/signup-success',
    component: Success
  },
  {
    path: '/',
    component: App,

    indexRoute: {
      component: Dashboard,
      onEnter: redirectScaleApp
    },
    childRoutes: [
      {
        path: 'registration',
        component: Registration
      },
      {
        path: 'guest-registration',
        component: GuestRegistrationPage
      },
      {
        path: 'project',
        component: Project
      },
      {
        path: 'settings',
        component: Settings
      },
      {
        path: 'starterPlanRegister',
        component: StarterRegister
      },
      {
        path: 'account',
        component: Account
      },
      {
        path: 'report',
        component: ReportPage,
        indexRoute: {
          component: ReportsOverview
        },
        childRoutes: [
          {
            path: 'quick-overview',
            component: QuickOverview
          },
          {
            path: 'accounts',
            component: Accounts
          },
          {
            path: 'frequency',
            component: RegistrationFrequency
          },
          {
            path: 'foodwaste(/:basis)',
            component: FoodWaste
          },
          {
            path: 'advanced',
            component: Advanced
          },
          {
            path: 'mail',
            component: Mail
          },
          {
            path: 'sustainability',
            component: Sustainability
          }
        ]
      },
      {
        //This has to be at the bottom of the stack else you'll get a 404 error once you visit the page you are setting up
        path: '*',
        status: 404,
        component: Error
      }
    ]
  }
] as RouteConfig;
