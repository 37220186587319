import getChartPNG, { ChartImage } from 'pages/Report/utils/getChartPNG';
import { sum } from 'utils/array';
import { ReportFilterState } from 'redux/ducks/reportFilter';
import { ChartRefs } from 'redux/ducks/charts';
import { SeriesResponse } from 'redux/ducks/reportData';
import Highcharts, { Chart } from 'highcharts';
import { formatNumber, transformAmount } from 'utils/number-format';
import generateDataLabelPDF from 'pages/Report/Accounts/utils/generateDataLabelPDF';

export type GetChartPNGsProps = {
  chartRefs: ChartRefs;
  chartsData: SeriesResponse;
  filter: ReportFilterState;
};

export function getChartPNGs(props: GetChartPNGsProps): Promise<ChartImage[][]> {
  const {
    chartRefs,
    chartsData,
    filter: { basis }
  } = props;
  return Promise.all<ChartImage[]>([
    getChartPNG({
      chartRefs,
      type: 'bar',
      sourceWidth: 900,
      maxHeight: 2700
    }),
    getChartPNG({
      chartRefs,
      type: basis === 'per-guest' ? 'column' : 'donut',
      sourceWidth: basis === 'per-guest' ? 800 : 850,
      options:
        basis === 'per-guest'
          ? undefined
          : {
              title: {
                style: {
                  fontSize: '23px'
                }
              },
              plotOptions: {
                pie: {
                  dataLabels: {
                    formatter: function () {
                      // label not part of the public Point api
                      const point = this.point as Highcharts.Point & { label: string };
                      const group = chartsData.series
                        .filter((s) => s.id === 'groupTotalSeries')
                        .flatMap((_) => _.series.filter((s) => s.id === 'totalAmountSeries'))
                        .flatMap((_) => _.points)
                        .find((p) => p.label === point.label);
                      const groupPercentage = formatNumber(point.percentage, {
                        displayUnit: true,
                        unitSpace: true,
                        unit: '%'
                      });
                      const groupAmount = group
                        ? formatNumber(transformAmount(group.value, { unit: 'g', as: 'kg' }), {
                            displayUnit: true,
                            unitSpace: true,
                            unit: 'kg'
                          })
                        : '0';
                      return generateDataLabelPDF(point.name, groupPercentage, groupAmount);
                    },
                    y: 0
                  }
                }
              }
            }
    }),
    getChartPNG({
      chartRefs,
      type: 'barGroup',
      imageWidth: 2480 / 3, //2480 is the A4 width at 300DPI
      sourceWidth: 250,
      metadataGen: (chart: Chart) => {
        const areaGroupSeries = chartsData.series.find(
          (chartData) => chartData.id === 'areaGroups'
        );
        const areaSeries = areaGroupSeries.series.flatMap((series) => series.series);
        // we have dummy empty series so that charts have correct colors (need to fix later)
        const currentAreaSeries = chart.options.series.filter(
          (series: Highcharts.SeriesBarOptions) => series.data.length > 0
        );
        const currentAreaName = currentAreaSeries[0].name;
        const currentAreaData = areaSeries.filter((series) => series.name === currentAreaName);
        const total = sum(currentAreaData.map((series) => series.aggregates.total));

        return {
          total: total,
          name: currentAreaName,
          unit: currentAreaData[0].unit
        };
      }
    })
  ]);
}
