import {
  AccountGroupFilter,
  AccountGroupFilterUpdate,
  ReportQuery,
  ReportQueryAccountGroup
} from 'redux/ducks/reportFilter/types';

const isAccountQueryRegex = /^top|^bottom|^\*/;

export function mapAccountFilterToQuery(
  accountFilter: NestedPartial<AccountGroupFilter>
): Partial<ReportQuery['query']> {
  const sourceFields = ['area', 'category', 'product', 'order'];
  const targetFields = ['areas', 'categories', 'products', 'order'];
  const result = {} as Partial<ReportQuery['query']>;

  if (accountFilter.accounts) {
    if (isAccountQueryRegex.test(accountFilter.accounts[0])) {
      result['accounts'] = accountFilter.accounts[0];
    } else {
      result['accounts'] = accountFilter.accounts;
    }
  }

  return sourceFields.reduce((result, field, i) => {
    if (accountFilter[field]) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      result[targetFields[i]] = accountFilter[field];
    }
    if (accountFilter['selectedRegistrationPoints'][field]) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      result[targetFields[i]] = accountFilter['selectedRegistrationPoints'][field];
    }
    return result;
  }, result);
}

export function mapQueryToAccountFilter(
  accountGroup: ReportQueryAccountGroup
): NestedPartial<AccountGroupFilter> {
  const targetFields = ['area', 'category', 'product'];
  const sourceFields = ['areas', 'categories', 'products'];
  const result = {
    selectedRegistrationPoints: { area: [], category: [], product: [] }
  };

  result['accounts'] = Array.isArray(accountGroup.accounts)
    ? accountGroup.accounts
    : [accountGroup.accounts];

  if (accountGroup.order) {
    result['order'] = accountGroup.order;
  }

  return sourceFields.reduce((result, field, i) => {
    if (accountGroup[field]) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      result['selectedRegistrationPoints'][targetFields[i]] = accountGroup[field];
    }
    return result;
  }, result as NestedPartial<AccountGroupFilter>);
}

// todo: this is not needed when the registration point selector component
// is fixed to return {id, name} or whole reg point instead of just names in callback
export function createFilter(filter: AccountGroupFilterUpdate): Partial<AccountGroupFilter> {
  const { accounts, selectedRegistrationPoints, order } = filter;
  const result = {};

  if (order) {
    result['order'] = order;
  }

  if (selectedRegistrationPoints) {
    result['selectedRegistrationPoints'] = selectedRegistrationPoints;
  }

  if (accounts) {
    result['accounts'] = accounts;
  }

  return result;
}

export const arrayParamSerializer = (params: { [key: string]: unknown }): string => {
  const parts = [];

  for (const key in params) {
    const val = params[key] as string | string[];
    if (val === null || typeof val === 'undefined') {
      continue;
    }

    const valueArray = Array.isArray(val) ? [val.join(',')] : [val];

    valueArray.forEach((value) => {
      parts.push(`${key}=${value}`);
    });
  }

  return parts.join('&');
};
