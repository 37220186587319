import * as React from 'react';

interface OnTimeOutOptions {
  timeout: number;
}

interface TimeOutRef extends OnTimeOutOptions {
  callback: () => void;
}

export const useIdleTimeout = () => {
  const timeOutOptions = React.useRef<TimeOutRef>(null);
  const timerId = React.useRef<NodeJS.Timeout>(null);

  const handleActivity = React.useCallback(() => {
    clearTimeout(timerId?.current);

    if (!timeOutOptions.current) {
      return;
    }

    const { callback, timeout } = timeOutOptions.current;
    timerId.current = setTimeout(callback, timeout);

    return () => {
      clearTimeout(timerId?.current);
    };
  }, []);

  React.useEffect(() => {
    document.addEventListener('mousedown', handleActivity, { passive: true });
    document.addEventListener('keydown', handleActivity, { passive: true });
    document.addEventListener('touchstart', handleActivity, { passive: true });

    handleActivity();

    return () => {
      document.removeEventListener('mousedown', handleActivity);
      document.removeEventListener('keydown', handleActivity);
      document.removeEventListener('touchstart', handleActivity);
    };
  }, [handleActivity]);

  const onTimeout = (callback: () => void, options: OnTimeOutOptions) => {
    timeOutOptions.current = { callback, ...options };
    handleActivity();
  };

  return { onTimeout };
};
