import { RootState } from 'redux/rootReducer';
import * as React from 'react';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  AppBar,
  createStyles,
  IconButton,
  Theme,
  Toolbar,
  Fab,
  Typography,
  WithStyles,
  withStyles,
  Box
} from '@material-ui/core';
import LanguageSwitcher from 'components/LanguageSwitcher';
import * as uiDispatch from 'redux/ducks/ui';
import * as settingsDispatch from 'redux/ducks/settings';
import { SidebarMenu } from 'styles/themes/global';
import classNames from 'classnames';
import MenuIcon from '@material-ui/icons/Menu';
import ModuleSelector from 'components/ModuleSelector';
import { ThunkDispatch } from 'redux-thunk';
import { getReturnToModule, ModuleLink, redirectToModule, UiActions } from 'redux/ducks/ui';
import './index.scss';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Feature, useFeature } from 'flagged';
import { ArrowBack } from '@material-ui/icons';
import NewModuleSelector from 'components/ModuleSelector/NewModuleSelector';
import { useNetworkStatus } from 'frontend-core';

interface OwnProps {
  title: string;
  isMobile: boolean;
  isBootstrap?: boolean;
}

type StoreProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const styles = (theme: Theme) =>
  createStyles({
    toolbar: {
      paddingLeft: 0 // to align with drawer
    },
    mobileToolbar: {
      paddingLeft: '20px'
    },
    sidebarMenuMargin: {
      marginLeft: SidebarMenu.desktopWidth,
      paddingLeft: theme.spacing(2),
      [theme.breakpoints.down(560)]: {
        marginLeft: 0
      }
    },
    sidebarMenuButton: {
      width: SidebarMenu.collapsedWith,
      display: 'flex',
      justifyContent: 'center',
      '& svg': {
        fill: '#ffffff'
      }
    },
    navRight: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      marginLeft: 'auto',
      '& > * + *': {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
          marginLeft: theme.spacing(1)
        }
      }
    },
    languageSwitcher: {
      color: '#ffffff',
      '& svg': {
        fill: '#ffffff',
        paddingLeft: '4px'
      }
    },
    backToHaccpFab: {
      position: 'fixed',
      bottom: '20px',
      right: '20px',
      zIndex: 1000,
      '&.mobile': {
        bottom: '80px',
        right: '10px'
      }
    },
    arrowIconSmall: {
      width: theme.spacing(2),
      height: theme.spacing(2)
    }
  });

// All the component's props
export interface IComponentProps
  extends OwnProps,
    StoreProps,
    DispatchProps,
    WithStyles<typeof styles> {}

export const Nav: React.FunctionComponent<IComponentProps> = (props) => {
  const { title, toggleMenu, isMenuOpen, changeLocale, classes, isBootstrap, returnToModule } =
    props;
  const intl = useIntl();
  const isDesktop = useMediaQuery('(min-width: 480px)', { noSsr: true });
  const isSmallScreen = useMediaQuery('(max-width: 620px)', { noSsr: true });
  const isGoNative = navigator.userAgent.includes('esmnative');
  const hasNewModuleSelector = useFeature('module-selector');
  const { online } = useNetworkStatus();

  const handleModuleClick = (module: ModuleLink) => {
    void redirectToModule(module);
  };

  return (
    <div className='topNavBar'>
      <AppBar className='nav'>
        <Toolbar
          className={classNames({
            [classes.toolbar]: !isBootstrap && isDesktop,
            [classes.mobileToolbar]: !isBootstrap && !isDesktop
          })}
        >
          {isMenuOpen || !isDesktop ? null : (
            <div className={classNames({ [classes.sidebarMenuButton]: !isBootstrap })}>
              <IconButton onClick={toggleMenu}>
                <MenuIcon />
              </IconButton>
            </div>
          )}
          <Typography
            className={classNames({ [classes.sidebarMenuMargin]: !isBootstrap && isMenuOpen })}
            variant='h5'
          >
            {title || intl.formatMessage({ id: 'module.name' })}
          </Typography>
          <div className={classes.navRight}>
            {!isBootstrap && isDesktop && (
              <LanguageSwitcher
                compact={isMenuOpen && isSmallScreen}
                className={classes.languageSwitcher}
                onChange={changeLocale}
              />
            )}
            <NewModuleSelector isEnabled={online && (hasNewModuleSelector as boolean)} />
            {!hasNewModuleSelector && online && (
              <Feature name='modules/full'>
                <ModuleSelector />
              </Feature>
            )}
          </div>
        </Toolbar>
      </AppBar>
      {returnToModule && (
        <Fab
          color='primary'
          className={classNames(classes.backToHaccpFab, {
            ['mobile']: isGoNative || !isDesktop
          })}
          variant='extended'
          onClick={() => handleModuleClick(returnToModule)}
        >
          <ArrowBack />
          <Box ml={1}>{intl.formatMessage({ id: 'base.backToHaccp' })}</Box>
        </Fab>
      )}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  isMenuOpen: state.ui.isMenuOpen,
  returnToModule: getReturnToModule(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<unknown, unknown, UiActions>) => ({
  toggleMenu: () => dispatch(uiDispatch.toggleMenu()),
  changeLocale: (locale: string) => dispatch(settingsDispatch.changeLocale(locale))
});

export default connect<StoreProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Nav));
