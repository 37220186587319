import {
  Button,
  Card,
  CardMedia,
  Divider,
  Grid,
  InputLabel,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { useIntl } from 'react-intl';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import CompetitionIcon from 'static/img/competition-icon.svg';
import InfoIcon from '@material-ui/icons/Info';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import EmojiEvents from '@material-ui/icons/EmojiEvents';
import classNames from 'classnames';
import { ThunkDispatch } from 'redux-thunk';
import * as funFactsDispatch from 'redux/ducks/funFacts';
import { FunFactsActions } from 'redux/ducks/funFacts';
import { Icon } from 'components/Icon';
import { getRandomFunFact } from 'redux/ducks/funFacts/selectors';
import NatureIcon from '@material-ui/icons/NaturePeople';
import Helmet from 'react-helmet';
import OverlayBox from 'pages/Report/components/OverlayBox';
import { withRouter, WithRouterProps } from 'react-router';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useFeature } from 'flagged';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type DashboardProps = StateProps & DispatchProps & WithRouterProps;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`
  },
  factContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1
  },
  iframeContainer: {
    flex: 2
  },
  iframe: {
    border: 'none'
  },
  divider: {
    height: '2px',
    width: '90%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  factImage: {
    margin: `${theme.spacing(1)}px 0`,
    objectFit: 'contain',
    height: '90px'
  },
  card: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '480px',
    padding: `0 ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(2)}px`
  },
  factCard: {
    padding: 0,
    marginBottom: theme.spacing(3)
  },
  iframeCard: {
    height: '100%'
  },
  dividerStyle: {
    width: '100%'
  },
  iconSoMe: {
    color: theme.palette.background.default
  },
  topGrid: {
    padding: theme.spacing(2)
  },
  footerGrid: {
    padding: `${theme.spacing(0.5)}px ${theme.spacing(2)}px 0 ${theme.spacing(2)}px`,
    position: 'relative'
  },
  competeImage: {
    objectFit: 'contain',
    width: '90px',
    height: '90px'
  },
  banner: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  inputField: {
    margin: '8px 0'
  },
  label: { ...theme.typography.subtitle2, fontWeight: theme.typography.fontWeightBold },
  input: {
    border: '1px solid ' + theme.palette.grey.A100,
    borderRadius: '6px',
    padding: '6px',
    pointerEvents: 'none'
  },
  button: {
    textTransform: 'initial',
    fontSize: '16px',
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(2)}px 0`,
    pointerEvents: 'none'
  },
  buttonSend: {
    textTransform: 'initial',
    fontSize: '16px',
    marginLeft: theme.spacing(2),
    pointerEvents: 'none'
  },
  buttonRegister: {
    marginBottom: theme.spacing(3)
  },
  invitationContainer: {
    display: 'flex',
    marginBottom: theme.spacing(2)
  },
  tableHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  tableGrid: {
    width: '100%'
  },
  inviteGrid: {
    width: '100%',
    position: 'relative'
  },
  headerGrid: {
    marginTop: theme.spacing(2)
  },
  points: {
    display: 'flex',
    alignItems: 'center'
  },
  rowsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  icon: {
    width: '20px'
  },
  trashIcon: {
    color: theme.palette.error.dark
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    borderBottom: '1px solid ' + theme.palette.grey.A400,
    height: '70px',
    justifyContent: 'center'
  },
  rowCompetitorData: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  rowCompetitorPoints: {
    display: 'flex',
    alignItems: 'center'
  },
  rowShare: {
    display: 'flex'
  },
  competeOneMonth: {
    fontSize: '40px'
  },
  italic: {
    fontStyle: 'italic'
  },
  rowResend: {
    display: 'flex',
    marginLeft: theme.spacing(1)
  },
  noFoodWasteData: {
    textAlign: 'center',
    margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px 0`
  },
  competeWrapper: {
    width: '100%'
  },
  opacityOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: '#ffffffad',
    top: '0px',
    pointerEvents: 'none',
    zIndex: 1
  },
  iconInfo: {
    width: '20px',
    marginLeft: theme.spacing(0.5)
  },
  footerOverlay: {
    top: 0
  },
  factImageContainer: {
    marginTop: theme.spacing(0.5),
    width: '100%'
  },
  defaultFunFactIcon: {
    fontSize: '70px'
  },
  infoIcon: {
    width: '20px',
    height: '20px',
    color: 'rgba(0,0,0,0.2)',
    marginLeft: theme.spacing(0.5)
  },
  funFactLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: theme.spacing(1)
  },
  overlayBoxContainer: {
    '& div': {
      height: 'auto',
      top: '150px'
    }
  }
}));

const StarterPlanDashboard: React.FunctionComponent<DashboardProps> = (props) => {
  const classes = useStyles();
  const intl = useIntl();
  const hasMarketingFeature = useFeature('marketing');
  const isDesktop = useMediaQuery('(min-width: 1024px)', { noSsr: true });
  const { funFact, router, competitors, handleFetchFunFacts, currency } = props;

  const { amount, nameKey, image } = funFact || {};

  const competitorsCount = competitors.filter(
    (value) => !value.pendingInvite && value.name !== 'You'
  ).length;

  React.useEffect(() => {
    void handleFetchFunFacts();
  }, []);

  return (
    <Grid container spacing={4} direction='row' className={classes.root}>
      {hasMarketingFeature && isDesktop && (
        <Grid item className={classes.iframeContainer}>
          <Card className={classes.iframeCard}>
            <iframe
              className={classes.iframe}
              height={'100%'}
              width={'100%'}
              src={intl.formatMessage({ id: 'dashboard.marketing.url' })}
            />
          </Card>
        </Grid>
      )}
      <Grid item className={classes.factContainer}>
        <Helmet title={intl.formatMessage({ id: 'dashboard.headline' })} />
        <Card className={classNames(classes.card, classes.factCard)}>
          {funFact?.amount ? (
            <Grid
              className={classes.topGrid}
              container
              justify='center'
              direction='column'
              alignItems='center'
            >
              <div className={classes.funFactLabel}>
                <div className={classes.infoIcon} />
                <Typography variant='h2' component='h1'>
                  {intl.formatMessage({ id: 'dashboard.didYouKnow' })}
                </Typography>
                <Tooltip
                  title={<span>{intl.formatMessage({ id: 'dashboard.funFactPeriod' })}</span>}
                >
                  <InfoIcon className={classes.infoIcon} />
                </Tooltip>
              </div>

              <div className={classes.factImageContainer}>
                {image ? (
                  <CardMedia
                    className={classes.factImage}
                    component='img'
                    image={image}
                    alt={nameKey}
                  />
                ) : (
                  <NatureIcon
                    className={classes.defaultFunFactIcon}
                    color='primary'
                    fontSize='large'
                  />
                )}
              </div>
              <Typography align='center'>
                {intl.formatMessage({ id: 'dashboard.foodwasteEqualsTo' })}
              </Typography>
              <Typography variant='h3' component='h3' align='center'>
                {intl.formatMessage({ id: nameKey }, { value: amount.toFixed(1), currency })}
              </Typography>
            </Grid>
          ) : (
            <Grid
              className={classes.topGrid}
              container
              justify='center'
              direction='column'
              alignItems='center'
            >
              <Typography variant='h2' component='h1'>
                {intl.formatMessage({ id: 'funFacts.label' })}
              </Typography>
              <Typography className={classes.noFoodWasteData}>
                {intl.formatMessage({ id: 'base.noFoodwasteDataAvailable' })}
              </Typography>
              <Button
                color='primary'
                variant='contained'
                className={classes.buttonRegister}
                onClick={() => router.push('/registration')}
              >
                {intl.formatMessage({ id: 'dashboard.register_foodwaste' })}
              </Button>
            </Grid>
          )}

          <Divider className={classes.dividerStyle} />

          <Grid className={classes.footerGrid} container justify='space-between'>
            <div className={classNames(classes.opacityOverlay, classes.footerOverlay)} />
            <Typography>{intl.formatMessage({ id: 'compete.shareScore' })}</Typography>
            <Grid>
              <FacebookIcon className={classes.iconSoMe} />
              <LinkedInIcon className={classes.iconSoMe} />
            </Grid>
          </Grid>
        </Card>

        <Card className={classes.card}>
          <div className={classes.opacityOverlay} />
          <Grid container direction='row' className={classes.headerGrid}>
            <Icon icon={CompetitionIcon} className={classes.competeImage} />
            <Grid className={classes.banner}>
              <Typography variant='h3' component='h3'>
                {intl.formatMessage({ id: 'compete.competeWithFriend' })}
              </Typography>
              <Typography className={classes.competeOneMonth} variant='h1' component='h1'>
                {intl.formatMessage({ id: 'compete.oneMonthFree' })}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.inviteGrid}>
            <div className={classes.overlayBoxContainer}>
              <OverlayBox message={intl.formatMessage({ id: 'base.featureComingSoon' })} />
            </div>
            <div className={classes.inputField}>
              <InputLabel className={classes.label}>
                {intl.formatMessage({ id: 'compete.sendInvitation' })}
              </InputLabel>
              <div className={classes.invitationContainer}>
                <TextField
                  fullWidth
                  required
                  className={classes.input}
                  placeholder={intl.formatMessage({ id: 'base.full_name' })}
                  value={''}
                  focused
                  InputProps={{ disableUnderline: true }}
                />
                <Button
                  color='primary'
                  type='submit'
                  variant='contained'
                  className={classes.buttonSend}
                >
                  {intl.formatMessage({ id: 'base.send' })}
                </Button>
              </div>
            </div>
          </Grid>
          <Grid className={classes.tableGrid}>
            <Typography variant='h3' component='h3'>
              {intl.formatMessage({ id: 'compete.competitorsLabel' })}
            </Typography>

            <div className={classes.tableHeaderContainer}>
              <Typography component='p'>
                {intl.formatMessage(
                  { id: `compete.competitorsCount` },
                  { value: competitorsCount }
                )}
              </Typography>
              <div className={classes.points}>
                <Typography component='p'>{intl.formatMessage({ id: 'base.points' })}</Typography>
                <Tooltip title='Food waste score'>
                  <InfoIcon className={classes.iconInfo} fontSize='small' />
                </Tooltip>
              </div>
            </div>
            <Divider className={classes.dividerStyle} />
            <div className={classes.rowsContainer}>
              {competitors.map((competitor, index) => {
                const { id, name, points, pendingInvite } = competitor;
                return (
                  <div key={id} className={classes.rowContainer}>
                    <div className={classes.rowCompetitorData}>
                      <div>
                        <span>{`${index + 1}. ${name}`}</span>
                        {name === 'You' && (
                          <div className={classes.rowShare}>
                            <Typography>
                              {intl.formatMessage({ id: 'compete.shareScore' })}
                            </Typography>
                            <Grid className={classes.rowResend}>
                              <FacebookIcon className={classes.iconSoMe} />
                              <LinkedInIcon className={classes.iconSoMe} />
                            </Grid>
                          </div>
                        )}
                        {pendingInvite && (
                          <Grid container className={classes.rowShare}>
                            <Typography component='p' className={classes.italic}>
                              {intl.formatMessage({ id: 'base.pendingRequest' })}
                            </Typography>
                            <Grid className={classes.rowResend}>
                              <SendIcon fontSize='small' color='primary' />
                              <Typography component='p' color='primary'>
                                {intl.formatMessage({ id: 'base.resend' })}
                              </Typography>
                            </Grid>
                          </Grid>
                        )}
                      </div>
                      <div className={classes.rowCompetitorPoints}>
                        {index === 0 && <EmojiEvents color='secondary' />}
                        <Typography component='p'>{points}</Typography>
                        <div className={classes.icon}>
                          {name !== 'You' && <DeleteIcon className={classes.trashIcon} />}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Grid>
          <Divider className={classes.dividerStyle} />
          <Button color='primary' type='submit' variant='outlined' className={classes.button}>
            {intl.formatMessage({ id: 'compete.recommendToFriend' })}
          </Button>
        </Card>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: RootState) => ({
  funFact: getRandomFunFact(state),
  competitors: [
    { id: 0, name: 'Marianne', points: 250, pendingInvite: false },
    {
      id: 1,
      name: 'Signe',
      points: 213,
      pendingInvite: false
    },
    { id: 2, name: 'Bo', points: 204, pendingInvite: false },
    { id: 3, name: 'Anne', points: 198, pendingInvite: false },
    { id: 4, name: 'You', points: 66, pendingInvite: false },
    { id: 5, name: 'Ville', points: '', pendingInvite: true }
  ],
  currency: state.settings.currency
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, FunFactsActions>) => ({
  handleFetchFunFacts: () => {
    void dispatch(funFactsDispatch.getFunFacts());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StarterPlanDashboard));
