import * as React from 'react';
import { TimeRange } from 'redux/ducks/reportFilter';
import moment from 'moment';
import { makeStyles, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';

export interface VacationBarProps {
  vacation: TimeRange[];
}

const useStyles = makeStyles({
  box: {
    width: '100%',
    borderRadius: '4px',
    backgroundColor: '#f0f0f0',
    padding: '8px 16px'
  },
  text: {
    fontSize: '1.1rem'
  }
});

export const VacationBar: React.FC<VacationBarProps> = ({ vacation }) => {
  const classes = useStyles();
  const intl = useIntl();
  if (vacation.length === 0) {
    return null;
  }
  const vacationDays = vacation.reduce((all, curr) => {
    const { from, to } = curr;
    return all + moment(to).diff(moment(from), 'days') + 1;
  }, 0);

  const pluralKey = vacationDays === 1 ? 'one' : 'other';

  return (
    <div className={classes.box}>
      <Typography component='span' variant='body1' className={classes.text}>
        {intl.formatMessage({ id: `dashboard.vacationDays.${pluralKey}` }, { count: vacationDays })}
      </Typography>
    </div>
  );
};
