const scaleAppUserAgent = 'scale-app';
const scaleAppClientQueryParam = 'scale';

export const isScaleApp = (): boolean => {
  // deprecated, old method using query parameter
  const hasScaleAppQueryParam =
    new URLSearchParams(window.location.search).get('client') === scaleAppClientQueryParam;
  // deprecated, scale app injects global variable
  const hasScaleAppGlobalVariable = window.isScaleApp;
  // current, scale-app user-agent string.
  // after all customer use scale app version > 2.1,
  // we should only use this detection method
  const hasScaleAppUserAgent = window.navigator.userAgent.startsWith(scaleAppUserAgent);

  return hasScaleAppQueryParam || hasScaleAppGlobalVariable || hasScaleAppUserAgent;
};
