interface Entity {
  id: number | string;
}

export function groupById<T extends Entity, K extends T['id'] = T['id']>(entities: T[]) {
  return entities.reduce(
    (obj, entity) => ({ ...obj, [entity.id]: entity }),
    {} as { [key in K]: T }
  );
}

export function toMapById<T extends Entity>(entities: T[]) {
  const map = new Map<T['id'], T>();
  entities.forEach((entity) => {
    map.set(entity.id, entity);
  });

  return map;
}
