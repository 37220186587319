import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Button, Theme, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { RootState } from 'redux/rootReducer';
import { connect } from 'react-redux';
import { RouterState } from 'react-router/lib/Router';
import { useEffect } from 'react';

type StateProps = ReturnType<typeof mapStateToProps>;

type ModalProps = StateProps;

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    background: '#ffffff',
    fontWeight: 'bold',
    color: 'rgb(0, 150, 136)',
    width: '100%',
    height: '60px',
    border: 'none',
    boxShadow: '0px 0px 10px -3px rgba(0,0,0,0.35)',
    '&:hover': {
      background: 'rgba(0, 150, 136, 0.4)'
    },
    '&:disabled': {
      background: '#ffffff'
    }
  },
  contentContainer: {
    padding: `0 ${theme.spacing(3)}px ${theme.spacing(1)}px ${theme.spacing(3)}px`,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'hidden',
    maxWidth: '460px',
    background: '#ffffff',
    borderRadius: '6px',
    minHeight: '200px',
    justifyContent: 'space-around'
  },
  contentTitle: {
    fontSize: '2rem',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  contentSubTitle: {
    fontSize: '0.9rem',
    textAlign: 'center',
    color: '#616161',
    marginBottom: theme.spacing(2)
  },
  overlay: {
    position: 'absolute',
    backgroundColor: 'rgba(97,97,97,0.32)',
    width: '100%',
    top: '64px',
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '95vh',
    flexDirection: 'column',
    zIndex: 10,
    padding: theme.spacing(2)
  },
  bodyLock: {
    overflowY: 'hidden'
  }
}));

const ExpiredTrialModal: React.FunctionComponent<ModalProps> = () => {
  const intl = useIntl();
  const classes = useStyles();
  const handlePhoneCall = () => {
    window.location.href = 'tel:+4570265555';
  };

  useEffect(() => {
    document.body.classList.add(classes.bodyLock);
    return () => {
      document.body.classList.remove(classes.bodyLock);
    };
  }, []);

  return (
    <div className={classes.overlay}>
      <div className={classes.contentContainer}>
        <Typography className={classes.contentTitle}>
          {intl.formatMessage({ id: 'expiration.dialog.title' })}
        </Typography>
        <Typography className={classes.contentSubTitle}>
          {intl.formatMessage({ id: 'expiration.dialog.message' })}
        </Typography>
        <Button className={classes.button} variant={'outlined'} onClick={handlePhoneCall}>
          {intl.formatMessage({ id: 'plan.dialog.call' })}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState, ownProps: RouterState) => ({
  paths: ownProps
});

export default connect<StateProps, unknown, unknown>(mapStateToProps, null)(ExpiredTrialModal);
