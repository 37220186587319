import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { useIntl } from 'react-intl';
import { PropsWithChildren } from 'react';
import Helmet from 'react-helmet';
import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TopbarMenu } from 'styles/themes/global';
import { useNetworkStatus } from 'frontend-core';

const mapStateProps = (state: RootState) => ({
  enableOffline: state.settings.enableOffline
});

type StateProps = ReturnType<typeof mapStateProps>;
type Props = StateProps;

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.down('xl')]: {
      maxWidth: '1200px'
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: '1600px'
    },
    minHeight: `calc(100vh - ${TopbarMenu.height})`,
    marginLeft: 'auto',
    marginRight: 'auto'
  }
}));

export const withOffline = <T,>(Component: React.ComponentType<T>): React.ComponentType<T> => {
  const HOC: React.FunctionComponent = (props: PropsWithChildren<T> & Props) => {
    const { enableOffline, ...restProps } = props;
    const { online } = useNetworkStatus();
    const intl = useIntl();
    const classes = useStyles();
    if (!online && enableOffline) {
      return (
        <Grid container justify='center' alignItems='center' className={classes.root}>
          <Grid item xs>
            <Helmet title='Offline' />
            <Typography align='center'>
              {intl.formatMessage({ id: 'offline.youAreOffline' })}
            </Typography>
          </Grid>
        </Grid>
      );
    }

    return <Component {...(restProps as T)} />;
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return connect<StateProps, unknown, T>(mapStateProps, null)(HOC);
};
