import * as React from 'react';
import RegistrationPage from 'pages/Registration/RegistrationPage';
import { withRouter, WithRouterProps } from 'react-router';
import { Feature } from 'flagged';
import StarterPlanGuestRegistration from 'pages/Registration/GuestRegistration/StarterPlanGuestRegistration';

const GuestRegistrationPage: React.FunctionComponent<WithRouterProps> = ({ location }) => {
  // we want to re-mount the component whenever user clicks on the menu item
  return (
    <>
      <Feature name='guest-registration/full'>
        <RegistrationPage key={location.key} guestRegistrationOnly />
      </Feature>
      <Feature name='guest-registration/view'>
        <StarterPlanGuestRegistration />
      </Feature>
    </>
  );
};
export default withRouter(GuestRegistrationPage);
