import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import * as React from 'react';
import { eSmileyBlue } from 'styles/palette';
import { Theme } from '@material-ui/core';

export interface SplashScreenProps {
  animate?: boolean;
  label: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  SplashScreen: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: theme.spacing(4),
    transform: 'translateZ(0)',
    backgroundColor: `${eSmileyBlue}`,
    backgroundImage: "url('https://cdn.esmiley.com/esmiley-common/esmiley-splash-background.svg')",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  SplashPicture: {
    display: 'flex',
    margin: '0 auto'
  },
  SplashLogoAnimated: {
    animation: '$fade 3s infinite'
  },
  '@keyframes fade': {
    '0%': {
      opacity: 1
    },
    '70%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  SplashImage: {
    width: '100%'
  }
}));

// Copied from frontend-ui
export const SplashScreen: React.FC<SplashScreenProps> = (props) => {
  const { animate = true, label } = props;
  const classes = useStyles();

  return (
    <div className={classes.SplashScreen}>
      <picture
        className={classNames(classes.SplashPicture, {
          [classes.SplashLogoAnimated]: animate
        })}
      >
        <source
          media='(max-width: 499px)'
          srcSet='https://cdn.esmiley.com/esmiley-common/esmiley-logo-small.svg'
        />
        <source
          media='(min-width: 500px)'
          srcSet='https://cdn.esmiley.com/esmiley-common/esmiley-logo.svg'
        />
        <img
          className={classes.SplashImage}
          alt={label}
          src='https://cdn.esmiley.com/esmiley-common/esmiley-logo.svg'
        />
      </picture>
    </div>
  );
};

export default SplashScreen;
