import { SignupActions, SignupState, SignUpData } from 'redux/ducks/signup/types';
import { ThunkResult } from 'redux/types';
import { displayError, ErrorActions } from 'redux/ducks/error';
import { AxiosResponse } from 'axios';
import { DataTransfer } from 'frontend-core';
import { browserHistory } from 'browserHistory';

const transfer = new DataTransfer();

const initialState: SignupState = {
  loading: false,
  smsToken: '',
  verificationStatus: null
};

export enum SignupActionTypes {
  SIGNUP_LOADING = 'esmiley/data/signup/SIGNUP_LOADING',
  SET_SMS_TOKEN = 'esmiley/data/signup/SET_SMS_TOKEN',
  SET_VERIFICATION_STATUS = 'esmiley/data/signup/SET_VERIFICATION_STATUS'
}

function reducer(state: SignupState = initialState, action: SignupActions): SignupState {
  switch (action.type) {
    case SignupActionTypes.SIGNUP_LOADING: {
      return { ...state, loading: action.payload };
    }
    case SignupActionTypes.SET_SMS_TOKEN: {
      return { ...state, smsToken: action.payload };
    }
    case SignupActionTypes.SET_VERIFICATION_STATUS: {
      return { ...state, loading: false, verificationStatus: action.payload };
    }
    default: {
      return state;
    }
  }
}

export function signup(
  data: SignUpData
): ThunkResult<Promise<SignupActions | ErrorActions>, SignupActions | ErrorActions> {
  return async (dispatch, getState) => {
    const { code, phone, prefix, ...rest } = data;
    const {
      signup: { smsToken },
      settings: { locale }
    } = getState();

    dispatch({
      type: SignupActionTypes.SIGNUP_LOADING,
      payload: true
    });

    try {
      await transfer.post(`/foodwaste/sms-verifications/${smsToken}/validation`, {
        phone: `${prefix} ${phone}`,
        code: code
      });
    } catch (error: unknown) {
      return dispatch({
        type: SignupActionTypes.SET_VERIFICATION_STATUS,
        payload: 'error'
      });
    }

    try {
      const {
        data: { accessToken, refreshToken }
      } = (await transfer.post(`/foodwaste/signup`, {
        phone: `${prefix} ${phone}`,
        language: locale,
        ...rest
      })) as AxiosResponse<{ accessToken: string; refreshToken: string }>;

      // note this will only create temporary "impersonation" session; user will be logged out
      // after s/he closes whe window/tab
      if (!window.navigator.userAgent.includes('esmnative')) {
        sessionStorage.setItem('accessToken', accessToken);
        sessionStorage.setItem('refreshToken', refreshToken);
        browserHistory.replace(`/signup-success`);
      } else {
        browserHistory.replace(`/?access_token=${accessToken}&refresh_token=${refreshToken}`);
      }
    } catch (error) {
      return dispatch(displayError(error as Error));
    }
  };
}

export function sendVerificationCode(
  phone: string
): ThunkResult<Promise<SignupActions | ErrorActions>, SignupActions | ErrorActions> {
  return async (dispatch) => {
    try {
      const response = (await transfer.post('/foodwaste/sms-verifications', {
        phone: phone
      })) as AxiosResponse<{ id: string }>;

      return dispatch({
        type: SignupActionTypes.SET_SMS_TOKEN,
        payload: response.data.id
      });
    } catch (error: unknown) {
      return dispatch(displayError(error as Error));
    }
  };
}

export default reducer;
