type country = {
  label: string;
  code: string;
  prefix: string;
};

export const COUNTRIES: country[] = [
  { label: 'Denmark', code: 'dk', prefix: '+45' },
  { label: 'Sweden', code: 'se', prefix: '+46' },
  { label: 'Finland', code: 'fi', prefix: '+358' },
  { label: 'Norway', code: 'no', prefix: '+47' },
  { label: 'United Kingdom', code: 'gb', prefix: '+44' },
  { label: 'Germany', code: 'ge', prefix: '+49' },
  { label: 'Iceland', code: 'is', prefix: '+354' }
];
