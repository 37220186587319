export const TOP_ROW_CHARTS_MIN_HEIGHT = 360;
export const chartColors = [
  '#008b87',
  '#a4dbeb',
  '#7172b1',
  '#ed8888',
  '#f2a663',
  '#5499ad',
  '#bfbfbf',
  '#9adcdb'
];
