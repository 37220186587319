/* eslint-disable */

import * as React from 'react';
import { WrappedComponentProps, injectIntl } from 'react-intl';

// MATERIAL TABLE
import { Column } from 'material-table';
import AlteredMaterialTable from 'components/MaterialTable';

// OVERWRITTEN MATERIAL TABLE COMPONENTS
import CustomTableHead from 'components/MaterialTable/components/customTableHead';
import CustomEditRow from 'components/MaterialTable/components/customEditRow';

import CreateIcon from '@material-ui/icons/Create';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/CheckCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddIcon from '@material-ui/icons/Add';

import { colors, IconButton, Button } from '@material-ui/core';

import { isIE11 } from 'utils/browsers';
import { Slide } from '@material-ui/core';
import { ReportMail } from '../api';
import { parseFrequency } from 'pages/Report/Mail/utils/frequency';

// MT options (https://material-table.com/#/docs/all-props)
const materialTableOptions = {
  toolbar: false,
  paging: false,
  disableToolbar: true,
  actionsColumnIndex: -1,
  showTitle: false,
  search: false,
  searchFieldAlignment: 'left',
  draggable: false,
  headerStyle: {
    width: '0%',
    backgroundColor: colors.grey['50'],
    padding: '5px 10px'
  },
  // need to override mui cell padding
  rowStyle: {
    //CRAS: IE11 has an issue with transitioning opacity on table rows
    transition: (isIE11 ? '' : 'opacity 300ms ease-out, ') + 'background-color 500ms ease-out',
    borderBottom: `1px solid ${colors.grey['200']}`,
    padding: 0,
    height: 80
  },
  actionsCellStyle: {
    width: '0%',
    whiteSpace: 'pre',
    padding: '5px 10px'
  }
};

interface OwnProps {
  reports: ReportMail[];

  onDelete(report: ReportMail): void;

  onCopy(report: ReportMail): void;

  onEdit(report: ReportMail): void;

  onEditReportJob(report: ReportMail): void;

  onToggleActive(report: ReportMail): void;
}

type ReportMailTableProps = WrappedComponentProps & OwnProps;

const deleteTransitionDuration = 1000;

class ReportMailTable extends React.Component<ReportMailTableProps, {}> {
  deletedRow: any;
  tableRef = React.createRef<any>();

  // whitelistedKeys: Array including properties that we want to work with. Taken from old solution.
  private whitelistedKeys: string[];

  constructor(props: ReportMailTableProps) {
    super(props);

    this.whitelistedKeys = [
      'name',
      'nameAction',
      'recipients',
      'frequency',
      'frequencyAction',
      'active'
    ];
  }

  shouldComponentUpdate(
    nextProps: Readonly<ReportMailTableProps>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    return nextProps.reports !== this.props.reports;
  }

  /**
   * Takes the registration-points data coming from the endpoint, parses it,
   * and starts setting up the columns based on the fields.
   * @returns { Column[] } - array of columns that will be passed as a prop to MT
   * */
  extractColumnsFromData = (): Column<ReportMail>[] => {
    const enabledColumns = this.whitelistedKeys;

    return enabledColumns.map((key) => {
      switch (key) {
        case 'name':
          return this.setupNameColumn(key);
        case 'nameAction':
          return this.setupNameActionColumn(key);
        case 'recipients':
          return this.setupRecipientsColumn(key);
        case 'frequency':
          return this.setupFrequencyColumn(key);
        case 'frequencyAction':
          return this.setupFrequencyActionColumn(key);
        case 'active':
          return this.setupActiveColumn(key);
        default:
          return this.setupDefaultColumn(key);
      }
    });
  };

  /**
   * Returns an object with needed data for custom-rendering
   * @returns { Column } - object including properties needed to custom-render the field in the table
   * */
  setupDefaultColumn = (key): Column<ReportMail> => {
    const { intl } = this.props;

    return {
      cellStyle: { padding: '5px 10px' },
      title: intl.formatMessage({ id: key }),
      field: key
    };
  };

  /**
   * Returns an object with needed data for custom-rendering
   * @param { key }
   * @returns { Column } - object including properties needed to custom-render the field in the table
   * */
  setupNameColumn = (key): Column<ReportMail> => {
    const { intl } = this.props;

    return {
      title: intl.formatMessage({ id: 'report.mail.tableColumn.name' }),
      field: key,
      cellStyle: {
        padding: '5px 10px',
        position: 'relative',
        whiteSpace: 'pre'
      },
      customSort: (a, b) => {
        return a.name.localeCompare(b.name, intl.locale, { sensitivity: 'base' });
      },
      render: this.renderNameColumn
    };
  };

  setupFrequencyColumn = (key): Column<ReportMail> => {
    const { intl } = this.props;

    return {
      title: intl.formatMessage({ id: 'report.mail.tableColumn.frequency' }),
      field: key,
      cellStyle: {
        width: '100%',
        padding: '5px 10px',
        position: 'relative',
        whiteSpace: 'pre'
      },
      render: this.renderFrequencyColumn
    };
  };

  renderFrequencyColumn = (rowData): string | JSX.Element => {
    const { intl } = this.props;
    return (
      <span
        style={{
          transition: 'opacity 300ms ease-out',
          opacity: rowData.active ? 1 : 0.4
        }}
      >
        {rowData.job
          ? intl.formatMessage({
              id: `report.mail.recipientDialog.frequencies.${parseFrequency(rowData.job)}`
            })
          : null}
      </span>
    );
  };

  setupFrequencyActionColumn = (key): Column<ReportMail> => {
    return {
      field: key,
      headerStyle: {
        borderRight: `1px solid ${colors.grey['200']}`
      },
      cellStyle: {
        width: '0%',
        padding: '5px 10px',
        position: 'relative',
        whiteSpace: 'pre',
        borderRight: `1px solid ${colors.grey['200']}`
      },
      render: this.renderFrequencyActionColumn
    };
  };

  renderFrequencyActionColumn = (rowData): string | JSX.Element => {
    return (
      <div
        style={{
          transition: 'opacity 300ms ease-out',
          opacity: rowData.active ? 1 : 0.4
        }}
      >
        {rowData.job ? (
          <IconButton onClick={() => this.props.onEditReportJob(rowData)}>
            <CreateIcon color='primary' />
          </IconButton>
        ) : null}
      </div>
    );
  };

  setupRecipientsColumn = (key): Column<ReportMail> => {
    const { intl } = this.props;

    return {
      title: intl.formatMessage({ id: 'report.mail.tableColumn.mailTo' }),
      field: key,
      cellStyle: {
        width: '100%',
        padding: '5px 10px',
        position: 'relative',
        whiteSpace: 'pre'
      },
      render: this.renderRecipientsColumn
    };
  };

  renderRecipientsColumn = (rowData): string | JSX.Element => {
    const { intl } = this.props;
    return (
      <span
        style={{
          transition: 'opacity 300ms ease-out',
          opacity: rowData.active ? 1 : 0.4
        }}
      >
        {rowData.job ? (
          rowData.job.recipients.join(', ')
        ) : (
          <Button
            startIcon={<AddIcon style={{ color: '#068c89' }} />}
            style={{
              background: '#e5e5e5',
              color: '#6c6c6c',
              width: '175px',
              height: '35px',
              padding: '20px 100px'
            }}
            onClick={() => this.props.onEditReportJob(rowData)}
          >
            {intl.formatMessage({ id: 'report.mail.addRecipients' })}
          </Button>
        )}
      </span>
    );
  };

  setupNameActionColumn = (key): Column<ReportMail> => {
    return {
      field: key,
      headerStyle: {
        borderRight: `1px solid ${colors.grey['200']}`
      },
      cellStyle: {
        width: '0%',
        padding: '5px 10px',
        position: 'relative',
        whiteSpace: 'pre',
        borderRight: `1px solid ${colors.grey['200']}`
      },
      render: this.renderNameActionColumn
    };
  };

  renderNameActionColumn = (rowData): string | JSX.Element => {
    const { tableData, ...mailData } = rowData;
    return (
      <div
        style={{
          transition: 'opacity 300ms ease-out',
          opacity: rowData.active ? 1 : 0.4
        }}
      >
        <IconButton color='primary' onClick={() => this.props.onCopy(mailData)}>
          <FileCopyIcon />
        </IconButton>
        <IconButton color='primary' onClick={() => this.props.onEdit(mailData)}>
          <CreateIcon />
        </IconButton>
      </div>
    );
  };

  renderActiveColumn = (rowData): string | JSX.Element => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        transition: 'opacity 300ms ease-out',
        opacity: rowData.active ? 1 : 0.4
      }}
    >
      <IconButton onClick={() => this.props.onToggleActive(rowData)}>
        {rowData.active ? <CheckIcon color='primary' /> : <RemoveCircleIcon />}
      </IconButton>
    </div>
  );

  setupActiveColumn = (key): Column<ReportMail> => {
    const { intl } = this.props;

    return {
      title: intl.formatMessage({ id: 'report.mail.tableColumn.status' }),
      field: key,
      cellStyle: {
        width: '0%',
        padding: '5px 10px',
        position: 'relative',
        whiteSpace: 'pre'
      },
      render: this.renderActiveColumn
    };
  };

  /**
   * Renders the data for the 'Name' column
   * @param {rowData} - row data
   * @returns {string | JSX.Element} - returns the value needed to be rendered, or the value together with an icon if
   it's a newly added row
   * */
  renderNameColumn = (rowData): string | JSX.Element => (
    <span
      style={{
        transition: 'opacity 300ms ease-out',
        opacity: rowData.active ? 1 : 0.4
      }}
    >
      {rowData.name}
    </span>
  );

  /**
   * Render an Edit Row
   *
   * @param {props}
   * */

  /**
   * Override the default MT table head with a custom one, and render it.
   *
   * @param {props}
   * */
  overrideTableHead = (props): JSX.Element => {
    return <CustomTableHead {...props} draggable={false} />;
  };

  /**
   * Override the default MT Overlay with an empty <span> in order to 'deactivate' it
   *
   * @param {props}
   * */
  overrideOverlay = (props): JSX.Element => <span />;

  /**
   * Override the default MT table edit row with a custom one, and render it.
   *
   * @param {props}
   * */
  overrideEditRow = (props): JSX.Element => {
    let deleteConfirmationText;
    const slidingIn =
      props.data && props.data.id && props.data.id === this.deletedRow ? false : true;
    const slideDuration =
      props.data && props.data.id && props.data.id === this.deletedRow
        ? deleteTransitionDuration
        : 0;

    if (props.mode === 'delete') {
      const { intl } = this.props;

      deleteConfirmationText = intl.formatMessage(
        { id: 'settings.content.deleteConfirmation' },
        { registrationPoint: props.data.name }
      );
    }

    return (
      <Slide in={slidingIn} direction={'left'} timeout={slideDuration}>
        <CustomEditRow
          {...props}
          rowStyle={{ height: '80px' }}
          cellStyle={{ padding: '5px 10px' }}
          disableEdit={(registration: any) => !registration?.name?.trim()}
          deleteConfirmationText={deleteConfirmationText}
          onCancelHandler={this.onCancelHandler}
          onSubmitHandler={this.onSubmitHandler}
        />
      </Slide>
    );
  };

  /**
   * Whenever an Edit row has been canceled, this callback is called
   *
   ** */
  onCancelHandler = (): void => {
    this.resetHelperGlobals();
  };

  /**
   * Whenever an Edit row has been approved (by Enter or clicking on the Check icon), this callback is called
   *
   * @param {data}
   **/
  onSubmitHandler = (data: ReportMail, mode?: string): void => {
    if (mode === 'delete') {
      this.deletedRow = data.id;
    }
  };

  /*
   * Reset some helper variables. This gets called when we cancel an Edit row.
   *
   * */
  resetHelperGlobals = (): void => {
    this.deletedRow = undefined;
  };

  /**
   * Runs when we confirmed deletion on an existing row.
   * Here we make the call to the endpoint with the data for the row we want to delete.
   *
   * @param {newData}
   * */
  onRowDelete = async (oldData) => {
    await new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        const { tableData, ...mailData } = oldData;
        this.props.onDelete(mailData);
        this.resetHelperGlobals();
        resolve();
      }, deleteTransitionDuration - 200);
    });
  };

  render() {
    const { reports, intl } = this.props;
    return (
      <AlteredMaterialTable
        fullWidth
        noContentMessage={intl.formatMessage({ id: 'report.mail.noReports' })}
        tableRef={this.tableRef}
        // @ts-ignore
        columns={this.extractColumnsFromData()}
        data={reports}
        // @ts-ignore
        options={materialTableOptions}
        components={{
          Header: this.overrideTableHead,
          OverlayLoading: this.overrideOverlay,
          EditRow: this.overrideEditRow
        }}
        editable={{
          onRowDelete: this.onRowDelete
        }}
        style={{
          border: `1px solid ${colors.grey['200']}`,
          overflow: 'hidden'
        }}
      />
    );
  }
}

export default injectIntl(ReportMailTable);
