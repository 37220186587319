import * as React from 'react';
import { Column } from 'material-table';
import { colors, WithStyles, withStyles } from '@material-ui/core';
import AlteredMaterialTable from 'components/MaterialTable';
import { WrappedComponentProps, injectIntl } from 'react-intl';
import { isIE11 } from 'utils/browsers';
import classNames from 'classnames';
import { ReportMailCategory } from 'pages/Report/Mail/api';

interface Category {
  key: ReportMailCategory;
  value: string;
}

export interface CategorySelectorProps extends WrappedComponentProps, WithStyles {
  categories: ReportMailCategory[];
  selected: ReportMailCategory;
  onChange: (category: ReportMailCategory) => void;
}

// MT options (https://material-table.com/#/docs/all-props)
const materialTableOptions = {
  toolbar: false,
  paging: false,
  disableToolbar: true,
  actionsColumnIndex: -1,
  showTitle: false,
  search: false,
  searchFieldAlignment: 'left',
  draggable: false,
  tableLayout: 'fixed',
  headerStyle: {
    height: '42px',
    width: '0%',
    backgroundColor: colors.grey['50'],
    padding: '5px 10px'
  },
  // need to override mui cell padding
  rowStyle: {
    //CRAS: IE11 has an issue with transitioning opacity on table rows
    transition: (isIE11 ? '' : 'opacity 300ms ease-out, ') + 'background-color 500ms ease-out',
    borderBottom: `1px solid ${colors.grey['200']}`,
    padding: 0,
    height: 80
  },
  actionsCellStyle: {
    width: '0%',
    whiteSpace: 'pre',
    padding: '5px 10px'
  }
};

const intlKeys: { [key: string]: string } = {
  all: 'report.mail.metrics.allCategories',
  total_waste: 'report.totalFoodwaste.title',
  departments: 'report.accounts.title',
  frequency: 'report.registrationFrequency.title',
  overview: 'report.quickOverview.title',
  per_guest: 'report.foodwaste.perGuest.title'
};

class CategorySelector extends React.Component<CategorySelectorProps> {
  tableRef = React.createRef<any>();
  whitelistedKeys: (keyof Category)[];
  export;
  default;

  constructor(props: CategorySelectorProps) {
    super(props);
    this.whitelistedKeys = ['key'];
  }

  extractColumnsFromData = (): Column<Category>[] => {
    return this.whitelistedKeys.map((key) => this.setupCategoryColumn(key));
  };

  setupCategoryColumn = (key: keyof Category): Column<Category> => {
    const { intl } = this.props;

    return {
      field: key,
      title: intl.formatMessage({ id: 'report.mail.metrics.reportPages' }),
      cellStyle: {
        padding: 0,
        position: 'relative',
        height: '80px'
      },
      customSort: (a, b) => a.value.localeCompare(b.value, intl.locale, { sensitivity: 'base' }),
      render: this.renderCategoryColumn
    };
  };

  renderCategoryColumn = (rowData: Category): string | JSX.Element => {
    const { selected, onChange, classes } = this.props;
    return (
      <div
        className={classNames(classes.col, { [classes.colSelected]: selected === rowData.key })}
        onClick={() => onChange(rowData.key)}
      >
        {rowData.value}
      </div>
    );
  };

  render() {
    const { intl, categories } = this.props;
    return (
      <AlteredMaterialTable
        noContentMessage={intl.formatMessage({ id: 'report.mail.noReports' })}
        tableRef={this.tableRef}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        columns={this.extractColumnsFromData()}
        data={categories.map((category) => ({
          key: category,
          value: intl.formatMessage({ id: intlKeys[category] })
        }))}
        /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        options={materialTableOptions}
        style={{
          border: `1px solid ${colors.grey['200']}`,
          borderBottomRightRadius: 0,
          borderTopRightRadius: 0,
          overflow: 'hidden',
          marginRight: '-1px'
        }}
      />
    );
  }
}

export default injectIntl(
  withStyles((theme) => ({
    col: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      whiteSpace: 'pre',
      padding: '5px 10px',
      width: '100%',
      height: '100%',
      transition: 'opacity 300ms ease-out',
      '&:hover': {
        backgroundColor: theme.palette.action.hover
      }
    },
    colSelected: {
      pointerEvents: 'none',
      backgroundColor: theme.palette.action.selected
    }
  }))(CategorySelector)
);
