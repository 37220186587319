import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SvgIcon,
  Switch,
  TextField
} from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { TimePicker } from '@material-ui/pickers';
import { Autocomplete } from '@material-ui/lab';
import { Close, DateRange, InfoOutlined } from '@material-ui/icons';
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { CustomTimePicker } from 'pages/Report/Mail/components/CustomTimePicker';
import { Moment } from 'moment';
import {
  useCreateReportJob,
  useUpdateReportJob,
  ReportJob,
  ReportMail
} from 'pages/Report/Mail/api';
import {
  frequencies,
  Frequency,
  frequencyToInterval,
  parseFrequency
} from 'pages/Report/Mail/utils/frequency';
import LoadingPlaceholder from 'components/LoadingPlaceholder';

interface ReportJobDialogProps {
  isOpen: boolean;
  onClose: () => void;
  report: ReportMail;
  onChange?: (report: ReportMail) => void;
}

const theme = createMuiTheme({
  overrides: {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'rgb(0, 150, 136)'
      }
    },
    MuiButtonBase: {
      root: {
        color: 'rgb(0, 150, 136) !important'
      }
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: 'rgb(0, 150, 136)'
      },
      thumb: {
        border: '14px solid rgb(0, 150, 136)',
        backgroundColor: 'rgb(0, 150, 136)'
      },
      noPoint: {
        backgroundColor: 'rgb(0, 150, 136)'
      }
    },
    MuiPickersClock: {
      pin: {
        border: '2px solid rgb(0, 150, 136)',
        backgroundColor: 'rgb(0, 150, 136)'
      }
    }
  }
});

const parseRecipients = (job?: ReportJob): string[] => {
  return job ? job.recipients : [];
};

const parseTime = (job?: ReportJob): Date => {
  if (!job) {
    return new Date();
  }

  const date = new Date();
  date.setHours(job.hour);
  date.setMinutes(job.minute, 0, 0);

  return date;
};

const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
const isValidEmail = (email: string) => emailRegex.test(email);

const ReportJobDialog: React.FunctionComponent<ReportJobDialogProps> = (props) => {
  const intl = useIntl();
  const classes = styles(props);
  const { isOpen, onClose, onChange, report } = props;
  const { name, job } = report;

  // not cleanest way to do this:
  // cant use conditions with hooks,
  // so need to figure out how to split these into
  // components (eg custom button for each)
  const createJob = useCreateReportJob();
  const updateJob = useUpdateReportJob();

  // would be cleaner with reducer hook instead of separate state hooks
  const [frequency, setFrequency] = React.useState<Frequency>(
    job ? parseFrequency(job) : 'onetime'
  );
  const [recipients, setRecipients] = React.useState(parseRecipients(job));
  const [emailInput, setEmailInput] = React.useState('');
  const [time, setTime] = React.useState<Date>(parseTime(job));

  const isLoading = createJob.isLoading || updateJob.isLoading;
  const isSuccess = createJob.isSuccess || updateJob.isSuccess;

  const validations = React.useMemo(() => {
    const validEmail = recipients.length > 0 && recipients.every(isValidEmail);
    const validTime = time.getHours() > 0 && time.getTime() >= 0;
    const allValid = validEmail && validTime;
    return {
      email: validEmail,
      time: validTime,
      allValid
    };
  }, [recipients, time]);

  React.useEffect(() => {
    if (isSuccess) {
      const data = createJob.data || updateJob.data;
      onChange && onChange({ ...report, job: data });
      onClose();
    }
  }, [isSuccess]);

  const handleFrequencyChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFrequency(e.target.value as Frequency);
  };

  const handleTimeChange = (date: Moment) => {
    setTime(date.toDate());
  };

  const handleSubmit = () => {
    if (!validations.allValid) {
      return;
    }

    const update = {
      ...job,
      reportMailId: report.id,
      recipients,
      hour: time.getHours(),
      minute: time.getMinutes(),
      ...frequencyToInterval[frequency]
    };

    if (job && job.id) {
      updateJob.mutate(job, update);
    } else {
      createJob.mutate(update);
    }
  };

  const handleRecipientsChange = (event: React.ChangeEvent, value: string[]) => {
    setRecipients(value);
  };

  const handleEmailInputChange = (event: React.ChangeEvent | React.FocusEvent, value: string) => {
    if (value.endsWith(',') && isValidEmail(value.slice(0, -1))) {
      setRecipients((prev) => [...prev, value.slice(0, -1)]);
    } else {
      setEmailInput(value);
    }
  };

  return isLoading ? (
    <Dialog open={isOpen} disableBackdropClick={true} disableEscapeKeyDown={true}>
      <DialogContent className={classes.dialog}>
        <LoadingPlaceholder
          classes={{ title: classes.title }}
          title={intl.formatMessage({ id: 'settings.processingGuestRegistrations' })}
        />
      </DialogContent>
    </Dialog>
  ) : (
    <Dialog open={isOpen} onClose={onClose}>
      <div className={classes.dialogTitle}>
        <DialogTitle>{intl.formatMessage({ id: 'report.mail.recipientDialog.title' })}</DialogTitle>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      <DialogContentText className={classes.dialogSubtitle}>
        {intl.formatMessage({ id: 'report.mail.recipientDialog.subtitle' })}
      </DialogContentText>
      <DialogContent>
        <Grid item container spacing={3} className={classes.grid}>
          <Grid container item xs={3}>
            <FormLabel>
              {intl.formatMessage({ id: 'report.mail.recipientDialog.labelReport' })}
            </FormLabel>
          </Grid>
          <Grid container item xs={9}>
            <FormLabel style={{ fontWeight: 'bold' }}>{name}</FormLabel>
          </Grid>
          <Grid container item xs={3}>
            <FormLabel>
              {intl.formatMessage({ id: 'report.mail.recipientDialog.labelEmail' })}
            </FormLabel>
          </Grid>
          <Grid container item xs={9} className={classes.inputMargin}>
            <Autocomplete
              multiple
              freeSolo
              autoSelect={true}
              id='size-small-outlined-multi'
              size='medium'
              popupIcon={null}
              value={recipients}
              inputValue={emailInput}
              options={[]}
              fullWidth
              onChange={handleRecipientsChange}
              onInputChange={handleEmailInputChange}
              classes={{ inputRoot: classes.inputRoot }}
              noOptionsText={intl.formatMessage({
                id: 'report.mail.recipientDialog.recipientselect.noOptions'
              })}
              renderInput={(params) => (
                <TextField
                  type={'email'}
                  error={!validations.email}
                  required
                  helperText={
                    !isValidEmail
                      ? intl.formatMessage({ id: 'report.mail.recipientDialog.emailInputError' })
                      : intl.formatMessage({ id: 'report.mail.recipientDialog.emailInputHelp' })
                  }
                  {...params}
                  placeholder={'hello@esmiley.dk'}
                  variant={'outlined'}
                />
              )}
            />
          </Grid>
          <Grid container item xs={3} />
          <Grid container item xs={9}>
            <FormControlLabel
              className={classes.switchMargin}
              label={intl.formatMessage({ id: 'report.mail.recipientDialog.labelToggle' })}
              labelPlacement='end'
              disabled={true}
              control={
                <>
                  <Switch disabled={true} name={'splitToggle'} title={'splitToggle'} />
                </>
              }
            />
            <IconButton className={classes.iconButton}>
              <InfoOutlined />
            </IconButton>
          </Grid>
          <Grid container item xs={3} alignItems={'center'}>
            <FormLabel>
              {intl.formatMessage({ id: 'report.mail.recipientDialog.labelTime' })}
            </FormLabel>
          </Grid>
          <Grid container item xs={9} alignItems={'center'}>
            <div className={classes.selectFrequency}>
              <Select
                defaultValue={'onetime'}
                value={frequency}
                className={classes.selectInterval}
                onChange={handleFrequencyChange}
                disableUnderline
                renderValue={(value: string) => {
                  return (
                    <Box style={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <SvgIcon style={{ color: 'rgb(0, 150, 136)', marginRight: '7.5px' }}>
                        <DateRange />
                      </SvgIcon>
                      {intl.formatMessage({
                        id: `report.mail.recipientDialog.frequencies.${value}`
                      })}
                    </Box>
                  );
                }}
                autoWidth
              >
                {frequencies.map((frequency) => (
                  <MenuItem key={frequency} value={frequency}>
                    {intl.formatMessage({
                      id: `report.mail.recipientDialog.frequencies.${frequency}`
                    })}
                  </MenuItem>
                ))}
              </Select>
              <span className={classes.atLabel}>
                {intl.formatMessage({ id: 'report.mail.recipientDialog.sendMail.label' })}
              </span>
              <ThemeProvider theme={theme}>
                <TimePicker
                  error={!validations.time}
                  required
                  TextFieldComponent={CustomTimePicker}
                  ampm={false}
                  value={time}
                  onChange={handleTimeChange}
                  inputProps={{ button: { background: 'none' } }}
                />
              </ThemeProvider>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={onClose}>{intl.formatMessage({ id: 'base.cancel' })}</Button>
        <Button className={classes.button} onClick={handleSubmit} disabled={!validations.allValid}>
          {intl.formatMessage({ id: 'report.mail.recipientDialog.addRecipient' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
const primaryColour = 'rgb(0, 150, 136)';
const styles = makeStyles(() => ({
  inputMargin: {
    marginTop: '-10px'
  },
  selectFrequency: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  atLabel: {
    padding: '0px 10px'
  },
  selectInterval: {
    width: '280px',
    fontWeight: 'bold',
    border: `1px solid ${primaryColour}`,
    borderRadius: '5px',
    color: primaryColour,
    padding: '1px 10px',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      color: primaryColour
    }
  },
  switchMargin: {
    marginTop: '-25px'
  },
  inputRoot: {
    border: `1px solid ${primaryColour} !important`,
    borderRadius: '5px',
    paddingLeft: '8px',
    padding: '1px 4px !important'
  },
  iconButton: {
    marginTop: '-25px',
    marginLeft: '-20px'
  },
  grid: {
    margin: 0,
    marginLeft: '-13px'
  },
  dialog: {
    padding: '0px!important'
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px'
  },
  dialogSubtitle: {
    marginLeft: '25px',
    marginRight: '25px',
    marginTop: '-15px'
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  formControl: {
    minWidth: 120
  },
  select: {
    margin: '0!important'
  },
  title: {
    fontSize: '16px'
  },
  button: {
    background: primaryColour,
    color: '#ffffff',
    width: '175px',
    height: '35px',
    '&:hover': {
      background: 'rgba(0, 150, 136, 0.4)'
    },
    '&:disabled': {
      background: '#ffffff'
    }
  }
}));

export default ReportJobDialog;
