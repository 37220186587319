import { ReportJob, ReportJobInterval, ReportWeekday } from 'pages/Report/Mail/api';

export const frequencies = [
  'onetime',
  'everyDay',
  'everyMonday',
  'everyFriday',
  'everyFirstDayMonday'
] as const;

export type Frequency = typeof frequencies[number];

export const frequencyToInterval: {
  [key in Frequency]: { interval: ReportJobInterval; weekday?: ReportWeekday };
} = {
  onetime: { interval: 'onetime' },
  everyDay: { interval: 'day' },
  everyMonday: { interval: 'week', weekday: 'monday' },
  everyFriday: { interval: 'week', weekday: 'friday' },
  everyFirstDayMonday: { interval: 'month', weekday: 'monday' }
};

export const parseFrequency = (job: ReportJob): Frequency => {
  const { interval, weekday } = job;

  switch (interval) {
    case 'onetime':
      return interval;
    case 'day':
      return 'everyDay';
    case 'week': {
      if (weekday === 'monday') {
        return 'everyMonday';
      }
      if (weekday === 'friday') {
        return 'everyFriday';
      }
      break;
    }
    case 'month': {
      if (weekday === 'monday') {
        return 'everyFirstDayMonday';
      }
      break;
    }
    default:
      return 'onetime';
  }
};
