import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import * as Highcharts from 'highcharts';
import { Options, SeriesBarOptions } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { connect } from 'react-redux';
import * as chartsDispatch from 'redux/ducks/charts';
import cloneDeep from 'lodash/cloneDeep';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'redux/rootReducer';
import { ChartsActions } from 'redux/ducks/charts';
import exportData from 'highcharts/modules/exporting';
import drilldown from 'highcharts/modules/drilldown';

import withLoading, { WithLoadingProps } from 'components/LoadingPlaceholder/withLoading';
import { ChartRef } from 'declarations/chart';
import theme from 'styles/themes/reports';
import { SubPointSeries } from 'pages/Report/components/Chart/utils/getChartData';
import calculateChartHeight from 'pages/Report/components/Chart/Bar/utils/calculateChartHeight';
import { SeriesData } from 'redux/ducks/reportData';

exportData(Highcharts);
drilldown(Highcharts);

export interface OwnProps extends WithLoadingProps {
  // todo: hc types should be encapsulated
  chartOptions: Options;
  group?: boolean;
  asModal?: boolean;
  enableDrillDown?: boolean;
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export interface ComponentProps extends OwnProps, DispatchProps {}

const DRILLDOWN_CONFIG = {
  series: [],
  activeAxisLabelStyle: {
    textDecoration: 'underline',
    fontStyle: 'none',
    fontWeight: `${theme.typography.fontWeightBold}`,
    color: theme.palette.primary['main']
  },
  activeDataLabelStyle: {
    textDecoration: 'none',
    fontStyle: 'none',
    fontWeight: `${theme.typography.fontWeightRegular}`,
    color: theme.palette.grey[400]
  },
  breadcrumbs: {
    floating: true,
    showFullPath: false,
    position: { align: 'right' as const },
    buttonTheme: { padding: 6, fill: '#f7f7f7', stroke: '#cccccc', 'stroke-width': 1 }
  }
};

const DRILLDOWN_EVENTS_CONFIG = {
  events: {
    drillup: function ({ seriesOptions }) {
      // still seems to be an issue https://www.highcharts.com/forum/viewtopic.php?t=31746
      // the issue only occurs if drilldown has less data points than the "parent" data set
      setTimeout(
        () =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
          this.update({
            chart: {
              height: calculateChartHeight({
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call
                series: [{ points: seriesOptions.data }],
                marginBottom: 40,
                marginTop: 40
              })
            }
          }),
        100
      );
    },
    drilldown: function ({ points }) {
      if (!points) {
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      const seriesData = points.map((p) => ({ points: p.subPoints })) as SeriesData[];
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      this.update({
        chart: {
          height: calculateChartHeight({
            series: seriesData,
            marginBottom: 40,
            marginTop: 40
          })
        }
      });
    }
  }
};

const initState = (options: Options): Options => {
  const { chart = {}, ...rest } = options;
  const { events, ...restChart } = chart;
  return {
    chart: {
      type: 'bar',
      ...restChart,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      events: {
        ...events,
        ...DRILLDOWN_EVENTS_CONFIG.events
      }
    },
    drilldown: DRILLDOWN_CONFIG,
    ...rest
  };
};

const BarChart: React.FunctionComponent<ComponentProps> = (props) => {
  const { chartOptions, registerChart, unregisterChart, group, enableDrillDown } = props;
  const classes = useStyles(props);
  const chartRef = useRef<ChartRef>();
  const [options, setOptions] = useState<Options>(initState(chartOptions));

  useEffect(() => {
    if (props.asModal) {
      return;
    }

    if (chartRef.current && chartRef.current.chart) {
      const chart = chartRef.current.chart;

      registerChart(group ? 'barGroup' : 'bar', chart);

      return () => {
        unregisterChart(group ? 'barGroup' : 'bar', chart);
      };
    }
  }, []);

  useEffect(() => {
    const { series: chartSeries, ...config } = chartOptions;
    const series = chartSeries as SeriesBarOptions[];
    if (series) {
      // UPDATE: this here probably is caused by the way the props are passed? should separate series data and chart config props,
      // since this issue is not present in donut chart.
      // Without this the colors are not mapped correctly when number of series change (seems a bit random how it works)

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore colorCounter is not in highcharts typings
      chartRef.current.chart.colorCounter =
        chartOptions.series.length === 0 ? 0 : chartOptions.series.length - 1;
      const deepCopy = series ? cloneDeep(series) : [];
      const drilldownSeries = [];

      if (!enableDrillDown) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        setOptions({ series: deepCopy, drilldown: { series: drilldownSeries }, ...config });
        return;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const mappedSeries = deepCopy.map((series: SubPointSeries, i) => {
        const { data, ...rest } = series;
        const mappedPoints = data.map(({ subPoints, ...point }) => {
          if (subPoints?.length > 0) {
            drilldownSeries.push({
              id: `${i}.${rest.name}.${point.name}`,
              data: subPoints
            });
            return { ...point, drilldown: `${i}.${rest.name}.${point.name}` };
          }
          return point;
        });
        return { data: mappedPoints, ...rest };
      });

      const { chart, ...restConfig } = config;
      const height = calculateChartHeight({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        series: deepCopy.map((s) => ({ points: s.data })),
        marginBottom: 40,
        marginTop: 40
      });

      setOptions({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        series: mappedSeries,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        drilldown: { series: drilldownSeries },
        ...restConfig,
        chart: { ...chart, height }
      });
    }
  }, [chartOptions]);

  React.useEffect(() => {
    // automatic redraw seems to have issues, eg labels not updated properly,
    // hence redoing in manually.
    // not sure if issue with hc lib or our set up.
    // cause: we change the chart height when series change => if we remove the height change, there are no issues
    // but that would require refactor / redesign
    chartRef.current.chart.redraw(true);
  }, [options]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      containerProps={{ className: classes.container }}
      ref={chartRef}
    />
  );
};

const useStyles = makeStyles<Theme, ComponentProps>({});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, ChartsActions>) => ({
  registerChart: (chartType: string, chartRef: Highcharts.Chart) =>
    dispatch(chartsDispatch.registerChart(chartType, chartRef)),
  unregisterChart: (chartType: string, chartRef: Highcharts.Chart) =>
    dispatch(chartsDispatch.unregisterChart(chartType, chartRef))
});

export default connect<unknown, DispatchProps, OwnProps>(
  null,
  mapDispatchToProps
)(withLoading(BarChart));
