import * as React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage as FormattedMessageBase } from 'react-intl';
import { RootState } from 'redux/rootReducer';

interface StateProps {
  currency: string;
  massUnit: string;
}

interface OwnProps {
  id: string;
  html?: boolean;
}

type FormattedMessageProps = StateProps & OwnProps;

function FormattedMessage({ id, currency, massUnit }: FormattedMessageProps) {
  return (
    <FormattedMessageBase
      id={id}
      values={{
        sub: (chunks) => <sub>{chunks}</sub>,
        currency,
        massUnit
      }}
      ignoreTag={false}
    />
  );
}

export default connect<StateProps, unknown, OwnProps>((state: RootState) => ({
  currency: state.settings.currency,
  massUnit: state.settings.unit
}))(FormattedMessage);
