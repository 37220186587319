/*
 * Report Filter v3.5
 */
import { DataTransfer } from 'frontend-core';

import moment from 'moment';
import { API_DATE_FORMAT, UI_DATE_FORMAT } from 'utils/datetime';
import { LABELS } from 'utils/labels';
import mergeWith from 'lodash/mergeWith';
import isEqual from 'lodash/isEqual';
import Cache from './cache';
import debounce from 'lodash/debounce';
import {
  AccountGroupFilter,
  AccountGroupFilterUpdate,
  Basis,
  CachedFilter,
  Dimension,
  Period,
  ReportActions,
  ReportActionTypes,
  ReportFilterState,
  ReportQuery,
  ReportTree,
  TimeRange,
  TimeRangeOptions
} from './types';
import { ThunkResult } from 'redux/types';
import { AnyAction } from 'redux';
import { AxiosError, AxiosResponse } from 'axios';
import { ApiError } from 'redux/ducks/error';
import { GuestType } from 'redux/ducks/guestTypes';
import { browserHistory } from 'browserHistory';
import { createPatch } from 'utils/helpers';
import {
  arrayParamSerializer,
  createFilter,
  mapAccountFilterToQuery,
  mapQueryToAccountFilter
} from './util';

export * from './types';

interface ReloadAllFiltersOptions {
  accountGroups: AccountGroupFilter[];
  reportFilter: ReportFilterState;
}

export interface InitFilterOptions
  extends Pick<ReportFilterState, 'basis' | 'dimension' | 'period' | 'timeRange'> {
  query?: string;
}

export const endpoints = {
  reportQueries: '/foodwaste/reports/queries',
  reportRegistrations: '/foodwaste/reports/registrations',
  registrationPoints: '/foodwaste/reports/registration-points',
  guestTypes: '/foodwaste/guest-types'
};

export const DefaultPeriod = 'week';
export const DefaultBasis = 'total';
export const DefaultDimension = 'weight';

const transfer = new DataTransfer({
  paramsSerializer: arrayParamSerializer
});

const cache = new Cache();

export const initialState: ReportFilterState = {
  timeRange: {
    from: moment().subtract(1, DefaultPeriod).startOf('isoWeek').format(API_DATE_FORMAT),
    to: moment().subtract(1, DefaultPeriod).endOf('isoWeek').format(API_DATE_FORMAT)
  },
  period: DefaultPeriod,
  dimension: DefaultDimension,
  basis: DefaultBasis,
  includeSoftDeleted: false,
  guestTypes: [],
  selectedGuestTypeNames: [],
  registrationPoints: [],
  filter: {
    order: 'desc',
    trees: [],
    accounts: [],
    selectedRegistrationPoints: { area: [], category: [], product: [] }
  },
  accountGroups: [],
  // replace these flags with state = 'initializing' | 'initialized' | 'loading'
  isInitialized: false,
  isInitializing: false,
  loading: true,
  error: null
};

export default function (
  state: ReportFilterState = { ...initialState },
  action: ReportActions
): ReportFilterState {
  switch (action.type) {
    case ReportActionTypes.FILTER_ERROR:
      return { ...state, ...action.payload };
    case ReportActionTypes.FILTER_INIT_REG_POINTS_SUCCESS:
      return { ...state, registrationPoints: action.payload };
    case ReportActionTypes.FILTER_CHANGE_REQUEST:
      return { ...state, ...action.payload, loading: true };
    case ReportActionTypes.FILTER_CHANGE_SUCCESS:
      return { ...state, ...action.payload, loading: !state.isInitialized };
    case ReportActionTypes.FILTER_INIT_REQUEST:
      return {
        ...initialState,
        isInitialized: false,
        loading: true,
        isInitializing: true
      };
    case ReportActionTypes.FILTER_INIT_QUERY_SUCCESS: {
      const { id, guestTypes, accountGroups } = action.payload;

      return {
        ...state,
        queryId: id,
        selectedGuestTypeNames: guestTypes,
        filter: accountGroups[0],
        accountGroups: accountGroups
      };
    }
    case ReportActionTypes.FILTER_INIT_SUCCESS:
      return { ...state, isInitialized: true, loading: false, isInitializing: false };
    case ReportActionTypes.FILTER_ADD_COMPARE_SUCCESS:
      return { ...state, accountGroups: [...state.accountGroups, action.payload] };
    case ReportActionTypes.FILTER_REMOVE_COMPARE_SUCCESS:
      return {
        ...state,
        accountGroups: state.accountGroups.filter((_, index) => index !== action.payload)
      };
    case ReportActionTypes.FILTER_CHANGE_COMPARE_SUCCESS: {
      const { key, accountGroup: update } = action.payload;
      const accountGroups = state.accountGroups.map((accountGroup, index) =>
        index === key ? update : accountGroup
      );
      return { ...state, accountGroups, filter: key === 0 ? update : state.filter };
    }
    case ReportActionTypes.FILTER_INIT_GUEST_TYPE_SUCCESS: {
      return {
        ...state,
        guestTypes: action.payload
      };
    }
    default:
      return state;
  }
}

// resetFilter clear filter cache + init
export function init(
  initOptions: Partial<InitFilterOptions> = {}
): ThunkResult<Promise<ReportActions>, ReportActions> {
  return async (dispatch, getState) => {
    dispatch({ type: ReportActionTypes.FILTER_INIT_REQUEST });

    const { reportFilter } = getState();
    const { query, ...rest } = initOptions;

    const baseFilters = {
      timeRange: rest.timeRange || reportFilter.timeRange,
      period: rest.period || reportFilter.period,
      basis: rest.basis || reportFilter.basis,
      dimension: rest.dimension || reportFilter.dimension
    };

    dispatch({ type: ReportActionTypes.FILTER_CHANGE_SUCCESS, payload: baseFilters });

    await dispatch(initGuestTypeFilter());
    await dispatch(initRegistrationPoints());
    await dispatch(initReportQuery(query));

    return dispatch({ type: ReportActionTypes.FILTER_INIT_SUCCESS });
  };
}

function initRegistrationPoints(): ThunkResult<Promise<ReportActions>, ReportActions> {
  return async (dispatch, getState) => {
    const {
      user: { accountId },
      subAccounts: { subscribed = [] }
    } = getState();
    const response = (await transfer.get(endpoints.registrationPoints, {
      params: { accounts: [accountId, ...subscribed.map((s) => s.id)] }
    })) as AxiosResponse<ReportTree[]>;

    return dispatch({
      type: ReportActionTypes.FILTER_INIT_REG_POINTS_SUCCESS,
      payload: response.data
    });
  };
}

function initGuestTypeFilter(): ThunkResult<Promise<ReportActions>, ReportActions> {
  return async (dispatch, getState) => {
    const {
      user: { accountId },
      subAccounts: { subscribed = [] }
    } = getState();
    const registeredAccountIds = subscribed.map((account) => account.id);
    const allAccounts = [...registeredAccountIds, accountId].join(',');
    const { data: guestTypesResponse } = (await transfer.get(
      endpoints.guestTypes,
      {
        params: { accounts: allAccounts }
      },
      true
    )) as AxiosResponse<GuestType[]>;

    const guestTypes = guestTypesResponse.map((guestType) => ({
      ...guestType,
      name: guestType.name.trim()
    }));

    return dispatch({
      type: ReportActionTypes.FILTER_INIT_GUEST_TYPE_SUCCESS,
      payload: Array.from(new Set(guestTypes.map((guestType) => guestType.name))).sort()
    });
  };
}

function initReportQuery(
  queryId: string | null
): ThunkResult<Promise<ReportActions>, ReportActions> {
  return async (dispatch, getState) => {
    const { reportFilter, user } = getState();

    if (queryId) {
      try {
        const { data: reportQuery } = (await transfer.get(
          `${endpoints.reportQueries}/${queryId}`,
          undefined,
          true
        )) as AxiosResponse<ReportQuery>;

        const { query } = reportQuery;
        const accountGroups = await Promise.all(
          query.accountGroups
            .map((group) => mapQueryToAccountFilter(group))
            .map((group) => reloadAccountGroup(group as AccountGroupFilter, reportFilter))
        );

        const payload = {
          id: reportQuery.id,
          accountGroups
        };

        if (query.guestTypes) {
          payload['guestTypes'] = query.guestTypes;
        }

        return dispatch({
          type: ReportActionTypes.FILTER_INIT_QUERY_SUCCESS,
          payload
        });
      } catch (error) {
        // noop
        // user had given an invalid query id, so nothing we can do but create
        // a new one
      }
    }

    try {
      // empty arrays so that the server state matches client state...
      // otherwise patch requests can throw errors,
      const payload = {
        guestTypes: [],
        accountGroups: [{ accounts: [user.accountId], areas: [], categories: [], products: [] }]
      };

      const { data: reportQuery } = (await transfer.post(endpoints.reportQueries, {
        query: payload
      })) as AxiosResponse<ReportQuery>;

      const initialFilter = await reloadAccountGroup(
        mapQueryToAccountFilter(reportQuery.query.accountGroups[0]) as AccountGroupFilter,
        reportFilter
      );

      return dispatch({
        type: ReportActionTypes.FILTER_INIT_QUERY_SUCCESS,
        payload: {
          id: reportQuery.id,
          guestTypes: reportQuery.query.guestTypes,
          accountGroups: [initialFilter]
        }
      });
    } catch (error) {
      return dispatch({
        type: ReportActionTypes.FILTER_ERROR,
        payload: error as AxiosError<ApiError>
      });
    }
  };
}

export function changeBasis(basis: Basis): ReportActions {
  return { type: ReportActionTypes.FILTER_CHANGE_SUCCESS, payload: { basis } };
}

export function changeGuestTypes(
  guestTypes: string[]
): ThunkResult<Promise<ReportActions>, ReportActions> {
  return async (dispatch, getState) => {
    const {
      reportFilter: { queryId, selectedGuestTypeNames: oldSelection }
    } = getState();

    const changeOps = createPatch(
      { query: { guestTypes: oldSelection } },
      { query: { guestTypes } }
    );

    try {
      await transfer.patch(`${endpoints.reportQueries}/${queryId}`, changeOps);

      return dispatch({
        type: ReportActionTypes.FILTER_CHANGE_SUCCESS,
        payload: { selectedGuestTypeNames: guestTypes }
      });
    } catch (error: unknown) {
      return dispatch({
        type: ReportActionTypes.FILTER_ERROR,
        payload: error as AxiosError<ApiError>
      });
    }
  };
}

// registration can change when dimension change and having top/bottom account query
export function changeDimension(
  dimension: Dimension
): ThunkResult<Promise<ReportActions>, ReportActions> {
  return async (dispatch) => {
    dispatch({ type: ReportActionTypes.FILTER_CHANGE_REQUEST, payload: { dimension } });
    return dispatch(changeStateAndReloadAccountGroups());
  };
}

// time range change will also trigger availability change request to registration points
export function changeTimeRange(
  timeRange: TimeRange,
  period: Period,
  options?: TimeRangeOptions
): ThunkResult<void, ReportActions> {
  return (dispatch, getState) => {
    const { skipFilterReload, updateCache } = options || {};

    dispatch({
      type: ReportActionTypes.FILTER_CHANGE_REQUEST,
      payload: { timeRange, period }
    });

    if (!skipFilterReload) {
      debouncedReloadAccountGroups(dispatch);
    }

    if (updateCache) {
      const {
        accountId,
        subscription: { type }
      } = getState().user;
      updateCachedFilter(accountId, type, { timeRange, period });
    }
  };
}

// this only affects advanced reports
export function toggleIncludeDeleted(includeDeleted: boolean): ReportActions {
  return {
    type: ReportActionTypes.FILTER_CHANGE_SUCCESS,
    payload: { includeSoftDeleted: includeDeleted }
  };
}

// mainly for if user clicks rapidly to change the time filter values
const debouncedReloadAccountGroups = debounce(
  (dispatch): ThunkResult<ReportActions, ReportActions> => {
    // eslint-disable-next-line
    return dispatch(changeStateAndReloadAccountGroups());
  },
  500,
  { leading: true }
);

function changeStateAndReloadAccountGroups(): ThunkResult<Promise<ReportActions>, ReportActions> {
  return async (dispatch, getState) => {
    const reportFilter: ReportFilterState = getState().reportFilter;
    const { accountGroups } = reportFilter;
    try {
      const update = await reloadAccountGroups({ accountGroups, reportFilter });
      return dispatch({
        type: ReportActionTypes.FILTER_CHANGE_SUCCESS,
        payload: update
      });
    } catch (error: unknown) {
      return dispatch({
        type: ReportActionTypes.FILTER_ERROR,
        payload: error as AxiosError<ApiError>
      });
    }
  };
}

export function changeAccountPointFilter(
  filterUpdate: AccountGroupFilterUpdate
): ThunkResult<Promise<ReportActions>, ReportActions> {
  return changeAccountGroup(0, filterUpdate);
}

export function addAccountGroup(
  filter: AccountGroupFilterUpdate
): ThunkResult<Promise<ReportActions>, ReportActions> {
  return async (dispatch, getState) => {
    const reportFilter = getState().reportFilter;
    const { accountId } = getState().user;
    const { accountGroups } = reportFilter;
    const withAccounts =
      filter.accounts && filter.accounts.length > 0
        ? filter
        : {
            ...filter,
            accounts: [accountId],
            order: 'desc'
          };

    const selectedRegistrationPoints = LABELS.reduce(
      (all, label) => ({
        ...all,
        [label]:
          (filter.selectedRegistrationPoints && filter.selectedRegistrationPoints[label]) || []
      }),
      {}
    );

    const withSelectedPoints = createFilter({
      ...withAccounts,
      selectedRegistrationPoints
    } as AccountGroupFilterUpdate);
    try {
      const oldGroups = accountGroups.map((f) => mapAccountFilterToQuery(f));
      const newGroups = [...oldGroups, mapAccountFilterToQuery(withSelectedPoints)];

      await transfer.patch(
        `${endpoints.reportQueries}/${reportFilter.queryId}`,
        createPatch(
          { query: { accountGroups: oldGroups } },
          { query: { accountGroups: newGroups } }
        )
      );

      const initialFilter = await reloadAccountGroup(
        withSelectedPoints as AccountGroupFilter,
        reportFilter
      );
      return dispatch({
        type: ReportActionTypes.FILTER_ADD_COMPARE_SUCCESS,
        payload: initialFilter
      });
    } catch (error: unknown) {
      return dispatch({
        type: ReportActionTypes.FILTER_ERROR,
        payload: error as AxiosError<ApiError>
      });
    }
  };
}

export function removeAccountGroup(
  filterIndex: number
): ThunkResult<Promise<ReportActions>, ReportActions> {
  return async (dispatch, getState) => {
    // base filter at index 0 cannot be deleted
    if (filterIndex === 0) {
      return;
    }

    const {
      reportFilter: { queryId }
    } = getState();

    await transfer.patch(`${endpoints.reportQueries}/${queryId}`, [
      { op: 'remove', path: `/query/accountGroups/${filterIndex}` }
    ]);

    return dispatch({
      type: ReportActionTypes.FILTER_REMOVE_COMPARE_SUCCESS,
      payload: filterIndex
    });
  };
}

export function changeAccountGroup(
  filterIndex: number,
  filterUpdate: AccountGroupFilterUpdate
): ThunkResult<Promise<ReportActions>, ReportActions> {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: ReportActionTypes.FILTER_CHANGE_COMPARE_REQUEST
      });
      const { reportFilter } = getState();
      const { accountGroups, queryId } = reportFilter;
      const oldFilter = accountGroups[filterIndex];
      const filter = createFilter(filterUpdate);

      const mergedFilter = mergeWith({}, { ...oldFilter }, { ...filter }, (target, source) => {
        if (Array.isArray(target)) {
          // eslint-disable-next-line
          return source; // override existing arrays
        }
      });

      const selected = {
        area: new Set(mergedFilter.selectedRegistrationPoints.area),
        category: new Set(mergedFilter.selectedRegistrationPoints.category),
        product: new Set(mergedFilter.selectedRegistrationPoints.product)
      };

      const validSelected = {
        area: new Set<string>(),
        category: new Set<string>(),
        product: new Set<string>()
      } as const;

      // to keep existing behaviour: deselect children that are not available in parent
      // we need go through all trees and make sure parent > category > product selections exist
      // eslint-disable-next-line no-inner-declarations
      function filterOutInvalidChildrenSelection(node: ReportTree) {
        if (selected[node.label].size === 0) {
          node.children.forEach((child) => filterOutInvalidChildrenSelection(child));
          return;
        }

        if (!selected[node.label].has(node.name)) {
          return;
        }

        validSelected[node.label].add(node.name);
        node.children.forEach((child) => filterOutInvalidChildrenSelection(child));
      }

      oldFilter.trees.forEach((tree) => filterOutInvalidChildrenSelection(tree));

      LABELS.forEach((label) => {
        mergedFilter.selectedRegistrationPoints[label] = Array.from(validSelected[label]);
      });

      const oldGroup = mapAccountFilterToQuery(oldFilter);
      const newGroup = mapAccountFilterToQuery(mergedFilter);

      const changeOps = createPatch(
        { query: { accountGroups: [oldGroup] } },
        { query: { accountGroups: [newGroup] } }
      ).map((op) => ({ ...op, path: op.path.replace('/0', `/${filterIndex}`) }));

      await transfer.patch(`${endpoints.reportQueries}/${queryId}`, changeOps);

      const isFullReloadRequired = !isEqual(oldFilter.accounts, mergedFilter.accounts);
      const nextFilter: AccountGroupFilter = isFullReloadRequired
        ? await reloadAccountGroup(mergedFilter, reportFilter)
        : mergedFilter;
      return dispatch({
        type: ReportActionTypes.FILTER_CHANGE_COMPARE_SUCCESS,
        payload: { key: filterIndex, accountGroup: nextFilter }
      });
    } catch (error) {
      return dispatch({
        type: ReportActionTypes.FILTER_ERROR,
        payload: error as AxiosError<ApiError>
      });
    }
  };
}

// atomic update on all filters,
// necessary for accounts compare filters or else
// we will make separate request for each filter update
async function reloadAccountGroups({
  accountGroups,
  reportFilter
}: ReloadAllFiltersOptions): Promise<{
  filter: AccountGroupFilter;
  accountGroups: AccountGroupFilter[];
}> {
  const groupPromises = await Promise.all(
    accountGroups.map((accountGroup) => reloadAccountGroup(accountGroup, reportFilter))
  );

  const updatedGroups = await Promise.all(groupPromises);

  return { filter: updatedGroups[0], accountGroups: updatedGroups };
}

async function reloadAccountGroup(
  filter: AccountGroupFilter,
  reportFilter: ReportFilterState
): Promise<AccountGroupFilter> {
  return setAvailableRegistrationPoints(filter, reportFilter);
}

export const setAvailableRegistrationPoints = async (
  filter: AccountGroupFilter,
  reportFilter: ReportFilterState
): Promise<AccountGroupFilter> => {
  const { timeRange, dimension } = reportFilter;
  const accountQuery = Array.isArray(filter.accounts) ? filter.accounts.join(',') : filter.accounts;
  const queryParams = {
    from: timeRange.from,
    to: timeRange.to,
    accounts: accountQuery,
    dimension
  };

  const response = (await transfer.get(endpoints.registrationPoints, {
    params: queryParams
  })) as AxiosResponse<ReportTree[]>;

  return {
    ...filter,
    trees: response.data
  };
};

// handling url / cache should be in one place,
// now in container and here in actions
export const getCachedState = (cacheKey: string): CachedFilter | null => {
  const cachedState = cache.getReportsPath(cacheKey);

  if (!cachedState) {
    return null;
  }

  return cachedState;
};

export const updateURL = (): ThunkResult<void, AnyAction> => {
  return (dispatch, getState) => {
    const {
      reportFilter: reportFilter,
      routing: { locationBeforeTransitions },
      user
    } = getState();
    const { queryId, basis, dimension, period, timeRange } = reportFilter;

    const basePath = locationBeforeTransitions.pathname;

    const searchQuery = [
      { basis },
      { dimension },
      { period },
      {
        from: moment(timeRange.from).format(UI_DATE_FORMAT),
        to: moment(timeRange.to).format(UI_DATE_FORMAT)
      }
    ] as Record<string, unknown>[];

    if (queryId) {
      searchQuery.push({ query: queryId });
    }

    // should never be empty, always has defaults if nothing else
    const serializedQuery = searchQuery
      .map((queryParam) => {
        return Object.keys(queryParam)
          .reduce((res, key) => {
            const queryValue = queryParam[key] as string | string[];
            const serializedValue = Array.isArray(queryValue)
              ? encodeURI(queryValue.join(','))
              : encodeURI(queryValue);
            return res.concat(`${key}=${serializedValue}`);
          }, [] as string[])
          .join('&');
      })
      .join('&');

    const nextPath = basePath + '?' + serializedQuery;
    if (
      locationBeforeTransitions.search.length === 0 &&
      basePath === locationBeforeTransitions.pathname
    ) {
      // no search means query params are not populated yet (from cache or defaults)
      browserHistory.replace(nextPath);
    } else if (nextPath !== locationBeforeTransitions.pathname + locationBeforeTransitions.search) {
      browserHistory.push(nextPath);
    }

    // to streamline the report filter initialization, this filter is used instead of parsing filter from url.
    // If we used the url, we'd need first to call location.replace and add additional flags,
    // which would add additional complexity to the init filter flow.
    const cachedURLFilter = {
      query: queryId,
      basis,
      dimension,
      period,
      timeRange
    } as Partial<InitFilterOptions>;

    const cacheKey = `${user.accountId}_${user.subscription.type}`;
    cache.persistReportsPath(cacheKey, {
      state: cachedURLFilter
    });
  };
};

export const updateCachedFilter = (
  accountId: string,
  planType: string,
  filter: Partial<InitFilterOptions>
): void => {
  const cacheKey = `${accountId}_${planType}`;
  const { state } = getCachedState(cacheKey) || {};
  cache.persistReportsPath(cacheKey, {
    state: {
      ...state,
      ...filter
    }
  });
};

// use case: delete cached filter if it has caused an error
export function resetFilter(): ThunkResult<Promise<ReportActions>, ReportActions> {
  return async (dispatch, getState) => {
    const { accountId, subscription } = getState().user;
    cache.remove(`${accountId}_${subscription.type}`);
    return dispatch(init());
  };
}
