import * as React from 'react';
import { Grid } from '@material-ui/core';

import DashboardMetrics from 'pages/Report/QuickOverview/components/OverviewMetrics/DashboardMetrics';
import TotalGuestMetric from 'pages/Report/QuickOverview/components/OverviewMetrics/TotalGuestMetric';

const OverviewMetrics: React.FunctionComponent = () => {
  return (
    <Grid container alignContent={'flex-start'} justify={'space-around'} spacing={3}>
      <DashboardMetrics />
      <TotalGuestMetric />
    </Grid>
  );
};

export default OverviewMetrics;
