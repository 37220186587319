import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const GuestIcon: React.FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d='M 3.449219 8.3125 C 4.71875 8.3125 5.75 7.246094 5.75 5.9375 C 5.75 4.628906 4.71875 3.5625 3.449219 3.5625 C 2.179688 3.5625 1.148438 4.628906 1.148438 5.9375 C 1.148438 7.246094 2.179688 8.3125 3.449219 8.3125 Z M 19.550781 8.3125 C 20.820312 8.3125 21.851562 7.246094 21.851562 5.9375 C 21.851562 4.628906 20.820312 3.5625 19.550781 3.5625 C 18.28125 3.5625 17.25 4.628906 17.25 5.9375 C 17.25 7.246094 18.28125 8.3125 19.550781 8.3125 Z M 20.699219 9.5 L 18.398438 9.5 C 17.765625 9.5 17.195312 9.761719 16.777344 10.191406 C 18.226562 11.011719 19.253906 12.492188 19.476562 14.25 L 21.851562 14.25 C 22.484375 14.25 23 13.71875 23 13.0625 L 23 11.875 C 23 10.566406 21.96875 9.5 20.699219 9.5 Z M 11.5 9.5 C 13.722656 9.5 15.523438 7.640625 15.523438 5.34375 C 15.523438 3.046875 13.722656 1.1875 11.5 1.1875 C 9.277344 1.1875 7.476562 3.046875 7.476562 5.34375 C 7.476562 7.640625 9.277344 9.5 11.5 9.5 Z M 14.261719 10.6875 L 13.960938 10.6875 C 13.214844 11.058594 12.382812 11.28125 11.5 11.28125 C 10.617188 11.28125 9.789062 11.058594 9.039062 10.6875 L 8.738281 10.6875 C 6.453125 10.6875 4.601562 12.601562 4.601562 14.960938 L 4.601562 16.03125 C 4.601562 17.015625 5.371094 17.8125 6.324219 17.8125 L 16.675781 17.8125 C 17.628906 17.8125 18.398438 17.015625 18.398438 16.03125 L 18.398438 14.960938 C 18.398438 12.601562 16.546875 10.6875 14.261719 10.6875 Z M 6.222656 10.191406 C 5.804688 9.761719 5.234375 9.5 4.601562 9.5 L 2.300781 9.5 C 1.03125 9.5 0 10.566406 0 11.875 L 0 13.0625 C 0 13.71875 0.515625 14.25 1.148438 14.25 L 3.519531 14.25 C 3.746094 12.492188 4.773438 11.011719 6.222656 10.191406 Z M 6.222656 10.191406' />
  </SvgIcon>
);

export default GuestIcon;
