import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const EmissionIcon: React.FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g id='surface1'>
      <path d='M 10.753906 0.65625 L 10.753906 2.828125 C 10.808594 3.203125 11.121094 3.480469 11.492188 3.480469 C 11.863281 3.480469 12.179688 3.203125 12.230469 2.828125 L 12.230469 0.652344 C 12.179688 0.277344 11.863281 -0.00390625 11.492188 -0.00390625 C 11.121094 -0.00390625 10.808594 0.277344 10.753906 0.652344 Z M 10.753906 0.65625 ' />
      <path d='M 6.765625 4.015625 C 6.765625 3.867188 6.71875 3.71875 6.636719 3.597656 L 5.652344 2.085938 C 5.550781 1.914062 5.382812 1.792969 5.191406 1.746094 C 5 1.699219 4.796875 1.734375 4.628906 1.84375 C 4.464844 1.949219 4.34375 2.117188 4.300781 2.316406 C 4.253906 2.511719 4.289062 2.71875 4.394531 2.890625 L 4.417969 2.925781 L 5.402344 4.4375 C 5.585938 4.707031 5.921875 4.828125 6.234375 4.730469 C 6.542969 4.632812 6.757812 4.34375 6.761719 4.011719 Z M 6.765625 4.015625 ' />
      <path d='M 16.339844 3.597656 C 16.113281 3.941406 16.207031 4.410156 16.546875 4.640625 C 16.886719 4.875 17.34375 4.777344 17.566406 4.433594 L 18.554688 2.921875 C 18.777344 2.574219 18.6875 2.105469 18.347656 1.875 C 18.007812 1.644531 17.550781 1.738281 17.324219 2.085938 Z M 16.339844 3.597656 ' />
      <path d='M 2.878906 6.359375 C 2.828125 6.21875 2.742188 6.09375 2.625 6.007812 L 1.191406 4.929688 C 0.871094 4.6875 0.417969 4.75 0.171875 5.074219 C -0.0742188 5.398438 -0.0273438 5.863281 0.285156 6.121094 L 0.316406 6.148438 L 1.746094 7.222656 C 2.078125 7.46875 2.539062 7.394531 2.78125 7.058594 C 2.925781 6.855469 2.960938 6.59375 2.878906 6.359375 Z M 2.878906 6.359375 ' />
      <path d='M 20.390625 5.996094 C 20.0625 6.242188 19.992188 6.714844 20.234375 7.050781 C 20.476562 7.386719 20.9375 7.460938 21.265625 7.210938 L 22.699219 6.136719 C 22.921875 5.980469 23.042969 5.710938 23.019531 5.433594 C 22.992188 5.160156 22.820312 4.921875 22.570312 4.8125 C 22.320312 4.703125 22.035156 4.746094 21.820312 4.917969 Z M 20.390625 5.996094 ' />
      <path d='M 19.886719 9.089844 L 12.214844 5.375 C 11.777344 5.195312 11.292969 5.195312 10.859375 5.375 L 10.664062 5.46875 L 10.652344 5.46875 L 3.179688 9.085938 L 3.171875 9.09375 L 3.140625 9.105469 C 2.90625 9.234375 2.800781 9.523438 2.902344 9.777344 L 2.921875 9.824219 L 3.160156 10.355469 C 3.21875 10.488281 3.328125 10.59375 3.460938 10.644531 C 3.59375 10.691406 3.742188 10.6875 3.871094 10.625 L 4.6875 10.234375 L 4.6875 16.511719 C 4.683594 16.640625 4.734375 16.765625 4.820312 16.855469 C 4.90625 16.945312 5.027344 16.996094 5.152344 16.996094 L 17.820312 16.996094 C 18.015625 16.996094 18.1875 16.871094 18.253906 16.683594 C 18.285156 16.617188 18.300781 16.542969 18.304688 16.46875 L 18.304688 10.191406 L 19.152344 10.601562 C 19.28125 10.664062 19.429688 10.671875 19.5625 10.621094 C 19.699219 10.570312 19.804688 10.46875 19.863281 10.335938 L 20.101562 9.800781 C 20.136719 9.726562 20.152344 9.644531 20.148438 9.558594 C 20.164062 9.363281 20.0625 9.175781 19.886719 9.089844 Z M 16.667969 15.273438 L 6.34375 15.273438 L 6.34375 9.433594 L 11.34375 7.011719 L 11.363281 7 L 11.515625 6.925781 L 16.667969 9.410156 Z M 16.667969 15.273438 ' />
    </g>
  </SvgIcon>
);

export default EmissionIcon;
