import * as React from 'react';
import { useIntl } from 'react-intl';
import { Subscription } from 'redux/ducks/user';

export interface PlanProps {
  subscription: Subscription;
}

const Plan: React.FunctionComponent<PlanProps> = ({ subscription }) => {
  const intl = useIntl();
  return (
    <p>{intl.formatMessage({ id: 'bootstrap.plan.description' }, { plan: subscription.type })}</p>
  );
};

export default Plan;
