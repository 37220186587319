import * as React from 'react';
import {
  getModuleLinks,
  getModuleSelectorItems,
  redirectToModule,
  UiActions,
  ModuleLink
} from 'redux/ducks/ui';
import { connect } from 'react-redux';
import ModuleDropdown from 'components/ModuleSelector/moduleDropdown';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type ModuleSelectorContainerProps = StateProps & DispatchProps;

const ModuleSelector: React.FunctionComponent<ModuleSelectorContainerProps> = (props) => {
  const { locale, modules, getModuleSelectorItems } = props;

  React.useEffect(() => {
    void getModuleSelectorItems();
  }, [locale]);

  const handleModuleClick = (module: ModuleLink) => {
    void redirectToModule(module);
  };

  return <ModuleDropdown modules={modules} onClick={handleModuleClick} />;
};

const mapStateToProps = (state: RootState) => ({
  modules: getModuleLinks(state),
  locale: state.settings.locale
});

const mapDispatchToProps = (dispatch: ThunkDispatch<unknown, unknown, UiActions>) => ({
  getModuleSelectorItems: () => dispatch(getModuleSelectorItems())
});

export default connect(mapStateToProps, mapDispatchToProps)(ModuleSelector);
