import * as React from 'react';
import FactsOverview from './components/FactsOverview';
import FoodWasteOverview from './components/FoodWasteOverview';
import DashboardMetrics from './components/OverviewMetrics';
import { PageTitle } from 'pages/Report/components/ReportPageLayout';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

interface ComponentProps {
  downloadButton: React.ReactNode;
}

const QuickOverview: React.FunctionComponent<ComponentProps> = ({ downloadButton }) => {
  const intl = useIntl();
  return (
    <>
      <PageTitle>{intl.formatMessage({ id: 'report.quickOverview.title' })}</PageTitle>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <FactsOverview />
        </Grid>
        <Grid item xs={12}>
          <DashboardMetrics />
        </Grid>
        <Grid item xs={12}>
          <FoodWasteOverview />
        </Grid>
      </Grid>
      {downloadButton}
    </>
  );
};

export default QuickOverview;
