import { DataTransfer, DataStorage } from 'frontend-core';
import { Dimension, Period } from 'redux/ducks/reportFilter';
import * as React from 'react';
import { createPatch } from 'utils/helpers';

const transfer = new DataTransfer();
const store = new DataStorage();

export type ReportMailCategory =
  | 'all'
  | 'total_waste'
  | 'departments'
  | 'frequency'
  | 'overview'
  | 'per_guest';

export type ReportWeekday =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday';

export type ReportJobInterval = 'onetime' | Exclude<Period, 'custom'>;

export interface ReportJob {
  id: string;
  reportMailId: string;
  recipients: string[];
  interval: ReportJobInterval;
  hour: number;
  minute: number;
  weekday: ReportWeekday;
  enabled?: boolean;
  split?: boolean;
}

export interface ReportMetric {
  id: string;
  name: string;
  nameKey: string;
  category: ReportMailCategory;
  categoryKey: string;
  enabled: boolean;
  setting: {
    enabledDimensions: Dimension[];
  };
}

export interface ReportMetricAccountGroup {
  // todo: should be renamed to accounts for consistency with report filter
  departments?: string[];
  areas?: string[];
  categories?: string[];
  products?: string[];
}

export interface SelectedReportMetric {
  id: string;
  dimensions: Dimension[];
}

export type ReportMailPeriod = Exclude<Period, 'custom'>;

export interface SelectedReportFilter {
  guestTypes?: string[];
  departmentGroups?: ReportMetricAccountGroup[];
  period: ReportMailPeriod; // need to make sure period >= report.job.interval
}

export interface ReportMail {
  id: string;
  userId?: string;
  accountId?: string;
  name: string;
  header?: string;
  body?: string;
  filter?: SelectedReportFilter;
  metrics?: SelectedReportMetric[];
  active: boolean;
  job?: ReportJob;
}

export const useFetchReportMetrics = () => {
  const [data, setData] = React.useState<ReportMetric[] | null>(null);
  const [isLoading, setLoading] = React.useState(true);
  const [isSuccess, setSuccess] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const token = store.getData('token') as string;
  // eslint-disable-next-line
  const query = () => {
    setLoading(true);
    setSuccess(false);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    transfer.library.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    transfer
      .get('foodwaste/reports/mail-metrics')

      .then(({ data }: { data: ReportMetric[] }) => {
        setData(
          data.map((item) => ({
            ...item,
            category: item.categoryKey.split('.').slice(-1)[0] as ReportMailCategory
          }))
        );
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setError(error as string);
        setLoading(false);
      });
  };

  React.useEffect(() => query(), []);

  return { query, data, isLoading, isSuccess, error };
};

export const useFetchReportJobs = () => {
  const [data, setData] = React.useState<ReportJob[] | null>(null);
  const [isLoading, setLoading] = React.useState(true);
  const [isSuccess, setSuccess] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const token = store.getData('token') as string;
  // eslint-disable-next-line
  const query = () => {
    setLoading(true);
    setSuccess(false);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    transfer.library.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    transfer
      .get('foodwaste/reports/mail-jobs')

      .then(({ data }: { data: ReportJob[] }) => {
        setData(data);
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setError(error as string);
        setLoading(false);
      });
  };

  React.useEffect(() => query(), []);

  return { query, data, isLoading, isSuccess, error };
};

export const useCreateReportJob = () => {
  const [data, setData] = React.useState<ReportJob | null>(null);
  const [isLoading, setLoading] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const mutate = (job: Omit<ReportJob, 'id'>) => {
    const token = store.getData('token') as string;
    // eslint-disable-next-line
    transfer.library.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    setSuccess(false);
    setLoading(true);
    transfer
      .post('foodwaste/reports/mail-jobs', job)

      .then(({ data }: { data: ReportJob }) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setData(data);
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setError(error as string);
        setLoading(false);
      });
  };

  return { data, isLoading, isSuccess, error, mutate };
};

export const useUpdateReportJob = () => {
  const [data, setData] = React.useState<ReportJob | null>(null);
  const [isLoading, setLoading] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const mutate = (original: ReportJob, updated: Partial<Omit<ReportJob, 'id'>>) => {
    const token = store.getData('token') as string;
    // eslint-disable-next-line
    transfer.library.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    setSuccess(false);
    setLoading(true);

    transfer
      .patch(`foodwaste/reports/mail-jobs/${original.id}`, createPatch(original, updated))
      .then(({ data }: { data: ReportJob }) => {
        setData(data);
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setError(error as string);
        setLoading(false);
      });
  };

  return { data, isLoading, isSuccess, error, mutate };
};

export const useFetchReportMails = () => {
  const [data, setData] = React.useState<ReportMail[] | null>(null);
  const [isLoading, setLoading] = React.useState(true);
  const [isSuccess, setSuccess] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const token = store.getData('token') as string;

  const query = () => {
    setLoading(true);
    setSuccess(false);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    transfer.library.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    transfer
      .get('foodwaste/reports/mails')

      .then(({ data }: { data: ReportMail[] }) => {
        setData(data);
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setError(error as string);
        setLoading(false);
      });
  };

  React.useEffect(() => query(), []);

  return { query, data, isLoading, isSuccess, error };
};

export const useUpdateReportMail = () => {
  const [data, setData] = React.useState<ReportMail | null>(null);
  const [isLoading, setLoading] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const mutate = (original: ReportMail, updated: Partial<Omit<ReportMail, 'id'>>) => {
    const token = store.getData('token') as string;
    // eslint-disable-next-line
    transfer.library.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    setSuccess(false);
    setLoading(true);
    transfer
      .patch(`foodwaste/reports/mails/${original.id}`, createPatch(original, updated))
      .then(({ data }: { data: ReportMail }) => {
        setData(data);
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setError(error as string);
        setLoading(false);
      });
  };

  return { data, isLoading, isSuccess, error, mutate };
};

export const useCreateReportMail = () => {
  const [data, setData] = React.useState<ReportMail | null>(null);
  const [isLoading, setLoading] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const mutate = (mail: Omit<ReportMail, 'id'>) => {
    const token = store.getData('token') as string;
    // eslint-disable-next-line
    transfer.library.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    setSuccess(false);
    setLoading(true);
    transfer
      .post('foodwaste/reports/mails', mail)

      .then(({ data }: { data: ReportMail }) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setData(data);
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setError(error as string);
        setLoading(false);
      });
  };

  return { data, isLoading, isSuccess, error, mutate };
};

export const useDeleteReportMail = () => {
  const [data, setData] = React.useState<ReportMail | null>(null);
  const [isLoading, setLoading] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const mutate = (mail: ReportMail) => {
    const token = store.getData('token') as string;
    // eslint-disable-next-line
    transfer.library.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    setSuccess(false);
    setLoading(true);
    transfer
      .delete(`foodwaste/reports/mails/${mail.id}`)

      .then(({ data }: { data: ReportMail }) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setData(data);
        setSuccess(true);
        setLoading(false);
      })
      .catch((error) => {
        setError(error as string);
        setLoading(false);
      });
  };

  return { data, isLoading, isSuccess, error, mutate };
};
