import React, { useState, useRef, ChangeEvent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

interface OwnProps {
  length: number;
  label: string;
  loading: boolean;
  onComplete: (code: string) => void;
  required: boolean;
  success?: boolean;
  error?: boolean;
}

const useStyles = makeStyles((theme) => {
  return {
    codeInput: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start'
    },
    codeLabel: {
      marginBottom: '16px'
    },
    codeInputs: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      marginBottom: theme.spacing(0.5)
    },
    codeBox: {
      backgroundImage: 'none',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      textAlign: 'center',
      height: '48px',
      width: '48px',
      borderRadius: '6px',
      margin: '0 2px',
      border: `1px solid ${theme.palette.grey.A100}`,
      fontSize: '18px',
      color: theme.palette.text.secondary
    },
    label: { ...theme.typography.h3, fontWeight: theme.typography.fontWeightBold },
    error: {
      border: `1px solid ${theme.palette.error.dark}`
    },
    success: {
      border: `1px solid ${theme.palette.success.main}`
    },
    errorMsg: {
      color: theme.palette.error.dark
    }
  };
});

const Index: React.FunctionComponent<OwnProps> = (props) => {
  const { length, loading, onComplete, required, error } = props;
  const intl = useIntl();
  const [code, setCode] = useState<string[]>(Array.from({ length: length }, () => ''));
  const inputs = useRef<(HTMLInputElement | null)[]>([]);
  const classes = useStyles(props);

  const processInput = (e: ChangeEvent<HTMLInputElement>, slot: number) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }
    if (newCode.every((num) => num !== '')) {
      onComplete(newCode.join(''));
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>, slot: number) => {
    if (e.key === 'Backspace' && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = '';
      setCode(newCode);
      inputs.current[slot - 1].focus();
    }
  };

  return (
    <div className={classes.codeInput}>
      <InputLabel className={classes.label} shrink>
        {intl.formatMessage({ id: 'base.verificationCode' })}
      </InputLabel>
      <div className={classes.codeInputs}>
        {code.map((num, idx) => {
          return (
            <input
              key={idx}
              className={classNames(classes.codeBox, { [classes.error]: error })}
              type='text'
              inputMode='numeric'
              maxLength={1}
              value={num}
              autoFocus={!code[0].length && idx === 0}
              readOnly={loading}
              onChange={(e) => processInput(e, idx)}
              onKeyUp={(e) => onKeyUp(e, idx)}
              ref={(ref) => inputs.current.push(ref)}
              required={required}
            />
          );
        })}
      </div>
      {error && (
        <Typography className={classes.errorMsg}>
          {intl.formatMessage({ id: 'signup.validation.error' })}
        </Typography>
      )}
    </div>
  );
};

export default Index;
