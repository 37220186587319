import * as React from 'react';
import { useIntl } from 'react-intl';
import { SettingsState } from 'redux/ducks/settings';
import TemplateSelector, {
  BootstrapTemplate
} from 'components/OnboardDialog/BootstrapDialog/TemplateSelector';
import { Subscription } from 'redux/ducks/user';

export interface BootstrapDataStepProps {
  subscription: Subscription;
  settings: Partial<SettingsState>;
  onChange: (settings: Partial<SettingsState>) => void;
}

type OwnProps = BootstrapDataStepProps;

const Template: React.FunctionComponent<OwnProps> = (props) => {
  const intl = useIntl();
  const { settings, onChange, subscription } = props;

  const handleChange = (template: BootstrapTemplate) => {
    onChange({ bootstrapTemplateId: template.id });
  };

  return (
    <div>
      <p>
        {
          intl.messages[
            `bootstrap.${subscription.type === 'essential' ? 'essential' : 'template'}.description`
          ]
        }
      </p>
      <p>{intl.formatMessage({ id: 'bootstrap.template.saveOrCancel' })}</p>
      <TemplateSelector selected={settings.bootstrapTemplateId} onChange={handleChange} />
    </div>
  );
};

export default Template;
