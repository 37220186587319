import * as React from 'react';
import { PageTitle } from 'pages/Report/components/ReportPageLayout';
import { Card, CardHeader, CardContent, Grid } from '@material-ui/core';
import SVGInline from 'react-svg-inline';
import { makeStyles } from '@material-ui/core/styles';
import getReportPages from '../utils/getReportPages';
import { Link } from 'react-router';
import { useIntl } from 'react-intl';

const StartPage: React.FunctionComponent = () => {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <Grid container spacing={3}>
      <PageTitle>{intl.formatMessage({ id: 'report.startpage.title' })}</PageTitle>
      {getReportPages(intl).map(
        (page, i) =>
          !page.isStartPage && (
            <Grid item container xs={12} md={6} xl={4} key={`page${i}`}>
              <Link to={page.link} className={classes.link}>
                <Card
                  classes={{
                    root: classes.card
                  }}
                >
                  <CardHeader title={page.title} subheader={page.cardSubheader} />
                  <CardContent>
                    <Grid item container xs={12} justify={'center'}>
                      <SVGInline className={classes.svgContainer} svg={page.cardGraphic} />
                    </Grid>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          )
      )}
    </Grid>
  );
};
const useStyles = makeStyles((theme) => ({
  card: {
    height: '100%'
  },
  link: {
    width: '100%',
    textDecoration: 'none'
  },
  svgContainer: {
    width: '100%',
    maxWidth: '80%',
    height: 0,
    paddingTop: '35%' /* Aspect ratio */,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',

    '& svg': {
      position: 'absolute',
      top: '50%',
      left: 'auto',
      maxHeight: '200px',
      transform: 'translateY(-50%)'
    }
  },
  snack: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    minWidth: '50px',
    borderRadius: '20px'
  },
  snackLink: {
    textDecoration: 'none'
  }
}));
export default StartPage;
