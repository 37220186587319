import { IntlShape } from 'react-intl';

import overviewImage from 'static/img/report-quick-overview.svg';
import accountsImage from 'static/img/report-accounts.svg';
import foodwasteImage from 'static/img/report-total-foodwaste.svg';
import perGuestImage from 'static/img/report-per-guest.svg';
import frequencyImage from 'static/img/report-frequency.svg';
import downloadImage from 'static/img/download.svg';
import reportMailImage from 'static/img/report-mail-report.svg';
import sustainabilityImage from 'static/img/competition-icon.svg';

const getReportPages = (
  intl: IntlShape
): {
  title: string;
  link?: string;
  divider?: boolean;
  isStartPage?: boolean;
  cardSubheader?: string;
  cardGraphic?: string;
  disabled?: true;
}[] => [
  {
    title: intl.formatMessage({ id: 'report.navigation.startpage' }),
    link: '/report/',
    divider: true,
    isStartPage: true
  },
  {
    title: intl.formatMessage({ id: 'report.quickOverview.title' }),
    link: '/report/quick-overview',
    cardSubheader: intl.formatMessage({ id: 'report.startpage.quickOverview.description' }),
    cardGraphic: overviewImage
  },
  {
    title: intl.formatMessage({ id: 'report.accounts.title' }),
    link: '/report/accounts',
    cardSubheader: intl.formatMessage({ id: 'report.startpage.accounts.description' }),
    cardGraphic: accountsImage
  },
  {
    title: intl.formatMessage({ id: 'report.totalFoodwaste.title' }),
    link: '/report/foodwaste',
    cardSubheader: intl.formatMessage({ id: 'report.startpage.totalFoodwaste.description' }),
    cardGraphic: foodwasteImage
  },
  {
    title: intl.formatMessage({ id: 'report.foodwaste.perGuest.title' }),
    link: '/report/foodwaste/per-guest',
    cardSubheader: intl.formatMessage({ id: 'report.startpage.foodwastePerGuest.description' }),
    cardGraphic: perGuestImage
  },
  {
    title: intl.formatMessage({ id: 'report.registrationFrequency.title' }),
    link: '/report/frequency',
    cardSubheader: intl.formatMessage({ id: 'report.startpage.registrationFrequency.description' }),
    cardGraphic: frequencyImage
  },
  {
    title: intl.formatMessage({ id: 'report.advancedReports.title' }),
    cardGraphic: downloadImage,
    link: '/report/advanced'
  },
  {
    title: intl.formatMessage({ id: 'report.mail.title' }),
    cardGraphic: reportMailImage,
    link: '/report/mail'
  },
  {
    title: intl.formatMessage({ id: 'report.sustainability.title' }),
    cardGraphic: sustainabilityImage,
    link: '/report/sustainability'
  }
];

export default getReportPages;
