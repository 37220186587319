import * as React from 'react';
import Advanced from 'pages/Report/Advanced/Advanced';
import { connect } from 'react-redux';
import {
  AccountPointFilterWithNames,
  getFilter,
  getRegistrations,
  getSelectedAccountNames
} from 'redux/ducks/reportFilter/selectors';
import { fetchData } from 'redux/ducks/reportData';
import { toggleIncludeDeleted } from 'redux/ducks/reportFilter';
import { createConfig } from 'pages/Report/Advanced/utils/configFactory';
import { useIntl } from 'react-intl';
import { ExportExtension, exportTable } from 'pages/Report/Advanced/utils/tableExport';
import {
  getBenchmarkedGuestRegistrations,
  getBenchmarkedRegistrations,
  getSalesRegistrations
} from 'redux/ducks/reportData/selector';
import { ThemeProvider } from '@material-ui/styles';
import { RootState } from 'redux/rootReducer';
import { Column } from 'material-table';

export enum AdvancedReportType {
  foodwaste = 'foodwaste',
  sales = 'sales',
  benchGuests = 'benchGuests',
  benchWaste = 'benchWaste'
}

const tableTheme = {
  overrides: {
    MuiTableCell: {
      head: {
        fontSize: '0.8rem'
      },
      body: {
        fontSize: '0.8rem',
        borderBottom: '1px solid rgba(0, 0, 0, 0.05)'
      }
    }
  }
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
type AdvancedContainerProps = StateProps & DispatchProps;

const AdvancedContainer: React.FunctionComponent<AdvancedContainerProps> = (props) => {
  const intl = useIntl();
  const {
    registrations,
    salesRegistrations,
    benchmarkedRegistrations,
    benchmarkedGuestRegistrations,
    filterLoading,
    selectedGuestTypeNames,
    accounts,
    settings,
    timeRange,
    includeDeleted,
    fetchData,
    toggleIncludeDeleted,
    selectedRegistrationPoints
  } = props;
  const { massUnit } = settings;
  const [resource, setResource] = React.useState<AdvancedReportType>(AdvancedReportType.foodwaste);
  const { createExportConfig, createTableColumnConfig } = createConfig(resource);
  React.useEffect(() => {
    switch (resource) {
      case AdvancedReportType.sales:
        fetchData('salesRegistrations');
        break;
      case AdvancedReportType.benchGuests:
        fetchData('guestRegistrations');
        break;
      case AdvancedReportType.benchWaste:
        fetchData('registrations');
        break;
      case AdvancedReportType.foodwaste:
        fetchData('registrations');
        fetchData('guestRegistrations');
        break;
      default:
        return;
    }
  }, [
    filterLoading,
    accounts,
    timeRange.to,
    timeRange.from,
    selectedGuestTypeNames,
    resource,
    includeDeleted,
    selectedRegistrationPoints
  ]);

  const getRegistrationData = () => {
    switch (resource) {
      case 'sales':
        return salesRegistrations;
      case 'foodwaste':
        return registrations;
      case 'benchGuests':
        return benchmarkedGuestRegistrations;
      case 'benchWaste':
        return benchmarkedRegistrations;
      default:
        return;
    }
  };

  const handleResourceChange = (resource: AdvancedReportType) => setResource(resource);

  const handleExport = (ext: ExportExtension, headers: Column<any>[], data: any[]) => {
    const exportConfig = createExportConfig(intl, { timeRange, massUnit, type: resource });
    exportTable({ ext, headers, data, options: exportConfig });
  };

  const isBenchmarkReport = resource === 'benchGuests' || resource === 'benchWaste';

  const columns = createTableColumnConfig(intl, {
    currency: settings.currency,
    massUnit: settings.massUnit,
    includeDeleted,
    data: isBenchmarkReport
      ? resource === 'benchGuests'
        ? benchmarkedGuestRegistrations
        : benchmarkedRegistrations
      : []
  });

  return (
    <ThemeProvider theme={(outerTheme) => ({ ...outerTheme, ...tableTheme })}>
      <Advanced
        registrations={getRegistrationData()}
        resource={resource}
        onResourceChange={handleResourceChange}
        columns={columns as any[]}
        onExport={handleExport}
        includeDeleted={includeDeleted}
        toggleIncludeDeleted={toggleIncludeDeleted}
      />
    </ThemeProvider>
  );
};

const mapStateToProps = (state: RootState) => {
  const accountFilter: AccountPointFilterWithNames = getFilter(state);

  return {
    registrations: getRegistrations(state),
    salesRegistrations: getSalesRegistrations(state),
    benchmarkedRegistrations: getBenchmarkedRegistrations(state),
    benchmarkedGuestRegistrations: getBenchmarkedGuestRegistrations(state),
    timeRange: state.reportFilter.timeRange,
    includeDeleted: state.reportFilter.includeSoftDeleted,
    selectedGuestTypeNames: state.reportFilter.selectedGuestTypeNames,
    accounts: getSelectedAccountNames(state),
    filterLoading: state.reportFilter.loading,
    settings: { currency: state.settings.currency, massUnit: state.settings.unit },
    selectedRegistrationPoints: accountFilter.selectedRegistrationPoints
  };
};

const mapDispatchToProps = { fetchData, toggleIncludeDeleted };

export default connect<StateProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(AdvancedContainer);
