import * as React from 'react';
import { useIntl } from 'react-intl';
import { Dialog, DialogContent, Button, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import eSmileyLogo from 'static/icons/esmiley-white.svg';
import SVGInline from 'react-svg-inline';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SettingsActions } from 'redux/ducks/settings';
import { ThunkDispatch } from 'redux-thunk';
import * as errorDispatch from 'redux/ducks/error';
import { connect } from 'react-redux';
import { DataTransfer } from 'frontend-core';
import { Spinner } from 'components/LoadingPlaceholder';
import { ErrorActions } from 'redux/ducks/error';

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnProps = { onClose: () => void };
type TrialDialogProps = DispatchProps & OwnProps;
const transfer = new DataTransfer();

const TrialDialog: React.FunctionComponent<TrialDialogProps> = (props) => {
  const { displayError, onClose } = props;
  const intl = useIntl();
  const classes = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);

  const submit = async () => {
    setLoading(true);
    try {
      await transfer.post('/foodwaste/bootstrap-tasks');
    } catch (error: unknown) {
      void displayError(error as Error);
    }

    onClose();
  };

  const handleSubmit = () => {
    void submit();
  };

  const isMobile = useMediaQuery('(max-width: 450px)', { noSsr: true });

  return (
    <Dialog className={classes.DialogMain} open fullScreen>
      <DialogContent className={classes.DialogContent}>
        <div className={isMobile ? classes.MobileDialogCard : classes.DialogCard}>
          {loading ? (
            <Spinner className={classes.Spinner} />
          ) : (
            <>
              <div className={isMobile ? classes.MobileContextText : classes.ContentText}>
                <SVGInline className={classes.Svg} svg={eSmileyLogo} />
                <span>{intl.formatMessage({ id: 'signup.trialConfirmation.title' })}</span>
              </div>
              <Button
                className={isMobile ? classes.MobileDialogAction : classes.DialogAction}
                onClick={handleSubmit}
              >
                {intl.formatMessage({ id: 'signup.trialConfirmation.action' })}
              </Button>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

// todo fix css, overly complex
const useStyles = makeStyles((theme: Theme) => ({
  DialogMain: {
    width: '100%'
  },
  DialogCard: {
    maxWidth: '400px'
  },
  Svg: {
    width: '75px',
    display: 'flex',
    marginBottom: '28px'
  },
  MobileContextText: {
    marginTop: '180px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontSize: '1.5rem',
    fontWeight: 'bold'
  },
  ContentText: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    fontSize: '1.6rem',
    fontWeight: 'bold'
  },
  DialogContent: {
    color: 'white',
    background: 'linear-gradient(180deg, rgba(1,88,84,1) 0%, rgba(1,138,133,1) 100%)',
    width: '100%',
    display: 'flex',
    padding: '0px 40px',
    justifyContent: 'center',
    alignItems: 'center'
  },
  MobileDialogCard: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  DialogAction: {
    fontSize: '1.15rem',
    width: '100%',
    marginTop: '40px',
    fontWeight: 'bold',
    backgroundColor: 'white',
    color: 'rgb(0,168,161)',
    borderRadius: '2px',
    padding: '10px 25px',
    '&:hover': {
      backgroundColor: 'white'
    },
    height: '60px'
  },
  MobileDialogAction: {
    position: 'relative',
    bottom: 0,
    fontSize: '1.15rem',
    width: '100%',
    fontWeight: 'bold',
    backgroundColor: 'white',
    color: 'rgb(0,168,161)',
    borderRadius: '2px',
    padding: '10px 25px',
    '&:hover': {
      backgroundColor: 'white'
    },
    height: '60px',
    marginBottom: '60px'
  },
  Spinner: {
    [theme.breakpoints.down(450)]: {
      margin: 'auto'
    }
  }
}));

const mapDispatchToProps = (
  dispatch: ThunkDispatch<unknown, unknown, SettingsActions | ErrorActions>
) => ({
  displayError: (error: Error) => dispatch(errorDispatch.displayError(error))
});

export default connect<unknown, DispatchProps, OwnProps>(null, mapDispatchToProps)(TrialDialog);
