import * as React from 'react';
import { CardHeader, Card, CardContent, Typography } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import Trend from './components/Trend';

const EmissionOverview: React.FunctionComponent = () => {
  return (
    <Card>
      <CardHeader
        title={
          <FormattedMessage
            id='report.sustainability.overview.title'
            values={{ sub: (chunks) => <sub>{chunks}</sub> }}
          />
        }
      />
      <CardContent>
        <Typography variant='body1' paragraph>
          <FormattedMessage
            id='report.sustainability.overview.description'
            values={{ sub: (chunks) => <sub>{chunks}</sub> }}
          />
        </Typography>
        <Trend />
      </CardContent>
    </Card>
  );
};

export default EmissionOverview;
