import { createSelectorCreator, defaultMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';
import { RootState } from 'redux/rootReducer';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getRandomFunFact = createDeepEqualSelector(
  (state: RootState) => state.funFacts.funFacts,
  (funFacts) => (funFacts.length > 0 ? funFacts[randomInteger(0, funFacts.length - 1)] : null)
);

const randomInteger = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
