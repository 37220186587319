import * as React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import BalanceScaleIcon from 'components/icons/BalanceScaleIcon';
import EmissionIcon from 'components/icons/EmissionIcon';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { RootState } from 'redux/rootReducer';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { Dimension, ReportActions } from 'redux/ducks/reportFilter';
import * as reportDispatch from 'redux/ducks/reportFilter';
import { selectReportDataIsLoading } from 'redux/ducks/reportData/selector';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type DimensionToggleProps = StateProps & DispatchProps;

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, ReportActions>) => ({
  changeDimension: (dimension: Dimension) => dispatch(reportDispatch.changeDimension(dimension))
});

const mapStateToProps = (state: RootState) => ({
  dimension: state.reportFilter.dimension,
  reportsAreLoading: selectReportDataIsLoading(state)
});

const useStyles = makeStyles((theme) => ({
  togglerDimension: {
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(2)
    }
  },
  toggleButtonIcon: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.primary.light,
    marginRight: 7,

    '& svg': {
      fill: theme.palette.primary.light,
      width: 16
    },

    '.Mui-selected &': {
      color: theme.palette.common.white,

      '& svg': {
        fill: theme.palette.common.white
      }
    }
  }
}));

const DimensionToggleInternal: React.FC<DimensionToggleProps> = (props) => {
  const classes = useStyles(props);
  const { dimension, changeDimension, reportsAreLoading } = props;
  const intl = useIntl();

  const handleDimensionChange = (event: React.MouseEvent<HTMLElement>, newDimension: Dimension) => {
    if (!newDimension) {
      return;
    }

    void changeDimension(newDimension);
  };

  return (
    <ToggleButtonGroup
      value={dimension}
      exclusive
      onChange={handleDimensionChange}
      aria-label='filter the data by cost or weight'
      className={classes.togglerDimension}
    >
      <ToggleButton
        value='cost'
        aria-label='filter by cost'
        disabled={reportsAreLoading || dimension === 'cost'}
      >
        <span className={classes.toggleButtonIcon}>$</span>
        {intl.formatMessage({ id: 'report.dimension.cost' })}
      </ToggleButton>
      <ToggleButton
        value='weight'
        aria-label='filter by weight'
        disabled={reportsAreLoading || dimension === 'weight'}
      >
        <span className={classes.toggleButtonIcon}>
          <BalanceScaleIcon />
        </span>
        {intl.formatMessage({ id: 'report.dimension.weight' })}
      </ToggleButton>
      <ToggleButton
        value='co2'
        aria-label='filter by co2'
        disabled={reportsAreLoading || dimension === 'co2'}
      >
        <span className={classes.toggleButtonIcon}>
          <EmissionIcon />
        </span>
        CO2
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const DimensionToggle = connect<StateProps, DispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps
)(DimensionToggleInternal);
