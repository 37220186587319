import { DataTransfer } from 'frontend-core';
import { AxiosResponse } from 'axios';
import * as errorDispatch from 'redux/ducks/error';
import { Feature, FeaturesActions, FeaturesActionTypes, FeaturesState } from './types';
import { ErrorActions } from 'redux/ducks/error/types';
import { ThunkResult } from 'redux/types';

export * from './types';

const transfer = new DataTransfer();

export const initialState: FeaturesState = {
  features: [],
  status: 'init'
};

// TODO remove, pointless state slice
export default function reducer(
  state: FeaturesState = initialState,
  action: FeaturesActions
): FeaturesState {
  switch (action.type) {
    case FeaturesActionTypes.GET_FEATURES:
      return {
        ...state,
        features: action.payload,
        status: 'fetched'
      };
    case FeaturesActionTypes.SET_FEATURES_STATUS:
      return {
        ...state,
        status: action.payload
      };
    default:
      return state;
  }
}

export function getFeatures(): ThunkResult<
  Promise<FeaturesActions | ErrorActions>,
  FeaturesActions | ErrorActions
> {
  return async (dispatch) => {
    try {
      dispatch({
        type: FeaturesActionTypes.SET_FEATURES_STATUS,
        payload: 'loading'
      });

      const response = (await transfer.get('/foodwaste/features')) as AxiosResponse<Feature[]>;

      return dispatch({
        type: FeaturesActionTypes.GET_FEATURES,
        payload: response.data
      });
    } catch (error: unknown) {
      return dispatch(errorDispatch.displayError(error as Error));
    }
  };
}
