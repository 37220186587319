import * as React from 'react';
import { ITip } from 'redux/ducks/tips';
import { IconButton, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MediaCard from 'pages/Dashboard/MediaCard';
import classNames from 'classnames';
import moment from 'moment';

export interface TipCardProps {
  tips: ITip[];
  classes?: {
    mediaCard?: string;
  };
}

type OwnProps = TipCardProps;

const useStyles = makeStyles({
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      padding: '4px'
    }
  },
  iconButton: {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderRadius: '4px'
  },
  xsSmallIcon: {
    fontSize: '1rem'
  },
  buttonGap: {
    width: '10px'
  },
  footnote: {
    width: '100%',
    display: 'inline-flex',
    justifyContent: 'space-between'
  },
  captionBold: {
    fontWeight: 800
  }
});

const TipCard: React.FunctionComponent<OwnProps> = ({ classes: classesProps = {}, ...props }) => {
  const classes = useStyles(props);
  const intl = useIntl();
  const { tips } = props;
  const initialIndex = moment().subtract(1, 'week').week();

  const [index, setIndex] = React.useState(initialIndex);
  const handlePrevious = () => {
    setIndex((prev) => (prev === 0 ? 52 : prev - 1));
  };

  const handleNext = () => {
    setIndex((prev) => (prev === 52 ? 0 : prev + 1));
  };

  const locale = intl.locale === 'da' ? 'DK' : intl.locale.toUpperCase();
  const tip = tips[index % tips.length];
  const tipLocale = tip && tip.title.hasOwnProperty(locale) ? locale : 'EN';

  return (
    tip && (
      <MediaCard
        className={classNames(classesProps.mediaCard)}
        title={intl.formatMessage({ id: 'dashboard.tip' })}
        imageSrc={tip.imageUrl}
        actions={
          <div className={classes.buttonGroup}>
            <IconButton
              className={classes.iconButton}
              disableRipple
              color='primary'
              size='small'
              onClick={handlePrevious}
            >
              <ChevronLeftIcon className={classes.xsSmallIcon} />
            </IconButton>
            <div className={classes.buttonGap} />
            <IconButton
              className={classes.iconButton}
              disableRipple
              color='primary'
              size='small'
              onClick={handleNext}
            >
              <ChevronRightIcon className={classes.xsSmallIcon} />
            </IconButton>
          </div>
        }
      >
        <Typography variant='body2'>{tip.content[tipLocale]}</Typography>
      </MediaCard>
    )
  );
};

export default TipCard;
