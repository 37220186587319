import * as React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/styles';
import { CSSTransitionGroup } from 'react-transition-group';
import reportTheme from 'styles/themes/reports';
import { withRouter, WithRouterProps } from 'react-router';
import { LayoutProvider } from 'pages/Report/utils/pageContext';
import ReportPageLayout from 'pages/Report/components/ReportPageLayout';
import StarterPlanOverview from 'pages/Report/StarterPlanOverview';
import { Feature, useFeature } from 'flagged';
import { withOffline } from 'components/WithOffline';

export interface ReportContainerProps {
  children: React.ReactElement & { getLayout?: (page) => React.ReactElement };
}

type OwnProps = WithRouterProps & ReportContainerProps;

const indexPageRegex = /report\/?$/;
const mailPageRegex = /report\/mail\/?$/;
export const VACATION_COLOR = '#ed8888ff' as const;
export const CHART_COLORS = [
  '#7172b1',
  '#9adcdb',
  '#ed8888',
  '#f2a663',
  '#5499ad',
  '#bfbfbf'
] as string[];

const ReportContainer: React.FunctionComponent<OwnProps> = (props) => {
  const {
    children,
    location: { pathname }
  } = props;
  const classes = useStyles(props);
  const isIndexPage = !!indexPageRegex.exec(pathname);
  const isMailPage = !!mailPageRegex.exec(pathname);

  const isFreeReportFeature = useFeature('report/free') as boolean;

  return (
    <ThemeProvider theme={(outerTheme) => ({ ...outerTheme, ...reportTheme })}>
      <LayoutProvider>
        <CSSTransitionGroup
          component={React.Fragment}
          transitionName={{
            enter: classes.enter,
            enterActive: classes.enterActive,
            appear: classes.appear,
            appearActive: classes.appearActive,
            leave: classes.leave,
            leaveActive: classes.leaveActive
          }}
          transitionAppear
          transitionAppearTimeout={500}
          transitionEnter={false}
          transitionLeave={false}
        >
          <ReportPageLayout
            disableMenu={isIndexPage}
            disableFilter={(isMailPage || isIndexPage) && !isFreeReportFeature}
            disableHeader={isFreeReportFeature}
          >
            <Feature name='report/free'>
              <StarterPlanOverview />
            </Feature>
            <Feature name='report/full'>{children}</Feature>
            <Feature name='report/view'>
              <StarterPlanOverview />
            </Feature>
          </ReportPageLayout>
        </CSSTransitionGroup>
      </LayoutProvider>
    </ThemeProvider>
  );
};

const useStyles = makeStyles({
  appear: {
    opacity: 0.01
  },
  appearActive: {
    opacity: 1,
    transition: 'opacity 500ms ease-out'
  },
  enter: {
    opacity: 0.01
  },
  enterActive: {
    opacity: 1,
    transition: 'opacity 500ms ease-out'
  },
  leave: {
    opacity: 1
  },
  leaveActive: {
    opacity: 0.01,
    transition: 'opacity 300ms ease-out'
  }
});

export default withOffline(withRouter(ReportContainer));
