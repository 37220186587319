import * as React from 'react';
import { connect } from 'react-redux';
import QuickOverview from 'pages/Report/QuickOverview/QuickOverview';
import { useIntl } from 'react-intl';
import {
  AccountPointFilterWithNames,
  getFilter,
  getReportDashboardMetrics,
  getSelectedAccountNames
} from 'redux/ducks/reportFilter/selectors';
import { useDownloadReportPDF } from 'pages/Report/utils/useDownloadPDF';
import DownloadBtn from 'pages/Report/components/DownloadBtn';
import { getChartPNGs } from 'pages/Report/QuickOverview/utils/generateChartImages';
import { RootState } from 'redux/rootReducer';
import { getTotalGuests } from 'redux/ducks/reportData/selector';
import { CHART_COLORS } from 'pages/Report';

type StateProps = ReturnType<typeof mapStateToProps>;
type QuickOverviewContainerProps = StateProps;

const QuickOverviewContainer: React.FunctionComponent<QuickOverviewContainerProps> = (props) => {
  const intl = useIntl();
  const {
    chartRefs,
    foodWasteOverview,
    trendFoodWaste,
    foodWasteMetrics,
    regFrequencyMetrics,
    filter: {
      basis,
      dimension,
      timeRange: { from: startDate, to: endDate }
    },
    filter,
    selectedRegistrationPoints,
    selectedAccountNames,
    dashboardMetrics,
    totalGuestRegistrations
  } = props;

  const [isDownloading, downloadPDF] = useDownloadReportPDF({
    generateChartPNG: () => getChartPNGs({ chartRefs, filter }),
    reportData: {
      AsyncDocument: async () => (await import('./PDF')).default,
      data: {
        chartColors: CHART_COLORS,
        foodWasteOverview,
        trendFoodWaste,
        foodWasteMetrics,
        regFrequencyMetrics,
        filter,
        selectedRegistrationPoints,
        selectedAccountNames,
        dashboardMetrics,
        totalGuestRegistrations,
        intl
      },
      basis,
      dimension,
      name: `quick-overview-${startDate}-${endDate}`
    }
  });

  return (
    <QuickOverview
      downloadButton={<DownloadBtn onClick={downloadPDF} isDownloading={isDownloading} />}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const accountFilter: AccountPointFilterWithNames = getFilter(state);
  return {
    chartRefs: state.charts.chartRefs,
    foodWasteOverview: state.reportData.foodWasteOverview.data,
    trendFoodWaste: state.reportData.trendFoodWaste.data,
    foodWasteMetrics: state.reportData.foodWasteMetricsOverview.data,
    regFrequencyMetrics: state.reportData.regFrequencyMetrics.data,
    filter: state.reportFilter,
    selectedRegistrationPoints: accountFilter.selectedRegistrationPoints,
    selectedAccountNames: getSelectedAccountNames(state),
    dashboardMetrics: getReportDashboardMetrics(state),
    totalGuestRegistrations: getTotalGuests(state)
  };
};

export default connect<StateProps, unknown, unknown>(mapStateToProps)(QuickOverviewContainer);
