import { SavedSettings } from 'redux/ducks/settings';
import { SubAccounts } from 'redux/ducks/subAccounts/types';

export enum UserActionTypes {
  USER_LOADED = 'esmiley/user/USER_LOADED',
  SET_CLIENT = 'esmiley/user/SET_CLIENT'
}

type LoadUserAction = {
  type: typeof UserActionTypes.USER_LOADED;
  payload: {
    profile: UserProfile;
    subscription: Subscription;
    subscriptionHistory: Subscription[];
    settings: SavedSettings;
    subAccounts: SubAccounts;
  };
};

type SetClientAction = {
  type: typeof UserActionTypes.SET_CLIENT;
  payload: string;
};

export interface Subscription {
  planId: string;
  type: string;
  startDate: Date;
  endDate?: Date;
}

export interface FoodwasteAccount {
  settings: SavedSettings;
  subscription: Subscription;
  subscriptionHistory: Subscription[];
  subAccounts: SubAccounts;
}

// not sure which of these should be optional
export interface UserProfile {
  accountId?: string;
  customerName?: string;
  email?: string;
  id?: string;
  locale?: string;
  name?: string;
  nickname?: string;
  phone?: string;
  username?: string;
  level?: number;
  company?: string;
}

export interface UserState extends Partial<UserProfile> {
  client?: string;
  subscription?: Subscription;
  subscriptionHistory?: Subscription[];
  state: 'init' | 'loading' | 'fetched';
}

export type UserActions = LoadUserAction | SetClientAction;
