import './index.scss';
import * as React from 'react';
import { connect } from 'react-redux';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import * as notificationDispatch from 'redux/ducks/notification';
import * as errorDispatch from 'redux/ducks/error';
import classNames from 'classnames';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { NotificationActions } from 'redux/ducks/notification';
import { ErrorActions, NETWORK_ERROR_CODE } from 'redux/ducks/error';
import { useIntl } from 'react-intl';

type StoreProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type NotificationProps = StoreProps & DispatchProps;

const Notification: React.FC<NotificationProps> = (props) => {
  const intl = useIntl();
  const { error, notification, closeNotification, closeError } = props;
  const handleClose = (event: React.MouseEvent): void => {
    event && event.stopPropagation();
    error.active ? closeError() : closeNotification();
  };

  const createMessage = (): string | undefined => {
    if (error.active) {
      const { code, message } = error;
      if (code === NETWORK_ERROR_CODE) {
        return intl.formatMessage({ id: 'errors.cannotConnectToServices' });
      }
      if (code) {
        return `[${code}]: ${message}`;
      }
      return message;
    }

    if (notification.active) {
      return notification.message;
    }
  };

  const isNotificationActive = error.active || notification.active;
  const isMessage = !error.active && notification.active;
  const notificationMessage = createMessage();

  const notificationClassName = classNames(
    'notification',
    {
      'is-message': isMessage
    },
    {
      error: (notification.isError && 'isError') || error.active
    }
  );

  const style = {
    minWidth: '102.2%',
    maxWidth: '102.2%',
    minHeight: '48px',
    padding: '12px 24px 8px 36px',
    fontSize: '18px'
  };

  const bodyStyle = isMessage ? style : null;
  const notificationTimeout = 5000;
  const vertical = 'top';

  const errorIcon = notification.icon || <ErrorIcon className='red-fill' />;

  const messageElement = notificationMessage ? (
    <div>
      {notification.isError ? errorIcon : notification.icon}
      <span>{notificationMessage}</span>
    </div>
  ) : undefined;

  return (
    <Snackbar
      onClick={closeNotification}
      anchorOrigin={{ vertical, horizontal: 'center' }}
      className={notificationClassName}
      open={isNotificationActive}
      autoHideDuration={notificationTimeout}
      onClose={handleClose}
    >
      <SnackbarContent
        message={messageElement}
        action={
          <IconButton className='closeIcon' onClick={handleClose}>
            <CloseIcon
              style={{
                width: '35px',
                height: '35px',
                fontWeight: 900
              }}
            />
          </IconButton>
        }
        style={bodyStyle}
      />
    </Snackbar>
  );
};

const mapStateToProps = (state: RootState) => ({
  notification: state.notification,
  error: state.error
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<unknown, unknown, NotificationActions | ErrorActions>
) => ({
  closeNotification: () => dispatch(notificationDispatch.closeNotification()),
  closeError: () => dispatch(errorDispatch.closeError())
});

const ConnectedNav = connect<StoreProps, DispatchProps, unknown>(
  mapStateToProps,
  mapDispatchToProps
)(Notification);

export default ConnectedNav;
