import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import ReasonTable from 'pages/Settings/components/settings/registrations/components/reasonTable/ReasonTable';
import { CreateReason, Reason, ReasonActions, ReasonQuery } from 'redux/ducks/reasons/types';
import * as reasonActions from 'redux/ducks/reasons';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import * as settingsDispatch from 'redux/ducks/settings';
import { SettingsState } from 'redux/ducks/settings';

type StoreProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface OwnProps {
  style?: React.CSSProperties;
}

type ComponentProps = DispatchProps & StoreProps & OwnProps;

const ReasonsContainer: React.FunctionComponent<ComponentProps> = (props) => {
  const { reasons, getAll, deleteById, update, create, updateSettings, enableRegistrationReason } =
    props;

  useEffect(() => {
    void getAll();
  }, []);

  function handleDelete(reason: Reason) {
    if (reasons.length - 1 === 0 && enableRegistrationReason === true) {
      void updateSettings({ enableRegistrationReason: false, allowReasonRegistrationsOnly: false });
    }
    void deleteById(reason.id);
  }

  function handleCreate(reason: CreateReason) {
    void create(reason);
  }

  function handleUpdate(reason: Reason) {
    void update(reason);
  }

  return (
    <ReasonTable
      reasons={reasons}
      onDelete={handleDelete}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      isReasonEnabled={enableRegistrationReason}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  reasons: state.reasons.reasons,
  enableRegistrationReason: state.settings.enableRegistrationReason
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, ReasonActions>) => ({
  getAll: (query?: ReasonQuery) => dispatch(reasonActions.getAll(query)),
  deleteById: (id: string) => dispatch(reasonActions.deleteById(id)),
  create: (reason: CreateReason) =>
    dispatch(reasonActions.create({ name: reason.name, active: true })),
  update: (reason: Reason) => dispatch(reasonActions.update(reason)),
  updateSettings: (data: Partial<SettingsState>) => dispatch(settingsDispatch.update(data))
});

export default connect<StoreProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(ReasonsContainer);
