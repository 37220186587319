import * as React from 'react';
import { Grid, Hidden, InputLabel, TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import { Email } from 'pages/Report/Mail/components/MailReportEditor/index';
import { HelpOutline } from '@material-ui/icons';

export interface EmailEditorProps {
  reportName: string;
  email: Email;
  onChangeEmail: (email: Email) => void;
  onChangeName: (reportName: string) => void;
}

const EmailEditor: React.FunctionComponent<EmailEditorProps> = (props) => {
  const intl = useIntl();
  const { onChangeEmail, onChangeName, reportName, email } = props;
  const [name, setName] = React.useState<string>(reportName);
  const [emailHeader, setHeader] = React.useState<string>(email.header);
  const [emailBody, setBody] = React.useState<string>(email.body);
  const classes = styles(props);

  const changeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const changeEmailHeader = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHeader(e.target.value);
  };

  const changeEmailText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBody(e.target.value);
  };

  return (
    <Grid container item className={classes.EmailEditor}>
      <Grid item container xs={12} md={6} spacing={2}>
        <Grid item container xs={12} alignContent={'flex-start'}>
          <Grid item container xs={3} alignItems={'center'}>
            <InputLabel htmlFor='name'>
              {intl.formatMessage({ id: 'report.mail.createReport.infoLabel.name' })}
            </InputLabel>
          </Grid>
          <Grid item container xs={9} alignItems={'center'}>
            <TextField
              id='name'
              name='name'
              variant='outlined'
              value={name}
              onChange={changeName}
              onBlur={() => onChangeName(name)}
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
              placeholder={intl.formatMessage({
                id: 'report.mail.createReport.infoPlaceholder.name'
              })}
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} alignContent={'flex-start'}>
          <Grid item container xs={3} alignItems={'center'}>
            <InputLabel htmlFor='header'>
              {intl.formatMessage({ id: 'report.mail.createReport.infoLabel.header' })}
            </InputLabel>
          </Grid>
          <Grid item container xs={9} alignItems={'center'}>
            <TextField
              id='header'
              name='header'
              variant='outlined'
              value={emailHeader}
              onChange={changeEmailHeader}
              onBlur={() => onChangeEmail({ body: emailBody, header: emailHeader })}
              placeholder={intl.formatMessage({
                id: 'report.mail.createReport.infoPlaceholder.header'
              })}
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
              fullWidth
              required
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} alignContent={'flex-start'}>
          <Grid item container xs={3}>
            <InputLabel htmlFor='body'>
              {intl.formatMessage({ id: 'report.mail.createReport.infoLabel.text' })}
            </InputLabel>
          </Grid>
          <Grid item container xs={9}>
            <TextField
              variant='outlined'
              name='body'
              id='body'
              value={emailBody}
              onChange={changeEmailText}
              onBlur={() => onChangeEmail({ body: emailBody, header: emailHeader })}
              placeholder={intl.formatMessage({
                id: 'report.mail.createReport.infoPlaceholder.text'
              })}
              InputProps={{
                classes: {
                  root: classes.input
                }
              }}
              size={'medium'}
              rows={6}
              multiline
              fullWidth
            />
          </Grid>
        </Grid>
      </Grid>
      <Hidden smDown>
        <Grid item container md={6}>
          <div className={classes.infoCard}>
            <div className={classes.infoCardHeader}>
              <HelpOutline className={classes.infoCardHeaderSvg} />
              {intl.formatMessage({ id: 'report.mail.createReport.infoCard.title' })}
            </div>
            <div className={classes.infoCardContent}>
              <div className={classes.infoCardSubtitle}>
                {intl.formatMessage({ id: 'report.mail.createReport.infoCard.subtitle' })}
              </div>
              <div className={classes.infoCardBodyOne}>
                {intl.formatMessage({ id: 'report.mail.createReport.infoCard.body.one' })}
              </div>
              <div>{intl.formatMessage({ id: 'report.mail.createReport.infoCard.body.two' })}</div>
            </div>
          </div>
        </Grid>
      </Hidden>
    </Grid>
  );
};

const styles = makeStyles({
  EmailEditor: {
    justifyContent: 'space-between'
  },
  input: {
    padding: '10px'
  },
  infoCard: {
    backgroundColor: '#f8f8f8'
  },
  infoCardHeader: {
    backgroundColor: '#1f96f4',
    color: '#fff',
    fontWeight: 'bold',
    display: 'flex',
    width: '100%',
    padding: '7px',
    alignItems: 'center'
  },
  infoCardHeaderSvg: {
    paddingRight: '7.5px'
  },
  infoCardContent: {
    padding: '20px'
  },
  infoCardSubtitle: {
    fontWeight: 'bold',
    width: '100%',
    marginBottom: '5px'
  },
  infoCardBodyOne: {
    marginBottom: '10px',
    width: '100%'
  }
});

export default EmailEditor;
