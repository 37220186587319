import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { Dimension } from 'redux/ducks/reportFilter';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import EmissionIcon from 'components/icons/EmissionIcon';
import GuestTypeSelect from 'pages/Report/components/GuestTypeSelect';
import BalanceScaleIcon from 'components/icons/BalanceScaleIcon';
import DollarIcon from '@material-ui/icons/AttachMoney';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import Select from 'pages/Report/components/Select';
import SelectItem from 'pages/Report/components/Select/components/SelectItem';
import { ReportMailCategory, ReportMailPeriod } from 'pages/Report/Mail/api';
import AccountGroupSelector, {
  AccountGroup
} from 'pages/Report/Mail/components/AccountGroupSelector';

interface ComponentProps {
  dimensions: Dimension[];
  accountGroupFilters: AccountGroup[];
  onAccountGroupChange: (id, group: AccountGroup) => void;
  onDimensionChange: (dimensions: Dimension[]) => void;
  guestTypeNames: string[];
  onGuestTypeChange: (names: string[]) => void;
  selectedGuestTypes: string[];
  selectedPeriod?: ReportMailPeriod;
  onPeriodChange: (period: ReportMailPeriod) => void;
  category: ReportMailCategory;
}

const reportMailPeriods: ReportMailPeriod[] = ['day', 'week', 'month', 'quarter', 'year'];

const ReportMailFilter: React.FunctionComponent<ComponentProps> = (props) => {
  const {
    selectedGuestTypes,
    accountGroupFilters,
    onAccountGroupChange,
    guestTypeNames,
    onGuestTypeChange,
    onDimensionChange,
    selectedPeriod,
    onPeriodChange,
    dimensions,
    category
  } = props;
  const classes = useStyles(props);
  const intl = useIntl();
  return (
    <Grid container className={classes.container}>
      <Grid
        className={classNames(classes.row)}
        container
        item
        justify='space-between'
        alignItems={'flex-end'}
      >
        <Grid item xs={12} md className={classes.dimensionFilterSlot}>
          <ToggleButtonGroup
            value={dimensions}
            color='primary'
            className={classes.dimensionButtonGroup}
            onChange={(_, values) => onDimensionChange(values as Dimension[])}
          >
            <ToggleButton value='cost' aria-label='filter by cost'>
              <DollarIcon />
              {intl.formatMessage({ id: 'report.dimension.cost' })}
            </ToggleButton>
            <ToggleButton value='weight' aria-label='filter by weight'>
              <BalanceScaleIcon />
              {intl.formatMessage({ id: 'report.dimension.weight' })}
            </ToggleButton>
            <ToggleButton value='co2' aria-label='filter by co2'>
              <EmissionIcon />
              CO2
            </ToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item xs={12} sm={5} md>
          <div className={classes.fixedLeftSlot}>
            <Typography component='div' className={classNames([classes.fixed120md, classes.label])}>
              {intl.formatMessage({ id: 'project.timeline.period' })}
            </Typography>
            <Select
              fullWidth
              renderValue={() => (
                <span>
                  {selectedPeriod
                    ? selectedPeriod
                    : intl.formatMessage({ id: 'report.filter.selectPeriod' })}
                </span>
              )}
              closeOnSelect
              menuProps={{
                MenuListProps: { disablePadding: true }
              }}
            >
              {reportMailPeriods.map((period) => (
                <SelectItem
                  selected={selectedPeriod === period}
                  key={period}
                  onClick={() => onPeriodChange(period)}
                >
                  {intl.formatMessage({ id: period }).toLowerCase()}
                </SelectItem>
              ))}
            </Select>
          </div>
        </Grid>
        {guestTypeNames.length > 0 && (
          <Grid item xs={12} sm={5} md={3} className={classes.guestTypeFilterSlot}>
            <div className={classes.guestTypeFilter}>
              <Typography component='div' className={classes.label}>
                {intl.formatMessage({ id: 'settings.guestType.other' })}
              </Typography>
              <GuestTypeSelect
                available={guestTypeNames}
                selected={selectedGuestTypes}
                onChange={onGuestTypeChange}
              />
            </div>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12}>
        {accountGroupFilters.map((filter, filterIndex) => (
          <AccountGroupSelector
            classes={{
              root: classNames(classes.accountGroup, {
                [classes.accountGroupDisabled]: category === 'departments'
              }),
              accountSelect: classNames(classes.accountSelect, classes.fixedLeftSlot)
            }}
            key={`account_group_${filterIndex}`}
            group={filter}
            onGroupChange={(group) => onAccountGroupChange(filterIndex, group)}
          />
        ))}
      </Grid>
    </Grid>
  );
};

const CustomFilterRowBreakpointInPx = 1500;

const useStyles = makeStyles((theme) => ({
  guestTypeFilterSlot: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto',
      marginRight: theme.spacing(2)
    }
  },
  dimensionFilterSlot: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1)
    },
    [theme.breakpoints.up('md')]: {
      order: 2,
      flexGrow: 0
    }
  },
  fixedLeftSlot: {
    [theme.breakpoints.up('md')]: {
      width: theme.typography.pxToRem(260),
      maxWidth: theme.typography.pxToRem(260)
    }
  },
  accountGroup: {
    marginTop: theme.spacing(2),
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'block'
    }
  },
  accountGroupDisabled: {
    opacity: 0.5,
    pointerEvents: 'none'
  },
  accountSelect: {
    width: theme.typography.pxToRem(260),
    maxWidth: theme.typography.pxToRem(260),
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%'
    }
  },
  sortButton: {
    marginLeft: theme.spacing(1)
  },
  container: {},
  row: {
    display: 'flex',
    alignContent: 'center'
  },
  rowBordered: {
    borderTop: `2px solid ${theme.palette.grey.A700}`,
    '&:last-child': {
      borderBottom: `2px solid ${theme.palette.grey.A700}`,
      marginBottom: theme.spacing(1)
    }
  },
  rowMultipleFilter: {
    '&:nth-child(even)': {
      backgroundColor: '#f9f9f9'
    }
  },
  timeFilter: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      display: 'block',
      '& > *': {
        width: '100%'
      }
    }
  },
  dimensionButtonGroup: {
    justifyContent: 'flex-end',
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(12),
      width: '100%',
      marginRight: 'initial',
      marginBottom: theme.typography.pxToRem(5)
    }
  },
  fixed120md: {
    width: theme.typography.pxToRem(100),
    flexBasis: 'auto'
  },
  fixed260md: {
    [theme.breakpoints.up('md')]: {
      width: theme.typography.pxToRem(270),
      flexBasis: 'auto'
    }
  },
  fixed120mdMarginLeft: {
    [theme.breakpoints.up(CustomFilterRowBreakpointInPx)]: {
      marginLeft: theme.typography.pxToRem(108)
    }
  },
  fixed260lg: {
    [theme.breakpoints.up('lg')]: {
      width: theme.typography.pxToRem(270),
      flexBasis: 'auto'
    }
  },
  guestTypeFilter: {
    display: 'flex',
    flexFlow: 'column'
  }
}));
export default ReportMailFilter;
