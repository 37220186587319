import { FormControlLabel, Grid, Paper, Switch } from '@material-ui/core';
import MaterialTable, { Column, MTableToolbar, MTableCell } from 'material-table';
import icons from 'components/MaterialTable/icons';
import * as React from 'react';
import ExportButton from 'pages/Report/Advanced/ExportButton';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { useIntl } from 'react-intl';
import { getLocalization, options } from 'pages/Report/Advanced/utils/tableUtils';
import { StyledTab, StyledTabs } from 'pages/Report/components/TabContainer/components/StyledTabs';
import { AdvancedReportType } from 'pages/Report/Advanced/index';
import BalanceScaleIcon from 'components/icons/BalanceScaleIcon';
import { makeStyles } from '@material-ui/core/styles';
import { ExportExtension } from 'pages/Report/Advanced/utils/tableExport';
import { PageTitle } from 'pages/Report/components/ReportPageLayout';

// eslint-disable-next-line @typescript-eslint/ban-types
interface AdvancedProps<T extends object> {
  registrations: T[];
  resource: AdvancedReportType;
  onResourceChange: (resource: string) => void;
  onExport: (ext: ExportExtension, headers: Column<T>[], data: T[]) => void;
  columns: Column<T>[];
  includeDeleted: boolean;
  toggleIncludeDeleted: (includeDeleted: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/ban-types
const Advanced = <T extends object>(props: AdvancedProps<T>) => {
  const intl = useIntl();
  const {
    resource,
    onResourceChange,
    onExport,
    registrations = [],
    columns,
    includeDeleted,
    toggleIncludeDeleted
  } = props;
  const tableRef = React.useRef<any>(null);
  const localization = getLocalization(intl);
  const classes = useStyles(props);

  const handleExport = (ext: ExportExtension) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const headers = tableRef.current.dataManager.columns as Column<T>[];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const data = tableRef.current.dataManager.sortedData as T[];
    onExport(ext, headers, data);
  };

  const customToolbar = (props) => (
    <>
      <MTableToolbar {...props} />
      {resource === 'foodwaste' && (
        <div className={classes.customToolbar}>
          <FormControlLabel
            control={
              <Switch
                size='small'
                color='primary'
                checked={includeDeleted}
                onChange={(event) => toggleIncludeDeleted(event.target.checked)}
              />
            }
            label={intl.formatMessage({ id: 'report.advancedReports.showDeleted' })}
          />
        </div>
      )}
    </>
  );

  return (
    <Grid container spacing={4}>
      <PageTitle>{intl.formatMessage({ id: 'report.advancedReports.page.title' })}</PageTitle>
      <Grid item xs={12}>
        <Paper>
          <StyledTabs
            className={classes.tabs}
            onChange={(_, v: string) => onResourceChange(v)}
            value={resource}
          >
            <StyledTab
              icon={<BalanceScaleIcon />}
              value={AdvancedReportType.foodwaste}
              label={intl.formatMessage({ id: 'food_waste' })}
            />
            <StyledTab
              icon={<AttachMoneyIcon />}
              value={AdvancedReportType.sales}
              label={intl.formatMessage({ id: 'report.sales_tab' })}
            />
            <StyledTab
              value={AdvancedReportType.benchGuests}
              label={intl.formatMessage({ id: 'report.tab.benchGuests' })}
            />
            <StyledTab
              value={AdvancedReportType.benchWaste}
              label={intl.formatMessage({ id: 'report.tab.benchWaste' })}
            />
          </StyledTabs>
          <MaterialTable
            tableRef={tableRef}
            style={{ boxShadow: 'none' }}
            icons={icons}
            columns={columns}
            data={registrations}
            options={options}
            actions={[
              {
                isFreeAction: true,
                icon: () => <SaveAltIcon />,
                tooltip: intl.formatMessage({ id: 'export' }),
                onClick: handleExport
              }
            ]}
            components={{
              Toolbar: customToolbar,
              Action: ExportButton,
              Cell: (props) =>
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                props.rowData.deletedAt ? (
                  <MTableCell {...props} className={classes.deletedRow} />
                ) : (
                  <MTableCell {...props} />
                )
            }}
            localization={localization}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  tabs: {
    margin: 'auto'
  },
  customToolbar: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: '10px'
  },
  deletedRow: {
    color: '#a5a5a5'
  }
});

export default Advanced;
