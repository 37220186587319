import * as React from 'react';

import { connect } from 'react-redux';
import { Period, ReportActions, TimeRange, TimeRangeOptions } from 'redux/ducks/reportFilter';
import * as reportDispatch from 'redux/ducks/reportFilter';
import { CalendarPeriod, DateChange } from 'components/DatePicker/utils/constants';
import { API_DATE_FORMAT } from 'utils/datetime';
import { DateInput } from 'components/DatePicker';
import { getTimeFilter } from 'redux/ducks/reportFilter/selectors';
import moment from 'moment';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { DatePickerProps } from 'components/DatePicker/DateInput';
import debounce from 'lodash/debounce';
import { selectReportDataIsLoading } from 'redux/ducks/reportData/selector';

type StoreProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface TimeFilterContainerProps extends Partial<DatePickerProps> {
  className?: string;
  skipFilterReload?: boolean;
  updateCache?: boolean;
  elevatePopover?: boolean;
  isBasic?: boolean;
  periods?: CalendarPeriod[];
}

const defaultPeriods = [
  CalendarPeriod.day,
  CalendarPeriod.week,
  CalendarPeriod.month,
  CalendarPeriod.quarter,
  CalendarPeriod.year,
  CalendarPeriod.custom
];

const TimeFilterContainer: React.FunctionComponent<
  TimeFilterContainerProps & StoreProps & DispatchProps
> = (props) => {
  const {
    timeFilter,
    changeTime,
    skipFilterReload,
    updateCache,
    elevatePopover,
    isBasic,
    periods = defaultPeriods,
    reportsAreLoading,
    ...restProps
  } = props;
  const period = CalendarPeriod[timeFilter.period];
  const from = moment(timeFilter.from);
  const to = moment(timeFilter.to);
  const [selection, setSelection] = React.useState({ from, to, type: period });

  const handleDateChange = (dateChange: DateChange) => {
    setSelection(dateChange);
  };

  const handleChangeTime = React.useCallback(
    debounce((change: DateChange) => {
      const timeRange = {
        from: change.from.format(API_DATE_FORMAT),
        to: change.to.format(API_DATE_FORMAT)
      };

      if (
        timeFilter.from === timeRange.from &&
        timeFilter.to === timeRange.to &&
        change.type === period
      ) {
        return;
      }
      changeTime(timeRange, change.type as Period, { skipFilterReload, updateCache });
    }, 300),
    []
  );

  React.useEffect(() => {
    handleChangeTime(selection as DateChange);
  }, [selection]);

  return (
    <DateInput
      periods={periods}
      isoWeek={true}
      size='medium'
      {...restProps}
      onDateChange={handleDateChange}
      selection={selection}
      period={selection.type}
      elevatePopover={elevatePopover}
      disabled={reportsAreLoading}
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  timeFilter: getTimeFilter(state),
  reportsAreLoading: selectReportDataIsLoading(state)
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, ReportActions>) => ({
  changeTime: (range: TimeRange, period: Period, options?: TimeRangeOptions) =>
    dispatch(reportDispatch.changeTimeRange(range, period, options))
});

export default connect<StoreProps, DispatchProps, TimeFilterContainerProps>(
  mapStateToProps,
  mapDispatchToProps
)(TimeFilterContainer);
