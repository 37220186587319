import { Feature } from 'flagged';
import * as React from 'react';
import StarterPlanRegister from 'pages/Registration/StarterRegister';
import RegistrationPage from 'pages/Registration/RegistrationPage';
const Registration: React.FunctionComponent = () => {
  return (
    <>
      <Feature name='registration/full'>
        <RegistrationPage />
      </Feature>
      <Feature name='registration/free'>
        <StarterPlanRegister />
      </Feature>
      <Feature name='registration/view'>
        <StarterPlanRegister />
      </Feature>
    </>
  );
};

export default Registration;
