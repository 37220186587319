import { DataTransfer } from 'frontend-core';
import { AxiosResponse } from 'axios';
import { fetchRegistrations, Registration } from 'redux/ducks/data/registrations';
import moment from 'moment';
import { unformatMass } from 'components/FormattedMass';
import { API_DATE_FORMAT } from 'utils/datetime';
import {
  DataRegistrationPointsActions,
  DataRegistrationPointsActionTypes,
  RegistrationPoint
} from 'redux/ducks/data/registrationPoints';
import {
  CreateFreeRegistration,
  CurrentRegistration,
  RegistrationActions,
  RegistrationActionTypes,
  RegistrationAvoidable,
  RegistrationState,
  RegistrationStatus,
  ScaleStatus,
  StepShape
} from 'redux/ducks/registration/types';
import { ErrorActions, displayError } from 'redux/ducks/error';
import { ThunkResult } from 'redux/types';
import {
  currentRegistrationDataFormat,
  getMultipleRegistration
} from 'redux/ducks/registration/selectors';

export * from './types';
export * from './selectors';

const transfer = new DataTransfer();

const currentDate = () => {
  const date = new Date();
  date.setFullYear(date.getFullYear());
  date.setHours(0, 0, 0, 0);
  return date;
};

const UpdateDateIntervalInMins = 30;

export const initialState: RegistrationState = {
  weight: undefined,
  date: currentDate(),
  step: 0,
  loading: false,
  scaleStatus: {
    isConnected: false
  },
  pageNumber: 0,
  nodesHistory: [],
  dateUpdatedAt: Date.now(),
  currentNodes: [],
  reasonId: undefined,
  currentRegistrations: [],
  areRegistrationsValid: false,
  displayMultipleRegistration: false,
  avoidable: undefined,
  funFact: null
};

export default function reducer(
  state: RegistrationState = initialState,
  action: RegistrationActions
): RegistrationState {
  switch (action.type) {
    case RegistrationActionTypes.SET_WEIGHT:
      return { ...state, weight: action.payload };
    case RegistrationActionTypes.SET_REASON:
      return { ...state, reasonId: action.payload };
    case RegistrationActionTypes.SET_DATE:
      return { ...state, date: action.payload, dateUpdatedAt: Date.now() };
    case RegistrationActionTypes.SET_COMMENT:
      return { ...state, comment: action.payload };
    case RegistrationActionTypes.REGISTER_REQUEST:
      return { ...state, loading: true };
    case RegistrationActionTypes.REGISTER_SUCCESS:
      return {
        ...state,
        comment: '',
        weight: undefined,
        currentNodes: [],
        nodesHistory: [],
        step: 0,
        date: currentDate(),
        loading: false,
        dateUpdatedAt: Date.now(),
        avoidable: undefined
      };
    case RegistrationActionTypes.REGISTER_FAILURE: {
      return { ...state, loading: false };
    }
    case RegistrationActionTypes.UPDATE_STEP:
      return { ...state, step: action.payload };
    case RegistrationActionTypes.UPDATE_REGISTRATION_POINTS:
      return {
        ...state,
        nodesHistory: action.payload.nodesHistory,
        currentNodes: action.payload.registrationPoints,
        currentRegistrations: [],
        displayMultipleRegistration: false
      };
    case RegistrationActionTypes.SET_SCALE_STATUS:
      return { ...state, scaleStatus: action.payload };
    case RegistrationActionTypes.UPDATE_PAGINATION:
      return { ...state, pageNumber: action.payload };
    case RegistrationActionTypes.UPDATE_STEPPER:
      return { ...state, pageNumber: 0, step: 0, nodesHistory: action.payload };
    case RegistrationActionTypes.UPDATE_CURRENT_REGISTRATIONS:
      return {
        ...state,
        currentRegistrations: action.payload,
        areRegistrationsValid: registrationsValidation(state.currentNodes, action.payload),
        displayMultipleRegistration:
          action.payload.length === 0 ? false : state.displayMultipleRegistration
      };
    case RegistrationActionTypes.MULTIPLE_REGISTER_SUCCESS:
      return {
        ...state,
        comment: '',
        loading: false,
        dateUpdatedAt: Date.now(),
        weight: 0
      };
    case RegistrationActionTypes.MULTIPLE_REGISTER_DISPLAY:
      return { ...state, displayMultipleRegistration: action.payload };
    case RegistrationActionTypes.SET_AVOIDABLE:
      return { ...state, avoidable: action.payload };
    case RegistrationActionTypes.SET_FUN_FACT:
      return { ...state, funFact: action.payload };
    default:
      return state;
  }
}

// remove this, after success reset right away
export function registerSuccess(): ThunkResult<
  DataRegistrationPointsActions,
  DataRegistrationPointsActions
> {
  return (dispatch, getState) => {
    const allNodes = getState().data.registrationPoints.allNodes;
    const roots = allNodes.filter((node) => !node.parentId);
    // todo fix this, or figure out why this is done here
    return dispatch({
      type: DataRegistrationPointsActionTypes.UPDATE,
      payload: roots
    });
  };
}

export const updateStep = (
  step: StepShape
): ThunkResult<RegistrationActions, RegistrationActions | DataRegistrationPointsActions> => {
  return (dispatch, getState) => {
    const { dateUpdatedAt } = getState().registration;
    const currentDate = new Date();
    // next step is registration weight page,
    // check set date and update it to current date,
    // if set date was updated more than given interval.
    // this is only necessary, because we allow users
    // to set data and go back to change registration point
    if (step === 1) {
      if (moment(currentDate.getTime()).diff(dateUpdatedAt, 'minutes') > UpdateDateIntervalInMins) {
        dispatch(setDate(currentDate));
      }
    }

    return dispatch({
      type: RegistrationActionTypes.UPDATE_STEP,
      payload: step
    });
  };
};

export const updatePagination = (pageNumber: number): RegistrationActions => ({
  type: RegistrationActionTypes.UPDATE_PAGINATION,
  payload: pageNumber
});

export const setDate = (date: Date): RegistrationActions => ({
  type: RegistrationActionTypes.SET_DATE,
  payload: date
});

export const setComment = (comment: string): RegistrationActions => ({
  type: RegistrationActionTypes.SET_COMMENT,
  payload: comment
});

export const setScaleStatus = (status: ScaleStatus): RegistrationActions => ({
  type: RegistrationActionTypes.SET_SCALE_STATUS,
  payload: status
});

// fix to not return api response => void | action
export function register(): ThunkResult<
  Promise<RegistrationActions | ErrorActions>,
  RegistrationActions | DataRegistrationPointsActions | ErrorActions
> {
  return async (dispatch, getState) => {
    const {
      scaleStatus: { isConnected: isScaleConnected },
      date,
      weight,
      comment,
      reasonId,
      currentNodes,
      currentRegistrations,
      nodesHistory,
      areRegistrationsValid,
      displayMultipleRegistration,
      avoidable
    } = getState().registration;
    const { enableAutoTare } = getState().settings;

    const registrations = getMultipleRegistration(getState());
    const allNodes = getState().data.registrationPoints.allNodes;
    const roots = allNodes.filter((node) => !node.parentId);
    const amount = weight ? parseInt((unformatMass(weight) as number).toFixed(0)) : undefined;

    const selectedRegistrationPoint =
      registrations.length > 1 || displayMultipleRegistration
        ? registrations.find((value) => value.selected === true)
        : currentRegistrationDataFormat(currentNodes[0]);

    dispatch({
      type: RegistrationActionTypes.REGISTER_REQUEST
    });

    // when pressing finish registration button
    if (selectedRegistrationPoint === undefined && areRegistrationsValid) {
      if (areRegistrationsValid) {
        dispatch({
          type: DataRegistrationPointsActionTypes.UPDATE,
          payload: roots
        });

        dispatch({
          type: RegistrationActionTypes.UPDATE_STEPPER,
          payload: []
        });

        return dispatch({
          type: RegistrationActionTypes.REGISTER_SUCCESS
        });
      }
    }

    if (!selectedRegistrationPoint) {
      return Promise.reject({ selectedRegistrationPoint });
    }

    if (amount < 100) {
      return Promise.reject({ amount });
    }

    const data = {
      scale: isScaleConnected,
      amount,
      comment,
      date: moment(date).format(API_DATE_FORMAT),
      registrationPointId: selectedRegistrationPoint.id,
      unit: 'g',
      reasonId: reasonId,
      avoidable
    };

    try {
      const response = (await transfer.post(
        '/foodwaste/registrations',
        data
      )) as AxiosResponse<Registration>;
      // why is this done?
      await dispatch(
        fetchRegistrations({
          createdAt: {
            from: moment().subtract(1, 'year').startOf('day').toISOString(),
            to: moment().endOf('day').toISOString()
          }
        })
      );

      dispatch({
        type: RegistrationActionTypes.SET_REASON,
        payload: null
      });

      dispatch({
        type: RegistrationActionTypes.SET_FUN_FACT,
        payload: response.data.funFact
      });

      if (
        displayMultipleRegistration === false &&
        (areRegistrationsValid || currentNodes.length === 1)
      ) {
        // todo fix this, or figure out why this is done here,
        // we only need to store id refs here in registration state slice,
        // not update other state slices
        dispatch({
          type: DataRegistrationPointsActionTypes.UPDATE,
          payload: roots
        });
      }

      const updatedCurrentRegistrations = updateCurrentRegistrationArray(currentRegistrations, {
        ...selectedRegistrationPoint,
        status: 'registered',
        amount: amount,
        date: response.data.date,
        pointPath: nodesHistory.flatMap((pointsByName) => Object.keys(pointsByName)),
        registrationId: response.data.id,
        reasonId: response.data.reasonId,
        comment: response.data.comment,
        offline: response.data.offline
      });

      dispatch({
        type: RegistrationActionTypes.UPDATE_CURRENT_REGISTRATIONS,
        payload: updatedCurrentRegistrations
      });

      if (window.ReactNativeWebView && window.ReactNativeWebView.postMessage && enableAutoTare) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ command: 'tare' }));
      }

      if (displayMultipleRegistration) {
        return dispatch({
          type: RegistrationActionTypes.MULTIPLE_REGISTER_SUCCESS
        });
      } else {
        return dispatch({
          type: RegistrationActionTypes.REGISTER_SUCCESS
        });
      }
    } catch (error: unknown) {
      dispatch({
        type: RegistrationActionTypes.REGISTER_FAILURE
      });

      return dispatch(displayError(error as Error));
    }
  };
}

export function selectRegistrationPoint(
  registrationPoint: RegistrationPoint,
  registerDirectly?: boolean
): ThunkResult<
  RegistrationActions | DataRegistrationPointsActions,
  RegistrationActions | DataRegistrationPointsActions
> {
  return (dispatch, getState) => {
    const { allNodes, roots } = getState().data.registrationPoints;
    const { nodesHistory } = getState().registration;

    const newRoots = allNodes.filter(
      (node: RegistrationPoint) =>
        node.parentId === registrationPoint.id && node.active && !node.deletedAt
    );

    dispatch({ type: RegistrationActionTypes.UPDATE_PAGINATION, payload: 0 });
    dispatch({
      type: RegistrationActionTypes.UPDATE_REGISTRATION_POINTS,
      payload: {
        nodesHistory: [...nodesHistory, { [registrationPoint.name]: roots }],
        registrationPoints: [registrationPoint]
      }
    });
    if (registerDirectly || newRoots.length === 0) {
      dispatch({
        type: DataRegistrationPointsActionTypes.UPDATE,
        payload: newRoots
      });
      return dispatch(updateStep(1));
    } else {
      return dispatch({
        type: DataRegistrationPointsActionTypes.UPDATE,
        payload: newRoots
      });
    }
  };
}

export function selectGuestRegistration(): RegistrationActions {
  return { type: RegistrationActionTypes.UPDATE_STEP, payload: 2 };
}

export function selectReasonRegistration(): RegistrationActions {
  return { type: RegistrationActionTypes.UPDATE_STEP, payload: 1 };
}

export function updateStepper(
  index: number,
  property: string
): ThunkResult<RegistrationActions, RegistrationActions | DataRegistrationPointsActions> {
  return (dispatch, getState) => {
    const { nodesHistory } = getState().registration;
    const newNodes = nodesHistory[index][property];

    dispatch({
      type: DataRegistrationPointsActionTypes.UPDATE,
      payload: newNodes
    });
    return dispatch({
      type: RegistrationActionTypes.UPDATE_STEPPER,
      payload: nodesHistory.slice(0, index)
    });
  };
}

export function resetStepper(): ThunkResult<
  RegistrationActions,
  RegistrationActions | DataRegistrationPointsActions
> {
  return (dispatch, getState) => {
    const allNodes = getState().data.registrationPoints.allNodes;
    const roots = allNodes.filter((node) => !node.parentId);

    dispatch({
      type: DataRegistrationPointsActionTypes.UPDATE,
      payload: roots
    });
    return dispatch({
      type: RegistrationActionTypes.UPDATE_STEPPER,
      payload: []
    });
  };
}

export function setWeight(weight: number): RegistrationActions {
  return {
    type: RegistrationActionTypes.SET_WEIGHT,
    payload: weight
  };
}

export function setReason(reasonId: string): RegistrationActions {
  return {
    type: RegistrationActionTypes.SET_REASON,
    payload: reasonId
  };
}

export function resetCurrentRegistrations(): RegistrationActions {
  return {
    type: RegistrationActionTypes.UPDATE_CURRENT_REGISTRATIONS,
    payload: []
  };
}

export function selectMultipleRegistrations(): ThunkResult<
  RegistrationActions | DataRegistrationPointsActions,
  RegistrationActions | DataRegistrationPointsActions
> {
  return (dispatch, getState) => {
    const { nodesHistory } = getState().registration;
    const { roots } = getState().data.registrationPoints;

    dispatch({
      type: RegistrationActionTypes.UPDATE_REGISTRATION_POINTS,
      payload: {
        nodesHistory: nodesHistory,
        registrationPoints: roots
      }
    });

    dispatch({
      type: RegistrationActionTypes.MULTIPLE_REGISTER_DISPLAY,
      payload: true
    });

    return dispatch(updateStep(1));
  };
}

export function updateMultipleRegistrations(
  statusType: RegistrationStatus
): ThunkResult<RegistrationActions, RegistrationActions> {
  return (dispatch, getState) => {
    const { currentRegistrations, date } = getState().registration;
    const selectedCurrentRegistration = getMultipleRegistration(getState()).find(
      (value) => value.selected === true
    );

    const updatedRegistrations = updateCurrentRegistrationArray(currentRegistrations, {
      ...selectedCurrentRegistration,
      status: statusType,
      date: moment(date).format(API_DATE_FORMAT)
    });

    return dispatch({
      type: RegistrationActionTypes.UPDATE_CURRENT_REGISTRATIONS,
      payload: updatedRegistrations
    });
  };
}

// prevents adding a duplicate object
// updates the current registrations array with the new registration
function updateCurrentRegistrationArray(
  currentRegistrations: CurrentRegistration[],
  newRegistration: CurrentRegistration
): CurrentRegistration[] {
  const isDuplicate = currentRegistrations.some(
    (registration) => registration.id === newRegistration.id
  );

  if (isDuplicate) {
    return currentRegistrations.map((registration) =>
      registration.id === newRegistration.id
        ? { ...registration, ...newRegistration }
        : registration
    );
  } else {
    return [...currentRegistrations, newRegistration];
  }
}

function registrationsValidation(
  currentNodes: RegistrationPoint[],
  currentRegistrations: CurrentRegistration[]
) {
  const registeredPoints = currentRegistrations.filter((registration) => !!registration.status);
  return registeredPoints.length === currentNodes.length;
}

export function editRegistration(
  registration: CurrentRegistration
): ThunkResult<Promise<RegistrationActions | ErrorActions>, RegistrationActions | ErrorActions> {
  return async (dispatch, getState) => {
    const { date, currentRegistrations } = getState().registration;
    const { amount, id, comment, reasonId, status, registrationId } = registration;

    if (status !== 'skipped') {
      void (await dispatch(deleteRegistrationMulti(registrationId)));
    }

    const data = {
      scale: false,
      amount,
      comment,
      date: moment(date).format(API_DATE_FORMAT),
      registrationPointId: id,
      unit: 'g',
      reasonId: reasonId
    };

    try {
      const response = (await transfer.post(
        '/foodwaste/registrations',
        data
      )) as AxiosResponse<Registration>;
      void (await dispatch(
        fetchRegistrations({
          createdAt: {
            from: moment().subtract(1, 'year').startOf('day').toISOString(),
            to: moment().endOf('day').toISOString()
          }
        })
      ));

      const updatedRegistrations = updateCurrentRegistrationArray(currentRegistrations, {
        ...registration,
        status: 'registered',
        date: response.data.date,
        registrationId: response.data.id,
        reasonId: response.data.reasonId,
        comment: response.data.comment
      });

      return dispatch({
        type: RegistrationActionTypes.UPDATE_CURRENT_REGISTRATIONS,
        payload: updatedRegistrations
      });
    } catch (error: unknown) {
      dispatch({
        type: RegistrationActionTypes.REGISTER_FAILURE
      });

      return dispatch(displayError(error as Error));
    }
  };
}

export function skipRegistrationAction(
  registration: CurrentRegistration
): ThunkResult<Promise<RegistrationActions>, RegistrationActions> {
  return async (dispatch, getState) => {
    const { currentRegistrations } = getState().registration;

    void (await dispatch(deleteRegistrationMulti(registration.registrationId)));

    const updatedRegistrations = updateCurrentRegistrationArray(currentRegistrations, {
      ...registration,
      status: 'skipped',
      registrationId: undefined
    });

    void (await dispatch(
      fetchRegistrations({
        createdAt: {
          from: moment().subtract(1, 'year').startOf('day').toISOString(),
          to: moment().endOf('day').toISOString()
        }
      })
    ));

    return dispatch({
      type: RegistrationActionTypes.UPDATE_CURRENT_REGISTRATIONS,
      payload: updatedRegistrations
    });
  };
}

function deleteRegistrationMulti(
  registrationId: string
): ThunkResult<Promise<Registration | ErrorActions>, ErrorActions> {
  return async (dispatch) => {
    try {
      const result = (await transfer.delete(
        `/foodwaste/registrations/${registrationId}`
      )) as AxiosResponse<Registration>;
      return result.data;
    } catch (error: unknown) {
      return dispatch(displayError(error as Error));
    }
  };
}

export function setAvoidable(avoidable: RegistrationAvoidable): RegistrationActions {
  return {
    type: RegistrationActionTypes.SET_AVOIDABLE,
    payload: avoidable
  };
}

export function registerFreePlan(
  registration: CreateFreeRegistration
): ThunkResult<
  Promise<RegistrationActions | ErrorActions>,
  RegistrationActions | DataRegistrationPointsActions | ErrorActions
> {
  return async (dispatch, getState) => {
    const { date, weight, comment, reasonId, avoidable } = registration;
    const { currentRegistrations } = getState().registration;
    const allNodes = getState().data.registrationPoints.allNodes;
    const roots = allNodes.filter((node) => !node.parentId);
    // makes no sense to store weight in kgs, should fix to use g later
    const amount = weight ? parseInt((unformatMass(weight) as number).toFixed(0)) : undefined;

    // workaround for PR
    // will be replaced by the real bootstrapped registrationPointId
    const selectedRegistrationPoint = roots.filter((tree) => tree.active && !tree.deletedAt)[0];

    dispatch({
      type: RegistrationActionTypes.REGISTER_REQUEST
    });

    if (!selectedRegistrationPoint) {
      return Promise.reject({ selectedRegistrationPoint });
    }

    if (amount < 100) {
      return Promise.reject({ amount });
    }

    const data = {
      scale: false,
      amount,
      comment,
      date: moment(date).format(API_DATE_FORMAT),
      registrationPointId: selectedRegistrationPoint.id,
      unit: 'g',
      reasonId: reasonId,
      avoidable
    };

    try {
      const response = (await transfer.post(
        '/foodwaste/registrations',
        data
      )) as AxiosResponse<Registration>;
      // why is this done?
      await dispatch(
        fetchRegistrations({
          createdAt: {
            from: moment().subtract(1, 'week').startOf('day').toISOString(),
            to: moment().endOf('day').toISOString()
          }
        })
      );

      dispatch({
        type: DataRegistrationPointsActionTypes.UPDATE,
        payload: roots
      });

      const updatedCurrentRegistrations = updateCurrentRegistrationArray(currentRegistrations, {
        id: selectedRegistrationPoint.id,
        status: 'registered',
        amount: amount,
        date: response.data.date,
        pointPath: null,
        registrationId: response.data.id,
        reasonId: response.data.reasonId,
        comment: response.data.comment,
        unit: 'g',
        name: null
      });

      dispatch({
        type: RegistrationActionTypes.UPDATE_CURRENT_REGISTRATIONS,
        payload: updatedCurrentRegistrations
      });

      dispatch({
        type: RegistrationActionTypes.SET_FUN_FACT,
        payload: response.data.funFact
      });

      return dispatch({
        type: RegistrationActionTypes.REGISTER_SUCCESS
      });
    } catch (error: unknown) {
      dispatch({
        type: RegistrationActionTypes.REGISTER_FAILURE
      });

      return dispatch(displayError(error as Error));
    }
  };
}

export function editRegistrationTable(
  registration: Registration
): ThunkResult<Promise<RegistrationActions | ErrorActions>, RegistrationActions | ErrorActions> {
  return async (dispatch) => {
    const { amount, id, comment, reasonId, registrationPoint, date, avoidable } = registration;

    void (await dispatch(deleteRegistrationMulti(id)));

    const data = {
      scale: false,
      amount,
      comment,
      date: date,
      registrationPointId: registrationPoint.id,
      unit: 'g',
      reasonId: reasonId,
      avoidable
    };

    try {
      await transfer.post('/foodwaste/registrations', data);

      void (await dispatch(
        fetchRegistrations({
          createdAt: {
            from: moment().subtract(1, 'week').startOf('day').toISOString(),
            to: moment().endOf('day').toISOString()
          }
        })
      ));
    } catch (error: unknown) {
      dispatch({
        type: RegistrationActionTypes.REGISTER_FAILURE
      });
      return dispatch(displayError(error as Error));
    }
  };
}
