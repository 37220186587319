import { RegistrationPoint } from 'redux/ducks/data/registrationPoints/types';

export type StepShape = 0 | 1 | 2;

export type ConnectionType = 'USB' | 'BLE';
export type ScaleStatus = {
  isConnecting?: boolean;
  isConnected: boolean;
  type?: ConnectionType;
};
export type RegistrationStatus = 'skipped' | 'registered';
export type RegistrationAvoidable = 'yes' | 'no' | 'partly';

export interface RegistrationState {
  weight: number;
  date: Date;
  comment?: string;
  step: StepShape;
  loading: boolean;
  scaleStatus: ScaleStatus;
  pageNumber: number;
  nodesHistory: { [name: string]: RegistrationPoint[] }[];
  dateUpdatedAt: number;
  currentNodes: RegistrationPoint[];
  reasonId?: string;
  currentRegistrations: CurrentRegistration[];
  areRegistrationsValid: boolean;
  displayMultipleRegistration: boolean;
  avoidable: RegistrationAvoidable;
  funFact: FunFact;
}

export interface CreateFreeRegistration {
  date: Date;
  weight: number;
  comment?: string;
  reasonId?: string;
  avoidable: string;
}

export interface CurrentRegistration {
  id: string;
  name: string;
  image?: string | null;
  amount: number;
  status?: RegistrationStatus;
  selected?: boolean;
  pointPath: string[];
  unit: string;
  date: string;
  registrationId?: string;
  comment?: string;
  reasonId?: string;
  offline?: boolean;
}

export interface FunFact {
  nameKey: string;
  image: string;
  amount: number;
}

export enum RegistrationActionTypes {
  SET_WEIGHT = 'esmiley/registration/SET_WEIGHT',
  SET_REASON = 'esmiley/registration/SET_REASON',
  SET_DATE = 'esmiley/registration/SET_DATE',
  SET_SCALE_STATUS = 'esmiley/registration/SET_SCALE_STATUS',
  REGISTER_SUCCESS = 'esmiley/registration/REGISTER_SUCCESS',
  REGISTER_FAILURE = 'esmiley/registration/REGISTER_FAILURE',
  REGISTER_REQUEST = 'esmiley/registration/REGISTER_REQUEST',
  UPDATE = 'esmiley/registration/UPDATE',
  UPDATE_STEP = 'esmiley/registration/UPDATE_STEP',
  UPDATE_PAGINATION = 'esmiley/registration/UPDATE_PAGINATION',
  UPDATE_REGISTRATION_POINTS = 'esmiley/registration/UPDATE_REGISTRATION_POINTS',
  UPDATE_STEPPER = 'esmiley/registration/UPDATE_STEPPER',
  SET_COMMENT = 'esmiley/registration/SET_COMMENT',
  UPDATE_CURRENT_REGISTRATIONS = 'esmiley/registration/UPDATE_CURRENT_REGISTRATIONS',
  MULTIPLE_REGISTER_SUCCESS = 'esmiley/registration/MULTIPLE_REGISTER_SUCCESS',
  MULTIPLE_REGISTER_DISPLAY = 'esmiley/registration/MULTIPLE_REGISTER_DISPLAY',
  SET_AVOIDABLE = 'esmiley/registration/SET_AVOIDABLE',
  SET_FUN_FACT = 'esmiley/registration/SET_FUN_FACT'
}

type RegisterSuccessAction = {
  type: typeof RegistrationActionTypes.REGISTER_SUCCESS;
};

type UpdateStepAction = {
  type: typeof RegistrationActionTypes.UPDATE_STEP;
  payload: StepShape;
};

type UpdatePaginationAction = {
  type: typeof RegistrationActionTypes.UPDATE_PAGINATION;
  payload: number;
};

type SetDateAction = {
  type: typeof RegistrationActionTypes.SET_DATE;
  payload: Date;
};

type SetWeightAction = {
  type: typeof RegistrationActionTypes.SET_WEIGHT;
  payload: number;
};

type SetReasonAction = {
  type: typeof RegistrationActionTypes.SET_REASON;
  payload: string;
};

type SetScaleStatusAction = {
  type: typeof RegistrationActionTypes.SET_SCALE_STATUS;
  payload: ScaleStatus;
};

type RegisterRequestAction = {
  type: typeof RegistrationActionTypes.REGISTER_REQUEST;
};

type RegisterRequestFailureAction = {
  type: typeof RegistrationActionTypes.REGISTER_FAILURE;
};

type UpdateRegistrationPointsAction = {
  type: typeof RegistrationActionTypes.UPDATE_REGISTRATION_POINTS;
  payload: {
    nodesHistory: { [name: string]: RegistrationPoint[] }[];
    registrationPoints: RegistrationPoint[];
  };
};

type UpdateStepperAction = {
  type: typeof RegistrationActionTypes.UPDATE_STEPPER;
  payload: { [name: string]: RegistrationPoint[] }[];
};

type SetCommentAction = {
  type: typeof RegistrationActionTypes.SET_COMMENT;
  payload: string;
};

type UpdateCurrentRegistrationsAction = {
  type: typeof RegistrationActionTypes.UPDATE_CURRENT_REGISTRATIONS;
  payload: CurrentRegistration[];
};

type MultipleRegisterSuccessAction = {
  type: typeof RegistrationActionTypes.MULTIPLE_REGISTER_SUCCESS;
};

type MultipleRegistrationsDisplayAction = {
  type: typeof RegistrationActionTypes.MULTIPLE_REGISTER_DISPLAY;
  payload: boolean;
};

type SetAvoidableAction = {
  type: typeof RegistrationActionTypes.SET_AVOIDABLE;
  payload: RegistrationAvoidable;
};

type SetFunFactAction = {
  type: typeof RegistrationActionTypes.SET_FUN_FACT;
  payload: FunFact;
};

export type RegistrationActions =
  | RegisterSuccessAction
  | UpdateStepAction
  | UpdatePaginationAction
  | SetDateAction
  | SetReasonAction
  | SetScaleStatusAction
  | RegisterRequestAction
  | RegisterRequestFailureAction
  | UpdateRegistrationPointsAction
  | UpdateStepperAction
  | SetWeightAction
  | SetCommentAction
  | UpdateCurrentRegistrationsAction
  | MultipleRegisterSuccessAction
  | MultipleRegistrationsDisplayAction
  | SetAvoidableAction
  | SetFunFactAction;
