import * as React from 'react';
import { Card, CardContent, Grid } from '@material-ui/core';
import Fact from 'pages/Report/components/Fact';
import { useIntl } from 'react-intl';
import ProgressMessage from 'pages/Report/components/ProgressMessage';
import ErrorMessage from 'pages/Report/components/ErrorMessage';
import { MetricsData, MetricsResponse, ReportChart } from 'redux/ducks/reportData';

interface ComponentProps {
  regFrequencyMetrics: ReportChart<MetricsResponse>;
}

export const getMetricKey = (metricId: string) => {
  switch (metricId) {
    case 'frequencyAvgRegistrationDaysPerWeek':
      return 'avgRegistrationDaysPerWeek';
    case 'frequencyAvgRegistrationsPerDay':
      return 'avgRegistrationsPerDay';
    case 'frequencyAvgGuestRegistrationDaysPerWeek':
      return 'avgGuestRegistrationDaysPerWeek';
    case 'frequencyAvgGuestsRegisteredPerWeek':
      return 'avgGuestsRegisteredPerWeek';
    default:
      return;
  }
};

export const getProgressMessage = (metric: MetricsData) => {
  if (metric.trend === 0) {
    return 'report.terms.noChangeComparedToPrevPeriod';
  }
  switch (metric.id) {
    case 'frequencyAvgRegistrationDaysPerWeek':
    case 'frequencyAvgGuestRegistrationDaysPerWeek':
      return `report.quickOverview.${metric.trend > 0 ? 'more' : 'less'}DaysRegistered`;
    case 'frequencyAvgRegistrationsPerDay':
      return `report.quickOverview.${metric.trend > 0 ? 'more' : 'less'}RegistrationsPerDay`;
    case 'frequencyAvgGuestsRegisteredPerWeek':
      return `report.quickOverview.${metric.trend > 0 ? 'more' : 'less'}GuestRegistrationsPerWeek`;
    default:
      return;
  }
};

const FactsOverview: React.FunctionComponent<ComponentProps> = ({ regFrequencyMetrics }) => {
  const intl = useIntl();
  const {
    error,
    isLoading,
    data: { metrics = [] }
  } = regFrequencyMetrics;

  return error ? (
    <Card>
      <ErrorMessage error={error} />
    </Card>
  ) : (
    <Grid container spacing={3}>
      {metrics.map((metric) => (
        <Grid item xs={12} md={6} lg={3} container key={metric.id}>
          <Card>
            <CardContent>
              <Fact
                title={intl.formatMessage({ id: `report.frequency.${getMetricKey(metric.id)}` })}
                value={metric.point as number}
                unit={metric.unit}
                isLoading={isLoading}
                message={{
                  progressValue: metric.trend,
                  text: <ProgressMessage id={getProgressMessage(metric)} trend={metric.trend} />
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default FactsOverview;
