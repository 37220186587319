import * as React from 'react';
import { useIntl } from 'react-intl';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  IconButton
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

interface OwnProps {
  isOpen: boolean;
  onClose: () => void;
}

type ContactUsDialogProps = OwnProps;

const useStyles = makeStyles(() => ({
  contentHeader: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    marginLeft: '20px',
    marginTop: '-15px',
    flex: 0
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: '23px'
  },
  button: {
    background: '#ffffff',
    fontWeight: 'bold',
    color: 'rgb(0, 150, 136)',
    width: '100%',
    height: '60px',
    border: 'none',
    boxShadow: '0px 0px 10px -3px rgba(0,0,0,0.35)',
    '&:hover': {
      background: 'rgba(0, 150, 136, 0.4)'
    },
    '&:disabled': {
      background: '#ffffff'
    }
  },
  contentContainer: {
    marginTop: '-40px',
    padding: 0,
    paddingLeft: '38px',
    paddingRight: '15px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'hidden'
  },
  contentTitle: {
    fontSize: '2rem',
    fontWeight: 'bold'
  },
  contentSubTitle: {
    fontSize: '0.9rem',
    textAlign: 'center',
    color: '#616161'
  }
}));

const ContactUsDialog: React.FunctionComponent<ContactUsDialogProps> = (props) => {
  const { isOpen, onClose } = props;
  const intl = useIntl();
  const classes = useStyles();
  const handlePhoneCall = () => {
    window.location.href = 'tel:+4570265555';
    onClose();
  };
  return (
    <Dialog open={isOpen} fullWidth>
      <DialogTitle classes={{ root: classes.contentHeader }}>
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.contentContainer}>
        <span className={classes.contentTitle}>
          {intl.formatMessage({ id: 'plan.dialog.title' })}
        </span>
        <span className={classes.contentSubTitle}>
          {intl.formatMessage({ id: 'plan.dialog.subtitle' })}
        </span>
      </DialogContent>
      <DialogActions className={classes.buttonWrapper}>
        <Button className={classes.button} variant={'outlined'} onClick={handlePhoneCall}>
          {intl.formatMessage({ id: 'plan.dialog.call' })}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ContactUsDialog;
