import { Button, Theme, MenuItem, ListItemIcon, Popover, ListItemText } from '@material-ui/core';
import * as React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import { useIntl } from 'react-intl';
import { makeStyles } from '@material-ui/styles';
import { connect } from 'react-redux';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import { ModuleLink } from 'redux/ducks/ui';
import classNames from 'classnames';
import { RootState } from 'redux/rootReducer';

const useStyles = makeStyles(
  (theme: Theme) => ({
    menuButton: {
      minHeight: '32px',
      paddingLeft: '32px',
      paddingRight: '32px',
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',

      '& .MuiButton-startIcon': {
        [theme.breakpoints.down('sm')]: {
          margin: 0
        }
      },
      [theme.breakpoints.down('sm')]: {
        padding: 0,
        minWidth: '48px'
      },
      '&.blink': {
        animation: '$buttonBlink 3s infinite'
      }
    },
    menuButtonIcon: {
      fill: '#ffffff'
    },
    menuButtonText: {
      color: '#ffffff',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    menuLink: {
      '& a': {
        textDecoration: 'none'
      },
      '&.blink': {
        animation: '$menuBlink 3s infinite'
      }
    },
    menuIcon: {
      color: 'rgba(0,0,0,0.87)'
    },
    '@keyframes buttonBlink': {
      '0%': {
        backgroundColor: theme.palette.primary
      },
      '50%': {
        backgroundColor: 'rgba(255, 255, 255, 0.4)'
      },
      '100%': {
        backgroundColor: theme.palette.primary
      }
    },
    '@keyframes menuBlink': {
      '0%': {
        backgroundColor: 'transparent'
      },
      '50%': {
        backgroundColor: 'rgba(33, 150, 243, 0.25)'
      },
      '100%': {
        backgroundColor: 'transparent'
      }
    }
  }),
  { name: 'ModuleDropdown' }
);

export interface OwnProps {
  modules: ModuleLink[];
  onClick: (module: ModuleLink) => void;
}

export interface ModuleSelectorState {
  anchorEl: any;
}

type StoreProps = ReturnType<typeof mapStateToProps>;
type ModuleSelectorProps = OwnProps & StoreProps;

const ModuleDropdown: React.FunctionComponent<ModuleSelectorProps> = (props) => {
  const classes = useStyles(props);
  const intl = useIntl();
  const { modules, backToHaccp, onClick } = props;
  const [anchorEl, setAnchor] = React.useState<HTMLElement>(null);

  const handleCloseMenu = () => {
    setAnchor(null);
  };

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchor(event.currentTarget);
  };

  const handleOnClick = (item: ModuleLink) => {
    setAnchor(null);
    setTimeout(() => {
      onClick(item);
    }, 100);
  };

  const isLoading = !modules || !modules.length || Object.keys(modules[0]).length === 0;
  const isGoNative = navigator.userAgent.includes('esmnative');

  return (
    <div>
      <Button
        id='moduleButton'
        className={classNames(classes.menuButton, {
          blink: backToHaccp && isGoNative
        })}
        startIcon={<MenuIcon className={classes.menuButtonIcon} />}
        onClick={handleOpenMenu}
      >
        <span className={classes.menuButtonText}>{intl.formatMessage({ id: 'module.name' })}</span>
      </Button>
      <Popover anchorEl={anchorEl} onClose={handleCloseMenu} open={Boolean(anchorEl)}>
        {isLoading ? (
          <LoadingPlaceholder />
        ) : (
          modules.map((item, index) => (
            <MenuItem
              className={classNames(classes.menuLink, {
                blink: backToHaccp && item.app === 'haccp_tablet'
              })}
              key={`module_link_${index}`}
              value={index}
            >
              {item.icon && (
                <ListItemIcon>
                  <i className={classNames(item.icon, classes.menuIcon)} />
                </ListItemIcon>
              )}
              <ListItemText>
                <span onClick={() => handleOnClick(item)}>{item.label}</span>
              </ListItemText>
            </MenuItem>
          ))
        )}
      </Popover>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  backToHaccp: state.ui.returnTo === 'haccp'
});

export default connect<StoreProps, unknown, OwnProps>(mapStateToProps, null)(ModuleDropdown);
