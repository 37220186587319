import * as React from 'react';
import { useFeature } from 'flagged';
import TrialDialog from './TrialDialog';
import BootstrapDialog from 'components/OnboardDialog/BootstrapDialog';
import { initUser, UserActions } from 'redux/ducks/user';
import { FeaturesActions, getFeatures } from 'redux/ducks/features';

import { connect } from 'react-redux';
import { useAuth, UserProfile } from 'frontend-core';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from 'redux/rootReducer';

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OnBoardDialogProps = DispatchProps;

const OnboardDialog: React.FunctionComponent<OnBoardDialogProps> = (props) => {
  const { reloadUser } = props;
  const { user } = useAuth();
  const [step, setStep] = React.useState<number>(0);

  const hasPlanFeature = useFeature('bootstrap/plan');
  const hasTrialFeature = useFeature('bootstrap/trial');
  const hasTemplateFeature = useFeature('bootstrap/template');
  const hasHaccpFeature = useFeature('bootstrap/haccp');

  const steps = React.useMemo(() => {
    const activeSteps = [] as React.ComponentClass<{ onClose: () => void }>[];
    if (hasPlanFeature || hasTemplateFeature || hasHaccpFeature) {
      activeSteps.push(BootstrapDialog);
    }
    if (hasTrialFeature) {
      activeSteps.push(TrialDialog);
    }
    return activeSteps;
  }, [hasPlanFeature, hasTrialFeature, hasTemplateFeature, hasHaccpFeature]);

  const handleNextStep = () => {
    if (step === steps.length - 1) {
      void handleClose();
    } else {
      setStep((prev) => prev + 1);
    }
  };

  const handleClose = () => {
    void reloadUser(user);
  };

  const Step = steps[step];

  return <Step onClose={handleNextStep} />;
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, void, UserActions | FeaturesActions>
) => ({
  reloadUser: async (user: UserProfile) => {
    await dispatch(initUser(user));
    return dispatch(getFeatures());
  }
});

export default connect<unknown, DispatchProps, unknown>(null, mapDispatchToProps)(OnboardDialog);
