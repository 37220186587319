const schemaCache = new Map<string, JSONSchema>();

export type JSONSchema = Record<string, unknown>;

// todo: put schemas behind rest api/cdn
// + centralize logic to bundle coupled schemas that use refs
// + we always get latest versions instead of updating dependencies
// + no need to bundle all schemas upfront (unnecessary overhead)
// - need to handle async somewhere (separate init method?)

(function initialize() {
  const schemaFiles = require.context('schemas/out', false, /\.json$/);
  schemaFiles.keys().forEach((key) => {
    const file = key.split('/').pop();
    const name = file.split('.')[0];
    // eslint-disable-next-line
    schemaCache.set(name, require(`schemas/out/${file}`));
  });
})();

export const getSchemas = () => schemaCache;
