import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Theme, Typography } from '@material-ui/core';

interface ChartHeaderProps {
  name: string;
  value: string;
  color: string;
  dimension?: string;
  action?: React.ReactElement;
}

const ChartHeader: React.FunctionComponent<ChartHeaderProps> = (props) => {
  const classes = useStyles(props);
  const { name, value, dimension, action } = props;

  return (
    <Typography component={'h3'} className={classes.header}>
      <div className={classes.nameWrapper}>
        <Typography component={'span'} className={classes.name}>
          {name}
        </Typography>
        <span>{action}</span>
      </div>
      <Typography component={'span'} className={classes.value}>
        {value + ' '}
        {dimension && dimension === 'co2' && (
          <span>
            CO<sub>2</sub>
          </span>
        )}
      </Typography>
    </Typography>
  );
};

const useStyles = makeStyles<Theme, ChartHeaderProps>((theme) => ({
  header: (props) => ({
    borderBottom: `2px solid ${props.color}`,
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  }),
  nameWrapper: {
    display: 'inline-flex',
    alignItems: 'baseline',
    marginRight: 10
  },
  name: {
    fontWeight: 900,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  },
  value: {
    fontWeight: 900
  }
}));

export default ChartHeader;
