import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, CardContent, Grid } from '@material-ui/core';
import { RootState } from 'redux/rootReducer';
import { connect } from 'react-redux';
import Fact from 'pages/Report/components/Fact';
import { makeStyles } from '@material-ui/styles';
import { ThunkDispatch } from 'redux-thunk';
import { ReportActions } from 'redux/ducks/reportFilter';
import * as reportDispatch from 'redux/ducks/reportData';
import createValueFormatter from 'pages/Report/utils/createValueFormatter';
import { useMemo } from 'react';
import ErrorMessage from 'pages/Report/components/ErrorMessage';
import { Icon } from 'components/Icon';
import guestsImage from 'static/icons/guests.svg';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type CarbonFootprintPerGuestProps = StateProps & DispatchProps;

const foodWasteMetricsID = 'foodWasteMetricsOverview';
const metricId = 'foodwastePerGuestCurrentPeriod';

const useStyles = makeStyles({
  fullHeight: {
    height: '100%'
  }
});
const TotalPerGuestMetric: React.FunctionComponent<CarbonFootprintPerGuestProps> = (props) => {
  const classes = useStyles(props);
  const { foodWasteMetrics, fetchData, filter } = props;

  const { isLoading } = foodWasteMetrics;
  React.useEffect(() => {
    void fetchData(foodWasteMetricsID);
  }, [filter.basis, filter.timeRange, filter.period, filter.filter, filter.selectedGuestTypeNames]);

  const co2Metric = useMemo(() => {
    return foodWasteMetrics.data.metrics.find((m) => m.id === metricId);
  }, [foodWasteMetrics]);

  return (
    <>
      <Grid key={foodWasteMetricsID} item xs={12} sm={6} lg={3}>
        <Card className={classes.fullHeight}>
          <CardContent>
            {foodWasteMetrics.error ? (
              <ErrorMessage error={foodWasteMetrics.error} />
            ) : (
              <Fact
                value={createValueFormatter('co2', 'per-guest').format(
                  (co2Metric?.point as number) ?? 0,
                  co2Metric?.unit ?? 'g'
                )}
                isLoading={isLoading}
                title={<FormattedMessage id='report.sustainability.metrics.perGuest' />}
                icon={<Icon icon={guestsImage} />}
                dimension='co2'
              />
            )}
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  foodWasteMetrics: state.reportData[foodWasteMetricsID],
  filter: state.reportFilter
});

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, ReportActions>) => ({
  fetchData: (identifier: reportDispatch.ReportDataIdentifier) =>
    dispatch(reportDispatch.fetchData(identifier))
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalPerGuestMetric);
