import History from './History';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import * as React from 'react';
import { connect } from 'react-redux';
import * as registrationsDispatch from 'redux/ducks/data/registrations';
import {
  DataRegistrationActions,
  fetchRegistrations,
  FetchRegistrationsOptions,
  getRegistrations,
  IPatchObject
} from 'redux/ducks/data/registrations';
import moment from 'moment';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type HistoryContainerProps = StateProps & DispatchProps;

const FixedRangeQuery = (): FetchRegistrationsOptions => ({
  createdAt: {
    from: moment().subtract(1, 'year').startOf('day').toISOString(),
    to: moment().endOf('day').toISOString()
  }
});

const HistoryContainer: React.FunctionComponent<HistoryContainerProps> = ({
  fetchRegistrations,
  deleteRegistration,
  updateRegistration,
  loading,
  registrations,
  registrationPointsMap,
  lastSyncTimestamp,
  enableLockedRegistrations
}) => {
  // also whenever offline data changes
  React.useEffect(() => {
    void fetchRegistrations(FixedRangeQuery());
  }, [lastSyncTimestamp]);

  const handleDelete = (id: string) => {
    void deleteRegistration(id);
  };

  const handleUpdate = (id: string, patch: IPatchObject[]) => {
    void updateRegistration(id, patch);
  };

  return loading ? (
    <LoadingPlaceholder />
  ) : (
    <History
      registrations={registrations}
      deleteHandler={handleDelete}
      updateHandler={handleUpdate}
      registrationPointsMap={registrationPointsMap}
      lockedDays={enableLockedRegistrations}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const { data } = state;
  const { registrations, registrationPoints } = data;
  const loading =
    registrations.loading || registrationPoints.initializing || registrationPoints.treeInitializing;
  return {
    lastSyncTimestamp: state.offline.lastSyncTimestamp,
    loading,
    registrationPoints: state.data.registrationPoints.allNodes,
    registrations: getRegistrations(state),
    registrationPointsMap: state.data.registrationPoints.registrationPointsMap,
    enableLockedRegistrations: state.settings.enableLockedRegistrations
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, DataRegistrationActions>) => ({
  fetchRegistrations: (options?: FetchRegistrationsOptions) =>
    dispatch(fetchRegistrations(options)),
  deleteRegistration: (id: string) => dispatch(registrationsDispatch.remove(id)),
  updateRegistration: (id: string, patch: IPatchObject[]) =>
    dispatch(registrationsDispatch.update(id, patch))
});

export default connect(mapStateToProps, mapDispatchToProps)(HistoryContainer);
