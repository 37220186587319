import * as React from 'react';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { Bar } from 'pages/Report/components/Chart';
import { Options } from 'highcharts';
import { useIntl } from 'react-intl';
import ErrorMessage from 'pages/Report/components/ErrorMessage';
import { ApiError } from 'redux/ducks/error';

interface ComponentProps {
  chartOptions: Options;
  isLoading: boolean;
  error: ApiError;
}

const RegistrationsPerAccount: React.FunctionComponent<ComponentProps> = ({
  chartOptions,
  isLoading,
  error
}) => {
  const intl = useIntl();
  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({ id: 'report.frequency.registrationsPerAccount.title' })}
        // Temporarily disabled until text format is agreed on:
        // subheader={intl.formatMessage({ id: 'report.frequency.registrationsPerAccount.description' })}
      />
      <CardContent>
        {error ? (
          <ErrorMessage error={error} />
        ) : (
          <Grid container>
            <Grid item xs={12}>
              <Bar chartOptions={chartOptions} isLoading={isLoading} />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default RegistrationsPerAccount;
