import { IntlShape } from 'react-intl';
import { Column } from 'material-table';
import { TimeRange } from 'redux/ducks/reportFilter';
import { BenchmarkReport } from 'redux/ducks/reportFilter/selectors';
import { SheetFormat } from 'pages/Report/Advanced/utils/tableExport';
import { AdvancedReportType } from 'pages/Report/Advanced';
import { unformat } from 'utils/number-format';

export const createTableColumnConfig = (
  intl: IntlShape,
  { data }: { data: BenchmarkReport[] }
): // eslint-disable-next-line @typescript-eslint/ban-types
Column<BenchmarkReport>[] => [
  {
    title: intl.formatMessage({ id: 'account' }),
    field: 'accountName',
    cellStyle: { borderColor: 'rgba(0, 0, 0, 0.05)' }
  },
  ...getColumnsFromData(data),
  {
    title: intl.formatMessage({ id: 'total' }),
    field: 'total',
    customSort: (row1: BenchmarkReport, row2: BenchmarkReport) =>
      unformat(row1.total) - unformat(row2.total),
    headerStyle: { fontWeight: 'bold' },
    cellStyle: { borderColor: 'rgba(0, 0, 0, 0.05)', fontWeight: 'bold' }
  }
];

export const createExportConfig = (
  intl: IntlShape,
  { timeRange, type }: { timeRange: TimeRange; type: AdvancedReportType }
) => ({
  fileName: `Benchmark_${type === 'benchGuests' ? 'guest_registrations' : 'foodwaste'}_${
    timeRange.from
  }_${timeRange.to}`,
  sheetName: `Benchmark ${type === 'benchGuests' ? 'guest registrations' : 'foodwaste'}`,
  render: (headers: Column<BenchmarkReport>[], dataRow: BenchmarkReport): SheetFormat =>
    headers.reduce((all, header) => {
      const data = dataRow[header.field];

      if (data === undefined) {
        return all;
      }

      return { ...all, [header.title as string]: data };
    }, {} as { [title: string]: string | number })
});

const getColumnsFromData = (data: BenchmarkReport[]) => {
  const { accountName, total, tableData, ...rest } = data?.[0] || { tableData: {} };
  return Object.keys(rest).map((column) => ({
    title: column,
    field: column,
    customSort: (row1: BenchmarkReport, row2: BenchmarkReport) =>
      unformat(row1[column]) - unformat(row2[column]),
    cellStyle: { borderColor: 'rgba(0, 0, 0, 0.05)' }
  }));
};
