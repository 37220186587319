import { DataTransfer } from 'frontend-core';
import { AxiosResponse } from 'axios';
import moment, { Moment } from 'moment';
import * as errorDispatch from 'redux/ducks/error';
import { RegistrationsActionTypes, RegistrationsActions, RegistrationsState } from './types';
import { Registration } from 'redux/ducks/data/registrations';
import { ErrorActions } from 'redux/ducks/error/types';
import { ThunkResult } from 'redux/types';
import { createSelector } from 'reselect';
import { RootState } from 'redux/rootReducer';
import { groupBy } from 'utils/array';
import { API_DATE_FORMAT } from 'utils/datetime';

export * from './types';

const transfer = new DataTransfer();

export const initialState: RegistrationsState = {
  registrations: [],
  yearlyRegistrations: []
};

// TODO remove, pointless state slice
export default function reducer(
  state: RegistrationsState = initialState,
  action: RegistrationsActions
): RegistrationsState {
  switch (action.type) {
    case RegistrationsActionTypes.GET_REGISTRATIONS:
      return {
        ...state,
        registrations: action.payload
      };
    case RegistrationsActionTypes.GET_YEARLY_REGISTRATIONS:
      return {
        ...state,
        yearlyRegistrations: action.payload
      };
    default:
      return state;
  }
}

export function getRegistrations(
  start: Moment = moment(),
  end: Moment = moment()
): ThunkResult<Promise<RegistrationsActions | ErrorActions>, RegistrationsActions | ErrorActions> {
  return async (dispatch) => {
    const params = Object.assign(
      {},
      { startDate: start.format(API_DATE_FORMAT) },
      { endDate: end.format(API_DATE_FORMAT) }
    );
    try {
      const response = (await transfer.get('/foodwaste/registrations', {
        params
      })) as AxiosResponse<Registration[]>;
      return dispatch({
        type: RegistrationsActionTypes.GET_REGISTRATIONS,
        payload: response.data
      });
    } catch (error: unknown) {
      return dispatch(errorDispatch.displayError(error as Error));
    }
  };
}

export function getYearlyRegistrations(): ThunkResult<
  Promise<RegistrationsActions | ErrorActions>,
  RegistrationsActions | ErrorActions
> {
  return async (dispatch) => {
    // Fetch registrations from one year back
    const startOfMonth: Moment = moment().startOf('month');
    const startDate = startOfMonth.subtract(12, 'month').format(API_DATE_FORMAT);
    const endDate = moment().format(API_DATE_FORMAT);
    const params = Object.assign({}, { startDate }, { endDate });
    try {
      const response = (await transfer.get('/foodwaste/registrations', {
        params
      })) as AxiosResponse<Registration[]>;
      return dispatch({
        type: RegistrationsActionTypes.GET_YEARLY_REGISTRATIONS,
        payload: response.data
      });
    } catch (error: unknown) {
      return dispatch(errorDispatch.displayError(error as Error));
    }
  };
}

export function deleteRegistration(
  id: number | string
): ThunkResult<Promise<RegistrationsActions | ErrorActions>, RegistrationsActions | ErrorActions> {
  return async (dispatch) => {
    try {
      await transfer.delete(`/foodwaste/registrations/${id}`);
      return dispatch({
        type: RegistrationsActionTypes.DELETE_REGISTRATION
      });
    } catch (error: unknown) {
      return dispatch(errorDispatch.displayError(error as Error));
    }
  };
}

export const getRegistrationsByDate = createSelector(
  (state: RootState) => state.registrations.registrations,
  (registrations) => groupBy(registrations, 'date')
);

export const getYearlyRegistrationsByDate = createSelector(
  (state: RootState) => state.registrations.yearlyRegistrations,
  (registrations) => groupBy(registrations, 'date')
);
