import * as React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { IRecipient } from './types';
import { useIntl } from 'react-intl';

export interface RecipientProps {
  recipient: IRecipient;
  type: 'sms' | 'email';
  onEdit: (recipient: IRecipient) => void;
  onDelete: (recipient: IRecipient) => void;
}

export const Recipient: React.FC<RecipientProps> = (props) => {
  const intl = useIntl();
  const { recipient, type, onEdit, onDelete } = props;

  return (
    <TableRow className='recipientItem'>
      <TableCell
        title={intl.formatMessage({ id: 'edit' })}
        onClick={() => {
          onEdit(recipient);
        }}
      >
        {recipient.name}
      </TableCell>
      <TableCell
        title={intl.formatMessage({ id: 'edit' })}
        onClick={() => {
          onEdit(recipient);
        }}
      >
        {type == 'sms' ? '+' + recipient.value : recipient.value}
      </TableCell>
      <TableCell
        align='right'
        className='removeButton'
        title={intl.formatMessage({ id: 'data_table.delete' })}
        onClick={() => {
          onDelete(recipient);
        }}
      >
        <DeleteIcon />
      </TableCell>
    </TableRow>
  );
};
