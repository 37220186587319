import * as React from 'react';
import EmissionDistribution from './components/EmissionDistribution';
import EmissionOverview from './components/EmissionOverview';
import { PageTitle } from 'pages/Report/components/ReportPageLayout';
import { Grid } from '@material-ui/core';
import EmissionMetricsOverview from './components/EmissionMetrics';
import { useIntl } from 'react-intl';

interface ComponentProps {
  downloadButton: React.ReactNode;
}

const Sustainability: React.FunctionComponent<ComponentProps> = ({ downloadButton }) => {
  const intl = useIntl();

  return (
    <>
      <PageTitle>
        {intl.formatMessage({ id: 'report.sustainability.title' }, { ignoreTag: true })}
      </PageTitle>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <EmissionOverview />
        </Grid>
        <Grid item xs={12}>
          <EmissionDistribution />
        </Grid>
        <Grid item xs={12}>
          <EmissionMetricsOverview />
        </Grid>
      </Grid>
      {downloadButton}
    </>
  );
};

export default Sustainability;
