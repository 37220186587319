import { RootState } from 'redux/rootReducer';
import { getFeaturesFlags } from 'redux/ducks/features/selectors';
import { connect } from 'react-redux';
import { FlagsProvider as FlaggedFlagProvider } from 'flagged';
import * as React from 'react';

type StoreProps = ReturnType<typeof mapStateToProps>;
type FlagProviderProps = StoreProps;

// sort of quick fix to hoist the flag provider outside App as
// we have coupled features with redux store
const FlagProvider: React.FunctionComponent<FlagProviderProps> = ({ features, children }) => (
  <FlaggedFlagProvider features={features}>{children}</FlaggedFlagProvider>
);

const mapStateToProps = (state: RootState) => ({
  features: getFeaturesFlags(state)
});

export default connect<StoreProps, unknown, unknown>(mapStateToProps)(FlagProvider);
