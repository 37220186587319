import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';

interface ComponentProps {
  id: string;
  trend?: number;
  value?: string;
  target?: string;
}

export const ProgressText: React.FunctionComponent = ({ children }) => (
  <Typography color={'textSecondary'} display={'inline'} style={{ fontWeight: 900 }}>
    {children}
  </Typography>
);

const ProgressMessage: React.FunctionComponent<ComponentProps> = ({ id, trend, value, target }) => {
  const intl = useIntl();
  return (
    <FormattedMessage
      tagName='div'
      id={id}
      values={{
        trend: trend && (
          <ProgressText>
            {intl.formatNumber(Math.abs(trend), { maximumFractionDigits: 2 }) + '% '}
          </ProgressText>
        ),
        value,
        target
      }}
    />
  );
};

export default ProgressMessage;
