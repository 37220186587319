import { OfflineActionTypes, OfflineActions, OfflineState } from 'redux/ducks/offline/types';
import { ThunkResult } from 'redux/types';

export const initialState: OfflineState = {
  lastSyncTimestamp: undefined
};

export default function reducer(
  state: OfflineState = initialState,
  action: OfflineActions
): OfflineState {
  switch (action.type) {
    case OfflineActionTypes.SYNC_OFFLINE_DATA:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

export interface UpdateOfflineDataOptions {
  lastSyncTimestamp?: number;
}

export function syncOfflineData(
  options: UpdateOfflineDataOptions = {}
): ThunkResult<Promise<OfflineActions>, OfflineActions> {
  return async (dispatch, getState) => {
    const {
      offline: { lastSyncTimestamp }
    } = getState();
    const { lastSyncTimestamp: syncTimestamp } = options;

    return dispatch({
      type: OfflineActionTypes.SYNC_OFFLINE_DATA,
      payload: {
        lastSyncTimestamp: syncTimestamp || lastSyncTimestamp
      }
    });
  };
}
