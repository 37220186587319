export enum PopupActionTypes {
  GET_POPUPS = 'esmiley/popup/GET_POPUPS',
  SHOW_POPUP = 'esmiley/popup/SHOW_POPUP',
  CLOSE_POPUP = 'esmiley/popup/CLOSE_POPUP'
}

export interface AdPopup {
  id: number;
  type: 'popup' | 'modal';
  title: string;
  content: string;
  image: string;
  action: string;
  action_title: string;
  read_more_link?: string;
  open_module_selector?: boolean;
}

export interface PopupState {
  popups: AdPopup[];
  activePopup: AdPopup;
}

type GetPopupsAction = {
  type: typeof PopupActionTypes.GET_POPUPS;
  payload: AdPopup[];
};

type ShowPopupAction = {
  type: typeof PopupActionTypes.SHOW_POPUP;
  payload: AdPopup;
};

type ClosePopupAction = {
  type: typeof PopupActionTypes.CLOSE_POPUP;
  payload: {
    id: number;
  };
};

export type PopupActions = GetPopupsAction | ShowPopupAction | ClosePopupAction;
