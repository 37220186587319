const generateDataLabelPDF = (
  areaName: string,
  areaPercentage: string,
  areaAmount?: string
): string => {
  // reason why highcharts export html doesnt work is that it wraps the html inside foreignobject,
  // which wont be parsed by canvg lib, which we use to convert the svg to png.
  // foreignobject is not supported by IE browsers, so we cant use it.
  // without html, highcharts renders the label inside text node, so we can use tspan
  return `<tspan x=0 style="font-weight:bold">${areaName}</tspan><tspan dx="20">${areaPercentage}</tspan><tspan x=0 dy="20">${areaAmount}</tspan>`;
};

export default generateDataLabelPDF;
