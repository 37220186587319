import { IntlShape } from 'react-intl';

const getFactsHeadlines = (intl: IntlShape) => ({
  foodwasteCurrentPeriod: intl.formatMessage({ id: 'report.metric.total' }),
  foodwasteBestPeriod: intl.formatMessage({ id: 'report.metric.bestPeriod' }),
  foodwasteWorstPeriod: intl.formatMessage({ id: 'report.metric.worstPeriod' }),
  foodwasteAveragePeriod: intl.formatMessage({ id: 'report.metric.average' }),
  foodwastePerGuestCurrentPeriod: intl.formatMessage({ id: 'report.metric.total' }),
  foodwastePerGuestBestPeriod: intl.formatMessage({ id: 'report.metric.bestPeriod' }),
  foodwastePerGuestWorstPeriod: intl.formatMessage({ id: 'report.metric.worstPeriod' }),
  foodwastePerGuestAveragePeriod: intl.formatMessage({ id: 'report.metric.average' })
});

export default getFactsHeadlines;
