import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useAuth } from 'frontend-core';
import moment from 'moment';

const TIME_OUT_IN_MS = 30 * 1000; // 30sec
const HUMANIZE_THRESHOLDS = {
  h: 748, // use hour unit if duration is 31 days
  m: 240 // use minute unit if duration is 4h
};

export const OfflineHelp: React.FC = () => {
  const { getOfflineExpirationTimestamp } = useAuth();
  const [expiresIn, setExpiresIn] = React.useState(
    moment(getOfflineExpirationTimestamp()).diff(moment.now())
  );

  React.useEffect(() => {
    const timerId = setInterval(() => {
      setExpiresIn(moment(getOfflineExpirationTimestamp()).diff(moment.now()));
    }, TIME_OUT_IN_MS);

    return () => clearInterval(timerId);
  }, []);

  const formattedExpiresIn = moment.duration(expiresIn).humanize(true, HUMANIZE_THRESHOLDS);

  return (
    <div>
      <FormattedMessage tagName='p' id='menu.offlineHelp.content.intro' />
      <ul>
        <FormattedMessage tagName='li' id='menu.offlineHelp.content.listItem1' />
        <FormattedMessage tagName='li' id='menu.offlineHelp.content.listItem2' />
      </ul>
      <FormattedMessage
        tagName='p'
        id='menu.offlineHelp.content.offlineSessionLimit'
        values={{ expiresIn: <b>{formattedExpiresIn}</b> }}
      />
    </div>
  );
};
