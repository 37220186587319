import * as React from 'react';
import { Select, MenuItem } from '@material-ui/core';
import playSound from 'utils/playSound';
import { connect } from 'react-redux';
import { IntlShape, useIntl } from 'react-intl';
import * as mediaDispatch from 'redux/ducks/media';
import { RootState } from 'redux/rootReducer';
import { getSettings, SavedSettings, SettingsActions } from 'redux/ducks/settings';
import { ThunkDispatch } from 'redux-thunk';
import { Media, MediaActions } from 'redux/ducks/media';

import waveGif from 'static/sound_wave.gif';

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export interface OwnProps {
  updateSettings: (settings: Partial<SavedSettings>) => void;
  hideWave?: boolean;
}

export interface SoundSelectorState {
  chosenObject: null | Media;
  playbackStatus: boolean;
}

type SoundSelectorProps = StateProps & DispatchProps & OwnProps;

const SoundSelector: React.FC<SoundSelectorProps> = (props) => {
  const [state, setState] = React.useState<SoundSelectorState>({
    chosenObject: null,
    playbackStatus: false
  });
  const intl = useIntl();
  const { sounds, updateSettings, soundSettings, fetchNotificationSounds, hideWave } = props;

  const handleSound = (id) => {
    const value = sounds.find((s) => s.id === id);
    const newState = {
      chosenObject: value,
      playbackStatus:
        value == null ? false : !state.playbackStatus ? !state.playbackStatus : state.playbackStatus
    };

    setState(newState);

    const newValue = value
      ? { sound: { ...value, enabled: true } }
      : { sounds: { enabled: false } };
    if (value) {
      playSound(value.url, () => {
        setState((prev) => ({ ...prev, playbackStatus: !state.playbackStatus }));
      });
    }

    updateSettings(newValue);
  };

  React.useEffect(() => {
    void fetchNotificationSounds(intl);
  }, []);

  React.useEffect(() => {
    if (!soundSettings.name || sounds.length === 0) {
      setState((prev) => ({ ...prev, chosenObject: null }));
    }

    const sound = sounds.find((sound) => sound.id === soundSettings.id);

    setState((prev) => ({ ...prev, chosenObject: sound }));
  }, [soundSettings.name, sounds]);

  const { chosenObject, playbackStatus } = state;

  return (
    <div className='soundSelector'>
      <Select
        onChange={(event) => handleSound(event.target.value)}
        value={chosenObject ? chosenObject.id : ''}
      >
        <MenuItem key='0' value={''}>
          {intl.formatMessage({ id: 'settings.noSound' })}
        </MenuItem>
        {sounds.map((sound, index) => (
          <MenuItem key={index + 1} value={sound.id}>
            {sound.name}
          </MenuItem>
        ))}
      </Select>
      {!hideWave && (
        <img className={`${playbackStatus ? 'soundWave' : 'hiddenSoundwave'}`} src={waveGif} />
      )}
    </div>
  );
};
const mapStateToProps = (state: RootState) => ({
  soundSettings: getSettings(state).sound,
  sounds: state.media.soundList
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, void, SettingsActions | MediaActions>
) => ({
  fetchNotificationSounds: (intl: IntlShape) =>
    dispatch(mediaDispatch.fetchNotificationSounds(intl))
});

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(SoundSelector);
