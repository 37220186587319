import moment from 'moment';

const isRegistrationLockedForDate = (date: Date, lockedDays?: number): boolean => {
  if (!lockedDays) {
    return false;
  }
  if (moment(date).isSameOrAfter(new Date(), 'date')) {
    return false;
  }
  const lockedUntil = moment().subtract(lockedDays + 1, 'days');
  return moment(date).isAfter(lockedUntil);
};

export default isRegistrationLockedForDate;
