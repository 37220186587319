export enum ServiceWorkerActionTypes {
  REQUEST_INIT = 'esmiley/sw/request-init',
  SUCCESS_INIT = 'esmiley/sw/success-init',
  REQUEST_SYNC = 'esmiley/sw/request-sync',
  PENDING_SYNC = 'esmiley/sw/pending-sync',
  SUCCESS_SYNC = 'esmiley/sw/success-sync',
  SKIP_WAITING = 'esmiley/sw/skip-waiting',
  NETWORK_STATUS = 'esmiley/sw/network-status',
  RESET_OFFLINE_STORE = 'esmiley/sw/reset-offline-store'
}

interface NetworkStatusMessage {
  type: typeof ServiceWorkerActionTypes.NETWORK_STATUS;
  payload: boolean;
}

interface RequestInitMessage {
  type: typeof ServiceWorkerActionTypes.REQUEST_INIT;
  payload: { token: string };
}

interface SuccessInitMessage {
  type: typeof ServiceWorkerActionTypes.SUCCESS_INIT;
}

interface SuccessSyncMessage {
  type: typeof ServiceWorkerActionTypes.SUCCESS_SYNC;
  payload: number;
}

interface RequestSyncMessage {
  type: typeof ServiceWorkerActionTypes.REQUEST_SYNC;
  payload: { token: string };
}

interface PendingSyncMessage {
  type: typeof ServiceWorkerActionTypes.PENDING_SYNC;
}

interface SkipWaitingMessage {
  type: typeof ServiceWorkerActionTypes.SKIP_WAITING;
}

interface ResetOfflineStoreMessage {
  type: typeof ServiceWorkerActionTypes.RESET_OFFLINE_STORE;
}

export type ServiceWorkerMessage =
  | RequestInitMessage
  | SuccessInitMessage
  | SuccessSyncMessage
  | RequestSyncMessage
  | SkipWaitingMessage
  | NetworkStatusMessage
  | PendingSyncMessage
  | ResetOfflineStoreMessage;
