import { createSelectorCreator, defaultMemoize } from 'reselect';
import { RootState } from 'redux/rootReducer';
import isEqual from 'lodash/isEqual';
import { Subscription } from 'redux/ducks/user/types';
import moment from 'moment';

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);
const userSelector = (state: RootState): Subscription => state.user.subscription;

const expiredStarterSubscription = createDeepEqualSelector(
  userSelector,
  (subscription): boolean =>
    moment(subscription?.endDate).diff(Date.now(), 'days') <= 0 && subscription?.type === 'starter'
);

export { expiredStarterSubscription };
