import { createSelectorCreator, defaultMemoize } from 'reselect';
import { Labels, LABELS } from 'utils/labels';
import { AccountGroupFilter, Order, RegistrationPointNamesByLabel, ReportTree } from './types';
import { groupBy } from 'utils/array';
import { formatMoney, formatWeight } from 'utils/number-format';
import { RootState } from 'redux/rootReducer';

import isEqual from 'lodash/isEqual';
import { DashboardMetricId, StatusMetric } from 'redux/ducks/dashboard';
import { GuestRegistration } from 'redux/ducks/guestRegistrations';

const RankingLimitRegex = /^(bottom|top)([1-9]\d*)$/;
const MoreThanOneNumberRegex = /(\d+)/;

export type AvailabilityStatus = { name: string; enabled: boolean; available: boolean };

export type RegistrationPointsAvailabilityStatusByLabel = {
  [key in Labels]: AvailabilityStatus[];
};

export type MetricsByName = {
  [key in DashboardMetricId]: StatusMetric;
};

export type AccountData = { id: string; name: string; company?: string; isCurrentAccount: boolean };

// to be extended on refined design; eg ops all, current & manual (would have account ids directly as value)
export enum AccountQueryOp {
  bottom = 'bottom',
  top = 'top'
}

export interface AccountQuery {
  op: AccountQueryOp;
  value?: string;
}

export interface AccountPointFilterWithNames {
  order?: Order;
  availableAccounts: AccountData[];
  accounts: string[];
  accountQuery?: AccountQuery; // currently top/bottom only
  selectedRegistrationPoints: RegistrationPointNamesByLabel;
  availableRegistrationPoints: RegistrationPointsAvailabilityStatusByLabel;
}

export interface AdvancedFoodwasteReport {
  date: string;
  createdAt: string;
  updatedAt: string;
  guestAmount: number;
  amount: string;
  cost: string;
  co2: string;
  account: string;
  registrationPointName: string;
  registrationPointPath: string;
  comment?: string;
  reason?: string;
  type?: string;
  avoidable?: string;
  deletedAt?: string;
}

export type BenchmarkReport = {
  [key: string]: string;
  accountName: string;
  total: string;
};

const createSelector = createSelectorCreator(defaultMemoize, (currentVal, previousVal) => {
  // eslint-disable-next-line
  return previousVal === undefined || (previousVal as any).error || currentVal === previousVal;
});

const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const getAvailableByName = (
  availableInFilter: ReportTree[],
  selected: RegistrationPointNamesByLabel
): RegistrationPointsAvailabilityStatusByLabel => {
  // each bucket reflects the registration point filter item
  const buckets = {
    area: new Set(selected.area),
    category: new Set(selected.category),
    product: new Set(selected.product)
  };

  // which points can be selected,
  // eg selecting an area disables category/product points that are not inside that area
  const enabled = {
    area: new Set<string>(),
    category: new Set<string>(),
    product: new Set<string>()
  };

  const selection = {
    area: new Set(selected.area),
    category: new Set(selected.category),
    product: new Set(selected.product)
  } as const;

  // which points have registrations in the current query (based on time range, accounts)
  const available = {
    area: new Set<string>(),
    category: new Set<string>(),
    product: new Set<string>()
  } as const;

  availableInFilter.forEach(function visit(node) {
    if (node.registrations > 0) {
      available[node.label].add(node.name);
    }
    node.children.forEach(visit);
  });

  // selection algo (tag nodes enabled)
  // 1) parent node can always extend existing selection (area > category > product)
  // 2) if no parent selected, can extend selection (area | category | product)
  // 3) if parent selected, all child nodes out side of that parent are tagged disabled
  // 3.1) if children were first selected, parent may deselect them, if child not part of the parent tree

  function enableNode(node: ReportTree, parent?: ReportTree, parentEnabled?: boolean) {
    if (!parent) {
      // roots and areas are enabled by default
      enabled[node.label].add(node.name);
      // children see parents enabled only if the parents are selected
      return selection[node.label].size === 0 || selection[node.label].has(node.name);
    }

    if (
      // egde case when nested same labels (eg area -> area):
      // skip the current nested node
      parent.label === node.label &&
      enabled[parent.label].has(parent.name)
    ) {
      enabled[node.label].add(node.name);
      return parentEnabled || selection[node.label].has(node.name);
    }

    if (parentEnabled && selection[node.label].size > 0 && !selection[node.label].has(node.name)) {
      enabled[node.label].add(node.name);
      return false;
    }

    if (selection[node.label].has(node.name)) {
      enabled[node.label].add(node.name);
      return true;
    }

    if (parentEnabled) {
      enabled[node.label].add(node.name);
    }

    return parentEnabled;
  }

  function visit(node: ReportTree, parent?: ReportTree, parentEnabled?: boolean) {
    if (node.registrations === 0) {
      // filter out points that have no registrations
      return;
    }
    buckets[node.label].add(node.name);
    const enabled = enableNode(node, parent, parentEnabled);
    node.children.forEach((child) => visit(child, node, enabled));
  }

  availableInFilter.forEach((node) => visit(node, null));

  return LABELS.reduce(
    (all, label) => ({
      ...all,
      [label]: Array.from(buckets[label])
        .sort()
        .map((name) => ({
          name,
          available: available[label].has(name),
          enabled: enabled[label].has(name)
        }))
    }),
    {} as RegistrationPointsAvailabilityStatusByLabel
  );
};

const getSelectedAccounts = (
  selectedIds: string[],
  availableAccounts: AccountData[]
): AccountData[] => {
  const availableById: { [index: string]: AccountData[] } = groupBy(availableAccounts, 'id');
  // filter out query strings (not numbers as account ids are)
  return selectedIds
    .filter((id) => !isNaN(id as unknown as number) && !!availableById[id])
    .map((id) => availableById[id][0]);
};

export const getAvailableAccounts = createSelector(
  (state: RootState) => state.subAccounts.subscribed,
  (state: RootState) => state.user,
  (accounts = [], loggedInUser): AccountData[] => {
    const loggedInAccount = {
      id: loggedInUser.accountId,
      name: loggedInUser.customerName,
      // todo: name should equal to company?, remove company
      company: loggedInUser.name
    };

    const accountsById = [...accounts, loggedInAccount]
      // not sure if account id can be null, but there was a check for it
      .filter((account) => Boolean(account.id))
      .reduce(
        (byId, account) => ({
          ...byId,
          [account.id]: account
        }),
        {} as { [id: string]: AccountData }
      );

    return Object.keys(accountsById)
      .map((accountId) => ({
        ...accountsById[accountId],
        isCurrentAccount: accountId === loggedInAccount.id
      }))
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
  }
);

const getAccountQuery = (accounts: string[]): AccountQuery | null => {
  if (!accounts || accounts.length === 0 || !RankingLimitRegex.test(accounts[0])) {
    return null;
  }

  const queryString = accounts[0];
  const [maybeOp, value] = queryString.split(MoreThanOneNumberRegex);
  return AccountQueryOp[maybeOp] ? { op: AccountQueryOp[maybeOp as AccountQueryOp], value } : null;
};

export const getSelectedAccountNames = createDeepEqualSelector(
  (state: RootState) => state.reportFilter.filter.accounts,
  getAvailableAccounts,
  (selectedAccountIds, availableAccounts) => {
    return getSelectedAccounts(selectedAccountIds, availableAccounts).map(
      (account) => account.name
    );
  }
);

export const getSelectedAccountIds = createDeepEqualSelector(
  (state: RootState) => state.reportFilter.filter.accounts,
  (selectedAccountIds) => selectedAccountIds
);

export const getRegistrationPoints = createDeepEqualSelector(
  (state: RootState) => state.reportFilter.registrationPoints,
  (reportTrees) => reportTrees
);

// todo: either remove or replace with getCompareFilters[0]
export const getFilter = createDeepEqualSelector(
  (state: RootState) => state.reportFilter.filter,
  getAvailableAccounts,
  getSelectedAccountIds,
  (
    filter: AccountGroupFilter,
    availableAccounts: AccountData[],
    selectedAccountIds: string[]
  ): AccountPointFilterWithNames => ({
    availableAccounts,
    order: filter.order,
    accounts: selectedAccountIds,
    accountQuery: getAccountQuery(filter.accounts),
    selectedRegistrationPoints: filter.selectedRegistrationPoints,
    availableRegistrationPoints: getAvailableByName(filter.trees, filter.selectedRegistrationPoints)
  })
);

export const getCompareToFilters = createDeepEqualSelector(
  (state: RootState) => state.reportFilter.accountGroups,
  getAvailableAccounts,
  (comparisonFilters, availableAccounts): AccountPointFilterWithNames[] =>
    comparisonFilters.map(({ order, accounts, selectedRegistrationPoints, trees }) => ({
      order,
      availableAccounts,
      accounts,
      accountQuery: getAccountQuery(accounts),
      selectedRegistrationPoints: selectedRegistrationPoints,
      availableRegistrationPoints: getAvailableByName(trees, selectedRegistrationPoints)
    }))
);

export const getTimeFilter = createDeepEqualSelector(
  (state: RootState) => state.reportFilter.timeRange,
  (state: RootState) => state.reportFilter.period,
  (timeRange, period) => ({ ...timeRange, period })
);

export const getRegistrations = createDeepEqualSelector(
  (state: RootState) => state.reportData.registrations,
  (state: RootState) => state.reportData.guestRegistrations,
  getAvailableAccounts,
  (registrations, guests, accounts): AdvancedFoodwasteReport[] => {
    const guestsRegistrations = formatGuestsData(guests.data, accounts);

    const foodwasteRegistrations = registrations.data.map((r) => ({
      comment: r.comment,
      date: r.date,
      createdAt: r.createdAt,
      updatedAt: r.updatedAt,
      deletedAt: r.deletedAt,
      guestAmount: null,
      amount: formatWeight(r.amount, false, 'kg'),
      cost: formatMoney(r.cost).toString(),
      co2: r.co2 ? formatWeight(r.co2, false, 'kg') : '-',
      account: accounts.find((account) => account.id === r.accountId)?.name,
      registrationPointName: r.registrationPoint.name,
      registrationPointPath: [
        ...(r.registrationPoint.namePath || []),
        r.registrationPoint.name
      ].join(' -> '),
      reason: r.reason,
      avoidable: r.avoidable && r.avoidable.charAt(0).toUpperCase() + r.avoidable.slice(1),
      type: 'Foodwaste'
    }));

    return [...foodwasteRegistrations, ...guestsRegistrations].sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
  }
);

const formatGuestsData = (
  guests: GuestRegistration[],
  accounts: AccountData[]
): AdvancedFoodwasteReport[] => {
  return guests
    .filter((g) => accounts.some((a) => a.id === g.accountId))
    .map((guest) => {
      const { amount, accountId, guestType } = guest;
      return {
        ...guest,
        createdAt: guest.createdAt,
        deletedAt: guest.deletedAt,
        updatedAt: guest.updatedAt,
        guestAmount: amount,
        amount: undefined,
        cost: undefined,
        co2: undefined,
        account: accounts.find((account) => account.id === accountId).name,
        registrationPointName: undefined,
        registrationPointPath: guestType ? guestType.name : undefined,
        comment: undefined,
        type: 'Guest'
      };
    });
};

export const getGuestTypeNames = createDeepEqualSelector(
  (state: RootState) => state.reportFilter.guestTypes,
  (guestTypes) => guestTypes || []
);

const reportDashboardMetricIds: Set<DashboardMetricId> = new Set([
  'co2_waste',
  'per_guest_saved',
  'per_guest_avoidable'
]);

export const getReportDashboardMetrics = createDeepEqualSelector(
  (state: RootState) => state.dashboard.metrics,
  (metrics) => metrics.filter((metric) => reportDashboardMetricIds.has(metric.id))
);

const sustainabilityReportMetricIds: Set<DashboardMetricId> = new Set([
  'co2_waste',
  'per_guest_saved'
]);

const formatByMetricName = (metrics: StatusMetric[]): MetricsByName => {
  return metrics.reduce((metricsByName, metric) => {
    return { ...metricsByName, [metric.id]: metric };
  }, {} as MetricsByName);
};

export const getSustainabilityDashboardMetrics = createDeepEqualSelector(
  (state: RootState) => state.dashboard.metrics,
  (metrics) => {
    const filteredMetrics = metrics.filter((metric) =>
      sustainabilityReportMetricIds.has(metric.id)
    );
    return formatByMetricName(filteredMetrics);
  }
);
