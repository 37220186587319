import History, { HistoryColumn } from './History';
import LoadingPlaceholder from 'components/LoadingPlaceholder';
import * as React from 'react';
import { connect } from 'react-redux';
import {
  GuestRegistrationActions,
  GuestRegistrationQuery
} from 'redux/ducks/guestRegistrations/types';
import * as actions from 'redux/ducks/guestRegistrations/';
import { useEffect } from 'react';
import { getHistoryRegistrations } from 'redux/ducks/guestRegistrations/selectors';
import moment from 'moment';
import { getHasGuestTypesEnabled } from 'redux/ducks/settings/selectors';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { IPatchObject } from 'redux/ducks/guestRegistrations/';

type StoreProps = ReturnType<typeof mapState>;
type DispatchProps = ReturnType<typeof mapDispatch>;
type HistoryContainerProps = StoreProps & DispatchProps;

const FixedRangeQuery = (): GuestRegistrationQuery => ({
  createdAt: {
    from: moment().subtract(1, 'year').startOf('day').toISOString(),
    to: moment().endOf('day').toISOString()
  }
});

const HistoryColumns: HistoryColumn[] = [
  { property: 'date', translationKey: 'date' },
  { property: 'guestType', translationKey: 'settings.guestType.one' },
  { property: 'amount', translationKey: 'guestAmount', alignRight: true, numeric: true }
];

const HistoryContainer: React.FunctionComponent<HistoryContainerProps> = (props) => {
  const {
    getRegistrations,
    deleteRegistration,
    updateRegistration,
    loading,
    registrations,
    hasGuestTypesEnabled,
    lastSyncTimestamp,
    enableLockedRegistrations
  } = props;
  const columns =
    hasGuestTypesEnabled === true
      ? HistoryColumns
      : HistoryColumns.filter((column) => column.property !== 'guestType');

  useEffect(() => {
    void getRegistrations(FixedRangeQuery());
  }, [lastSyncTimestamp]);

  const handleDelete = (id: string) => {
    void deleteRegistration(id);
  };

  const handleUpdate = (id: string, patch: IPatchObject[]) => {
    void updateRegistration(id, patch);
  };

  return loading ? (
    <LoadingPlaceholder />
  ) : (
    <History
      columns={columns}
      registrations={registrations}
      onDelete={handleDelete}
      onUpdate={handleUpdate}
      lockedDays={enableLockedRegistrations}
    />
  );
};

const mapState = (state: RootState) => ({
  hasGuestTypesEnabled: getHasGuestTypesEnabled(state),
  loading: state.guestRegistrations.loading,
  registrations: getHistoryRegistrations(state),
  lastSyncTimestamp: state.offline.lastSyncTimestamp,
  enableLockedRegistrations: state.settings.enableLockedRegistrations
});

const mapDispatch = (dispatch: ThunkDispatch<RootState, void, GuestRegistrationActions>) => ({
  getRegistrations: (query?: GuestRegistrationQuery) => dispatch(actions.getAll(query)),
  deleteRegistration: (id: string) => dispatch(actions.deleteById(id)),
  updateRegistration: (id: string, patch: IPatchObject[]) => dispatch(actions.update(id, patch))
});

export default connect<StoreProps, DispatchProps, unknown>(mapState, mapDispatch)(HistoryContainer);
