import * as React from 'react';
import { Avatar as AvatarMui } from '@material-ui/core';
import esmileylogo from '../../../static/icons/esmiley-logo-mobile.png';

export interface AvatarProps {
  className?: string;
}

export const Avatar: React.FunctionComponent<AvatarProps> = (props) => {
  const { className } = props;

  return <AvatarMui className={className} src={esmileylogo} />;
};
