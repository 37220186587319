import * as React from 'react';
import Dashboard from 'pages/Dashboard/DashboardView';
import { Feature } from 'flagged';
import StarterPlanDashboard from 'pages/Dashboard/StarterPlanDashboard';
import { withOffline } from 'components/WithOffline';

const DashboardContainer: React.FunctionComponent = () => {
  return (
    <>
      <Feature name='dashboard/free'>
        <StarterPlanDashboard />
      </Feature>
      <Feature name='dashboard/full'>
        <Dashboard />
      </Feature>
      <Feature name='dashboard/view'>
        <StarterPlanDashboard />
      </Feature>
    </>
  );
};

export default withOffline(DashboardContainer);
