import * as React from 'react';
import { makeStyles } from '@material-ui/styles';
import { useIntl } from 'react-intl';
import { Button } from '@material-ui/core';
import { ReportActions } from 'redux/ducks/reportFilter';
import { RootState } from 'redux/rootReducer';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { resetFilter as resetFilterAction } from 'redux/ducks/reportFilter';

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type ReportErrorProps = DispatchProps;

const useStyles = makeStyles({
  header: {
    color: 'black'
  }
});

// todo:
//  add proper error handling logic / styling
//  eg undo last change instead of resetting filter, showing what caused the error

const ReportError: React.FunctionComponent<ReportErrorProps> = ({ resetFilter }) => {
  const classes = useStyles();
  const intl = useIntl();

  const handleResetFilter = () => {
    void resetFilter();
  };

  return (
    <div>
      <h3 className={classes.header}>{intl.formatMessage({ id: 'error.headline' })}</h3>
      <Button onClick={handleResetFilter}>
        {intl.formatMessage({ id: 'report.resetFilter' })}
      </Button>
    </div>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, void, ReportActions>) => ({
  resetFilter: () => dispatch(resetFilterAction())
});

export default connect(null, mapDispatchToProps)(ReportError);
