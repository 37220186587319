import * as React from 'react';
import { Grid } from '@material-ui/core';

import EmissionMetrics from 'pages/Report/Sustainability/components/EmissionMetrics/DashboardMetrics';
import TotalGuestMetric from 'pages/Report/QuickOverview/components/OverviewMetrics/TotalGuestMetric';

const EmissionMetricsOverview: React.FunctionComponent = () => {
  return (
    <Grid container alignContent={'flex-start'} justify={'space-around'} spacing={3}>
      <EmissionMetrics />
      <TotalGuestMetric />
    </Grid>
  );
};

export default EmissionMetricsOverview;
