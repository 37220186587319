import * as React from 'react';

type Refs<TType> =
  | React.MutableRefObject<TType>
  | ((arg: TType) => void)
  | React.Dispatch<React.SetStateAction<TType>>;

export const useCombineRefs = <TElement>(...refs: Refs<TElement>[]) => {
  const originalRef = React.useRef();

  React.useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) {
        return;
      }

      if (typeof ref === 'function') {
        ref(originalRef.current);
        return;
      }

      ref.current = originalRef.current;
    });
  }, [refs]);

  return originalRef;
};
