import UserFoldout from 'components/SidebarMenu/userfoldout';

import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { useIntl } from 'react-intl';

import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssessmentIcon from '@material-ui/icons/Assessment';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import DollarIcon from '@material-ui/icons/AttachMoney';
import PersonIcon from '@material-ui/icons/Person';
import GuestIcon from 'components/icons/GuestIcon';
import ScaleIcon from 'components/icons/ScaleIcon';
import PinIcon from 'components/icons/PinIcon';
import {
  ClickAwayListener,
  createStyles,
  Divider,
  Drawer,
  MenuItem,
  Theme,
  WithStyles,
  withStyles
} from '@material-ui/core';
import { MenuLink, MenuLinkProps } from 'components/SidebarMenu/MenuLink';
import LanguageSwitcher from 'components/LanguageSwitcher';
import * as uiDispatch from 'redux/ducks/ui';
import classNames from 'classnames';
import { SidebarMenu } from 'styles/themes/global';
import { RootState } from 'redux/rootReducer';
import { ThunkDispatch } from 'redux-thunk';
import { Modal, UiActions } from 'redux/ducks/ui';
import SalesDialog from 'components/modalContent/salesDialog';
import { getSettings, changeLocale } from 'redux/ducks/settings';
import StepAnchor from 'components/Tutorial/StepAnchor';
import TutorialPopper from 'components/Tutorial/TutorialPopper';
import { useAuth, useNetworkStatus } from 'frontend-core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MobileMenu from './MobileMenu';
import { useFeature } from 'flagged';
import { Wifi, WifiOff } from '@material-ui/icons';
import { useServiceWorker } from 'context/ServiceWorkerContext';
import { OfflineHelp } from 'components/SidebarMenu/OfflineHelp';

const NoOp = () => {
  /* no op */
};
const ReportPathRegex = /^\/report\b/;
const styles = (theme: Theme) =>
  createStyles({
    paper: {
      overflow: 'hidden',
      width: SidebarMenu.collapsedWith,
      transition:
        'width 0.2s cubic-bezier(0.23, 1, 0.32, 1), box-shadow 0.2s cubic-bezier(0.23, 1, 0.32, 1)',
      backgroundColor: '#fafafa',
      zIndex: 1000,
      border: 'none',
      '& .MuiDivider-root': {
        margin: `${theme.spacing(1.5)}px 0`
      }
    },
    paperOpen: {
      zIndex: 1300,
      width: (props: MenuProps) =>
        props.isMobile ? SidebarMenu.mobileWidth : SidebarMenu.desktopWidth,
      boxShadow: 'rgba(0, 0, 0, 0.26) 0px 2px 8px, rgba(0, 0, 0, 0.33) 0px 2px 8px',
      [theme.breakpoints.up('lg')]: {
        width: SidebarMenu.desktopWidth
      }
    },
    paperShadowSmall: {
      boxShadow: '1px 0 2px 0 rgba(0, 0, 0, 0.16)'
    },
    pin: {
      transition: 'transform 0.25s ease-in-out'
    },
    unPinned: {
      transform: 'rotate(45deg)'
    },
    secondaryMenu: {
      bottom: 0,
      position: 'absolute',
      width: '100%',
      paddingBottom: '16px',
      '@media (max-height:540px)': {
        position: 'initial',
        display: 'block',
        paddingBottom: '100px'
      }
    },
    backdrop: {
      zIndex: 1500
    },
    menuIcon: {
      width: SidebarMenu.collapsedWith,
      justifyContent: 'center',
      display: 'flex',
      '& img': {
        margin: 0
      }
    },
    menuText: {
      fontSize: '0.875rem',
      fontWeight: 'bold',
      paddingRight: theme.spacing(2)
    },
    buttonsContainer: {
      overflowY: 'auto'
    }
  });

type TMenuElement = {
  element: JSX.Element;
  only: 'collapsed' | 'expanded';
  disabled?: boolean;
};

interface OwnProps {
  isMobile: boolean;
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

// All the component's props
export interface MenuProps
  extends WithRouterProps,
    WithStyles<typeof styles>,
    OwnProps,
    StateProps,
    DispatchProps {}

type ExtendedMenuLink = Partial<MenuLinkProps> & {
  only?: 'expanded' | 'collapsed';
  disabled?: boolean;
};
type MenuItem = TMenuElement | ExtendedMenuLink;

const helpUrl = window['sysvars'].HELP_URL;

const isTMenuElement = (item: MenuItem): item is TMenuElement => {
  return (item as TMenuElement).element !== undefined;
};

const isTMenuLink = (item: MenuItem): item is ExtendedMenuLink => {
  return (item as TMenuElement).element === undefined;
};

export const Menu: React.FunctionComponent<MenuProps> = (props) => {
  const intl = useIntl();
  const {
    enableGuestRegistrationFlow,
    isMenuOpen,
    isMobile,
    hideMenu,
    toggleMenu,
    isMenuPinned,
    classes,
    openModal,
    subscription,
    changeLocale,
    togglePinning,
    level,
    locale,
    location
  } = props;

  const isStarterOrEssential = subscription.type === 'starter' || subscription.type === 'essential';
  const { logout } = useAuth();
  const { online } = useNetworkStatus();
  const { active: serviceWorkerIsActive } = useServiceWorker();
  const hasSettings = useFeature('settings');
  const hasSales = useFeature('sales/full');
  const hasProject = useFeature('project/full');
  const isDesktop = useMediaQuery('(min-width: 480px)', { noSsr: true });

  React.useEffect(() => {
    if (!isDesktop && isMenuOpen) {
      toggleMenu();
    }
  }, [isDesktop, isMenuOpen]);

  const handleLogout = () => {
    if (!online) {
      return;
    }
    void logout({ locale });
  };

  const handleMenuItemClick = () => {
    if (!isMenuPinned && isMenuOpen) {
      hideMenu();
    }
  };

  const handleShowSales = (e: React.MouseEvent) => {
    e.preventDefault();

    openModal({
      content: <SalesDialog />,
      title: intl.formatMessage({ id: 'sales.dialog.headline' })
    });

    handleMenuItemClick();
  };

  const buildLinks = (
    props: MenuProps
  ): { mainMenuItems: MenuItem[]; secondaryMenuItems: MenuItem[] } => ({
    mainMenuItems: [
      {
        path: '/',
        text: intl.formatMessage({ id: 'dashboard.headline' }),
        icon: <HomeIcon />,
        disabled: !online
      },
      {
        path: '/registration',
        text: intl.formatMessage({ id: 'measureWaste' }),
        icon: <ScaleIcon />
      },
      {
        // todo move guest registration to a feature toggle
        path:
          isStarterOrEssential || enableGuestRegistrationFlow ? '/guest-registration' : undefined,
        href: isStarterOrEssential || enableGuestRegistrationFlow ? undefined : '#',
        onClick: isStarterOrEssential || enableGuestRegistrationFlow ? undefined : handleShowSales,
        text: intl.formatMessage({ id: 'registerGuests' }),
        icon: <GuestIcon />
      },
      hasSales && {
        href: '#',
        onClick: handleShowSales,
        text: intl.formatMessage({ id: 'report.sales_tab' }),
        icon: <DollarIcon />,
        disabled: !online
      },
      {
        element: <Divider key='divider-1' />,
        only: 'expanded'
      },
      hasProject && {
        path: '/project',
        text: intl.formatMessage({ id: 'project.dialog.project' }),
        icon: <InsertDriveFileIcon />,
        disabled: !online
      },
      {
        path: '/report',
        text: intl.formatMessage({ id: 'report.headline' }),
        icon: <AssessmentIcon />,
        disabled: !online
      },
      hasSettings && {
        path: '/settings',
        text: intl.formatMessage({ id: 'settings.headline' }),
        icon: <SettingsIcon />,
        disabled: !online
      },
      {
        path: '/account',
        text: intl.formatMessage({ id: 'account' }),
        icon: <PersonIcon />,
        disabled: !online
      },
      {
        element: <Divider key='divider-2' />,
        only: 'expanded'
      },
      {
        element: props.isMobile ? (
          <div key='sidebar-language-switch'>
            <LanguageSwitcher
              classes={{ icon: props.classes.menuIcon, text: props.classes.menuText }}
              onChange={changeLocale}
            />
          </div>
        ) : null,
        only: 'expanded'
      },
      {
        href: helpUrl,
        target: '_BLANK',
        text: intl.formatMessage({ id: 'help' }),
        icon: <HelpOutlineIcon />,
        only: 'expanded',
        disabled: !online
      },
      {
        href: '#',
        onClick: handleLogout,
        text: intl.formatMessage({ id: 'auth.sign_out' }),
        icon: <ExitToAppIcon />,
        only: 'expanded',
        disabled: !online
      },
      {
        element: <Divider key='divider-3' />,
        only: 'expanded'
      },
      {
        href: '#',
        onClick: (e: React.MouseEvent) => {
          e.preventDefault();
          togglePinning();
        },
        text: intl.formatMessage({ id: 'menu.pin_menu' }),
        icon: (
          <PinIcon className={classNames(classes.pin, { [classes.unPinned]: !isMenuPinned })} />
        ),
        isActive: isMenuPinned,
        only: 'expanded'
      }
    ].filter(Boolean) as MenuItem[],
    secondaryMenuItems: [
      {
        href: helpUrl,
        target: '_BLANK',
        icon: <HelpOutlineIcon />,
        disabled: !online,
        only: 'collapsed'
      },
      {
        href: '#',
        onClick: handleLogout,
        icon: <ExitToAppIcon />,
        disabled: !online,
        only: 'collapsed'
      },
      serviceWorkerIsActive
        ? {
            icon: online ? <Wifi /> : <WifiOff />,
            isActive: online,
            helper: {
              title: intl.formatMessage({ id: 'menu.offlineHelp.title' }),
              content: <OfflineHelp />
            },
            text: intl.formatMessage({ id: online ? 'menu.online' : 'menu.offline' })
          }
        : null
    ].filter(Boolean) as MenuItem[]
  });

  const renderMenuItems = (menuItems: MenuItem[]): JSX.Element[] => {
    return menuItems
      .filter(
        (i) =>
          !i.only ||
          (i.only === 'expanded' && isMenuOpen) ||
          (i.only === 'collapsed' && !isMenuOpen)
      )
      .map(({ only, ...item }: MenuItem, index: number) => {
        if (isTMenuElement(item)) {
          return item.element;
        }

        if (isTMenuLink(item)) {
          const LinkComponent =
            item.path === '/settings' && level < 4 ? null : (
              <MenuLink
                key={`item_${index}`}
                {...item}
                // isActive is true/false based on whether the URL we're on is equal to the menu link's path,
                //          OR if the URL starts with the link's path, to test if we're on sub-pages
                isActive={
                  item.isActive ||
                  (item.path &&
                    (item.path === '/'
                      ? location.pathname === item.path
                      : location.pathname.startsWith(item.path.slice(1))))
                }
                onClick={item.onClick || handleMenuItemClick}
                hideText={!isMenuOpen}
              />
            );

          // probably could centralize available tutorial configs
          if (item.path === '/registration') {
            return (
              <StepAnchor
                key={`StepAnchor_item_${index}`}
                tutorialId={'tutorial-registration-foodwaste'}
                step={1}
              >
                {LinkComponent}
              </StepAnchor>
            );
          }

          if (item.path === '/guest-registration') {
            return (
              <StepAnchor
                key={`StepAnchor_item_${index}`}
                tutorialId={'tutorial-registration-guest'}
                step={1}
              >
                {LinkComponent}
              </StepAnchor>
            );
          }

          if (item.path === '/settings' && level > 3) {
            return (
              <StepAnchor key={`StepAnchor_item_${index}`} tutorialId={'tutorial-targets'} step={1}>
                {LinkComponent}
              </StepAnchor>
            );
          }

          return LinkComponent;
        }
      });
  };

  const { mainMenuItems, secondaryMenuItems } = React.useMemo(
    () => buildLinks(props),
    [props, online, serviceWorkerIsActive]
  );

  const paperClass = classNames('sidebar-menu', classes.paper, {
    [classes.paperOpen]: isMenuOpen,
    [classes.paperShadowSmall]:
      (isMenuPinned && isMenuOpen) || (ReportPathRegex.test(location.pathname) && !isMenuOpen)
  });

  const disableClickAway = isMobile || isMenuPinned || !isMenuOpen;

  if (!isDesktop) {
    return (
      <MobileMenu
        enableGuestRegistrationFlow={enableGuestRegistrationFlow}
        handleLogout={handleLogout}
        handleShowSales={handleShowSales}
        helpUrl={helpUrl}
      />
    );
  }

  return (
    <ClickAwayListener
      mouseEvent={disableClickAway ? false : 'onClick'}
      touchEvent={disableClickAway ? false : 'onTouchEnd'}
      onClickAway={disableClickAway ? NoOp : hideMenu}
    >
      <Drawer
        PaperProps={{ className: classNames(paperClass) }}
        onChange={toggleMenu}
        variant={isMobile ? 'temporary' : 'permanent'}
        open={isMobile ? isMenuOpen : false}
      >
        <UserFoldout menuHandler={toggleMenu} />
        <div className={classes.buttonsContainer}>
          {renderMenuItems(mainMenuItems)}
          <div className={classes.secondaryMenu}>{renderMenuItems(secondaryMenuItems)}</div>
        </div>
        <TutorialPopper /* here because of sidebar menu's high z-index */ />
      </Drawer>
    </ClickAwayListener>
  );
};

const mapStateToProps = (state: RootState, props: OwnProps) => ({
  isMenuPinned: state.ui.isMenuPinned && !props.isMobile,
  isMenuOpen: state.ui.isMenuOpen,
  enableGuestRegistrationFlow: getSettings(state).enableGuestRegistrationFlow,
  level: state.user.level,
  subscription: state.user.subscription,
  locale: state.settings.locale
});

const mapDispatchToProps = (dispatch: ThunkDispatch<unknown, unknown, UiActions>) => ({
  togglePinning: () => dispatch(uiDispatch.toggleMenuPinning()),
  changeLocale: (locale: string) => dispatch(changeLocale(locale)),
  hideMenu: () => dispatch(uiDispatch.hideMenu()),
  toggleMenu: () => dispatch(uiDispatch.toggleMenu()),
  openModal: (modal: Modal) => {
    dispatch(uiDispatch.showModal(modal));
  }
});

export default connect<StateProps, DispatchProps, OwnProps>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(Menu)));
