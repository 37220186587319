import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { getMessagesState } from './selectors';
import { RootState } from 'redux/rootReducer';
import * as React from 'react';
import moment from 'moment';
import * as Highcharts from 'highcharts';
import { setFormatting } from 'utils/number-format';

function mapStateToProps(state: RootState) {
  const { locale, currency, unit } = state.settings;

  return {
    locale: locale === 'phraseapp' ? 'en' : locale,
    currency,
    unit,
    key: locale,
    // eslint-disable-next-line
    messages: getMessagesState(state)
  };
}

type StateProps = ReturnType<typeof mapStateToProps>;

const InternalIntlProvider: React.FC<StateProps> = (props) => {
  const { locale, currency, unit, children, ...intProps } = props;

  React.useEffect(() => {
    moment.locale(locale);
    Highcharts.setOptions({
      lang: {
        months: moment.months(),
        weekdays: moment.weekdays(),
        shortMonths: moment.monthsShort()
      }
    });
    setFormatting(locale, { currency, unit });
  }, [locale, currency, unit]);

  // textComponent just for backward compat, by default it's Fragment
  return (
    <IntlProvider textComponent='span' locale={locale} {...intProps}>
      {children}
    </IntlProvider>
  );
};
export default connect<StateProps, any, any>(mapStateToProps)(InternalIntlProvider);
