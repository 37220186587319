import * as React from 'react';
import { Input, SvgIcon, TextFieldProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DateRange } from '@material-ui/icons';

export const CustomTimePicker: React.FunctionComponent<TextFieldProps> = (props) => {
  const classes = styles(props);

  return (
    <Input
      startAdornment={
        <SvgIcon style={{ color: 'rgb(0, 150, 136)', marginRight: '7.5px' }}>
          <DateRange />
        </SvgIcon>
      }
      disableUnderline
      type='text'
      onClick={props.onClick}
      value={props.value}
      onChange={props.onChange}
      className={classes.root}
    />
  );
};

const styles = makeStyles({
  root: {
    fontWeight: 'bold',
    border: '1px solid rgb(0, 150, 136)',
    borderRadius: '5px',
    color: 'rgb(0, 150, 136)',
    padding: '5px 10px',
    justifyContent: 'center',
    alignItems: 'center'
  }
});
