import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { CardHeader, Card, CardContent, Typography } from '@material-ui/core';
import ErrorMessage from 'pages/Report/components/ErrorMessage';
import { ReportChart, SeriesResponse } from 'redux/ducks/reportData';
import donutChartOptions from 'pages/Report/components/Chart/Donut/options';
import { Donut } from 'pages/Report/components/Chart';

interface ComponentProps {
  chartsData: ReportChart<SeriesResponse>;
  chartColors: Array<string>;
}

const EmissionDistribution: React.FunctionComponent<ComponentProps> = (props) => {
  const { chartsData, chartColors } = props;
  const {
    isLoading,
    data: {
      series: [totalSeries, ratioSeries]
    },
    error
  } = chartsData;

  const { aggregates = { total: 0 } } = totalSeries || {};

  return error ? (
    <ErrorMessage error={error} />
  ) : (
    <Card>
      <CardHeader
        title={
          <FormattedMessage
            id='report.sustainability.distribution.title'
            values={{ sub: (chunks) => <sub>{chunks}</sub> }}
          />
        }
      />
      <CardContent>
        <Typography variant='body1' paragraph>
          <FormattedMessage id='report.sustainability.distribution.description' />
        </Typography>
        <Donut
          options={{
            colors: chartColors,
            total: aggregates.total
          }}
          seriesData={ratioSeries || []}
          chartOptions={donutChartOptions}
          isLoading={isLoading}
        />
      </CardContent>
    </Card>
  );
};

export default EmissionDistribution;
