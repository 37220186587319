import getChartPNG, { ChartImage } from 'pages/Report/utils/getChartPNG';
import { ChartRefs } from 'redux/ducks/charts';

export type GetChartPNGsProps = {
  chartRefs: ChartRefs;
};

export function getChartPNGs(props: GetChartPNGsProps): Promise<ChartImage[][]> {
  const { chartRefs } = props;
  return Promise.all<ChartImage[]>([
    getChartPNG({
      chartRefs,
      type: 'donut',
      sourceWidth: 850,
      options: {
        title: {
          style: {
            fontSize: '23px'
          }
        },
        plotOptions: {
          pie: {
            dataLabels: {
              y: -10
            }
          }
        }
      }
    }),
    getChartPNG({
      chartRefs,
      type: 'line',
      sourceWidth: 1000
    })
  ]);
}
