import { DataTransfer } from 'frontend-core';
import { AxiosResponse } from 'axios';
import * as errorDispatch from 'redux/ducks/error';
import { ErrorActions } from 'redux/ducks/error/types';
import { ThunkResult } from 'redux/types';
import {
  FunFact,
  FunFactsActions,
  FunFactsActionTypes,
  FunFactsState
} from 'redux/ducks/funFacts/types';
import moment from 'moment';
import { API_DATE_FORMAT } from 'utils/datetime';

export * from './types';

const transfer = new DataTransfer();

export const initialState: FunFactsState = {
  funFacts: [],
  status: undefined
};

// TODO remove, pointless state slice
export default function reducer(
  state: FunFactsState = initialState,
  action: FunFactsActions
): FunFactsState {
  switch (action.type) {
    case FunFactsActionTypes.GET_FUN_FACTS:
      return {
        ...state,
        funFacts: action.payload,
        status: 'ready'
      };
    case FunFactsActionTypes.SET_FUN_FACTS_STATUS:
      return {
        ...state,
        status: action.payload
      };
    default:
      return state;
  }
}

export function getFunFacts(): ThunkResult<
  Promise<FunFactsActions | ErrorActions>,
  FunFactsActions | ErrorActions
> {
  return async (dispatch) => {
    try {
      dispatch({
        type: FunFactsActionTypes.SET_FUN_FACTS_STATUS,
        payload: 'loading'
      });
      const timeRange = {
        from: moment().subtract(30, 'days').format(API_DATE_FORMAT),
        to: moment().format(API_DATE_FORMAT)
      };

      const response = (await transfer.get(
        `/foodwaste/fun-facts?from=${timeRange.from}&to=${timeRange.to}`
      )) as AxiosResponse<FunFact[]>;

      return dispatch({
        type: FunFactsActionTypes.GET_FUN_FACTS,
        payload: response.data
      });
    } catch (error: unknown) {
      return dispatch(errorDispatch.displayError(error as Error));
    }
  };
}
