export interface SubAccount {
  id: string;
  name: string;
  nickname?: string;
}

export interface SubAccounts {
  subscribed: SubAccount[];
  unsubscribed: SubAccount[];
}

export interface SubAccountState {
  subscribed: SubAccount[];
  unsubscribed: SubAccount[];
  state: 'init' | 'loading' | 'loaded' | 'pending';
}

export enum SubAccountActionTypes {
  GET_SUB_ACCOUNTS_REQUEST = 'esmiley/sub-accounts/GET_SUB_ACCOUNTS_REQUEST',
  GET_SUB_ACCOUNTS = 'esmiley/sub-accounts/GET_SUB_ACCOUNTS',
  UPDATE_SUB_ACCOUNTS_REQUEST = 'esmiley/sub-accounts/UPDATE_SUB_ACCOUNTS_REQUEST',
  UPDATE_SUB_ACCOUNTS = 'esmiley/sub-accounts/UPDATE_SUB_ACCOUNTS'
}

type GetSubAccountsRequestAction = { type: SubAccountActionTypes.GET_SUB_ACCOUNTS_REQUEST };
type GetSubAccountsAction = {
  type: typeof SubAccountActionTypes.GET_SUB_ACCOUNTS;
  payload: SubAccounts;
};

type UpdateSubAccountsRequestAction = { type: SubAccountActionTypes.UPDATE_SUB_ACCOUNTS_REQUEST };
type UpdateSubAccountsAction = {
  type: typeof SubAccountActionTypes.UPDATE_SUB_ACCOUNTS;
  payload: SubAccounts;
};

export type SubAccountActions =
  | GetSubAccountsRequestAction
  | GetSubAccountsAction
  | UpdateSubAccountsRequestAction
  | UpdateSubAccountsAction;
