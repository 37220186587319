import * as React from 'react';

import {
  Box,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Divider,
  Button,
  Link,
  Grid,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { COUNTRIES } from 'utils/countries';
import * as palette from 'styles/palette';
import { SignUpData } from 'redux/ducks/signup/types';
import { onSubmitForm } from 'utils/helpers';
import { FormEvent } from 'react';
import { eSmileyBlue } from 'styles/palette';
import LanguageSwitcher from 'components/LanguageSwitcher';
import { ThunkDispatch } from 'redux-thunk';
import { UiActions } from 'redux/ducks/ui';
import { changeLocale } from 'redux/ducks/settings';
import { connect } from 'react-redux';
import { RootState } from 'redux/rootReducer';
import { useIntl } from 'react-intl';

interface SignupPageProps {
  onStepChange: React.Dispatch<React.SetStateAction<number>>;
  data: SignUpData;
  onChange: (value: string | boolean, target: string) => void;
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
export type OwnProps = SignupPageProps & DispatchProps;

const useStyles = makeStyles((theme) => {
  return {
    input: {
      border: '1px solid ' + theme.palette.grey.A100,
      borderRadius: '6px',
      padding: '6px'
    },
    form: {
      display: 'flex',
      flexDirection: 'column'
    },
    inputField: {
      margin: '8px 0'
    },
    label: { ...theme.typography.h3, fontWeight: theme.typography.fontWeightBold },
    headerLabel: {
      color: theme.palette.background.default,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: '30px'
    },
    signInLabel: {
      ...theme.typography.subtitle2,
      fontWeight: theme.typography.fontWeightLight,
      display: 'flex',
      alignItems: 'center',
      margin: '12px 0 0 0'
    },
    formContainer: {
      width: '90%',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '6px',
      padding: '12px 10px',
      height: '550px',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      maxWidth: '400px',
      marginBottom: '20px'
    },
    container: {
      background: 'linear-gradient(180deg, rgba(2,93,136,1) 0%, rgba(0,130,197,1) 100%)',
      width: '100%',
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    logo: {
      width: '200px',
      margin: '18px 0 0 0'
    },
    divider: {
      margin: '0 0 12px 0',
      color: theme.palette.grey.A100
    },
    textLink: {
      ...theme.typography.subtitle2,
      margin: '0 0 0 3px',
      fontWeight: theme.typography.fontWeightBold,
      color: eSmileyBlue
    },
    button: {
      backgroundColor: palette.eSmileyBlue,
      color: theme.palette.background.paper,
      textTransform: 'capitalize',
      height: '60px',
      fontSize: '16px'
    },
    select: {
      color: theme.palette.text.secondary,
      border: '1px solid ' + theme.palette.grey.A100,
      borderRadius: '6px',
      padding: '6px'
    },
    languageSwitcher: {
      width: '46px',
      '& svg': {
        fill: theme.palette.text.secondary
      }
    },
    checkbox: {
      color: theme.palette.text.secondary,
      '& .MuiFormControlLabel-label': {
        color: theme.palette.text.secondary
      }
    }
  };
});

const SignUpStepOne: React.FunctionComponent<OwnProps> = (props) => {
  const intl = useIntl();
  const classes = useStyles(props);
  const { onStepChange, onChange, data, changeLocale } = props;
  const { country, company, newsletter, firstName, lastName } = data;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onStepChange(2);
  };

  return (
    <Box
      className={classes.formContainer}
      component='form'
      onSubmit={onSubmitForm((e) => handleSubmit(e))}
    >
      <div>
        <Grid container justify='space-between' direction='row' alignItems={'center'}>
          <Typography className={classes.headerLabel} variant='body1' component='h1'>
            {intl.formatMessage({ id: 'auth.sign_up' })}
          </Typography>
          <LanguageSwitcher
            compact
            size='small'
            className={classes.languageSwitcher}
            onChange={changeLocale}
          />
        </Grid>

        <div className={classes.form}>
          <div className={classes.inputField}>
            <InputLabel className={classes.label} shrink>
              {intl.formatMessage({ id: 'base.firstName' })}
            </InputLabel>
            <TextField
              fullWidth
              required
              className={classes.input}
              placeholder={intl.formatMessage({ id: 'base.firstName' })}
              value={firstName}
              onChange={(event) => onChange(event.target.value, 'firstName')}
              focused
              InputProps={{ disableUnderline: true }}
            />
          </div>
          <div className={classes.inputField}>
            <InputLabel className={classes.label} shrink>
              {intl.formatMessage({ id: 'base.lastName' })}
            </InputLabel>
            <TextField
              fullWidth
              required
              className={classes.input}
              placeholder={intl.formatMessage({ id: 'base.lastName' })}
              value={lastName}
              onChange={(event) => onChange(event.target.value, 'lastName')}
              focused
              InputProps={{ disableUnderline: true }}
            />
          </div>
          <div className={classes.inputField}>
            <InputLabel className={classes.label} shrink>
              {intl.formatMessage({ id: 'company.name' })}
            </InputLabel>
            <TextField
              fullWidth
              required
              className={classes.input}
              placeholder={intl.formatMessage({ id: 'company.name' })}
              value={company}
              onChange={(event) => onChange(event.target.value, 'company')}
              focused
              InputProps={{ disableUnderline: true }}
            />
          </div>
          <div className={classes.inputField}>
            <InputLabel className={classes.label} shrink>
              {intl.formatMessage({ id: 'base.country' })}
            </InputLabel>
            <Select
              required
              fullWidth
              value={country}
              disableUnderline
              className={classes.select}
              onChange={(event) => onChange(event.target.value as string, 'country')}
            >
              {COUNTRIES.map((element, index) => (
                <MenuItem
                  value={element.code}
                  key={`country_${index}`}
                  selected={element.code === country}
                >
                  {element.label}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            className={classes.checkbox}
            checked={newsletter}
            color='default'
            onChange={() => onChange(!newsletter, 'newsletter')}
          />
        }
        label={intl.formatMessage({ id: 'signup.newsletter' })}
      />

      <Divider className={classes.divider} />

      <Button type='submit' fullWidth variant='contained' className={classes.button}>
        {intl.formatMessage({ id: 'base.continue' })}
      </Button>

      <div className={classes.signInLabel}>
        <Typography variant='body1' component='p'>
          {intl.formatMessage({ id: 'sign_up.content.have_account' })}
        </Typography>
        <Link href='/' className={classes.textLink} underline='none'>
          {intl.formatMessage({ id: 'sign_in.action' })}
        </Link>
      </div>
    </Box>
  );
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, unknown, UiActions>) => ({
  changeLocale: (locale: string) => dispatch(changeLocale(locale))
});

export default connect<unknown, DispatchProps, SignupPageProps>(
  null,
  mapDispatchToProps
)(SignUpStepOne);
