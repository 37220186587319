import enJson from 'i18n/en.json';
import daJson from 'i18n/da.json';
import svJson from 'i18n/sv.json';
import nbJson from 'i18n/nb.json';
import deJson from 'i18n/de.json';
import isJson from 'i18n/is.json';
import fiJson from 'i18n/fi.json';

export default {
  en: enJson,
  da: daJson,
  sv: svJson,
  nb: nbJson,
  de: deJson,
  is: isJson,
  fi: fiJson
};
