import * as React from 'react';
import { Link, IndexLink, LinkProps } from 'react-router';
import { IconButton, ListItem, ListItemIcon, MenuItem, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SidebarMenu } from 'styles/themes/global';
import classNames from 'classnames';
import { HelpOutline } from '@material-ui/icons';
import { useCombineRefs } from 'hooks/useCombineRefs';
import { Popper } from 'components/Popper';

export interface MenuLinkItemProps {
  icon?: JSX.Element;
  text?: string;
  isActive?: boolean;
  onClick?: (event: React.MouseEvent) => void;
  hideText?: boolean;
  disabled?: boolean;
  asListItem?: boolean;
  helper?: { title: React.ReactNode; content: React.ReactNode };
}

export interface MenuLinkProps extends MenuLinkItemProps {
  path?: string;
  href?: string;
  target?: string;
}

const ExtLink: React.FunctionComponent<LinkProps> = (props) => {
  const { to, children, ...rest } = props;
  return (
    <a {...rest} href={to as string}>
      {children}
    </a>
  );
};

const DisabledLink: React.FunctionComponent<LinkProps> = (props) => {
  const { children } = props;
  return (
    <a role='link' aria-disabled='true'>
      {children}
    </a>
  );
};

const MenuLinkItem = React.forwardRef<HTMLElement, MenuLinkItemProps>((props, ref) => {
  const classes = useStyles(props);
  const [helperRef, setHelperRef] = React.useState<HTMLElement>(null);
  const [helpOpen, setHelpOpen] = React.useState(false);
  const { icon, text, isActive, onClick, hideText = false, disabled, helper, asListItem } = props;
  const Element = asListItem ? ListItem : MenuItem;
  const refs = useCombineRefs(ref, setHelperRef);

  const handleHelperClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setHelpOpen((prev) => !prev);
  };

  return (
    <Element
      disabled={disabled}
      /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
      /* @ts-ignore */
      ref={refs as React.RefObject<HTMLLIElement>}
      className={classNames(classes.menuItem, { [classes.isActive]: isActive })}
      onClick={onClick}
    >
      {icon && (
        <ListItemIcon
          className={classNames(classes.menuItemIcon, { [classes.isActive]: isActive })}
        >
          {icon}
        </ListItemIcon>
      )}
      <Typography className={classNames(classes.text, { [classes.textHidden]: hideText })}>
        {text}
      </Typography>
      {helper ? (
        <>
          <IconButton onClick={handleHelperClick}>
            <HelpOutline />
          </IconButton>
          <Popper
            {...helper}
            open={helpOpen}
            onClose={() => setHelpOpen(false)}
            anchorEl={helperRef}
            modifiers={{
              arrow: { enabled: false }, // couldnt figure out why arrow not pointing correctly
              preventOverflow: { enabled: true, offset: '0,10', boundariesElement: 'viewport' }
            }}
          />
        </>
      ) : null}
    </Element>
  );
});

export const MenuLink = React.forwardRef<HTMLElement, MenuLinkProps>((props, ref) => {
  const classes = useStyles(props);
  const { path, href, target, ...restProps } = props;

  if (!path && !href) {
    return <MenuLinkItem {...restProps} asListItem ref={ref} />;
  }

  const LinkComponent = restProps.disabled
    ? DisabledLink
    : href
    ? ExtLink
    : path === '/'
    ? IndexLink
    : Link;
  const to = href || path;

  // due to outdated react-router, we cant use refs properly on Link
  return (
    <LinkComponent
      className={classNames(classes.link)}
      to={to}
      title={restProps.text}
      target={target}
    >
      <MenuLinkItem {...restProps} ref={ref} />
    </LinkComponent>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
    position: 'relative'
  },
  iconOnlyMenuItem: {},
  menuItem: {
    padding: `${theme.spacing(1.5)}px 0`,
    width: '100%'
  },
  text: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold'
  },
  isActive: {
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main
  },
  iconOnlyIcon: {
    minWidth: 'auto'
  },
  menuItemIcon: {
    width: SidebarMenu.collapsedWith,
    justifyContent: 'center'
  },
  textHidden: {
    display: 'none'
  }
}));
