import { ApiError, ErrorActions, ErrorActionTypes, ErrorState } from 'redux/ducks/error/types';
import { AxiosError } from 'axios';

export * from 'redux/ducks/error/types';
export const NETWORK_ERROR_CODE = 'CNE';

export const initialState: ErrorState = {
  code: null,
  message: null,
  active: false
};

export default function reducer(
  state: ErrorState = initialState,
  action: ErrorActions
): ErrorState {
  switch (action.type) {
    case ErrorActionTypes.SHOW_ERROR: {
      const { message, code } = action.payload;
      return {
        code,
        message,
        active: true
      };
    }
    case ErrorActionTypes.CLOSE_ERROR:
      return initialState;

    default:
      return state;
  }
}

export function displayError(error: Error): ErrorActions {
  const apiError = error as AxiosError<ApiError>;

  // api error
  if (apiError.isAxiosError) {
    // api returned an error
    if (apiError.response) {
      const {
        data: { errorCode, message },
        status
      } = apiError.response;

      return {
        type: ErrorActionTypes.SHOW_ERROR,
        payload: { code: errorCode || status, message }
      };
    }

    // request want made but no response => network error
    if (apiError.request) {
      return {
        type: ErrorActionTypes.SHOW_ERROR,
        payload: { code: NETWORK_ERROR_CODE, message: error.message }
      };
    }
  }

  return {
    type: ErrorActionTypes.SHOW_ERROR,
    payload: { message: error.message }
  };
}

export function closeError(): ErrorActions {
  return {
    type: ErrorActionTypes.CLOSE_ERROR
  };
}
