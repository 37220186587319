import { compare } from 'fast-json-patch';
import {
  CreateReason,
  ReasonQuery,
  Reason,
  ReasonState,
  ReasonActions
} from 'redux/ducks/reasons/types';
import { ErrorActions, displayError } from 'redux/ducks/error';
import { AxiosResponse } from 'axios';
import { DataStorage, DataTransfer } from 'frontend-core';
import { ThunkResult } from 'redux/types';

export * from './types';

const transfer = new DataTransfer({ retryConfig: { retries: 3 } });
const store = new DataStorage();

const reasonEndpoints = {
  collection: '/foodwaste/reasons',
  resource: (id: string) => `/foodwaste/reasons/${id}`
};

const initialState: ReasonState = {
  reasons: [],
  loading: false
};

export enum ReasonActionTypes {
  GET_REASON_SUCCESS = 'esmiley/data/reasons/GET_REASON_SUCCESS',
  FIND_REASON_SUCCESS = 'esmiley/data/reasons/FIND_REASON_SUCCESS',
  UPDATE_REASON_SUCCESS = 'esmiley/data/reasons/UPDATE_REASON_SUCCESS',
  CREATE_REASON_SUCCESS = 'esmiley/data/reasons/CREATE_REASON_SUCCESS',
  REMOVE_REASON_SUCCESS = 'esmiley/data/reasons/REMOVE_REASON_SUCCESS',
  REASON_REQUEST = 'esmiley/data/reasons/REASON_REQUEST'
}

function reducer(state: ReasonState = initialState, action: ReasonActions): ReasonState {
  switch (action.type) {
    case ReasonActionTypes.REASON_REQUEST: {
      return { ...state, loading: true };
    }
    case ReasonActionTypes.FIND_REASON_SUCCESS: {
      const reasons: Reason[] = action.payload;
      return { ...state, reasons, loading: false };
    }
    case ReasonActionTypes.GET_REASON_SUCCESS: {
      const reason: Reason = action.payload;
      const reasons = [...state.reasons, reason];
      return { ...state, reasons, loading: false };
    }
    case ReasonActionTypes.UPDATE_REASON_SUCCESS: {
      const reason: Reason = action.payload;
      const reasons: Reason[] = state.reasons.map((oldReason) =>
        oldReason.id === reason.id ? reason : oldReason
      );
      return { ...state, reasons, loading: false };
    }
    case ReasonActionTypes.CREATE_REASON_SUCCESS: {
      const reason: Reason = action.payload;
      const reasons = [...state.reasons, reason];
      return { ...state, reasons, loading: false };
    }
    case ReasonActionTypes.REMOVE_REASON_SUCCESS: {
      const { id } = action.payload;
      const reasons = state.reasons.filter((reason) => reason.id !== id);
      return { ...state, reasons, loading: false };
    }
    default: {
      return state;
    }
  }
}

export function create(
  reason: CreateReason
): ThunkResult<Promise<ReasonActions | ErrorActions>, ReasonActions | ErrorActions> {
  return async (dispatch) => {
    const token = store.getData('token') as string;
    // eslint-disable-next-line
    transfer.library.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    dispatch({
      type: ReasonActionTypes.REASON_REQUEST
    });

    try {
      const response = (await transfer.post(
        reasonEndpoints.collection,
        reason
      )) as AxiosResponse<Reason>;
      return dispatch({
        type: ReasonActionTypes.CREATE_REASON_SUCCESS,
        payload: response.data
      });
    } catch (error: unknown) {
      return dispatch(displayError(error as Error));
    }
  };
}

export function update(
  reason: Reason
): ThunkResult<Promise<ReasonActions | ErrorActions>, ReasonActions | ErrorActions> {
  return async (dispatch, getState) => {
    const token = store.getData('token') as string;
    // eslint-disable-next-line
    transfer.library.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    const original = getState().reasons.reasons.find(({ id }) => id === reason.id);
    const update = { ...original, ...reason };
    const patchOps = compare(original, update);

    dispatch({
      type: ReasonActionTypes.REASON_REQUEST
    });

    try {
      const response = (await transfer.patch(
        reasonEndpoints.resource(reason.id),
        patchOps
      )) as AxiosResponse<Reason>;
      return dispatch({
        type: ReasonActionTypes.UPDATE_REASON_SUCCESS,
        payload: response.data
      });
    } catch (error: unknown) {
      return dispatch(displayError(error as Error));
    }
  };
}

export function deleteById(
  id: string
): ThunkResult<Promise<ReasonActions | ErrorActions>, ReasonActions | ErrorActions> {
  return async (dispatch) => {
    const token = store.getData('token') as string;
    // eslint-disable-next-line
    transfer.library.defaults.headers.common['Authorization'] = `Bearer ${token}`;

    dispatch({
      type: ReasonActionTypes.REASON_REQUEST
    });

    try {
      const response = (await transfer.delete(
        reasonEndpoints.resource(id)
      )) as AxiosResponse<Reason>;
      return dispatch({
        type: ReasonActionTypes.REMOVE_REASON_SUCCESS,
        payload: response.data
      });
    } catch (error: unknown) {
      return dispatch(displayError(error as Error));
    }
  };
}

export function getById(
  id: string
): ThunkResult<Promise<ReasonActions | ErrorActions>, ReasonActions | ErrorActions> {
  return async (dispatch) => {
    dispatch({
      type: ReasonActionTypes.REASON_REQUEST
    });

    try {
      const response = (await transfer.get(
        reasonEndpoints.resource(id),
        {},
        true
      )) as AxiosResponse<Reason>;
      return dispatch({
        type: ReasonActionTypes.GET_REASON_SUCCESS,
        payload: response.data
      });
    } catch (error: unknown) {
      return dispatch(displayError(error as Error));
    }
  };
}

export function getAll(
  query: ReasonQuery = null
): ThunkResult<Promise<ReasonActions | ErrorActions>, ReasonActions | ErrorActions> {
  return async (dispatch) => {
    dispatch({
      type: ReasonActionTypes.REASON_REQUEST
    });
    try {
      const response = (await transfer.get(
        reasonEndpoints.collection,
        {
          params: query
        },
        true
      )) as AxiosResponse<Reason[]>;

      return dispatch({
        type: ReasonActionTypes.FIND_REASON_SUCCESS,
        payload: response.data
      });
    } catch (error: unknown) {
      return dispatch(displayError(error as Error));
    }
  };
}

export default reducer;
